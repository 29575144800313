import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const StyledContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

export const FileProgressSpinner = () => {
  return (
    <StyledContentCenter>
      <CircularProgress />
    </StyledContentCenter>
  );
};
