/* eslint-disable react/jsx-no-useless-fragment */
import styled from '@emotion/styled';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { convertToReadableDate, nameShortener } from '@/utils';
import { FileProgressSpinner } from '@/components/common';
import { EyeIcon, TrashIcon } from '@/components/icon';
import { IFileAttachment } from '@/interfaces/IFileAttachments';
import {
  IHandleDeleteAttachment,
  IHandleGetAttachment,
} from './OpenAttachmentDialog/OpenAttachmentDialog';
import { IFileResponse } from './types';

export interface AttachmentFileViewerProps {
  onSelectedFileAndOpen?: (selectedFile: IFileResponse) => void;
  selectedRowIndexes?: number[];
  selectedRowIndex?: number;
  fileAttachments: IFileAttachment[] | undefined;
  deleteAttachment: (deleteFiles: IHandleDeleteAttachment[], attachmentIds: string[]) => void;
  getAttachment: (body: IHandleGetAttachment[]) => void;
  deleting: boolean;
  getting: boolean;
}

interface IDeleteAttachment extends IFileAttachment {
  index: number;
}

const StyledListItem = styled('div')`
  & .MuiListItem-root {
    border-bottom: 1px solid ${({ theme }) => (theme as Theme).palette.secondary.main};
  };

  & .MuiListItemText-root {
    display: flex;
    justify-content: space-between;
  };

  .MuiListItem-root {
    cursor: pointer;
    padding-left: 0px;
    max-height: 6vh;
  };

  .MuiButton-root {
    min-width: 0px;
  };

  display: flex;
  flex-direction: column;
`;

const StyledDialogActionTopBarIcons = styled.div`
  color: white;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledFileActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5rem;
`;

const StyledContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

const StyledButton = styled.div`
  display: flex;
`;

const Separator = styled.div`
  margin-top: 0.5rem;
  border-top: 1px solid #d8d9e0;
  margin-bottom: 0rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minWidth: 460,
  },
}));

export function AttachmentFileViewer({
  fileAttachments,
  selectedRowIndexes,
  deleteAttachment,
  getAttachment,
  deleting,
  getting,
}: AttachmentFileViewerProps) {
  const classes = useStyles();
  const [fileAttachmentsCount, setFileAttachmentsCount] = useState<boolean>(false);
  const theme = useTheme(); //Main theme
  const [selectedAttachments, setSelectedAttachments] = useState<IDeleteAttachment[]>([]);

  useEffect(() => {
    if (fileAttachments) {
      if (fileAttachments.length > 0) {
        setFileAttachmentsCount(true);
      } else {
        setFileAttachmentsCount(false);
      }
    }
  }, [selectedRowIndexes, fileAttachments]);

  const checkAllCheckBoxes = () => {
    const allAttachments: Array<IDeleteAttachment> = [];

    if (fileAttachments) {
      fileAttachments.forEach((file, index) => {
        allAttachments.push({ ...file, index });
      });

      if (selectedAttachments.length === fileAttachments.length) {
        setSelectedAttachments([]);
      } else {
        setSelectedAttachments(allAttachments);
      }
    }
  };

  const handleCheckBoxUpdate = (attachment: IDeleteAttachment) => {
    if (selectedAttachments.some((attach) => attach.index === attachment.index)) {
      const update = selectedAttachments.filter((attach) => attach.index !== attachment.index);
      setSelectedAttachments(update);
    } else {
      setSelectedAttachments([...selectedAttachments, attachment]);
    }
  };

  const getExtensionAndFileName = (fileNameWithExtension: string) => {
    const filename = fileNameWithExtension;
    const extensionIndex = filename.lastIndexOf('.');
    const name = filename.substring(0, extensionIndex);
    const extension = filename.substring(extensionIndex + 1);

    return { name, extension };
  };

  const handleGetAttachment = async () => {
    const getAttachmentBody: Array<IHandleGetAttachment> = [];

    for (const attachment of selectedAttachments) {
      getAttachmentBody.push({ getFileName: attachment.name });
    }
    getAttachment(getAttachmentBody);
  };

  const deleteAttachments = () => {
    if (selectedAttachments) {
      const allSelectedAttachments: Array<IHandleDeleteAttachment> = [];
      const attachmentIds: Array<string> = [];

      for (const attachment of selectedAttachments) {
        const { extension } = getExtensionAndFileName(attachment.name);
        allSelectedAttachments.push({ deleteFileName: attachment.name, contentType: extension });
        attachmentIds.push(attachment.id);
      }

      deleteAttachment(allSelectedAttachments, attachmentIds);
    }
    setSelectedAttachments([]);
  };

  const renderDataSheetList = () => {
    if (!fileAttachments) {
      return <StyledContentCenter>No results found</StyledContentCenter>;
    }
    return (
      <>
        {fileAttachmentsCount ? (
          <>
            {fileAttachments.map((file, index) => {
              const nameToBeShortened = file.displayName || file.name;
              const fileName = nameShortener(nameToBeShortened);

              return (
                <ListItem key={file.id} disableGutters={true}>
                  <ListItemButton role={undefined} dense>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      checked={selectedAttachments.some((attachment) => attachment.index === index)}
                      onChange={() => handleCheckBoxUpdate({ ...file, index })}
                    />
                    <ListItemText
                      primary={fileName}
                      secondary={convertToReadableDate(file.lastModified)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </>
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            No Attachments found
          </Typography>
        )}
      </>
    );
  };
  return (
    <StyledListItem theme={theme}>
      <StyledFileActionBar>
        <StyledTitle>
          <Checkbox
            indeterminate={
              selectedAttachments.length > 0 &&
              selectedAttachments.length !== fileAttachments?.length
            }
            onChange={checkAllCheckBoxes}
            checked={
              selectedAttachments.length === fileAttachments?.length &&
              selectedAttachments.length > 0
            }
          />
          <strong>File Name</strong>
          <StyledDialogActionTopBarIcons></StyledDialogActionTopBarIcons>
        </StyledTitle>

        <StyledButton>
          <ButtonContainer>
            <EyeIcon style={{ color: 'white' }} />
            <Button
              variant="text"
              onClick={handleGetAttachment}
              disabled={selectedAttachments.length !== 1 || getting}
            >
              View
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <TrashIcon style={{ color: 'white' }} />
            <Button
              variant="text"
              onClick={deleteAttachments}
              disabled={selectedAttachments.length < 1 || deleting}
            >
              Delete
            </Button>
          </ButtonContainer>
        </StyledButton>
      </StyledFileActionBar>
      <Separator />
      <List className={classes.root}>
        {deleting ? <FileProgressSpinner /> : renderDataSheetList()}
      </List>
    </StyledListItem>
  );
}

export default AttachmentFileViewer;
