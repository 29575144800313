import { atom } from 'recoil';

interface IUserDetailAtom {
  mobileNumber: string;
  countryCode: string;
  mobileOnly: string;
  isValid: boolean;
  isError: boolean;
  loading: boolean;
  locale: string;
  preMobileNumber: string;
}

interface IUserChangePasswordAtom {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  isValid: boolean;
  isError: boolean;
  loading: boolean;
  oldPasswordNetworkError: string;
}

export const UserDetailAtom = atom<IUserDetailAtom>({
  key: 'userDetailAtom',
  default: {
    mobileNumber: '',
    countryCode: '',
    mobileOnly: '',
    isValid: false,
    isError: false,
    loading: false,
    locale: '',
    preMobileNumber: '',
  },
});

export const UserChangePasswordAtom = atom<IUserChangePasswordAtom>({
  key: 'userChangePasswordAtom',
  default: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isValid: false,
    isError: false,
    loading: false,
    oldPasswordNetworkError: '',
  },
});
