import { Button, Stack, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { InputTextField } from '@/components/common';
import { HeaderTitle } from './HeaderTitle';
import { type ApiConnectionFormState } from './hooks/useApiConnectionForm';

interface ApiConnectionFormProps {
  isDpfConnected: boolean;
  onSubmit: (data: ApiConnectionFormState) => void;
}

export const ApiConnectionForm = ({ isDpfConnected, onSubmit }: ApiConnectionFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<ApiConnectionFormState>();

  return (
    <Stack direction="column" width={'100%'}>
      <HeaderTitle name="API Connection To Eclipse" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="apiUrl"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputTextField
              labelText="API URL"
              type="text"
              onChange={onChange}
              value={value}
              error={error?.message}
            />
          )}
        />

        <Controller
          name="redirectUrl"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputTextField
              labelText="Redirect URL"
              type="text"
              onChange={onChange}
              value={value}
              error={error?.message}
            />
          )}
        />

        <Button
          type="submit"
          disabled={isDpfConnected || !isValid}
          sx={{
            boxShadow: 'none',
            maxWidth: '80px',
            height: '35px',
            fontSize: 'small',
            fontWeight: 400,
          }}
          variant="contained"
        >
          Connect
        </Button>
      </form>
      <Typography sx={{ pt: 2 }} variant="body2" color="text.secondary">
        Connection status:
        <span style={{ color: isDpfConnected ? 'green' : 'red' }}>
          {isDpfConnected ? ' Connected' : ' Disconnected'}
        </span>
      </Typography>
    </Stack>
  );
};
