export enum UnitDescriptionTypes {
  ID = 'id',
  CATEGORY = 'category',
  CODE = 'code',
  DESCRIPTION = 'description',
  UNITS = 'units',
  USAGE = 'usage',
  ATTACHMENTS = 'attachments',
  NOTES = 'notes',
}

export interface IUnitDescriptionData {
  id: string;
  category: string;
  code: string;
  description: string;
  units: string;
  usage: string;
  attachments: string;
  notes: string;
}
