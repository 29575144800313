import {
  CostsToDateColumnIndexes,
  SetColumnIndexes,
  LocationColumnIndexes,
  CurrencyColumnIndexes,
  UnitDescriptionColumnIndexes,
  FringesColumnIndexes,
  GroupColumnIndexes,
  GlobalColumnIndexes,
  SheetNames,
} from '@/enums';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IFileAttachment } from '@/interfaces/IFileAttachments';
import FormulaSheet from '@/sheets/FormulaSheet';

function handleDeleteAttachmentsFromCTD(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.costsToDate[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.costsToDate[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.COSTS_TO_DATE,
                selectedRowIndex,
                CostsToDateColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromSets(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.sets[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.sets[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.SETS,
                selectedRowIndex,
                SetColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromLocations(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.locations[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.locations[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.LOCATIONS,
                selectedRowIndex,
                LocationColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromCurrency(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.currency[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.currency[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.CURRENCY,
                selectedRowIndex,
                CurrencyColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromUnitDescription(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.unitDesc[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.unitDesc[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.UNIT_DESCRIPTIONS,
                selectedRowIndex,
                UnitDescriptionColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromFringes(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.fringes[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.fringes[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.FRINGES,
                selectedRowIndex,
                FringesColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromGroups(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.groups[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.groups[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.GROUPS,
                selectedRowIndex,
                GroupColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}
function handleDeleteAttachmentsFromGlobals(
  currentDataSheetCopy: IDataSheet,
  attachmentIds: string[],
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.globals[selectedRowIndex].id;
      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const currentRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          let update: IFileAttachment[] = [];
          currentRow.forEach((attachment, index) => {
            if (attachmentIds.includes(attachment.id)) {
              currentDataSheetCopy.fileAttachments[rowId].splice(index, 1);
              update = currentRow.filter((attach) => !attachmentIds.includes(attach.id));
              currentDataSheetCopy.fileAttachments[rowId] = update;
              const count = currentDataSheetCopy.fileAttachments[rowId].length;
              currentDataSheetCopy.globals[selectedRowIndex].attachments = count.toString();
              formulaSheet?.setSheetContent(
                SheetNames.GLOBALS,
                selectedRowIndex,
                GlobalColumnIndexes.attachments,
                count,
              );
            }
          });
        }
      }
    });
  }
}

export {
  handleDeleteAttachmentsFromCTD,
  handleDeleteAttachmentsFromSets,
  handleDeleteAttachmentsFromLocations,
  handleDeleteAttachmentsFromCurrency,
  handleDeleteAttachmentsFromUnitDescription,
  handleDeleteAttachmentsFromFringes,
  handleDeleteAttachmentsFromGroups,
  handleDeleteAttachmentsFromGlobals,
};
