import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CloseSquareIcon } from '@/components/icon';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  headerList: string[];
  setSelectedColumn: (list: number[]) => void;
  getSelectedColumn: number[];
}

export function ShowHideDialog({
  open,
  setOpen,
  headerList,
  setSelectedColumn,
  getSelectedColumn,
}: Props) {
  const [checkList, setCheckList] = useState<string[]>([]);
  const [itemNumberList, setItemNumberList] = useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const list = headerList.map((header, index) => index.toString());

    setItemNumberList(list);
    setCheckList(list);

    if (getSelectedColumn.length !== 0) {
      setCheckList(list.filter((i, r) => !getSelectedColumn.some((s) => s === r)));
    }
  }, [headerList]);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: string[] = [...checkList];
    if (event.target.checked) {
      updatedList = [...checkList, event.target.value];
    } else {
      updatedList.splice(checkList.indexOf(event.target.value), 1);
    }
    setSelectedColumn(
      itemNumberList.filter((i) => !updatedList.some((s) => s === i)).map((t) => Number(t)),
    );
    setCheckList(updatedList);
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckList(itemNumberList);
      setSelectedColumn([]);
    } else {
      setSelectedColumn(itemNumberList.map((t) => Number(t)));
      setCheckList([]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      style={{
        maxHeight: '900px',
        overflowX: 'hidden',
        position: 'fixed',
      }}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'right',
          '& .MuiPaper-root': {
            width: '50%',
            maxWidth: '310px',
          },
        },
      }}
    >
      <div
        style={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: '400',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
          {'Columns'}
        </DialogTitle>
        <CloseSquareIcon
          fontSize="medium"
          onClick={() => setOpen(false)}
          sx={{ cursor: 'pointer', color: 'black', stroke: 'white' }}
        />
      </div>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ fontSize: '14px', justifyContent: 'flex-start' }}
        >
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                key={`action-details-options`}
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleCheckAll}
                    checked={checkList.length === itemNumberList.length}
                  />
                }
                label={<Typography sx={{ fontSize: 14 }}>{'Show All'}</Typography>}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Divider
          sx={{
            height: '2px',
            backgroundColor: '#F1F3F4',
            marginBottom: '15px',
            borderColor: '#F1F3F4',
          }}
        />
        <Grid
          container
          justifyContent="center"
          style={{ fontSize: '14px', justifyContent: 'flex-start' }}
        >
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              {headerList &&
                headerList.map(
                  (option, key) =>
                    // Check if option is not an empty string
                    option !== '' && (
                      <FormControlLabel
                        key={`action-details-options-${option}`}
                        value={key}
                        control={
                          <Switch
                            color="primary"
                            onChange={handleCheck}
                            checked={checkList.includes(key.toString())}
                          />
                        }
                        label={<Typography sx={{ fontSize: 14 }}>{option}</Typography>}
                        labelPlacement="end"
                      />
                    ),
                )}
            </FormGroup>
          </FormControl>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
