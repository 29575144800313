import { Avatar, Backdrop, Box, Button, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { getSession } from '@/utils';
import { openFileDialogAtom } from '@/atoms/GlobalAtoms';
import {
  Wrapper,
  MainPanel,
  HeaderPanelContainer,
  ButtonContainer,
  FooterPanel,
  HeaderPanel,
} from './WelcomeView.styled';

interface UserState {
  loading: boolean;
  username: string | null;
}

function WelcomeText() {
  const [user, setUser] = useState<UserState>({ loading: true, username: null });

  useEffect(() => {
    async function fetchUserData() {
      try {
        const session = await getSession();
        setUser({ loading: false, username: session ? session.email : null });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setUser({ loading: false, username: null });
      }
    }

    fetchUserData();
  }, []);

  return (
    <Box sx={{ paddingInline: '10px', display: 'flex', alignItems: 'center' }}>
      {user.loading ? (
        <>
          <Skeleton variant="circular" width={40} height={40} sx={{ marginInline: '5px' }} />
          <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
        </>
      ) : (
        <>
          <Avatar sx={{ marginInline: '5px' }}>{user?.username?.charAt(0).toUpperCase()}</Avatar>
          <Typography variant="body1" component="span">
            {user.username}
          </Typography>
        </>
      )}
    </Box>
  );
}

const WelcomeView = () => {
  const { onLogout } = useOutletContext<{ onLogout?: () => void }>();
  const setOpenFileOpen = useSetRecoilState(openFileDialogAtom);

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <Wrapper>
        <HeaderPanel>
          <HeaderPanelContainer>
            <WelcomeText />
            <Button variant="text" color="primary" onClick={onLogout}>
              Logout
            </Button>
          </HeaderPanelContainer>
        </HeaderPanel>
        <MainPanel>
          <Typography
            variant="body1"
            component="div"
            sx={{
              padding: 2,
            }}
          >
            Click the button below to access your files.
          </Typography>
          <ButtonContainer>
            <Button variant="contained" color="primary" onClick={() => setOpenFileOpen(true)}>
              Open File
            </Button>
          </ButtonContainer>
        </MainPanel>
        <FooterPanel></FooterPanel>
      </Wrapper>
    </Backdrop>
  );
};

export default WelcomeView;
