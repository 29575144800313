import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { ReactElement } from 'react';

export const ReportIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M18.334 5v2.017c0 1.316-.834 2.15-2.15 2.15h-2.85V3.342c0-.925.758-1.675 1.683-1.675a3.35 3.35 0 012.341.975c.6.608.976 1.441.976 2.358z"
      />
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M1.667 5.833V17.5A.83.83 0 003 18.167L4.425 17.1a.84.84 0 011.1.083l1.383 1.392a.84.84 0 001.184 0l1.4-1.4a.826.826 0 011.083-.075L12 18.167a.835.835 0 001.333-.667V3.333c0-.916.75-1.666 1.667-1.666H5C2.5 1.667 1.667 3.158 1.667 5v.833z"
      />
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 7.5h5M5.625 10.833h3.75"
      />
    </SvgIcon>
  );
};
