export enum TopBarDocumentActionTypes {
  PRINT = 'Printer',
  SUBTOTAL = 'Sub-total',
  COPY = 'Copy',
  CUT = 'Cut',
  PASTE = 'Paste row',
  ADD = 'Add row',
  DELETE = 'Delete',
  MOVE_DOWN = 'Move down',
  MOVE_UP = 'Move up',
  IMPORT_CSV = 'Import CSV',
}
