import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Config } from '@/config';
import { AUTH_PATH } from '@/enums';
import { SUBSCRIPTION_EXPIRATION_THRESHOLD } from '@/app/constants/subscription';
import { rootFolder, subscriptionLevelAtom } from '@/atoms/GlobalAtoms';
import { useToaster } from '@/components/common';
import { getUserSubscription } from '@/services/UserApiService';

const TODAY = 0;
const ONE_DAY = 1;

const checkSubscriptionExpiration = (subscriptionExpirationDate: string) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const expiryDate = new Date(subscriptionExpirationDate).setHours(0, 0, 0, 0);
  const daysUntilExpiration = Math.floor((expiryDate - currentDate) / (1000 * 60 * 60 * 24));

  return {
    isExpired: daysUntilExpiration < 0,
    daysUntilExpiration: daysUntilExpiration >= 0 ? daysUntilExpiration : undefined,
  };
};

export const useSubscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const snackBar = useToaster();
  const userEmail = useRecoilValue(rootFolder);
  const [subscriptionLevel, setSubscriptionLevel] = useRecoilState(subscriptionLevelAtom);

  const getSubscriptionExpiryDate = async () => {
    if (!userEmail) return;

    const { trialExpiryDate, expiryDate, productLevel } = await getUserSubscription(userEmail);
    const trialStatus = checkSubscriptionExpiration(trialExpiryDate ?? '');
    const subscriptionStatus = checkSubscriptionExpiration(expiryDate ?? '');

    if (trialStatus.isExpired && subscriptionStatus.isExpired) {
      navigate(AUTH_PATH.subscriptionRenew);
    } else if (!trialStatus.isExpired && trialStatus.daysUntilExpiration) {
      handleExpiryCheck(trialExpiryDate ?? '', productLevel, true);
    } else if (!subscriptionStatus.isExpired) {
      handleExpiryCheck(expiryDate ?? '', productLevel, false);
    }
  };

  const handleExpiryCheck = (expiryDate: string, productLevel: string, isTrial: boolean) => {
    setSubscriptionLevel(productLevel);

    const { isExpired, daysUntilExpiration } = checkSubscriptionExpiration(expiryDate);
    handleNavigationBasedOnExpiration(isExpired, daysUntilExpiration, expiryDate, isTrial);
  };

  const handleNavigationBasedOnExpiration = (
    isExpired: boolean,
    daysUntilExpiration: number | undefined,
    expiryDate: string,
    isTrial: boolean,
  ) => {
    const isSubscriptionRenewPath = location.pathname === AUTH_PATH.subscriptionRenew;

    if (isExpired && !isSubscriptionRenewPath) {
      navigate(AUTH_PATH.subscriptionRenew);
      return;
    }

    if (!isExpired && isSubscriptionRenewPath) {
      navigate(AUTH_PATH.main);
      return;
    }

    if (
      daysUntilExpiration !== undefined &&
      daysUntilExpiration <= SUBSCRIPTION_EXPIRATION_THRESHOLD
    ) {
      const subscriptionType = isTrial ? 'trial' : 'subscription';
      const expiryPeriod =
        daysUntilExpiration === TODAY
          ? 'today'
          : `(${daysUntilExpiration} day${daysUntilExpiration === ONE_DAY ? '' : 's'} remaining)`;

      const formattedExpiryDate = moment(expiryDate, 'YYYY MM DD').format('MMMM Do, YYYY');

      const warningMessage = `Your ${subscriptionType} will expire on ${formattedExpiryDate} ${expiryPeriod}.`;

      snackBar.warning(warningMessage);
    }
  };

  useEffect(() => {
    if (!Config.subscription.enabled) return;

    getSubscriptionExpiryDate();
  }, [userEmail]);

  return { subscriptionLevel };
};
