import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';

const ApiConnectionFormSchema = yup
  .object({
    apiUrl: yup.string().url().required(),
    redirectUrl: yup.string().url().required(),
  })
  .required();

export type ApiConnectionFormState = yup.InferType<typeof ApiConnectionFormSchema>;

const DEFAULT_API_URL = 'https://auth-dev-main.tphglobal.com/';
const DEFAULT_REDIRECT_URL = 'https://dev.hollywoodbudgets.com/dpf-auth-success';

export const useApiConnectionForm = () => {
  const dataSheet = useRecoilValue(dataSheetAtom);
  return useForm<ApiConnectionFormState>({
    resolver: yupResolver(ApiConnectionFormSchema),
    defaultValues: {
      apiUrl: dataSheet?.meta.costTrackerOAuthApiUrl ?? DEFAULT_API_URL,
      redirectUrl: dataSheet?.meta.costTrackerOAuthRedirectUrl ?? DEFAULT_REDIRECT_URL,
    },
  });
};
