import { CellValue } from 'handsontable/common';
import lodash from 'lodash';
import { SheetNames } from '@/enums';
import { IDataSheet } from '@/interfaces/IDataSheet';

export function getDataSheetByName(sheets: Record<string, CellValue[][]>, sheetName: SheetNames) {
  if (lodash.isEmpty(sheets[sheetName])) {
    return [];
  }
  return sheets[sheetName];
}

const SHEETS_WITH_NAMED_EXPRESSIONS: Array<keyof IDataSheet> = [
  SheetNames.CURRENCY,
  SheetNames.UNIT_DESCRIPTIONS,
  SheetNames.GROUPS,
  SheetNames.GLOBALS,
  SheetNames.FRINGES,
  SheetNames.COSTS_TO_DATE,
];

function transformCodeToValidFormat(code: string | number): string {
  return code.toString().replace(/\s+/g, '.').toLowerCase();
}

export const isCodeAlreadyExist = (
  dataSheet: IDataSheet,
  sheetName: keyof IDataSheet,
  code: string | number,
): boolean => {
  const isNamedExpressionSheet = SHEETS_WITH_NAMED_EXPRESSIONS.includes(sheetName);
  if (isNamedExpressionSheet) {
    return isCodeAlreadyExistGlobally(dataSheet, code);
  }

  const transformedCode = transformCodeToValidFormat(code);
  const sheetData = dataSheet[sheetName as keyof IDataSheet];

  if (Array.isArray(sheetData)) {
    return sheetData.some(
      (item: { code?: string | number }) => item.code?.toString().toLowerCase() === transformedCode,
    );
  }

  return false;
};

const isCodeAlreadyExistGlobally = (dataSheet: IDataSheet, code: string | number): boolean => {
  const transformedCode = transformCodeToValidFormat(code);

  return SHEETS_WITH_NAMED_EXPRESSIONS.some((sheetName) => {
    const sheetData = dataSheet[sheetName];

    if (Array.isArray(sheetData)) {
      return sheetData.some(
        (item: { code?: string | number }) =>
          item.code?.toString().toLowerCase() === transformedCode,
      );
    }

    return false;
  });
};
