import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface ICloseIconButton {
  onClick: () => void;
  disabled: boolean;
}

export const CloseIconButton = ({ onClick, disabled }: ICloseIconButton) => {
  return (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};
