import { Box, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useField } from '@/hooks';
import { passwordValidator } from '@/utils';
import theme from '@/app/theme/base/theme';
import { UserChangePasswordAtom } from '@/atoms/UserAccountAtom';
import { AlertMessage, FormProgressBar, InputPasswordField } from '@/components/common';
import { checkPasswordStrength } from '@/helpers/checkPasswordStrength';

const confirmedPasswordValidator = (confirmedPassword: string, originalPassword: string) => {
  if (confirmedPassword === originalPassword) return true;
  return 'This must match with a new password';
};

export const ChangePassword = () => {
  const [oldPassword, setOldPassword, oldPasswordError] = useField<string>('', passwordValidator);
  const [newPassword, setNewPassword, newPasswordError] = useField<string>('', passwordValidator);
  const [confirmedPassword, setConfirmedPassword, confirmedPasswordError] = useField('', (value) =>
    confirmedPasswordValidator(value, newPassword),
  );
  const [changePassword, setChangePassword] = useRecoilState(UserChangePasswordAtom);

  useEffect(() => {
    setChangePassword({ ...changePassword, isValid: !newPasswordError && !confirmedPasswordError });
  }, [newPasswordError, confirmedPasswordError]);

  useEffect(() => {
    if (!newPasswordError && !confirmedPasswordError) {
      setChangePassword({
        ...changePassword,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmedPassword,
        isValid: !newPasswordError && !confirmedPasswordError,
      });
    }
  }, [oldPassword, newPassword, confirmedPassword, newPasswordError, confirmedPasswordError]);

  return (
    <>
      <div>
        <Typography variant="h6" mb={1} sx={{ fontWeight: 600 }}>
          {'Change Password'}
        </Typography>
        <Divider
          sx={{
            height: '2px',
            backgroundColor: '#F1F3F4',
            marginBottom: '15px',
            borderColor: '#F1F3F4',
          }}
        />
      </div>
      {
        <Box sx={{ width: '100%', maxWidth: '410px' }}>
          {changePassword.isError ? (
            <AlertMessage
              message="Please fill out and validate all required fields."
              severity="error"
            />
          ) : null}
        </Box>
      }
      <div
        style={{
          position: 'relative',
          marginBottom: changePassword.oldPasswordNetworkError !== '' ? 20 : 0,
        }}
      >
        <InputPasswordField
          labelText={'Old Password'}
          placeholder={'Enter Password'}
          required
          onChange={(value) => {
            setChangePassword((prevState) => ({
              ...prevState,
              oldPasswordNetworkError: '',
            }));
            setOldPassword(value);
          }}
          value={oldPassword}
          error={oldPasswordError}
          autoFocus
        />
        <label
          style={{
            color: '#d32f2f',
            fontSize: '14px',
            fontFamily: theme.typography.fontFamily,
            position: 'absolute',
            left: 0,
            bottom: 0,
            margin: '-5px 0',
          }}
        >
          {changePassword.oldPasswordNetworkError}
        </label>
      </div>
      <InputPasswordField
        labelText={'New Password'}
        placeholder={'Enter Password'}
        required
        onChange={(value) => {
          setNewPassword(value);
        }}
        value={newPassword}
        error={newPasswordError}
      />
      <FormProgressBar value={checkPasswordStrength(newPassword)} />
      <InputPasswordField
        labelText={'Confirm Password'}
        placeholder={'Enter Password'}
        required
        onChange={setConfirmedPassword}
        value={confirmedPassword}
        error={confirmedPasswordError}
      />
    </>
  );
};
