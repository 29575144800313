import {
  BudgetSheetL1Header,
  BudgetSheetL2Header,
  BudgetSheetL3Header,
  CostToDateSheetHeader,
  CostTrackerSheetL1Header,
  CostTrackerSheetL2aHeader,
  CostTrackerL3TransactionsHeader,
  CurrencySheetHeader,
  FringesSheetHeader,
  GlobalSheetHeader,
  GroupsSheetHeader,
  LocationSheetHeader,
  SetsSheetHeader,
  UnitDescriptionSheetHeader,
} from '@/constants';
import { SheetNames } from '@/enums';

export const tableColumnHeaderDefault = [
  {
    headerList: BudgetSheetL1Header,
    selectedIndex: [],
    sheetName: SheetNames.L1,
    isOpen: false,
  },
  {
    headerList: CostTrackerSheetL1Header,
    selectedIndex: [],
    sheetName: SheetNames.CT_L1,
    isOpen: false,
  },
  {
    headerList: CostTrackerSheetL2aHeader,
    selectedIndex: [],
    sheetName: SheetNames.CT_L2A,
    isOpen: false,
  },
  {
    headerList: CostTrackerL3TransactionsHeader,
    selectedIndex: [],
    sheetName: SheetNames.CT_L3,
    isOpen: false,
  },
  {
    headerList: BudgetSheetL2Header,
    selectedIndex: [],
    sheetName: SheetNames.L2,
    isOpen: false,
  },
  {
    headerList: BudgetSheetL3Header,
    selectedIndex: [],
    sheetName: SheetNames.L3,
    isOpen: false,
  },
  {
    headerList: GroupsSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.GROUPS,
    isOpen: false,
  },
  {
    headerList: GlobalSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.GLOBALS,
    isOpen: false,
  },
  {
    headerList: CostToDateSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.COSTS_TO_DATE,
    isOpen: false,
  },
  {
    headerList: SetsSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.SETS,
    isOpen: false,
  },
  {
    headerList: LocationSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.LOCATIONS,
    isOpen: false,
  },
  {
    headerList: UnitDescriptionSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.UNIT_DESCRIPTIONS,
    isOpen: false,
  },
  {
    headerList: FringesSheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.FRINGES,
    isOpen: false,
  },
  {
    headerList: CurrencySheetHeader,
    selectedIndex: [],
    sheetName: SheetNames.CURRENCY,
    isOpen: false,
  },
];
