import lodash from 'lodash';
import { SheetNames } from '@/enums';

export type OrderingCriteria = {
  sheetName: SheetNames;
  order: number;
  startsWith?: string;
};

export const defaultCriteria: OrderingCriteria[] = [
  { sheetName: SheetNames.L1, order: 1 },
  { sheetName: SheetNames.L2, order: 2, startsWith: `${SheetNames.L2}_` },
  { sheetName: SheetNames.L3, order: 3, startsWith: `${SheetNames.L3}_` },
  { sheetName: SheetNames.GLOBALS, order: 4 },
  { sheetName: SheetNames.GROUPS, order: 5 },
  { sheetName: SheetNames.FRINGES, order: 6 },
  { sheetName: SheetNames.SETS, order: 7 },
  { sheetName: SheetNames.COSTS_TO_DATE, order: 8 },
  { sheetName: SheetNames.LOCATIONS, order: 9 },
  { sheetName: SheetNames.CURRENCY, order: 10 },
  { sheetName: SheetNames.UNIT_DESCRIPTIONS, order: 11 },
];

const reorderCriteria = (sheetName?: string): OrderingCriteria[] => {
  const defaultCriteriaClone = lodash.cloneDeep(defaultCriteria);
  if (!sheetName) {
    // SheetName not found
    return defaultCriteriaClone;
  }

  const indexToMove = defaultCriteriaClone.findIndex((criteria) =>
    sheetName.includes(criteria.sheetName),
  );

  if (indexToMove === -1) {
    // SheetName not found in defaultCriteria
    return defaultCriteriaClone;
  }

  const criteriaToMove = defaultCriteriaClone.splice(indexToMove, 1)[0];

  let aboveCriteria: OrderingCriteria[] = [];
  let belowCriteria: OrderingCriteria[] = [];
  let adjustedCriteria: OrderingCriteria[] = [];

  if (sheetName.startsWith(`${SheetNames.L2}_`)) {
    const level1Index = defaultCriteriaClone.findIndex(
      (criteria) => criteria.sheetName === SheetNames.L1,
    );
    const level1CriteriaToMove = defaultCriteriaClone.splice(level1Index, 1)[0];

    const level3Index = defaultCriteriaClone.findIndex(
      (criteria) => criteria.sheetName === SheetNames.L3,
    );
    const level3CriteriaToMove = defaultCriteriaClone.splice(level3Index, 1)[0];

    aboveCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order < criteriaToMove.order,
    );
    belowCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order >= criteriaToMove.order,
    );

    adjustedCriteria = [
      criteriaToMove,
      level3CriteriaToMove,
      level1CriteriaToMove,
      ...belowCriteria,
      ...aboveCriteria,
    ];
  } else if (sheetName.startsWith(`${SheetNames.L3}_`)) {
    const level1Index = defaultCriteriaClone.findIndex(
      (criteria) => criteria.sheetName === SheetNames.L1,
    );
    const level1CriteriaToMove = defaultCriteriaClone.splice(level1Index, 1)[0];

    const level2Index = defaultCriteriaClone.findIndex(
      (criteria) => criteria.sheetName === SheetNames.L2,
    );
    const level2CriteriaToMove = defaultCriteriaClone.splice(level2Index, 1)[0];

    aboveCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order < criteriaToMove.order,
    );
    belowCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order >= criteriaToMove.order,
    );

    adjustedCriteria = [
      criteriaToMove,
      level1CriteriaToMove,
      level2CriteriaToMove,
      ...belowCriteria,
      ...aboveCriteria,
    ];
  } else {
    aboveCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order < criteriaToMove.order,
    );

    belowCriteria = defaultCriteriaClone.filter(
      (criteria) => criteria.order >= criteriaToMove.order,
    );

    adjustedCriteria = [criteriaToMove, ...belowCriteria, ...aboveCriteria];
  }

  adjustedCriteria = adjustedCriteria.map((criteria, index) => ({ ...criteria, order: index + 1 }));
  return adjustedCriteria;
};

export default reorderCriteria;
