import lodash from 'lodash';

import { SetColumnAlias, SetColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';

import { ISet, ISetsSheet } from '@/interfaces/IFormulaSheet';
import { ISetData } from '@/interfaces/ISetData';

export const transformSets = (setsData: Array<ISetData>): ISetsSheet => {
  if (lodash.isEmpty(setsData)) {
    return [];
  }
  const setSheet: ISetsSheet = setsData?.map((set: ISetData, index) => {
    return setDataToFormulaTransformation(set, index);
  });

  const totalSetRow = [
    generatedId(),
    '',
    '',
    'Total',
    '',
    0,
    0,
    `=SUM(${SetColumnAlias.Attachments}0:${SetColumnAlias.Attachments}${setSheet.length})`,
    '',
    'T', // row type
    `=ROW(${SetColumnAlias.Code}${setSheet.length + 1})-1`,
  ];

  setSheet.push(totalSetRow);

  return setSheet;
};

export const setsToDataSheetTransformation = (set: ISet) => {
  return {
    id: set[SetColumnIndexes.id],
    category: set[SetColumnIndexes.category],
    code: set[SetColumnIndexes.code],
    description: set[SetColumnIndexes.description],
    includeFringe: set[SetColumnIndexes.includeFringe],
    total: set[SetColumnIndexes.total],
    usage: set[SetColumnIndexes.usage],
    attachments: set[SetColumnIndexes.attachments],
    notes: set[SetColumnIndexes.notes],
  } as ISetData;
};

export const setDataToFormulaTransformation = (set: ISetData, index: number) => {
  const code = `${set.code}` ? `'${set.code}` : '';

  return [
    set.id,
    set.category,
    code,
    set.description,
    set.includeFringe,
    0,
    0,
    set.attachments,
    set.notes,
    'D',
    `=ROW(${SetColumnAlias.Code}${index + 1})-1`,
  ] as ISet;
};
