import { useQuery } from '@tanstack/react-query';
import type { IFileResponse } from '@/components/dialogBoxes/types';
import DataSheetApiService from '@/services/DataSheetApiService';

interface UseFetchFilesOptions {
  rootPath: string | null;
  query?: string;
  folderPath?: string;
  archiveToggleStatus?: boolean;
  templateToggleStatus?: boolean;
}

const fetchSheets = async (
  rootPath: string,
  query?: string,
  folderPath?: string,
  archiveToggleStatus?: boolean,
  templateToggleStatus?: boolean,
): Promise<IFileResponse[]> => {
  const response = await DataSheetApiService.fetchSheets(
    rootPath,
    query,
    folderPath,
    archiveToggleStatus,
    templateToggleStatus,
    true,
  );
  return response.filter((file: IFileResponse) => file.filename.length > 0);
};

const useFetchFiles = ({
  rootPath,
  query,
  folderPath,
  archiveToggleStatus,
  templateToggleStatus,
}: UseFetchFilesOptions) => {
  return useQuery<IFileResponse[], Error>({
    queryKey: [
      'useFetchFiles_query_key',
      rootPath,
      query,
      folderPath,
      archiveToggleStatus,
      templateToggleStatus,
    ],
    queryFn: () => {
      if (!rootPath) return [];
      return fetchSheets(rootPath, query, folderPath, archiveToggleStatus, templateToggleStatus);
    },
    // rootPath determines if user is logged in or not
    enabled: !!rootPath,
  });
};

export default useFetchFiles;
