export enum SheetNames {
  META = 'meta',
  SHEET_NAMES = 'sheetNames',
  CT_L1 = 'ct_l1',
  CT_L2A = 'ct_l2a',
  CT_L3 = 'ct_l3',
  L1 = 'l1',
  L2 = 'l2',
  L3 = 'l3',
  COSTS_TO_DATE = 'costsToDate',
  CURRENCY = 'currency',
  FRINGES = 'fringes',
  GLOBALS = 'globals',
  GROUPS = 'groups',
  LOCATIONS = 'locations',
  SETS = 'sets',
  UNIT_DESCRIPTIONS = 'unitDesc',
  FILE_ATTACHMENTS = 'fileAttachments',
  NOTES = 'notes',
  LOOKUP = 'lookup',
  CONFIGS = 'configs',
}

export type SheetNamesKeys = keyof typeof SheetNames;
