import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import theme from '@/app/theme/base/theme';

type Props = {
  onChange: (value: string[]) => void;
  fullWidth?: boolean;
  options: { value: string; label: string }[];
  labelText?: string;
  values: string[];
  placeholder?: string;
  countOption?: boolean;
};

export const MultiSelectDropDown = ({
  onChange,
  fullWidth = true,
  options,
  labelText,
  values,
  placeholder,
  countOption,
}: Props) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelectAllChange = (event: any) => {
    setIsSelected(event.target.checked);
    if (event.target.checked) {
      onChange(options.map((option) => (option.value === ' ' ? ' ' : option.value)));
    } else {
      onChange([]);
      values.splice(0, values.length);
    }
  };
  useEffect(() => {
    setIsSelected(values.length === options.length);
  }, [values, options]);

  const handleOptionToggle = (selectedValue: string) => {
    const selectedIndex = values.indexOf(selectedValue);
    let newValues: string[];

    if (selectedIndex === -1) {
      newValues = [...values, selectedValue];
    } else {
      newValues = [...values.slice(0, selectedIndex), ...values.slice(selectedIndex + 1)];
    }

    if (isSelected && newValues.length !== options.length) {
      setIsSelected(false);
    } else if (newValues.length === options.length) {
      setIsSelected(true);
    }
    onChange(newValues);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {labelText}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {countOption && (
            <Typography variant="caption" color={theme.palette.text.secondary}>
              ({options.length === values.length ? 'All' : values.length})
            </Typography>
          )}
        </Stack>
      </Stack>
      <Select
        size={'small'}
        placeholder="Placeholder Text"
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={values}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
        renderValue={(selected: any) => {
          return selected.map((val: string) => (val === '' ? 'Blank' : val)).join(', ');
        }}
        sx={{
          mb: 2,
          mt: 0.5,
          fontSize: '14px',
          '.MuiSelect-select': {
            fontSize: '14px',
          },
        }}
      >
        <FormControlLabel
          label="Select All"
          control={<Checkbox checked={isSelected} size="small" onChange={handleSelectAllChange} />}
          sx={{
            pl: '10px',
            fontSize: '14px',
          }}
        />
        {options.map(({ label, value }, index) => (
          <MenuItem
            key={index}
            value={value}
            sx={{
              fontSize: '14px',
              padding: '0px',
            }}
            onClick={() => handleOptionToggle(value)}
          >
            <ListItemIcon>
              <Checkbox checked={values.indexOf(value) > -1} size="small" />
            </ListItemIcon>
            <ListItemText
              primary={label}
              sx={{
                fontSize: '14px',
                '.MuiTypography-root': {
                  fontSize: '14px',
                },
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
