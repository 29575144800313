export enum DATA_SHEET_FILE_TYPES {
  JSON = 'json',
  ARCH = 'arch',
  TEMP = 'temp',
  TMPL = 'tmpl',
  XML = 'xml',
}

export enum S3ObjectType {
  SYSTEM_FOLDER = 'SYSTEM_FOLDER',
  USERS_FOLDER = 'USERS_FOLDER',
  JSON_FILE = 'JSON_FILE',
  TEMPORARY_FILE = 'TEMPORARY_FILE',
  ARCHIVED_FILE = 'ARCHIVED_FILE',
  TEMPLATE_FILE = 'TEMPLATE_FILE',
}
export enum IMPORT_FILE {
  JSON = 'application/json',
  XML = 'text/xml',
}

export enum SELECTED_FILE {
  JSON = 'JSON',
  XML = 'XML',
  PIVOT_SHEET = 'PIVOT',
}
