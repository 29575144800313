import styled from '@emotion/styled';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { S3ObjectType } from '@/enums';
import { existingFileNameHandler } from '@/utils';
import { ConfirmationDialog } from '@/components/dialogBoxes';
import { IFileResponse } from '@/components/dialogBoxes/types';

interface SaveFooterDialogProps {
  newFileUrl: string;
  setNewFileUrl: (fileUrl: string) => void;
  handleSave: (fileUrl: string, fileType: S3ObjectType) => void;
  isSaving: boolean;
  folderPath: string;
  fetchedFiles?: IFileResponse[];
  saveAsTemplateDisabled?: boolean;
}

const StyledSaveActionFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SaveActionFooter = ({
  newFileUrl,
  setNewFileUrl,
  handleSave,
  isSaving,
  folderPath,
  fetchedFiles,
  saveAsTemplateDisabled,
}: SaveFooterDialogProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
  const [saveFileType, setSaveFileType] = useState<S3ObjectType>(S3ObjectType.JSON_FILE);
  const [saveFileName, setSaveFileName] = useState<string>(newFileUrl);

  function convertFileUrl(fileName: string, fileType: S3ObjectType) {
    if (fileType === S3ObjectType.TEMPLATE_FILE) {
      return fileName.replace(/\.[^.]+$/, '.tmpl');
    } else {
      return fileName.replace(/\.[^.]+$/, '.json');
    }
  }

  async function handleSaveAsOption() {
    let nameWithoutPath = saveFileName.split('/').pop() || '';
    let newName = folderPath.concat(saveFileName);

    //check if the file extension .json or .tmpl is present
    if (!newName.includes('.json') && !newName.includes('.tmpl')) {
      newName = newName.concat(saveFileType === S3ObjectType.TEMPLATE_FILE ? '.tmpl' : '.json');
      nameWithoutPath = nameWithoutPath.concat(
        saveFileType === S3ObjectType.TEMPLATE_FILE ? '.tmpl' : '.json',
      );
    }

    const isNewNameEmpty = newName !== '';
    const isNewNameAvailable = fetchedFiles?.some((file) => file.filename === nameWithoutPath);

    if (isNewNameEmpty && !isNewNameAvailable) {
      handleSave(newName, saveFileType);
    } else {
      setOpenConfirmationDialog(true);
      setConfirmationDialogMessage('Do you want to overwrite this file ?');
    }
  }

  function handleDialogYesOption() {
    setNewFileUrl(saveFileName);
    handleSave(saveFileName, saveFileType);
    setOpenConfirmationDialog(false);
  }

  function handleDialogNoOption() {
    setOpenConfirmationDialog(false);

    const newName = folderPath.concat(existingFileNameHandler(saveFileName, saveFileType));

    setSaveFileName(newName);
    setNewFileUrl(newName);

    setTimeout(() => {
      handleSave(newName, saveFileType);
    }, 0);
  }

  async function handleDialogOkOption() {
    setOpenConfirmationDialog(false);
  }

  const handleTemplateSave = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSaveFileType(S3ObjectType.TEMPLATE_FILE);
      setSaveFileName(convertFileUrl(newFileUrl, S3ObjectType.TEMPLATE_FILE));
    } else {
      setSaveFileType(S3ObjectType.JSON_FILE);
      setSaveFileName(convertFileUrl(newFileUrl, S3ObjectType.JSON_FILE));
    }
  };

  useEffect(() => {
    //if file name contains .tmpl, set the checkbox to true
    if (newFileUrl.includes('.tmpl')) {
      setSaveFileType(S3ObjectType.TEMPLATE_FILE);
    } else {
      setSaveFileType(S3ObjectType.JSON_FILE);
    }
  }, [newFileUrl]);

  return (
    <StyledSaveActionFooter>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="File Name"
          type="text"
          value={saveFileName}
          size="small"
          onChange={(e) => {
            setSaveFileName(e.target.value);
          }}
          style={{ width: '80%' }}
          variant="outlined"
          error={saveFileName === ''}
          helperText={saveFileName === '' ? 'File name cannot be empty' : ''}
        />
        <Button
          disabled={isSaving || newFileUrl === '' || saveFileName === ''}
          color="primary"
          variant="contained"
          style={{ textTransform: 'none' }}
          onClick={handleSaveAsOption}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleTemplateSave}
            disabled={saveAsTemplateDisabled}
            checked={saveFileType === S3ObjectType.TEMPLATE_FILE}
          />
        }
        label="Save as Template"
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onOpen={setOpenConfirmationDialog}
        message={confirmationDialogMessage}
        title="Message Alert"
        onClickYes={handleDialogYesOption}
        onClickNo={handleDialogNoOption}
        onClickOk={handleDialogOkOption}
        isYesNoOptionAllowed={true}
      />
    </StyledSaveActionFooter>
  );
};

export default SaveActionFooter;
