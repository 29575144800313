import styled from '@emotion/styled';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const StyledTableHeader = styled.th`
  border: 1px solid #dcdcdc;
  padding: 8px 12px;
  background-color: #f5f5f5;
`;

export const StyledTableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

export const StyledTableCell = styled.td`
  border: 1px solid #dcdcdc;
  padding: 8px 12px;
`;
