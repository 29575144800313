/* eslint-disable array-callback-return */
import styled from '@emotion/styled';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { sideBarToggleAtom } from '@/atoms/GlobalAtoms';
import { ArrowDownIcon, CloseSquareIcon } from '@/components/icon';

/* eslint-disable-next-line */
export interface DetailsOptions {
  value: string;
  label: string;
}

export interface TooltipDetailIconProps {
  tooltip: string;
  isVisible?: boolean;
  disableIcon?: boolean;
  disableOptions?: string[];
  icon: React.ReactNode;
  onClickIcon?: (event: React.MouseEvent<HTMLElement>) => void;
  selectedOption?: string[];
  onClickSaveChange?: (updateOption: string[]) => void;
  onChange?: (updateOption: string[]) => void;
  actionModelTitle?: string;
  detailsOptions?: DetailsOptions[];
  onClose?: () => void;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '.MuiList-root': {
    paddingTop: '0px',
  },
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      // padding: '4px 0',
    },
  },
}));

const StyledIconButton = styled('span')(() => ({
  '& .MuiIconButton-root.Mui-disabled': {
    opacity: 0.5,
  },
}));

const StyledArrow = styled.span`
  margin-top: 16px;
  margin-left: -10px;
  position: absolute;
`;

export function TooltipDetailIcon({
  tooltip,
  isVisible = true,
  disableIcon = false,
  disableOptions = [],
  icon,
  onClickIcon,
  onClickSaveChange,
  onChange,
  selectedOption,
  actionModelTitle,
  detailsOptions,
  onClose,
}: TooltipDetailIconProps) {
  const sideBarShow = useRecoilValue(sideBarToggleAtom); // to Hide the Icon toggle while open the leftSide bar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDrop = Boolean(anchorEl);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  // const [selectedSpecialPaste, setSelectedSpecialPaste] =
  //   useRecoilState<string[]>(selectedSpecialPasteAtom);
  const [showDetailModel, setShowDetailModel] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    selectedOption && setCheckedList(selectedOption);
    !disableIcon && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const onClickOk = (event: React.MouseEvent<HTMLElement>) => {
    handleClose();
    onClickSaveChange?.(checkedList);
  };

  useEffect(() => {
    if (detailsOptions && selectedOption) {
      setCheckedList(selectedOption);
    }

    return () => {
      setCheckedList([]);
    };
  }, [detailsOptions, selectedOption]);

  useEffect(() => {
    if (
      !disableIcon &&
      detailsOptions &&
      detailsOptions?.length > 0 &&
      actionModelTitle &&
      !sideBarShow.left
    ) {
      setShowDetailModel(true);
    } else {
      setShowDetailModel(false);
    }
  }, [disableIcon, detailsOptions, actionModelTitle, sideBarShow]);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedOption) {
      if (detailsOptions && event.target.checked) {
        onChange?.([...selectedOption, event.target.value]);
      } else {
        onChange?.(selectedOption.filter((item) => item !== event.target.value));
      }
    }
  };

  const handleOnClickIcon = (event: React.MouseEvent<HTMLElement>) => {
    onClickIcon?.(event);
  };

  return (
    <span hidden={!isVisible}>
      <Tooltip title={tooltip}>
        <StyledIconButton>
          <IconButton
            style={{ color: 'white' }}
            disabled={disableIcon}
            onClick={(event) => handleOnClickIcon(event)}
          >
            {icon}
          </IconButton>
        </StyledIconButton>
      </Tooltip>
      {showDetailModel ? (
        <>
          <StyledArrow onClick={handleClick}>
            <ArrowDownIcon style={{ color: 'white' }} />
          </StyledArrow>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={openDrop}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              disableRipple
              style={{
                backgroundColor: 'black',
                color: 'white',
                paddingTop: '0.75rem',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={10} md={10} style={{ fontWeight: '600' }}>
                  {actionModelTitle}
                </Grid>
                <Grid item xs={2} md={2}>
                  <CloseSquareIcon fontSize="medium" style={{ color: 'black', stroke: 'white' }} />
                </Grid>
              </Grid>
            </MenuItem>
            <Grid container justifyContent="center" style={{ fontSize: '14px' }}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  {detailsOptions?.map((option, key) => (
                    <FormControlLabel
                      key={`action-details-options-${option.value}`}
                      value={option.value}
                      control={
                        <Switch
                          color="primary"
                          onChange={handleCheck}
                          checked={checkedList.includes(option.value)}
                          disabled={disableOptions.includes(option.value)}
                        />
                      }
                      label={<Typography sx={{ fontSize: 14 }}>{option.label}</Typography>}
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <MenuItem disableRipple>
              <Grid container justifyContent="end">
                <Button
                  variant="contained"
                  onClick={(event) => onClickOk(event)}
                  style={{
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  OK
                </Button>
              </Grid>
            </MenuItem>
          </StyledMenu>
        </>
      ) : null}
    </span>
  );
}

export default TooltipDetailIcon;
