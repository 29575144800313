export enum CurrencyColumnIndex {
  category = 1,
  code = 2,
  description = 3,
  units = 4,
  default = 5,
  includeFringe = 6,
  value = 7,
  total = 8,
  usage = 9,
  attachments = 10,
  notes = 11,
}
