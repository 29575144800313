import lodash from 'lodash';

export function isColumnNotEditable(
  editableColumnIndexes: Array<number>,
  currentColumnIndex: number,
): boolean {
  const editableColumnsObject = lodash.reduce(
    editableColumnIndexes,
    (result, value) => {
      result[value] = true;
      return result;
    },
    {} as { [key in string]: boolean },
  );
  const isColumnNotEditable = lodash.isUndefined(editableColumnsObject[currentColumnIndex]);
  return isColumnNotEditable;
}
