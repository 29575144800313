import { Divider, Typography } from '@mui/material';

export const HeaderTitle = ({ name }: { name: string }) => (
  <>
    <Typography variant="h6" mb={1}>
      {name}
    </Typography>
    <Divider
      sx={{
        height: '2px',
        backgroundColor: '#F1F3F4',
        marginBottom: '15px',
        borderColor: '#F1F3F4',
      }}
    />
  </>
);
