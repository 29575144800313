import { Icon, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import papaparse from 'papaparse';
import React, { useEffect, useState } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { useSetRecoilState } from 'recoil';
import { addDataToCTDatom } from '@/atoms/DataSheetAtom';
import { importCSVDetailsAtom } from '@/atoms/GlobalAtoms';
import { AlertMessage, CheckboxField } from '@/components/common';
import { InputTextField } from '@/components/common/InputTextField';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import { boxStyles, typographyStyles } from '@/components/dialogBoxes/ImportCTDDialog/style';
import { CloseSquareIcon, ReceiveSquareGrayIcon } from '@/components/icon';
import FormulaSheet from '@/sheets/FormulaSheet';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
  handleClickEvent: () => void;
  onClose?: () => void;
  isError: boolean;
  checkCodeUsage: boolean;
  isInvalidCodeImport: boolean;
}

export function ImportCTDDialog({
  open,
  handleClickEvent,
  onClose,
  isError,
  checkCodeUsage,
  isInvalidCodeImport,
}: Props) {
  const [category, setCategory] = useState<string>();
  const [prefix, setPrefix] = useState<string>('');
  const [isNotAppend, setIsNotAppend] = useState<boolean>(false);
  const setImportCSVDetails = useSetRecoilState(importCSVDetailsAtom);
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [isPrefixError, setIsPrefixError] = useState<boolean>(false);
  const [isCodeError, setIsCodeError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const setCsvReadData = useSetRecoilState<any[]>(addDataToCTDatom);
  const [lastFileValid, setLastFileValid] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps }: DropzoneState = useDropzone({
    accept: {
      'file/csv': ['.csv'],
    },
    onDropRejected: () => {
      setErrorMessage('Invalid file type. Please upload a CSV file.');
      setLastFileValid(false);
    },
    onDropAccepted: () => {
      setErrorMessage('');
      setLastFileValid(true);
    },
  });

  useEffect(() => {
    setImportCSVDetails({
      isNotAppendValue: isNotAppend,
      categoryValue: category || '',
      prefixValue: prefix || '',
    });
    return () => {
      setImportCSVDetails({
        isNotAppendValue: false,
        categoryValue: '',
        prefixValue: '',
      });
    };
  }, [category, isNotAppend, open, prefix, setImportCSVDetails]);

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        const { data } = papaparse.parse(result as string, { header: true });
        const expectedKeys = ['Account', 'Account Description', 'Amount'];
        const hasExpectedKeys = data.every((row: any) => {
          return Object.keys(row).every((key) => {
            return expectedKeys.includes(key);
          });
        });

        if (hasExpectedKeys) {
          const nonEmptyRowsData = data.filter((row: any) =>
            expectedKeys.some((key) => row[key] !== '' && row[key] !== undefined),
          );
          setCsvReadData(nonEmptyRowsData);
          setUploadFile(file);
          setErrorMessage(null);
          setLastFileValid(true);
        } else {
          setErrorMessage('Invalid CSV format');
          setLastFileValid(false);
        }
      };
      reader.readAsText(file);
    });
  }, [acceptedFiles, setCsvReadData, lastFileValid]);

  useEffect(() => {
    if (lastFileValid && uploadFile !== undefined) {
      setUploadSuccess(true);
    }
  }, [lastFileValid, uploadFile]);

  const handleCheckErrorCode = () => {
    if (isInvalidCodeImport) {
      setIsCodeError(true); // If the form data is invalid, set isCodeError to true to open the dialog
    }
  };

  useEffect(() => {
    if (buttonClicked) {
      handleCheckErrorCode();
      // Reset buttonClicked state
      setButtonClicked(false);
    }
  }, [buttonClicked]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      style={{
        maxHeight: '700px',
        overflowX: 'hidden',
      }}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '310px',
          },
        },
      }}
    >
      <div
        style={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: '400',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
          {'CTD Import'}
        </DialogTitle>
        <CloseSquareIcon
          fontSize="medium"
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: 'black',
            stroke: 'white',
          }}
        />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: 'black',
          }}
          id="alert-dialog-description"
        >
          <Stack direction="row" spacing={3}>
            <Stack direction="column" width={'100%'}>
              <div style={{ position: 'relative' }}>
                {/*<span style={{ position: 'absolute', right: '0', bottom: '-20px' }}>*/}
                {/*  <InfoCycle></InfoCycle>*/}
                {/*</span>*/}
              </div>
              {isError ? (
                <Box mr={4}>
                  <AlertMessage message="Cannot use zero list before import ." severity="error" />
                </Box>
              ) : null}
              {checkCodeUsage ? (
                <Box mr={4}>
                  <AlertMessage
                    message="Clear the usage codes and import again."
                    severity="error"
                  />
                </Box>
              ) : null}
              <InputTextField
                labelText="Category"
                placeholder="Enter Input text"
                value={category}
                onChange={(value) => setCategory(value)}
              />
              <InputTextField
                labelText="Prefix"
                placeholder="Prefix"
                value={prefix}
                onChange={(e) => {
                  if (e.length <= 4) {
                    setPrefix(e);
                  }
                }}
              />

              <CheckboxField
                label={<Typography variant="caption">Zero list before import</Typography>}
                checked={isNotAppend}
                onChange={setIsNotAppend}
                sx={{
                  marginTop: '-20px !important',
                  marginBottom: '5px !important',
                  zIndex: 1000,
                  width: 'fit-content',
                }}
              />
              <Box {...getRootProps()} sx={boxStyles}>
                {uploadSuccess && lastFileValid ? (
                  <Typography>
                    File Upload SuccessFully
                    <Typography sx={{ textAlign: 'center' }}>{uploadFile?.name}</Typography>
                  </Typography>
                ) : (
                  <>
                    <input {...getInputProps()} required />
                    {errorMessage ? (
                      <Typography sx={typographyStyles}>{errorMessage}</Typography>
                    ) : (
                      <Typography sx={typographyStyles}>
                        Drag & Drop or Click to Add File
                      </Typography>
                    )}
                    {!errorMessage && (
                      <Icon sx={{ fontSize: 18 }}>
                        <ReceiveSquareGrayIcon />
                      </Icon>
                    )}
                  </>
                )}
              </Box>
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isError || !uploadSuccess || checkCodeUsage}
          onClick={() => {
            //check if prefix first character is _ or alphabet character
            if (prefix.match(/^[a-zA-Z_]+$/) || prefix === '') {
              setButtonClicked(true);
              handleClickEvent();
            } else {
              setIsPrefixError(true);
            }
          }}
          style={{
            marginBottom: '10px',
            marginRight: '5px',
            fontSize: '14px',
            fontWeight: '600',
          }}
          color="warning"
          variant="contained"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
      <DialogBox
        title="Prefix Error"
        dialogBoxContent={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <p>Prefix must start with an alphabet character or underscore (_).</p>
            <Button
              onClick={() => {
                setPrefix('');
                setIsPrefixError(false);
              }}
              style={{
                marginBottom: '10px',
                marginRight: '5px',
                fontSize: '14px',
                fontWeight: '600',
              }}
              color="warning"
              variant="contained"
              autoFocus
            >
              OK
            </Button>
          </div>
        }
        isOpen={isPrefixError}
        customHeight="100px"
        onClose={() => {
          setIsPrefixError(false);
        }}
      />
    </Dialog>
  );
}

export default ImportCTDDialog;
