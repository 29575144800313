/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { Config } from '@/config';
import { IGetSessionCognito } from '@/interfaces/types';
import { retrieveTokenFromLocalStorage } from './extractTokensFromLocalStorage';
import Pool from '../../UserPool';

const cognito = new AWS.CognitoIdentityServiceProvider({ region: Config.region });

export const authenticate = async (Username: string, Password: string) =>
  await new Promise((resolve, reject) => {
    localStorage.clear();

    const user = new CognitoUser({
      Username,
      Pool,
    });

    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve({ ...data, user });
      },

      onFailure: (err) => {
        console.error('On Failure: ', err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        resolve(data);
      },
      totpRequired: (challengeName) => {
        resolve({ challengeName, user });
      },
    });
  });

export const getSession = async (): Promise<IGetSessionCognito | undefined> =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err: any, session: any) => {
        if (err) {
          reject();
        } else {
          const attributes: any = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {} as any;

                for (const attribute of attributes as any) {
                  const { Name, Value }: any = attribute as any;
                  results[Name] = Value;
                }
                resolve(results);
              }
            });
          });

          const accessToken = session.idToken.jwtToken;

          const userGroup = session.idToken.payload['cognito:groups'];

          const mfaEnabled = await new Promise((resolve) => {
            cognito.getUser(
              {
                AccessToken: accessToken,
              },
              (err, data) => {
                if (err) {
                  resolve(false);
                } else {
                  resolve(
                    data.UserMFASettingList &&
                      data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA'),
                  );
                }
              },
            );
          });
          resolve({
            user,
            accessToken,
            mfaEnabled,
            userGroup,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            ...session,
            ...(attributes as any),
          });
        }
      });
    } else {
      reject();
    }
  });

export const refreshSession = async (): Promise<IGetSessionCognito | undefined> => {
  const refreshToken = retrieveTokenFromLocalStorage('.refreshToken');
  if (!refreshToken) {
    throw new Error('Refresh token not found');
  }

  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
      user.refreshSession(cognitoRefreshToken, (err: any, refreshedSession: any) => {
        if (err) {
          reject(err);
        } else {
          resolve({ ...refreshedSession, user });
        }
      });
    } else {
      throw new Error('User or refreshSession function not available');
    }
  });
};

export const ResetPassword = async (Username: string, code: string, password: string) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username,
      Pool,
    });

    user.confirmPassword(code, password, {
      onSuccess: (data) => {
        resolve(data);
      },

      onFailure: (err) => {
        console.error('On Failure: ', err);
        reject(err);
      },
    });
  });

export const forgotPassword = async (Username: string) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username,
      Pool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        console.info('On Success: ', data);
        resolve(data);
      },

      onFailure: (err) => {
        console.error('On Failure: ', err);
        reject(err);
      },

      inputVerificationCode: (data) => {
        resolve(data);
      },
    });
  });

export const TotpRequired = async (token: string, user: any) => {
  await new Promise((resolve, reject) => {
    user.sendMFACode(
      token,
      {
        onSuccess: (data: any) => {
          resolve(data);
        },
        onFailure: (err: any) => {
          console.error('On Failure: ', err);
          reject(err);
        },
      },
      'SOFTWARE_TOKEN_MFA',
    );
  });
};

export const logout = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};
