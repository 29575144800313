import { HotTableClass } from '@handsontable/react';
import { CellCoords } from 'handsontable';
import { useEffect } from 'react';
import { HANDSONTABLE_ROWS_INDEX_COLUMN_SELECTOR } from '@/constants';

interface useDblClickNavigationProps {
  hotTable: HotTableClass | null;
  onNavigateSuccess: (event: MouseEvent, coords: CellCoords) => void;
}

const useDblClickNavigation = ({ hotTable, onNavigateSuccess }: useDblClickNavigationProps) => {
  useEffect(() => {
    const hot = hotTable?.__hotInstance;
    if (!hot) return;

    const rowsIndexElement = document.querySelector<HTMLTableSectionElement>(
      `${HANDSONTABLE_ROWS_INDEX_COLUMN_SELECTOR} table tbody`,
    );
    if (!rowsIndexElement) return;

    const handleDblClick = (event: MouseEvent) => {
      const tableRowElement = (event.target as HTMLElement).closest('tr[role="row"]');
      if (!tableRowElement) return;

      // Row indexes in the DOM are higher by 2 than the indexes we use in logic. If the attribute is missing, it defaults to -10, which stops the function on next condition.
      const rowIndex = parseInt(tableRowElement.getAttribute('aria-rowindex') ?? '-10') - 2;
      if (rowIndex < 0) return;

      onNavigateSuccess(event, new CellCoords(rowIndex, 0));
    };

    rowsIndexElement.addEventListener('dblclick', handleDblClick);

    return () => rowsIndexElement.removeEventListener('dblclick', handleDblClick);
  }, [hotTable?.__hotInstance, onNavigateSuccess]);
};

export default useDblClickNavigation;
