import { Backdrop, CircularProgress } from '@mui/material';
import { type ReactNode } from 'react';

interface SuspenseSpinnerProps {
  children?: ReactNode;
}

const SuspenseSpinner = ({ children }: SuspenseSpinnerProps) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }} open={true}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
      <div
        style={{
          margin: '20px',
        }}
      >
        {children}
      </div>
    </div>
  </Backdrop>
);

export default SuspenseSpinner;
