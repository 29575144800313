import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { formatDate } from '@/utils';
import { MainButton, ThemeTables } from '@/components/common';
import { useToaster } from '@/components/common/Toaster';
import fetchSystemUsers from '@/services/CommonApiService';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SystemListDialog = ({ open, setOpen }: Props) => {
  const [systemUsers, setSystemUsers] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const snackBar = useToaster();

  const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      valueFormatter: (params: any) => {
        return params.value ? params.value : 'N/A';
      },
      width: 150,
    },
    {
      field: 'company',
      headerName: 'Company Id',
      width: 130,
      align: 'center',
      valueFormatter: (params: any) => {
        return params.value ? params.value : 'N/A';
      },
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'projects',
      headerName: 'Projects',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'is2faEnabled',
      headerName: '2FA',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'isLocked',
      headerName: 'Locked',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'isEnabled',
      headerName: 'Disabled',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'subscriptionT',
      headerName: 'Subscription Tier',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      valueFormatter: (params: any) => {
        const date = new Date(params.value);
        return date.toString() !== 'Invalid Date' ? formatDate(params.value, 'dd-MMM-yyyy') : 'N/A';
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'lastAccessDate',
      headerName: 'Last Accessed',
      valueFormatter: (params: any) => {
        return formatDate(params.value, 'dd-MMM-yyyy');
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  useEffect(() => {
    fetchSystemUsers()
      .then((res: any) => {
        if (res.status === 'PERMITTED') {
          const { data } = res;
          data.forEach((item: any, index: number) => {
            item.id = index + 1;
            item.projects = 'N/A';
            item.is2faEnabled = item.is2faEnabled ? 'Y' : 'N';
            item.isLocked = item.isLocked ? 'Y' : 'N';
            item.isEnabled = item.isEnabled ? 'N' : 'Y';
            item.subscriptionT = '1';
          });
          setSystemUsers(data);
          setDataLoaded(true);
        } else if (res.status === 'NOT_PERMITTED') {
          setSystemUsers([]);
          setOpen(false);
          snackBar.error(
            'You are not in admin group or not permitted to access this page. ' +
              'Please contact your administrator.',
          );
        }
      })
      .catch((err) => {
        setSystemUsers([]);
        setOpen(false);
        snackBar.error('Something went wrong while fetching system users. Please try again.');
      });
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={dataLoaded && open}
      onKeyDown={handleKeyDown}
      onClose={() => setOpen(false)}
      scroll="paper"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1200px',
          },
        },
      }}
    >
      <DialogContent
        sx={{
          padding: '10px !important',
        }}
      >
        <ThemeTables headerColumns={columns} rowData={systemUsers} />
      </DialogContent>
      <DialogActions>
        <MainButton variant="contained" onClick={() => setOpen(false)}>
          Close
        </MainButton>
      </DialogActions>
    </Dialog>
  );
};
