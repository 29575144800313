import type { RangeType } from 'handsontable/common';
import type Core from 'handsontable/core';

export const mapSelectedCellsToRanges = (selectedCells: ReturnType<Core['getSelected']>) => {
  return (selectedCells?.map(([startRow, startCol, endRow, endCol]) => ({
    startRow,
    startCol,
    endRow,
    endCol,
  })) || []) satisfies RangeType[];
};
