import lodash from 'lodash';
import { IPageTitleDetails } from '@/interfaces/sheetTitleTypes';

export function getFormattedDescription(
  fullDescription: string,
  selectedSheetName: string,
): string {
  const isFullDescriptionEmpty = lodash.isEmpty(fullDescription);
  if (isFullDescriptionEmpty) return selectedSheetName;

  return fullDescription;
}

export function getValidatedTooltipTitle(pageTitleDetails: IPageTitleDetails): string {
  const { fileName, accountNumber, fullDescription, selectedSheetName } = pageTitleDetails;

  const isAccountNumberEmpty = lodash.isEmpty(accountNumber);
  let tooltipTitle = `${fileName} - ${getFormattedDescription(
    fullDescription,
    selectedSheetName,
  )} [${accountNumber}]`;

  if (isAccountNumberEmpty) {
    tooltipTitle = `${fileName} - ${getFormattedDescription(fullDescription, selectedSheetName)}`;
    return tooltipTitle;
  }

  return tooltipTitle;
}
