import { DATA_SHEET_FILE_TYPES } from '@/enums';

export function removedExtensionsFilename(filename: string): string {
  const extensionsToRemove = Object.values(DATA_SHEET_FILE_TYPES);

  return extensionsToRemove.reduce((acc, ext) => {
    const regex = new RegExp(`\\.${ext}$`, 'i');
    return acc.replace(regex, '');
  }, filename);
}
