export const splitFormulaByArithmeticOperations = (inputString: string): Array<string> => {
  // Remove the leading '=' character if it exists
  const formula = inputString.startsWith('=') ? inputString.slice(1) : inputString;

  const operators = ['+', '-', '*', '/', '%', '(', ')', '{', '}', '[', ']', '^', '$'];
  const splitValues: string[] = [];
  let currentChunk = '';

  for (const char of formula) {
    if (operators.includes(char)) {
      if (currentChunk.trim() !== '') {
        splitValues.push(currentChunk.trim());
      }
      currentChunk = '';
    } else {
      currentChunk += char;
    }
  }

  // Add the last chunk to the result
  if (currentChunk.trim() !== '') {
    splitValues.push(currentChunk.trim());
  }

  return splitValues;
};
