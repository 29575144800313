import { countries } from '@/app/constants/countries';

export function splitPhoneNumber(response: {
  status: string;
  phoneNumber: string;
  locale: string;
}) {
  const { phoneNumber, locale } = response;
  let countryCode = '';
  let remainingNumber = '';

  // Find the country object in the countriesSelected array based on the locale
  const country = countries.find((c) => c.code === locale);

  if (country) {
    countryCode = country.dial_code;
    // Remove the country dial code from the phoneNumber to get the remaining number
    remainingNumber = phoneNumber.replace(country.dial_code, '');
  }

  return [countryCode, remainingNumber];
}

export const countryCodeLookup: Record<string, string> = {
  AU: '+61',
  LK: '+94',
  US: '+1',
};

export function getCountry(countryCode: string) {
  countries.forEach((country) => {
    if (country.dial_code === countryCode) {
      return country.code;
    }
  });
  return null;
}
