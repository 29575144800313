import { L1ColumnIndexes, L2ColumnIndexes, RowTypes } from '@/enums';
import { IL1Sheet, IL2Sheet } from '@/interfaces/IFormulaSheet';

export const extractFromDataSheet = (
  columnIndex: L1ColumnIndexes | L2ColumnIndexes,
  data?: IL1Sheet | IL2Sheet,
) =>
  Array.from(
    data?.reduce((accumulator, rowData) => {
      if (
        rowData[L2ColumnIndexes.rowType] === RowTypes.D &&
        rowData[L2ColumnIndexes.account] !== ''
      ) {
        accumulator.add(rowData[columnIndex] as string);
      }
      return accumulator;
    }, new Set<string>()) ?? [],
  );
