import { SvgIcon } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
  size?: number;
};

export const EditPenIcon = ({ size }: Props): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{
        width: size || 18,
        height: size || 18,
        fill: 'none',
      }}
    >
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M10.509 2.913l-7.139 7.71c-.27.293-.53.87-.583 1.269l-.321 2.874c-.113 1.038.617 1.748 1.626 1.57l2.8-.487c.391-.072.939-.364 1.208-.666l7.14-7.71c1.234-1.33 1.79-2.848-.131-4.702-1.913-1.836-3.365-1.189-4.6.142z"
      />
      <path
        stroke="#747474"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M9.818 4.09c.323 2.193 2.005 3.87 4.091 4.092"
      />
    </SvgIcon>
  );
};
