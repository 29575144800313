export enum FringeTypes {
  ID = 'id',
  CATEGORY = 'category',
  CODE = 'code',
  DESCRIPTION = 'description',
  RATE = 'rate',
  UNIT_DESCRIPTION = 'unitDesc',
  CAP = 'cap',
  CU = 'cu',
  TOTAL = 'total',
  USAGE = 'usage',
  ATTACHMENTS = 'attachments',
  NOTES = 'notes',
}

export interface IFringeData {
  id: string;
  category: string;
  code: string;
  description: string;
  rate: string;
  unitDesc: string;
  cap: string;
  cu: string;
  total: string;
  usage: string;
  attachments: string;
  notes: string;
}
