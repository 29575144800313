import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ArrowSquareRightIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.25004 20.1666H13.75C18.3334 20.1666 20.1667 18.3333 20.1667 13.75V8.24998C20.1667 3.66665 18.3334 1.83331 13.75 1.83331H8.25004C3.66671 1.83331 1.83337 3.66665 1.83337 8.24998V13.75C1.83337 18.3333 3.66671 20.1666 8.25004 20.1666Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.84497 14.2358L13.0716 11L9.84497 7.76416"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
