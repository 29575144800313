import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const CloseSquareIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.25004 20.1666H13.75C18.3334 20.1666 20.1667 18.3333 20.1667 13.75V8.24998C20.1667 3.66665 18.3334 1.83331 13.75 1.83331H8.25004C3.66671 1.83331 1.83337 3.66665 1.83337 8.24998V13.75C1.83337 18.3333 3.66671 20.1666 8.25004 20.1666Z"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40588 13.5942L13.5942 8.40582"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5942 13.5942L8.40588 8.40582"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
