export enum FringesColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  rate = 4,
  unitDesc = 5,
  cap = 6,
  cu = 7,
  total = 8,
  usage = 9,
  attachments = 10,
  notes = 11,
  rowType = 12,
  index = 13,
  rateCalculation = 14,
  UnitDescValue = 15,
  FringeCuValue = 16,
}

export enum VisibleFringesColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  rate = 3,
  unitDesc = 4,
  cap = 5,
  cu = 6,
  total = 7,
  usage = 8,
  index = 9,
  rateCalculation = 10,
}

export const fringesToVisibleColumnMap: {
  [key in FringesColumnIndexes]?: VisibleFringesColumnIndexes;
} = {
  [FringesColumnIndexes.id]: VisibleFringesColumnIndexes.id,
  [FringesColumnIndexes.category]: VisibleFringesColumnIndexes.category,
  [FringesColumnIndexes.code]: VisibleFringesColumnIndexes.code,
  [FringesColumnIndexes.description]: VisibleFringesColumnIndexes.description,
  [FringesColumnIndexes.rate]: VisibleFringesColumnIndexes.rate,
  [FringesColumnIndexes.unitDesc]: VisibleFringesColumnIndexes.unitDesc,
  [FringesColumnIndexes.cap]: VisibleFringesColumnIndexes.cap,
  [FringesColumnIndexes.cu]: VisibleFringesColumnIndexes.cu,
  [FringesColumnIndexes.total]: VisibleFringesColumnIndexes.total,
  [FringesColumnIndexes.usage]: VisibleFringesColumnIndexes.usage,
};
