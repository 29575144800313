import { Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { rootFolder } from '@/atoms/GlobalAtoms';
import { UserDetailAtom } from '@/atoms/UserAccountAtom';
import { InputTextField, MobileTextField } from '@/components/common';

interface Props {
  onClickChangePassword: () => void;
  mobile: string;
  setMobile: (value: string) => void;
  mobileError: boolean | string;
}

export const UserDetails = ({ onClickChangePassword, mobile, setMobile, mobileError }: Props) => {
  const [userDetail, setUserDetail] = useRecoilState(UserDetailAtom);
  const [email, setEmail] = useState<string>('');
  const userEmail = useRecoilValue(rootFolder);

  useEffect(() => {
    setUserDetail({ ...userDetail, isValid: !mobileError });
  }, [mobileError]);

  useEffect(() => {
    if (!mobileError) {
      setUserDetail({
        ...userDetail,
        mobileNumber: `${userDetail.countryCode}${mobile}`,
        isValid: !mobileError,
      });
    }
  }, [mobile, mobileError, userDetail.countryCode]);

  return (
    <>
      <div>
        <Typography variant="h6" mb={1} sx={{ fontWeight: 600 }}>
          {'User'}
        </Typography>
        <Divider
          sx={{
            height: '2px',
            backgroundColor: '#F1F3F4',
            marginBottom: '15px',
            borderColor: '#F1F3F4',
          }}
        />
      </div>
      <InputTextField
        size="small"
        labelText="Email"
        placeholder="Enter email"
        value={userEmail}
        onChange={setEmail}
        required
        disabled
      />
      <MobileTextField
        labelText="Mobile"
        placeholder="Enter mobile number"
        setSelectedCode={(e) => {
          setUserDetail((prevState) => ({
            ...prevState,
            countryCode: e.dialCode,
            locale: e.code,
          }));
        }}
        selectedCode={userDetail.countryCode}
        size="small"
        value={mobile}
        onChange={(e) => {
          setMobile(e);
          setUserDetail((prevState) => ({
            ...prevState,
            mobileOnly: e,
          }));
        }}
        error={mobileError}
      />
      <Button
        onClick={onClickChangePassword}
        size="small"
        style={{
          textAlign: 'left',
          textDecoration: 'underline',
          display: 'inline-block',
          color: '#747474',
          width: 'fit-content',
          fontSize: '14px',
        }}
      >
        Change Password
      </Button>
      {/* <div>
        <Typography variant="h6" mb={1} sx={{ fontWeight: 600 }}>
          {'API'}
        </Typography>
        <Divider
          sx={{
            height: '2px',
            backgroundColor: '#F1F3F4',
            marginBottom: '15px',
            borderColor: '#F1F3F4',
          }}
        />
      </div>
      <SelectField
        labelText="Connection"
        placeholder="Select Connection"
        options={formatSelectOptionArray(connectionType, 1, 0)}
        onChange={(value) => setSelectedConnection(value)}
        value={selectedConnection}
      />
      <SelectField
        labelText="Project"
        placeholder="Select Project"
        options={formatSelectOptionArray(projectType, 1, 0)}
        onChange={(value) => setSelectedProject(value)}
        value={selectedProject}
      /> */}
    </>
  );
};
