import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { CloseSquareIcon } from '@/components/icon';

export interface DialogBoxProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  dialogBoxContent: React.ReactNode;
  dialogBoxFooter?: React.ReactNode;
  customHeight?: string;
  minWidth?: string;
  customFooterHeight?: string;
  isLoading?: boolean;
}

const StyledSaveAsDialog = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const StyledDialogBox = styled.div<{ customHeight: string; minWidth: string }>`
  & .MuiDialogContent-root {
    padding: 0rem 1rem 1rem;
    overflow-y: scroll;
    scrollbar-width: none;
    height: ${({ customHeight }) => customHeight};
    min-width: ${({ minWidth }) => minWidth};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledDialogActionFooter = styled.div<{ customHeight: string }>`
  & .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    height: ${({ customHeight }) => customHeight};
  }
`;

const StyledDialogTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem;
  background-color: black;
  color: white;
`;

export function DialogBox({
  title,
  isOpen,
  onClose,
  dialogBoxContent,
  dialogBoxFooter,
  customHeight = '45vh',
  minWidth = 'auto',
  customFooterHeight = 'auto',
  isLoading = false,
}: DialogBoxProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    if (isLoading) return;
    onClose();
    setOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
      <StyledDialogBox customHeight={customHeight} minWidth={minWidth}>
        <StyledSaveAsDialog>
          <StyledDialogTitleBar>
            {title}
            <CloseSquareIcon
              fontSize="medium"
              onClick={handleClose}
              style={{ color: 'black', stroke: 'white' }}
            />
          </StyledDialogTitleBar>
          <DialogContent>{dialogBoxContent}</DialogContent>
          {dialogBoxFooter && (
            <StyledDialogActionFooter customHeight={customFooterHeight}>
              <DialogActions>{dialogBoxFooter}</DialogActions>
            </StyledDialogActionFooter>
          )}
        </StyledSaveAsDialog>
      </StyledDialogBox>
    </Dialog>
  );
}

export default DialogBox;
