import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import {
  globalSearchStateAtom,
  GlobalSearchStateType,
  IsSearchingType,
  IsSearchTriggeredType,
  SearchedQueryType,
  SearchResultsType,
  SelectedResultKeyType,
  SelectedResultType,
} from '@/atoms/GlobalAtoms';

export interface GlobalSearchStateService {
  setSearchedQuery: (newQuery: SearchedQueryType) => void;
  getSearchedQuery: () => SearchedQueryType;
  setSelectedResult: (newSelectedResult: SelectedResultType) => void;
  getSelectedResult: () => SelectedResultType;
  setIsSearchTriggered: (newIsSearchTriggered: IsSearchTriggeredType) => void;
  getIsSearchTriggered: () => IsSearchTriggeredType;
  setIsSearching: (newIsSearching: IsSearchingType) => void;
  getIsSearching: () => IsSearchingType;
  setSelectedResultKey: (newSelectedResultKey: SelectedResultKeyType) => void;
  getSelectedResultKey: () => SelectedResultKeyType;
  setSearchResults: (newSearchResults: SearchResultsType) => void;
  getSearchResults: () => SearchResultsType;
}

const useGlobalSearchStateService = (): GlobalSearchStateService => {
  const [globalSearchState, setGlobalSearchState] =
    useRecoilState<GlobalSearchStateType>(globalSearchStateAtom);

  const setSearchedQuery = useCallback(
    (newQuery: SearchedQueryType) => {
      setGlobalSearchState((prev) => ({ ...prev, searchedQuery: newQuery }));
    },
    [setGlobalSearchState],
  );

  const getSearchedQuery = useCallback(
    (): SearchedQueryType => globalSearchState.searchedQuery,
    [globalSearchState.searchedQuery],
  );

  const setSelectedResult = useCallback(
    (newSelectedResult: SelectedResultType) => {
      setGlobalSearchState((prev) => ({ ...prev, selectedResult: newSelectedResult }));
    },
    [setGlobalSearchState],
  );

  const getSelectedResult = useCallback(
    (): SelectedResultType => globalSearchState.selectedResult,
    [globalSearchState.selectedResult],
  );

  const setIsSearchTriggered = useCallback(
    (newIsSearchTriggered: IsSearchTriggeredType) => {
      setGlobalSearchState((prev) => ({ ...prev, isSearchTriggered: newIsSearchTriggered }));
    },
    [setGlobalSearchState],
  );

  const getIsSearchTriggered = useCallback(
    (): IsSearchTriggeredType => globalSearchState.isSearchTriggered,
    [globalSearchState.isSearchTriggered],
  );

  const setIsSearching = useCallback(
    (newIsSearching: IsSearchingType) => {
      setGlobalSearchState((prev) => ({ ...prev, isSearching: newIsSearching }));
    },
    [setGlobalSearchState],
  );

  const getIsSearching = useCallback(
    (): IsSearchingType => globalSearchState.isSearching,
    [globalSearchState.isSearching],
  );

  const setSelectedResultKey = useCallback(
    (newSelectedResultKey: SelectedResultKeyType) => {
      setGlobalSearchState((prev) => ({ ...prev, selectedResultKey: newSelectedResultKey }));
    },
    [setGlobalSearchState],
  );

  const getSelectedResultKey = useCallback(
    (): SelectedResultKeyType => globalSearchState.selectedResultKey,
    [globalSearchState.selectedResultKey],
  );

  const setSearchResults = useCallback(
    (newSearchResults: SearchResultsType) => {
      setGlobalSearchState((prev) => ({
        ...prev,
        searchResults: newSearchResults,
      }));
    },
    [setGlobalSearchState],
  );

  const getSearchResults = useCallback(
    (): SearchResultsType => globalSearchState.searchResults,
    [globalSearchState.searchResults],
  );

  return {
    setSearchedQuery,
    getSearchedQuery,
    setSelectedResult,
    getSelectedResult,
    setIsSearchTriggered,
    getIsSearchTriggered,
    setIsSearching,
    getIsSearching,
    setSelectedResultKey,
    getSelectedResultKey,
    setSearchResults,
    getSearchResults,
  };
};

export default useGlobalSearchStateService;
