export enum LocationColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  includeFringe = 4,
  total = 5,
  usage = 6,
  attachments = 7,
  notes = 8,
  rowType = 9,
  index = 10,
}

export enum VisibleLocationColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  includeFringe = 3,
  total = 4,
  usage = 5,
  index = 6,
}

export const locationToVisibleColumnMap: {
  [key in LocationColumnIndexes]?: VisibleLocationColumnIndexes;
} = {
  [LocationColumnIndexes.id]: VisibleLocationColumnIndexes.id,
  [LocationColumnIndexes.category]: VisibleLocationColumnIndexes.category,
  [LocationColumnIndexes.code]: VisibleLocationColumnIndexes.code,
  [LocationColumnIndexes.description]: VisibleLocationColumnIndexes.description,
  [LocationColumnIndexes.includeFringe]: VisibleLocationColumnIndexes.includeFringe,
  [LocationColumnIndexes.total]: VisibleLocationColumnIndexes.total,
  [LocationColumnIndexes.usage]: VisibleLocationColumnIndexes.usage,
};
