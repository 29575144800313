import lodash from 'lodash';

import { LocationAlias, LocationColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';

import { ILocation, ILocationSheet } from '@/interfaces/IFormulaSheet';
import { ILocationData } from '@/interfaces/ILocationData';

export const transformLocation = (locations: ILocationData[]) => {
  if (lodash.isEmpty(locations)) {
    return [];
  }

  const locationSheet: ILocationSheet = locations?.map((location: ILocationData, index) => {
    return locationDataToFormulaTransformation(location, index);
  });

  const locationTotalRow: ILocation = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', // includeFringe
    0, // total
    0, // usage
    `=SUM(${LocationAlias.Attachments}0:${LocationAlias.Attachments}${locationSheet.length})`, // attachments
    '', // notes
    'T', // row type
    `=ROW(${LocationAlias.Code}${locationSheet.length + 1})-1`,
  ];

  locationSheet.push(locationTotalRow);
  return locationSheet;
};

export const locationToDataSheetTransformation = (location: ILocation) => {
  return {
    id: location[LocationColumnIndexes.id],
    category: location[LocationColumnIndexes.category],
    code: location[LocationColumnIndexes.code],
    description: location[LocationColumnIndexes.description],
    includeFringe: location[LocationColumnIndexes.includeFringe],
    total: location[LocationColumnIndexes.total],
    usage: location[LocationColumnIndexes.usage],
    attachments: location[LocationColumnIndexes.attachments],
    notes: location[LocationColumnIndexes.notes],
  } as ILocationData;
};

export const locationDataToFormulaTransformation = (location: ILocationData, index: number) => {
  return [
    location.id,
    location.category,
    `'${location.code}`,
    location.description,
    location.includeFringe,
    0,
    0,
    location.attachments,
    location.notes,
    'D',
    `=ROW(${LocationAlias.Code}${index + 1})-1`,
  ] as ILocation;
};
