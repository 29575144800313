import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const Export = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#262626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.834 9.167l6.833-6.834M18.334 5.667v-4h-4M9.166 1.667H7.5c-4.167 0-5.833 1.666-5.833 5.833v5c0 4.167 1.666 5.833 5.833 5.833h5c4.166 0 5.833-1.666 5.833-5.833v-1.667"
        />
      </svg>
    </SvgIcon>
  );
};
