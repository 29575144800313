const FringeAllocationData = [
  { value: 'budget', label: 'Budget' },
  { value: 'production', label: 'Production Level' },
  { value: 'category', label: 'Category' },
  { value: 'account', label: 'Account' },
  { value: 'none', label: 'None' },
];

export enum FringeAllocationDataKeys {
  BUDGET = 'budget',
  PRODUCTION = 'production',
  CATEGORY = 'category',
  ACCOUNT = 'account',
  NONE = 'none',
}

export default FringeAllocationData;
