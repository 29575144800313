import { PostHogProvider } from 'posthog-js/react';
import { Config } from '@/config';
import type { FC, ReactNode } from 'react';

interface PostHogAnalyticsProviderProps {
  children: ReactNode;
}

const PostHogAnalyticsProvider: FC<PostHogAnalyticsProviderProps> = ({ children }) => {
  const { analytics } = Config;
  if (!analytics?.enabled) return children;

  return (
    <PostHogProvider
      apiKey={analytics.postHogKey}
      options={{
        api_host: analytics.postHogApiHost,
        autocapture: true,
        person_profiles: 'identified_only',
      }}
    >
      {children}
    </PostHogProvider>
  );
};

export default PostHogAnalyticsProvider;
