import { withSentryReactRouterV6Routing } from '@sentry/react';
import { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AUTH_PATH, APP_PATH } from '@/enums';
import { FileProgressSpinner } from '@/components/common';
import { AppLayout } from '@/components/layout';
import { WelcomeView } from '@/components/WelcomeView';
import { CostTrackerProvider } from '@/contexts/CostTrackerContext';
import ReactQueryClientProvider from '@/lib/react-query';
import { ProtectedRoute } from './auth/ProtectedRoute';

const AuthenticationPage = lazy(() =>
  import('@/pages/AuthenticationPage').then((module) => ({
    default: module.AuthenticationPage,
  })),
);

const SignUpPage = lazy(() =>
  import('@/pages/SignUpPage').then((module) => ({
    default: module.SignUpPage,
  })),
);

const BudgetContainer = lazy(() =>
  import('@/components/sheets/Container').then((module) => ({
    default: module.default,
  })),
);

const GlobalContainer = lazy(() =>
  import('@/components/sheets/Globals/Container').then((module) => ({
    default: module.default,
  })),
);

const GroupsContainer = lazy(() =>
  import('@/components/sheets/Groups/Container').then((module) => ({
    default: module.default,
  })),
);

const FringesContainer = lazy(() =>
  import('@/components/sheets/Fringes/Container').then((module) => ({
    default: module.default,
  })),
);

const SetsContainer = lazy(() =>
  import('@/components/sheets/Sets/Container').then((module) => ({
    default: module.default,
  })),
);

const CostsToDateContainer = lazy(() =>
  import('@/components/sheets/CostsToDate/Container').then((module) => ({
    default: module.default,
  })),
);

const LocationsContainer = lazy(() =>
  import('@/components/sheets/Locations/Container').then((module) => ({
    default: module.default,
  })),
);

const CurrencyContainer = lazy(() =>
  import('@/components/sheets/Currency/Container').then((module) => ({
    default: module.default,
  })),
);

const UnitDescriptionsContainer = lazy(() =>
  import('@/components/sheets/UnitDescriptions/Container').then((module) => ({
    default: module.default,
  })),
);

const ChangePasswordPage = lazy(() =>
  import('@/pages/ChangePasswordPage').then((module) => ({
    default: module.ChangePasswordPage,
  })),
);

const ForceDetailsChangePage = lazy(() =>
  import('@/pages/ForceDetailsChangePage').then((module) => ({
    default: module.ForceDetailsChangePage,
  })),
);

const ForgotPasswordPage = lazy(() =>
  import('@/pages/ForgotPasswordPage').then((module) => ({
    default: module.ForgotPasswordPage,
  })),
);

const MultiFactorAuthPage = lazy(() =>
  import('@/pages/MultiFactorAuthpage').then((module) => ({
    default: module.MultiFactorAuthPage,
  })),
);

const ResetPasswordPage = lazy(() =>
  import('@/pages/ResetPasswordPage').then((module) => ({ default: module.ResetPasswordPage })),
);

const DpfAuthSuccessPage = lazy(() =>
  import('@/pages/DpfAuthSuccessPage').then((module) => ({ default: module.DpfAuthSuccessPage })),
);

const UserVerification = lazy(() =>
  import('@/pages/UserVerification').then((module) => ({ default: module.UserVerification })),
);

const SubscriptionExpired = lazy(() =>
  import('@/pages/SubscriptionExpiredPage').then((module) => ({
    default: module.SubscriptionExpiredPage,
  })),
);

const ReportPreview = lazy(() =>
  import('@/pages/ReportPreview').then((module) => ({
    default: module.ReportPreview,
  })),
);

const TransactionsPreview = lazy(() =>
  import('@/pages/TransactionsPreview').then((module) => ({
    default: module.TransactionsPreview,
  })),
);

const RoutesWithSentryTracing = withSentryReactRouterV6Routing(Routes);

export const BaseRoutes = (): ReactElement => (
  <Suspense fallback={<FileProgressSpinner />}>
    <RoutesWithSentryTracing>
      <Route path={AUTH_PATH.login} element={<AuthenticationPage />} />
      <Route
        path={AUTH_PATH.signup}
        element={
          <ReactQueryClientProvider>
            <SignUpPage />
          </ReactQueryClientProvider>
        }
      />
      <Route path={AUTH_PATH.forgotPassword} element={<ForgotPasswordPage />} />
      <Route path={AUTH_PATH.resetPassword} element={<ResetPasswordPage />} />
      <Route path={AUTH_PATH.changePassword} element={<ChangePasswordPage />} />
      <Route path={AUTH_PATH.multiFactorAuth} element={<MultiFactorAuthPage />} />
      <Route path={AUTH_PATH.userVerification} element={<UserVerification />} />
      <Route
        path={AUTH_PATH.dpfAuthSuccess}
        element={
          <ProtectedRoute>
            <DpfAuthSuccessPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_PATH.subscriptionRenew}
        element={
          <ProtectedRoute>
            <SubscriptionExpired />
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_PATH.forceDetailsChange}
        element={
          <ProtectedRoute>
            <ReactQueryClientProvider>
              <ForceDetailsChangePage />
            </ReactQueryClientProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_PATH.main}
        element={
          <ProtectedRoute>
            <CostTrackerProvider>
              <ReactQueryClientProvider>
                <AppLayout />
              </ReactQueryClientProvider>
            </CostTrackerProvider>
          </ProtectedRoute>
        }
      >
        <Route path={APP_PATH.budget} element={<BudgetContainer />} />
        <Route path={APP_PATH.globals} element={<GlobalContainer />} />
        <Route path={APP_PATH.groups} element={<GroupsContainer />} />
        <Route path={APP_PATH.fringes} element={<FringesContainer />} />
        <Route path={APP_PATH.sets} element={<SetsContainer />} />
        <Route path={APP_PATH.ctd} element={<CostsToDateContainer />} />
        <Route path={APP_PATH.locations} element={<LocationsContainer />} />
        <Route path={APP_PATH.currency} element={<CurrencyContainer />} />
        <Route path={APP_PATH.unitDesc} element={<UnitDescriptionsContainer />} />
        <Route path={APP_PATH.welcome} element={<WelcomeView />} />
        <Route index element={<Navigate to={APP_PATH.budget} replace />} />
        <Route path="*" element={<Navigate to={APP_PATH.budget} replace />} />
      </Route>
      <Route
        path={APP_PATH.reportPreview}
        element={
          <ProtectedRoute>
            <ReportPreview />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PATH.transactions}
        element={
          <ProtectedRoute>
            <ReactQueryClientProvider>
              <CostTrackerProvider>
                <TransactionsPreview />
              </CostTrackerProvider>
            </ReactQueryClientProvider>
          </ProtectedRoute>
        }
      />
    </RoutesWithSentryTracing>
  </Suspense>
);
