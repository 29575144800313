export enum L1ColumnAlias {
  UnderOver = 'B',
  Account = 'C',
  Description = 'D',
  Fringes = 'E',
  Total = 'F',
  Comparison = 'G',
  Variance = 'H',
  MaxVariance = 'I',
  RowType = 'J',
  Index = 'K',
  FringeComparison = 'L',
}

export enum L2ColumnAlias {
  UnderOver = 'B',
  Account = 'C',
  Description = 'D',
  Fringes = 'E',
  Total = 'F',
  Comparison = 'G',
  Variance = 'H',
  MaxVariance = 'I',
  RowType = 'J',
  Index = 'K',
  FringeComparison = 'L',
}

export enum L3ColumnAlias {
  UnderOver = 'B',
  Range = 'C',
  Fringe = 'D',
  Fringes = 'E',
  Groups = 'F',
  Loc = 'G',
  Set = 'H',
  Description = 'I',
  Units = 'J',
  Desc = 'K',
  X = 'L',
  Rate = 'M',
  Cu = 'N',
  Total = 'O',
  Comparison = 'P',
  Variance = 'Q',
  MaxVariance = 'R',
  RowType = 'S',
  FringesTotal = 'T',
  GroupSuppress = 'U',
  UnitFormula = 'W',
  XFormula = 'X',
  RateFormula = 'Y',
  FringeComparison = 'AA',
}

export enum UnitDescAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Units = 'E',
  Usage = 'F',
  Attachments = 'G',
  Notes = 'I',
}

export enum CurrencyAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Rate = 'E',
  Default = 'F',
  IncludeFringe = 'G',
  Total = 'H',
  Usage = 'I',
  Attachments = 'J',
  Notes = 'K',
}

export enum GlobalAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Units = 'E',
  Calculation = 'F',
  Usage = 'G',
  Attachments = 'H',
  Notes = 'I',
  RowType = 'J',
  unitUsedFormula = 'K',
}

export enum GroupsColumnAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Suppress = 'E',
  Cap = 'F',
  Cu = 'G',
  Color = 'H',
  IncludeFringe = 'I',
  Total = 'J',
  Usage = 'K',
  Attachments = 'L',
  Notes = 'M',
}

export enum FringesColumnAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Rate = 'E',
  UnitDescription = 'F',
  Cap = 'G',
  Cu = 'H',
  Total = 'I',
  Usage = 'J',
  Attachments = 'K',
  Notes = 'L',
  RateCalculation = 'O',
  UnitDescValue = 'P',
  FringeCuValue = 'Q',
}

export enum SetColumnAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  IncludeFringe = 'E',
  Total = 'F',
  Usage = 'G',
  Attachments = 'H',
  Notes = 'I',
}

export enum CostsToDateAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  Units = 'E',
  Value = 'F',
  Total = 'G',
  Usage = 'H',
  Attachments = 'I',
  Notes = 'J',
}

export enum LocationAlias {
  Category = 'B',
  Code = 'C',
  Description = 'D',
  IncludeFringe = 'E',
  Total = 'F',
  Usage = 'G',
  Attachments = 'H',
  Notes = 'I',
}

export enum AggregationAlias {
  Groups = 'B',
  Loc = 'C',
  Set = 'D',
  unitDesc = 'E',
  Cu = 'F',
  Units = 'G',
  X = 'H',
  Rate = 'I',
  ConcatenateUnitXRate = 'J',
  Total = 'K',
  FringesTotal = 'L',
  SumOfTotalAndFringes = 'M',
}

export enum LookupAlias {
  Key = 'A',
  Value = 'B',
}
