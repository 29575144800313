import { useCallback, useEffect, useState } from 'react';

const TAB_PANEL_AND_TOPBAR_HEIGHT = 108;

export function useSheetHeight(topOffset = TAB_PANEL_AND_TOPBAR_HEIGHT + 8): number {
  const [height, setHeight] = useState<number>(window.innerHeight - topOffset);

  const onWindowResize = useCallback(() => {
    setHeight(window.innerHeight - topOffset);
  }, [topOffset]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  return height;
}
