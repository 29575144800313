import lodash from 'lodash';
import { L1ColumnIndexes, L1CostTrackerColumnIndexes, RowTypes } from '@/enums';
import { generatedId } from '@/utils';
import { ICostTrackerL1Row } from '@/interfaces/ICostTracker';
import { ICostTrackerLevel1Data } from '@/interfaces/ICostTracker';
import { IDataSheet, IL1Data, IL1DataSheet, IL2DataSheet } from '@/interfaces/IDataSheet';
import { IL1, IL1Sheet } from '@/interfaces/IFormulaSheet';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';
import { IPageTitleDetails } from '@/interfaces/sheetTitleTypes';
import { COST_TRACKER_L1_ROWS_LENGTH } from '../constants/costTracker';

export function getLevel1SheetBySelectedLevel2Sheet(
  level1Sheet: IL1DataSheet,
  selectedSheet: ISelectedSheet,
): IL1Data | null {
  const selectedSheetGroupById = lodash.groupBy(level1Sheet, 'id');
  const currentLevel1 = selectedSheetGroupById[selectedSheet.l1SheetId];
  if (lodash.isEmpty(currentLevel1)) return null;
  return currentLevel1[0];
}

export function handleLevel1PageTitleDetails(fileName: string): IPageTitleDetails {
  return {
    fileName: fileName ?? '',
    accountNumber: '',
    fullDescription: 'Top Sheet',
    selectedSheetName: 'Top Sheet',
  };
}

export function getLevel1IdByLevel2Id(level2Id: string, level2Sheet: IL2DataSheet): string {
  for (const [parentId, childrenId] of Object.entries(level2Sheet)) {
    const child = lodash.find(childrenId as any, { id: level2Id });
    if (child) {
      return parentId;
    }
  }
  return '';
}

const addSumRow = (data: IL1Sheet, totalFringes: number) => {
  const sums = new Array(11).fill(0);
  data.forEach((row) => {
    // If rowType is not 'D' return
    if (row[L1CostTrackerColumnIndexes.rowType + 1] !== RowTypes.D) return;

    row.forEach((cell, index) => {
      // Only sums columns from 'Actual this Period' to 'EFCW'
      if (
        index >= L1CostTrackerColumnIndexes.actual &&
        index <= L1CostTrackerColumnIndexes.rowType &&
        typeof cell === 'number'
      ) {
        const value = isNaN(cell) ? 0 : cell;
        sums[index - L1CostTrackerColumnIndexes.actual] += value;
      }
    });
  });

  const offset = 3;
  sums[L1CostTrackerColumnIndexes.estimateToComplete - offset] += totalFringes;
  sums[L1CostTrackerColumnIndexes.estimatedFinalCost - offset] += totalFringes;
  sums[L1CostTrackerColumnIndexes.varianceToDate - offset] += totalFringes;
  sums[L1CostTrackerColumnIndexes.varianceThisPeriod - offset] += -totalFringes;

  return [generatedId(), '', '', 'TOTAL', ...sums, 'T', 0, 0];
};

export function transformBudgetToCostTrackerLevel1Data(
  budgetData: IL1Sheet,
  costTrackerData: Array<ICostTrackerLevel1Data>,
  currentDataSheet: IDataSheet | null,
) {
  let subTotalRow = new Array(COST_TRACKER_L1_ROWS_LENGTH).fill(null);
  let grandTotalRow = new Array(COST_TRACKER_L1_ROWS_LENGTH).fill(null);
  let totalFringesCostTrackerRow: IL1 = [];
  const totalFringesBudgetRowIndex = budgetData.findIndex(
    (val) => val[L1ColumnIndexes.rowType] === RowTypes.F,
  );
  const totalFringesBudgetRow = budgetData[totalFringesBudgetRowIndex];

  const transformedData = budgetData.reduce((sheetData, budgetRow) => {
    const totalSubFringesRow = new Array(COST_TRACKER_L1_ROWS_LENGTH).fill(null);

    const transaction = costTrackerData.find(
      (transaction) => transaction.Account === budgetRow[L1ColumnIndexes.account],
    );

    if (
      budgetRow[L1ColumnIndexes.rowType] !== RowTypes.D &&
      budgetRow[L1ColumnIndexes.rowType] !== RowTypes.S &&
      budgetRow[L1ColumnIndexes.rowType] !== RowTypes.G &&
      budgetRow[L1ColumnIndexes.rowType] !== RowTypes.FS
    )
      return sheetData;

    const actual = transaction?.Actual ?? 0;
    const costsToDate = actual + (transaction?.Unposted ?? 0) + (transaction?.PO ?? 0);
    const estimateToComplete = Number(budgetRow[L1ColumnIndexes.total]) - costsToDate;
    const varianceToDate =
      currentDataSheet?.configs.varianceCalc === 'over'
        ? Number(budgetRow[L1ColumnIndexes.total]) - (transaction?.Budget ?? 0)
        : (transaction?.Budget ?? 0) - Number(budgetRow[L1ColumnIndexes.total]);
    const varianceThisPeriod = (transaction?.EFCW ?? 0) - Number(budgetRow[L1ColumnIndexes.total]);

    const transformedRow: ICostTrackerL1Row = {
      id: String(budgetRow[L1ColumnIndexes.id]),
      underOver: Math.floor(varianceThisPeriod),
      account: String(budgetRow[L1ColumnIndexes.account]),
      description: String(budgetRow[L1ColumnIndexes.description]),
      actualThisPeriod: transaction?.Period ?? 0,
      actual: actual,
      processing: transaction?.Unposted ?? 0,
      purchaseOrders: transaction?.PO ?? 0,
      costsToDate: costsToDate,
      estimateToComplete: estimateToComplete,
      estimatedFinalCost: Number(budgetRow[L1ColumnIndexes.total]),
      budget: transaction?.Budget ?? 0,
      varianceToDate: varianceToDate,
      varianceThisPeriod: varianceThisPeriod,
      efcw: transaction?.EFCW ?? 0,
      rowType: budgetRow[L1ColumnIndexes.rowType],
      index: Number(budgetRow[L1ColumnIndexes.index]),
      fringeComparison: isNaN(Number(budgetRow[L1ColumnIndexes.fringeComparison]))
        ? null
        : Number(budgetRow[L1ColumnIndexes.fringeComparison]),
    };

    // If rowType is 'D', add the values to the subtotal row and grand total row
    if (budgetRow[L1ColumnIndexes.rowType] === RowTypes.D) {
      subTotalRow = subTotalRow.map((value, index) => {
        if (typeof Object.values(transformedRow)[index] === 'number') {
          const rowValue = Number(Object.values(transformedRow)[index]);

          return (typeof value === 'number' ? value : 0) + (!isNaN(rowValue) ? rowValue : 0);
        }
        return value;
      });

      grandTotalRow = grandTotalRow.map((value, index) => {
        if (typeof Object.values(transformedRow)[index] === 'number') {
          const rowValue = Number(Object.values(transformedRow)[index]);

          return (typeof value === 'number' ? value : 0) + (!isNaN(rowValue) ? rowValue : 0);
        }
        return value;
      });

      sheetData.push(Object.values(transformedRow));
    }

    if (budgetRow[L1ColumnIndexes.rowType] === RowTypes.FS) {
      totalSubFringesRow[L1CostTrackerColumnIndexes.account + 1] =
        budgetRow[L1ColumnIndexes.account];
      totalSubFringesRow[L1CostTrackerColumnIndexes.description + 1] =
        budgetRow[L1ColumnIndexes.description];
      totalSubFringesRow[L1CostTrackerColumnIndexes.actualThisPeriod + 1] =
        budgetRow[L1ColumnIndexes.total];
      totalSubFringesRow[L1CostTrackerColumnIndexes.rowType + 1] = RowTypes.FS;
      sheetData.push(totalSubFringesRow);
    }

    // If rowType is 'S', push the subtotal row to the sheetData and reset it
    if (budgetRow[L1ColumnIndexes.rowType] === RowTypes.S) {
      subTotalRow[L1ColumnIndexes.id] = budgetRow[L1ColumnIndexes.id];
      subTotalRow[L1ColumnIndexes.underOver] = budgetRow[L1ColumnIndexes.underOver];
      subTotalRow[L1ColumnIndexes.account] = budgetRow[L1ColumnIndexes.account];
      subTotalRow[L1ColumnIndexes.description] = budgetRow[L1ColumnIndexes.description];
      subTotalRow[L1CostTrackerColumnIndexes.rowType + 1] = RowTypes.S;
      sheetData.push(subTotalRow);

      // Reset
      subTotalRow = Array(COST_TRACKER_L1_ROWS_LENGTH).fill(0);
    }

    // If rowType is 'G', push the grand total row to the sheetData and reset it
    if (budgetRow[L1ColumnIndexes.rowType] === RowTypes.G) {
      grandTotalRow[L1ColumnIndexes.id] = budgetRow[L1ColumnIndexes.id];
      grandTotalRow[L1ColumnIndexes.underOver] = budgetRow[L1ColumnIndexes.underOver];
      grandTotalRow[L1ColumnIndexes.account] = budgetRow[L1ColumnIndexes.account];
      grandTotalRow[L1ColumnIndexes.description] = budgetRow[L1ColumnIndexes.description];
      grandTotalRow[L1CostTrackerColumnIndexes.rowType + 1] = RowTypes.G;
      sheetData.push(grandTotalRow);
    }

    return sheetData;
  }, [] as IL1Sheet);

  if (totalFringesBudgetRowIndex !== -1) {
    totalFringesCostTrackerRow = new Array(COST_TRACKER_L1_ROWS_LENGTH).fill(0);

    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.account + 1] = null;
    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.sheetId + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.id];
    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.description + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.description];

    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.estimateToComplete + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.total];
    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.estimatedFinalCost + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.total];
    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.varianceToDate + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.total];
    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.varianceThisPeriod + 1] =
      totalFringesBudgetRow[L1ColumnIndexes.total];

    totalFringesCostTrackerRow[L1CostTrackerColumnIndexes.rowType + 1] = RowTypes.F;
  }

  const sumRow = addSumRow(
    transformedData,
    Number(totalFringesBudgetRow?.[L1ColumnIndexes.total] ?? 0),
  );

  return [
    ...transformedData,
    ...(totalFringesCostTrackerRow.length > 0 ? [totalFringesCostTrackerRow] : []),
    sumRow,
  ] as IL1Sheet;
}
