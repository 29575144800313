import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { INotes } from '@/interfaces/INotes';

interface IViewAndUpdateNote {
  note: INotes;
  onClickUpdate: (update: string) => void;
}

export const ViewAndUpdateNote = ({ note, onClickUpdate }: IViewAndUpdateNote) => {
  const [updateTextBox, setUpdateTextBox] = useState<string>(note.note);
  const MAXCHAREACTERLENGTH = 500;

  return (
    <>
      <TextField
        id="test"
        multiline
        sx={{
          minWidth: '460px',
          height: '45v',
          mt: 'auto',
          mb: 'auto',
        }}
        minRows={10}
        maxRows={14}
        onChange={(val) => setUpdateTextBox(val.target.value)}
        value={updateTextBox}
        helperText={`${updateTextBox.length}/${MAXCHAREACTERLENGTH}`}
        InputProps={{
          inputProps: {
            maxLength: 500,
          },
        }}
      />
      <Button
        variant="contained"
        onClick={() => onClickUpdate(updateTextBox)}
        disabled={updateTextBox.length < 1}
      >
        Update
      </Button>
    </>
  );
};
