import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ScissorIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.04159 9.16667C6.8135 9.16667 8.24992 7.73025 8.24992 5.95833C8.24992 4.18642 6.8135 2.75 5.04159 2.75C3.26967 2.75 1.83325 4.18642 1.83325 5.95833C1.83325 7.73025 3.26967 9.16667 5.04159 9.16667Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04159 19.25C6.8135 19.25 8.24992 17.8136 8.24992 16.0416C8.24992 14.2697 6.8135 12.8333 5.04159 12.8333C3.26967 12.8333 1.83325 14.2697 1.83325 16.0416C1.83325 17.8136 3.26967 19.25 5.04159 19.25Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 5.5L7.9292 14.6483"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 16.4725L7.9292 7.315"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
