import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const LogoutIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.158 6.93c.284-3.3 1.98-4.647 5.693-4.647h.119c4.097 0 5.738 1.64 5.738 5.738v5.976c0 4.098-1.64 5.739-5.738 5.739h-.12c-3.684 0-5.38-1.33-5.683-4.574M13.75 11H3.318M5.362 7.93L2.292 11l3.07 3.07"
      />
    </SvgIcon>
  );
};
