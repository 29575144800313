/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import theme from '@/app/theme/base/theme';

interface Props {
  headerColumns: any;
  rowData: any;
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell': {
    fontSize: '13px',
    borderColor: 'whiteSmoke',
    lineHeight: 'unset !important',
    whiteSpace: 'normal',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: 'unset !important',
    height: '45px',
  },
  '& .MuiDataGrid-columnHeader': {
    color: '#fff',

    height: '40px',
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      '& .MuiDataGrid-menuOpen > .MuiButtonBase-root': {
        color: '#fff',
      },
    },
  },
  '& .MuiDataGrid-sortIcon': {
    color: '#fff',
  },
  '& .MuiDataGrid-menuOpen > .MuiButtonBase-root': {
    color: '#fff',
  },
  height: 600,
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'space-between',
  },
}));

export const ThemeTables = ({ headerColumns, rowData }: Props) => {
  return (
    <div>
      <StyledDataGrid
        rows={rowData}
        columns={headerColumns}
        initialState={{
          ...rowData.initialState,
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 30]}
        disableColumnFilter={true}
        checkboxSelection={false}
        rowHeight={40}
        disableColumnMenu={true}
      />
    </div>
  );
};
