export enum L2ColumnIndexes {
  sheetId = -1,
  id = 0,
  underOver = 1,
  account = 2,
  description = 3,
  fringes = 4,
  total = 5,
  comparison = 6,
  variance = 7,
  rowType = 9,
  index = 10,
  fringeComparison = 11, //hidden
}

export enum VisibleL2ColumnIndexes {
  sheetId = -2,
  id = -1,
  underOver = 0,
  account = 1,
  description = 2,
  fringes = 3,
  total = 4,
  comparison = 5,
  variance = 6,
  index = 7,
  rowType = 9,
  fringeComparison = 10, //hidden
}

export enum L2aCostTrackerColumnIndexes {
  sheetId = -1, //hidden
  underOver = 0,
  cu = 1,
  loc = 2,
  account = 3,
  set = 4,
  description = 5,
  actualThisPeriod = 6,
  actual = 7,
  processing = 8,
  purchaseOrders = 9,
  costsToDate = 10,
  estimateToComplete = 11,
  estimatedFinalCost = 12,
  budget = 13,
  varianceToDate = 14,
  varianceThisPeriod = 15,
  efcw = 16,
  rowType = 17, //hidden
  index = 18, //hidden
  fringeComparison = 19, //hidden
}

export const level2ToVisibleColumnMap: Record<L2ColumnIndexes, VisibleL2ColumnIndexes> = {
  [L2ColumnIndexes.sheetId]: VisibleL2ColumnIndexes.sheetId,
  [L2ColumnIndexes.id]: VisibleL2ColumnIndexes.id,
  [L2ColumnIndexes.underOver]: VisibleL2ColumnIndexes.underOver,
  [L2ColumnIndexes.account]: VisibleL2ColumnIndexes.account,
  [L2ColumnIndexes.description]: VisibleL2ColumnIndexes.description,
  [L2ColumnIndexes.fringes]: VisibleL2ColumnIndexes.fringes,
  [L2ColumnIndexes.total]: VisibleL2ColumnIndexes.total,
  [L2ColumnIndexes.comparison]: VisibleL2ColumnIndexes.comparison,
  [L2ColumnIndexes.variance]: VisibleL2ColumnIndexes.variance,
  [L2ColumnIndexes.index]: VisibleL2ColumnIndexes.index,
  [L2ColumnIndexes.rowType]: VisibleL2ColumnIndexes.rowType,
  [L2ColumnIndexes.fringeComparison]: VisibleL2ColumnIndexes.fringeComparison,
};
