export enum L1ColumnIndexes {
  sheetId = -1,
  id = 0,
  underOver = 1,
  account = 2,
  description = 3,
  fringes = 4,
  total = 5,
  comparison = 6,
  variance = 7,
  maxVariance = 8,
  rowType = 9,
  index = 10,
  fringeComparison = 11, //hidden
}

export enum VisibleL1ColumnIndexes {
  sheetId = -2,
  id = -1,
  underOver = 0,
  account = 1,
  description = 2,
  fringes = 3,
  total = 4,
  comparison = 5,
  variance = 6,
  index = 7,
  rowType = 9,
}

export enum L1CostTrackerColumnIndexes {
  sheetId = -1, //hidden
  underOver = 0,
  account = 1,
  description = 2,
  actualThisPeriod = 3,
  actual = 4,
  processing = 5,
  purchaseOrders = 6,
  costsToDate = 7,
  estimateToComplete = 8,
  estimatedFinalCost = 9,
  budget = 10,
  varianceToDate = 11,
  varianceThisPeriod = 12,
  efcw = 13,
  rowType = 14, //hidden
  index = 15, //hidden
  fringeComparison = 16, //hidden
}

export type SelectedVisibleL1ColumnIndexes = Exclude<
  L1ColumnIndexes,
  L1ColumnIndexes.maxVariance | L1ColumnIndexes.rowType | L1ColumnIndexes.fringeComparison
>;

export const level1ToVisibleColumnMap: Record<
  SelectedVisibleL1ColumnIndexes,
  VisibleL1ColumnIndexes
> = {
  [L1ColumnIndexes.sheetId]: VisibleL1ColumnIndexes.sheetId,
  [L1ColumnIndexes.id]: VisibleL1ColumnIndexes.id,
  [L1ColumnIndexes.underOver]: VisibleL1ColumnIndexes.underOver,
  [L1ColumnIndexes.account]: VisibleL1ColumnIndexes.account,
  [L1ColumnIndexes.description]: VisibleL1ColumnIndexes.description,
  [L1ColumnIndexes.fringes]: VisibleL1ColumnIndexes.fringes,
  [L1ColumnIndexes.total]: VisibleL1ColumnIndexes.total,
  [L1ColumnIndexes.comparison]: VisibleL1ColumnIndexes.comparison,
  [L1ColumnIndexes.variance]: VisibleL1ColumnIndexes.variance,
  [L1ColumnIndexes.index]: VisibleL1ColumnIndexes.index,
};
