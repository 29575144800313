const defaultNumberValueSet = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
];

const defaultNumberValueSetWithZero = [
  { value: 'none', label: 'No Formatting' },
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
];

const categoryDigits = defaultNumberValueSet;

const accountDigits = defaultNumberValueSet;

const setDigits = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

const separator = [
  { value: '.', label: '.' },
  { value: '-', label: '-' },
  { value: '_', label: '_' },
  { value: 'none', label: 'None' },
];

const AccountFormatData = {
  categoryDigits,
  accountDigits,
  setDigits,
  separator,
  defaultNumberValueSet,
  defaultNumberValueSetWithZero,
};

export const varianceCalcOptions = [
  {
    value: 'over',
    label: 'Over as negative',
  },
  {
    value: 'under',
    label: 'Under as negative',
  },
];

export default AccountFormatData;
