import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const TrashIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.25 5.48165C16.1975 5.17915 13.1267 5.02332 10.065 5.02332C8.25 5.02332 6.435 5.11498 4.62 5.29832L2.75 5.48165"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79175 4.55581L7.99341 3.35498C8.14008 2.48415 8.25008 1.83331 9.79925 1.83331H12.2009C13.7501 1.83331 13.8692 2.52081 14.0067 3.36415L14.2084 4.55581"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 6L16.4307 16.9627C16.3343 18.6718 16.2555 20 13.8117 20H8.18832C5.74453 20 5.66569 18.6718 5.56934 16.9627L5 6"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46924 15.125H12.5217"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70825 11.4583H13.2916"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
