import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useAggregations } from '@/hooks';
import { aggregationsAtom, dataSheetAtom } from '@/atoms/DataSheetAtom';
import { selectedCurrencyAtom } from '@/atoms/GlobalAtoms';
import { DialogBoxContainer, InputTextField, MainButton, SelectField } from '@/components/common';
import { ThemeSwitch } from '@/components/common/ThemeSwitch';
import { validateSheetMetaData } from '@/components/layout/AppLayout.utils';
import AccountFormatData, { varianceCalcOptions } from '@/data/meta/AccountFormatData';
import FringeAllocationData from '@/data/meta/FringeAllocationData';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IAccountFormat, INumberFormat } from '@/interfaces/IMetaData';
import { IOptions } from '@/interfaces/masterDataTypes';
import FormulaSheet from '@/sheets/FormulaSheet';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
}

interface HeaderTitleProps {
  name: string;
}

const HeaderTitle = ({ name }: HeaderTitleProps) => (
  <>
    <Typography variant="h6" mb={1}>
      {name}
    </Typography>
    <Divider
      sx={{
        height: '2px',
        backgroundColor: '#F1F3F4',
        marginBottom: '15px',
        borderColor: '#F1F3F4',
      }}
    />
  </>
);

export const BudgetOptionDialog = ({ open, setOpen, formulaSheet }: Props) => {
  const aggregationsProcessor = useAggregations();

  const [, setSelected] = useState<string>();
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [varianceCalc, setVarianceCalc] = useState<string>('under');
  const [categoryDigits, setCategoryDigits] = useState<string>('4');
  const [accountDigits, setAccountDigits] = useState<string>('4');
  const [rateDecimalPlaces, setRateDecimalPlaces] = useState<string>('2');
  const [unitsDecimalPlaces, setUnitsDecimalPlaces] = useState<string>('2');
  const [xDecimalPlaces, setXDecimalPlaces] = useState<string>('2');
  const [setDigits, setSetDigits] = useState<string>('2');
  const [separator, setSeparator] = useState<string>('.');
  const [fringeCalc, setFringeCalc] = useState<string>('account');
  const [projectName, setProjectName] = useState<string>('');
  const [budget, setBudget] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [options, setOptions] = useState<IOptions>({} as IOptions);

  const [dataSheet, setDataSheet] = useRecoilState(dataSheetAtom);
  const setAggregations = useSetRecoilState(aggregationsAtom);
  const setSelectedCurrency = useSetRecoilState(selectedCurrencyAtom);

  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet) as IDataSheet;

  function loadAccountFormatInitialData(accountFormatData: IAccountFormat) {
    setCategoryDigits(accountFormatData.categoryDigits.toString());
    setAccountDigits(accountFormatData.accountDigits.toString());
    setSetDigits(accountFormatData.setDigits.toString());
    setSeparator(accountFormatData.separator);
  }

  function loadNumberFormatInitialData(numberFormatData: INumberFormat) {
    setRateDecimalPlaces(numberFormatData.rateDecimalPlaces.toString());
    setUnitsDecimalPlaces(numberFormatData.unitsDecimalPlaces.toString());
    setXDecimalPlaces(numberFormatData.xDecimalPlaces.toString());
  }

  const handleSave = async () => {
    setIsSavingChanges(true);
    if (dataSheetCopy) {
      if (dataSheetCopy.meta?.file) {
        const existingFile = dataSheetCopy.meta.file;
        const newFile = {
          ...existingFile,
          projectName: projectName,
          version: budget,
          note: note,
        };
        dataSheetCopy.meta.file = newFile;
      }
      if (dataSheetCopy.meta?.options) {
        dataSheetCopy.meta.options = options;
      }

      if (dataSheetCopy.meta?.accountFormat) {
        const existingAccountFormat = dataSheetCopy.meta.accountFormat;
        const newAccountFormat = {
          ...existingAccountFormat,
          categoryDigits: Number(categoryDigits),
          accountDigits: Number(accountDigits),
          setDigits: Number(setDigits),
          separator: separator,
        };
        dataSheetCopy.meta.accountFormat = newAccountFormat;
        dataSheetCopy.meta.fringes.calc = fringeCalc;
      }

      if (dataSheetCopy.configs?.varianceCalc) {
        dataSheetCopy.configs.varianceCalc = varianceCalc;
      }
      if (dataSheetCopy.meta?.numberFormat) {
        const numberFormat = dataSheetCopy.meta?.numberFormat;
        const newNumberFormat = {
          ...numberFormat,
          varianceCalculation: varianceCalc === 'under' ? 'UNDER_AS_NEGATIVE' : 'OVER_AS_NEGATIVE',
          rateDecimalPlaces: Number(rateDecimalPlaces),
          unitsDecimalPlaces: Number(unitsDecimalPlaces),
          xDecimalPlaces: Number(xDecimalPlaces),
        };
        dataSheetCopy.meta.numberFormat = newNumberFormat;
      }

      const { dataSheet, defaultCurrencyCode } = validateSheetMetaData(dataSheetCopy);

      setSelectedCurrency(defaultCurrencyCode);
      setDataSheet(dataSheet);
      await formulaSheet.saveDataSheetOnCache(dataSheet);
      await formulaSheet.fromDataSheet(dataSheet);
      const updatedAggregations = await aggregationsProcessor.calculate(
        formulaSheet.getAllSheetValues(),
        formulaSheet.getL3FringesCellOrder(),
      );
      setAggregations(updatedAggregations);
      setOpen(false);
      setIsSavingChanges(false);
    }
  };

  const handleOptionToggle = (changed: keyof IOptions) => {
    setOptions((prevOptions) => ({ ...(prevOptions ?? {}), [changed]: !prevOptions[changed] }));
  };

  useEffect(() => {
    if (dataSheetCopy) {
      if (dataSheetCopy.meta?.file) {
        setProjectName(dataSheetCopy.meta.file.projectName);
        setBudget(dataSheetCopy.meta.file.version);
        setNote(dataSheetCopy.meta.file.note);
        setOptions(dataSheetCopy.meta.options);
        loadAccountFormatInitialData(dataSheetCopy.meta.accountFormat);
        loadNumberFormatInitialData(dataSheetCopy.meta.numberFormat);

        //if fringe calc is not one of the options, set it to account
        setFringeCalc(dataSheetCopy.meta.fringes.calc);
      }

      if (dataSheetCopy.configs?.varianceCalc) {
        setVarianceCalc(dataSheetCopy.configs.varianceCalc);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.key === 'Enter') {
      handleSave();
    }
  };
  return (
    <DialogBoxContainer
      open={open}
      onKeyDown={handleKeyDown}
      setOpen={setOpen}
      dialogTitle="Budget Options"
      boxWidth={'800px'}
      dialogActions={
        <Stack direction="row" justifyContent={'flex-end'} width={'100%'}>
          <MainButton
            onClick={async () => await handleSave()}
            autoFocus
            loading={isSavingChanges}
            color="primary"
            variant="contained"
          >
            {isSavingChanges ? 'Saving...' : 'Save'}
          </MainButton>
        </Stack>
      }
    >
      <Stack direction="row" spacing={3}>
        <Stack direction="column" width={'100%'}>
          <HeaderTitle name="Information" />
          <InputTextField
            labelText="Project Name"
            placeholder="Project Name"
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e)}
          />
          <InputTextField
            labelText="Budget #"
            placeholder="Budget #"
            value={budget}
            onChange={(e) => setBudget(e)}
          />
          <InputTextField
            labelText="Notes"
            placeholder="Notes here"
            value={note}
            rows={4}
            multiline
            onChange={(e) => setNote(e)}
          />
          <HeaderTitle name="Fringes" />
          <SelectField
            labelText="Post Fringes by"
            options={FringeAllocationData}
            onChange={(value) => setFringeCalc(value)}
            value={fringeCalc}
          />
          <HeaderTitle name="Options" />
          <ThemeSwitch
            checked={options?.insertBaseCurrency}
            onChange={() => handleOptionToggle('insertBaseCurrency')}
            label="Insert base currency"
          />
        </Stack>
        <Stack direction="column" width={'100%'}>
          <HeaderTitle name="Number Format" />
          <SelectField
            labelText="Number format"
            placeholder="Number format"
            options={[{ value: '1', label: 'English' }]}
            onChange={(value) => setSelected(value)}
            value={'1'}
          />
          <SelectField
            labelText="Rate column decimal places"
            options={AccountFormatData.defaultNumberValueSetWithZero}
            onChange={(value) => setRateDecimalPlaces(value)}
            value={rateDecimalPlaces}
          />
          <SelectField
            labelText="Unit column decimal places"
            options={AccountFormatData.defaultNumberValueSetWithZero}
            onChange={(value) => setUnitsDecimalPlaces(value)}
            value={unitsDecimalPlaces}
          />
          <SelectField
            labelText="X column decimal places"
            options={AccountFormatData.defaultNumberValueSetWithZero}
            onChange={(value) => setXDecimalPlaces(value)}
            value={xDecimalPlaces}
          />
          <SelectField
            labelText="Variance calculation"
            options={varianceCalcOptions}
            onChange={(value) => setVarianceCalc(value)}
            value={varianceCalc}
          />
          <HeaderTitle name="Account Format" />
          <SelectField
            labelText="Category digits"
            options={AccountFormatData.categoryDigits}
            onChange={(value) => setCategoryDigits(value)}
            value={categoryDigits}
          />
          <SelectField
            labelText="Account digits"
            options={AccountFormatData.accountDigits}
            onChange={(value) => setAccountDigits(value)}
            value={accountDigits}
          />
          <SelectField
            labelText="Set digits"
            options={AccountFormatData.setDigits}
            onChange={(value) => setSetDigits(value)}
            value={setDigits}
          />
          <SelectField
            labelText="Separator"
            options={AccountFormatData.separator}
            onChange={(value) => setSeparator(value)}
            value={separator}
          />
        </Stack>
      </Stack>
    </DialogBoxContainer>
  );
};
