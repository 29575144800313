import { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const DollarSquareIcon = (props: SvgIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 20 20">
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.227 11.941c0 1.075.825 1.942 1.85 1.942h2.091c.892 0 1.617-.758 1.617-1.691 0-1.017-.442-1.376-1.1-1.609L8.327 9.417c-.659-.234-1.1-.592-1.1-1.609 0-.933.725-1.691 1.616-1.691h2.092c1.025 0 1.85.866 1.85 1.941M10 5v10"
      />
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.5 18.333h-5c-4.167 0-5.833-1.666-5.833-5.833v-5c0-4.167 1.666-5.833 5.833-5.833h5c4.166 0 5.833 1.666 5.833 5.833v5c0 4.167-1.666 5.833-5.833 5.833z"
      />
    </svg>
  );
};
