import lodash from 'lodash';
import { generatedId } from '@/utils';
import { setDataToFormulaTransformation } from '@/helpers/Mappers/SetMapper';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { ISet } from '@/interfaces/IFormulaSheet';
import { ISetData, SetTypes } from '@/interfaces/ISetData';

export function getEmptySet(): ISetData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    includeFringe: 'Y',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): { updatedDataSheet: IDataSheet; newSetData: ISet; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newSet: ISetData = getEmptySet();
  updatedDataSheet.sets = updatedDataSheet.sets || [];
  updatedDataSheet.sets.splice(index, 0, newSet);
  const newSetData = setDataToFormulaTransformation(newSet, index);
  const updatedIds = [newSet.id.toString()];
  return { updatedDataSheet, newSetData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: ISetData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.sets = updatedDataSheet.sets.map((set) => {
    if (set.id === record.id) {
      updatedIds.push(record.id.toString());
      return { ...set, ...record };
    }
    return set;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.sets[0] = { ...getEmptySet(), id: updatedDataSheet.sets[0].id };
  }
  const removedSetSheet: ISetData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet[DateSheetAttributeType.SETS].forEach((set, index) => {
    if (!removeIndexes.includes(index)) {
      removedSetSheet.push(set);
    } else {
      updatedIds.push(set.id.toString());
    }
  });
  updatedDataSheet[DateSheetAttributeType.SETS] = removedSetSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: ISetData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.sets[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.sets.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.sets.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedSetRow(copiedRow: ISetData): ISetData {
  const updatedRow = getEmptySet();

  // columns that need to be updated before pasting
  const reviseColumns = [SetTypes.CATEGORY, SetTypes.DESCRIPTION, SetTypes.INCLUDE_FRINGE];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeSetsCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  emptyCells: ICellCoords[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const emptyCells: ICellCoords[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.sets.forEach((data: ISetData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof ISetData] = '';
        }
      });
    });
  }
  return { updatedDataSheet, updatedIds, emptyCells };
}
