export enum GroupColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  suppress = 4,
  cap = 5,
  cu = 6,
  color = 7,
  includeFringe = 8,
  total = 9,
  usage = 10,
  attachments = 11,
  notes = 12,
  rowType = 13,
  index = 14,
}

export enum VisibleGroupColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  suppress = 3,
  cap = 4,
  cu = 5,
  color = 6,
  includeFringe = 7,
  total = 8,
  usage = 9,
  index = 10,
}

export const groupToVisibleColumnMap: {
  [key in GroupColumnIndexes]?: VisibleGroupColumnIndexes;
} = {
  [GroupColumnIndexes.id]: VisibleGroupColumnIndexes.id,
  [GroupColumnIndexes.category]: VisibleGroupColumnIndexes.category,
  [GroupColumnIndexes.code]: VisibleGroupColumnIndexes.code,
  [GroupColumnIndexes.description]: VisibleGroupColumnIndexes.description,
  [GroupColumnIndexes.suppress]: VisibleGroupColumnIndexes.suppress,
  [GroupColumnIndexes.cap]: VisibleGroupColumnIndexes.cap,
  [GroupColumnIndexes.cu]: VisibleGroupColumnIndexes.cu,
  [GroupColumnIndexes.color]: VisibleGroupColumnIndexes.color,
  [GroupColumnIndexes.includeFringe]: VisibleGroupColumnIndexes.includeFringe,
  [GroupColumnIndexes.total]: VisibleGroupColumnIndexes.total,
  [GroupColumnIndexes.usage]: VisibleGroupColumnIndexes.usage,
};
