import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const EyeIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.0001 18.5808C14.2359 18.5808 17.2517 16.6741 19.3509 13.3741C20.5696 11.4583 20.6251 10.6197 19.3509 8.61664C17.2517 5.31664 14.2359 3.40997 11.0001 3.40997C7.76423 3.40997 4.7484 5.31664 2.64923 8.61664C1.37506 10.6197 1.37506 11.3711 2.64923 13.3741C4.7484 16.6741 7.76423 18.5808 11.0001 18.5808Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9999 7.71832C9.18493 7.71832 7.71826 9.18499 7.71826 11C7.71826 12.815 9.18493 14.2817 10.9999 14.2817C12.8149 14.2817 14.2816 12.815 14.2816 11C14.2816 10.3808 14.1109 9.80214 13.8139 9.30844"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
