import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const MenuIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path d="M2.75 6.41669H19.25" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.75 11H19.25" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.75 15.5833H19.25" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  );
};
