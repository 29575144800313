import { SvgIcon } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  size?: number;
};

export const PlusIcon = ({ size }: Props): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{
        width: size || 18,
        height: size || 18,
        fill: 'none',
      }}
    >
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M9 12.273V5.727M5.728 9h6.545"
      />
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.21 16.364H6.79c-3.682 0-5.154-1.473-5.154-5.155V6.791c0-3.682 1.472-5.155 5.154-5.155h4.418c3.682 0 5.155 1.473 5.155 5.155v4.418c0 3.682-1.473 5.155-5.155 5.155z"
      />
    </SvgIcon>
  );
};
