import { CellValue } from 'hyperformula';
import {
  transformCostToDateToReport,
  transformCurrencyToReport,
  transformFilteredL1,
  transformFilteredL1Export,
  transformFilteredL2,
  transformFilteredL2Export,
  transformFilteredL3,
  transformFilteredL3Export,
  transformFringeToReport,
  transformGlobalToReport,
  transformGroupsToReport,
  transformHeaderNoteToReport,
  transformLocationsToReport,
  transformSetsToReport,
  transformUnitDescToReport,
} from '@/helpers/Mappers/ReportMapper';
import { IDataSheetConfigs } from '@/interfaces/IDataSheet';
import { IMeta } from '@/interfaces/IMetaData';
import { FilteredData, IHeaderNotes } from '@/interfaces/IReportDataSheet';

interface NonEmptyDataObject {
  [key: string]: any;
}

type SheetName = keyof FilteredData;

type GenerateReportParams = {
  reportData: Record<string, CellValue[][]>;
  headerNotes?: IHeaderNotes;
  meta?: IMeta;
  configs?: IDataSheetConfigs;
};

export const generateReportDataWithSheetName = ({
  reportData,
  headerNotes,
  meta,
  configs,
  context,
}: GenerateReportParams & { context: 'report' | 'export' }): any => {
  const filteredData: FilteredData = {
    meta: [],
    l1: [],
    l2: [],
    l3: [],
    costsToDate: [],
    currency: [],
    fringes: [],
    globals: [],
    groups: [],
    locations: [],
    sets: [],
    unitDesc: [],
    headerNotes: [],
    configs: [],
  };
  const reportDataWithSheetName = Object.entries(reportData).reduce(
    (result: any, [sheetName, sheetValues]) => {
      if (sheetName === 'aggregations' || sheetName === 'fringe_aggregations') {
        return result;
      }
      const match = sheetName.match(
        /^(l1|l2|l3|l2_|l3_|currency|costsToDate|fringes|globals|groups|locations|sets|unitDesc)/,
      );
      if (!match) {
        result[sheetName] = sheetValues;
        return result;
      }

      const insertSheetName = sheetName.replace(/^(l1|l2_|l3_)/, '');
      const dataKey = match[0] as SheetName;
      const dataArray = filteredData[dataKey];

      sheetValues.forEach((cellValue: any) => {
        const item = cellValue;
        if (Array.isArray(cellValue)) {
          if (dataKey === 'l1' && sheetName.startsWith('l1')) {
            item.unshift(insertSheetName);
          } else if (dataKey === 'l2' && sheetName.startsWith('l2_')) {
            item.unshift(insertSheetName);
          } else if (dataKey === 'l3' && sheetName.startsWith('l3_')) {
            item.unshift(insertSheetName);
          }
          dataArray?.push(item);
        }
      });
      return result;
    },
    {},
  );

  const nonEmptyReportData: NonEmptyDataObject = {};
  Object.entries(reportDataWithSheetName).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      nonEmptyReportData[key] = value;
    }
  });
  try {
    if (context === 'report') {
      nonEmptyReportData.l1 = transformFilteredL1(filteredData.l1);
      nonEmptyReportData.l2 = transformFilteredL2(filteredData.l2);
      nonEmptyReportData.l3 = transformFilteredL3(filteredData.l3);
    } else {
      nonEmptyReportData.l1 = transformFilteredL1Export(filteredData.l1);
      nonEmptyReportData.l2 = transformFilteredL2Export(filteredData.l2);
      nonEmptyReportData.l3 = transformFilteredL3Export(filteredData.l3);
    }
    nonEmptyReportData.currency = transformCurrencyToReport(filteredData.currency);
    nonEmptyReportData.globals = transformGlobalToReport(filteredData.globals);
    nonEmptyReportData.groups = transformGroupsToReport(filteredData.groups);
    nonEmptyReportData.locations = transformLocationsToReport(filteredData.locations);
    nonEmptyReportData.sets = transformSetsToReport(filteredData.sets);
    nonEmptyReportData.unitDesc = transformUnitDescToReport(filteredData.unitDesc);
    nonEmptyReportData.costsToDate = transformCostToDateToReport(filteredData.costsToDate);
    nonEmptyReportData.fringes = transformFringeToReport(filteredData.fringes);
    nonEmptyReportData.headerNotes = transformHeaderNoteToReport(headerNotes);
    nonEmptyReportData.meta = meta ?? {};
    nonEmptyReportData.configs = configs ?? {};
    return nonEmptyReportData;
  } catch (error: any) {
    console.error(`Error transforming data: ${error.message}`);
    throw error;
  }
};
