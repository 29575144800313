import { FringeCellOrder } from '@/utils';
import { getLevel1SheetIdByLevel2Id } from '@/helpers/L2Helpers';
import * as CostsToDateMapper from '@/helpers/Mappers/CostsToDateMapper';
import * as CurrencyMapper from '@/helpers/Mappers/CurrencyMapper';
import * as FringeMapper from '@/helpers/Mappers/FringeMapper';
import * as GlobalMapper from '@/helpers/Mappers/GlobalMapper';
import * as GroupMapper from '@/helpers/Mappers/GroupsMapper';
import * as L1Mapper from '@/helpers/Mappers/L1Mapper';
import * as L2Mapper from '@/helpers/Mappers/L2Mapper';
import * as L3Mapper from '@/helpers/Mappers/L3Mapper';
import * as LocationsMapper from '@/helpers/Mappers/LocationsMapper';
import * as LookupsMapper from '@/helpers/Mappers/LookupMapper';
import * as SetMapper from '@/helpers/Mappers/SetMapper';
import * as UnitDescMapper from '@/helpers/Mappers/UnitDescriptionsMapper';
import { ICostsToDateData } from '@/interfaces/ICostsToDateData';
import { IDataSheet, IDataSheetNames } from '@/interfaces/IDataSheet';
import {
  ICostsToDate,
  ICostsToDateSheet,
  ICurrencySheet,
  IFormulaSheetNames,
  IFringesSheet,
  IGlobalSheet,
  IGroupsSheet,
  IL1Sheet,
  IL2Sheet,
  IL3Sheet,
  ILocationSheet,
  ILookupSheet,
  ISetsSheet,
  IUnitDescSheet,
} from '@/interfaces/IFormulaSheet';

export default class DataSheetTransformer {
  toFormulaSheetNames(dataSheetNames: IDataSheetNames): IFormulaSheetNames {
    const formulaSheetNames: IFormulaSheetNames = {
      l2: [],
      l3: [],
    } as IFormulaSheetNames;

    formulaSheetNames.l2 = dataSheetNames?.l2.map((name: string) => name);
    formulaSheetNames.l3 = dataSheetNames?.l3.map((name: string) => name);

    return formulaSheetNames;
  }

  // sheet id of level 2
  transformL3Sheet(
    dataSheet: IDataSheet,
    sheetId: string,
    fringeCellOrder: FringeCellOrder,
  ): IL3Sheet {
    if (!dataSheet) {
      throw new Error('Data sheet is empty');
    }

    const level1SheetId = getLevel1SheetIdByLevel2Id(dataSheet.l2, sheetId);
    const level2RowNumber =
      dataSheet.l2[level1SheetId ?? '']?.findIndex((level2) => level2.id === sheetId) + 1;

    type DataSheetKey = keyof typeof dataSheet.l3;
    const transformL3SheetData = {
      l3DataSheet: dataSheet.l3[sheetId as DataSheetKey],
      level2RowNumber: level2RowNumber,
      l1SheetId: level1SheetId,
      dataSheet,
      fringeCellOrder,
    };

    const l3FormulaSheet = L3Mapper.transformL3Sheet(transformL3SheetData);

    return l3FormulaSheet;
  }

  // sheet id of level 1
  transformL2Sheet(dataSheet: IDataSheet, sheetId: string): IL2Sheet {
    if (!dataSheet) {
      throw new Error('Data sheet is empty');
    }
    const level1RowNumber = dataSheet.l1.findIndex((level1) => level1.id === sheetId) + 1;
    type DataSheetKey = keyof typeof dataSheet.l2;

    const l2SheetDataToTransform = {
      l2DataSheet: dataSheet.l2[sheetId as DataSheetKey],
      l3DataSheet: dataSheet.l3,
      level1RowNumber: level1RowNumber,
      metaData: dataSheet.meta,
      configData: dataSheet.configs,
    };

    const l2FormulaSheet = L2Mapper.transformL2Sheet(l2SheetDataToTransform);

    return l2FormulaSheet;
  }

  transformL1Sheets(dataSheet: IDataSheet): IL1Sheet {
    return L1Mapper.transformL1Sheet(dataSheet.l1, dataSheet.l2, dataSheet.meta, dataSheet.configs);
  }

  transformL2Sheets(dataSheet: IDataSheet): IL2Sheet {
    return L2Mapper.transformAllL2Sheets(
      dataSheet.l1,
      dataSheet.l2,
      dataSheet.l3,
      dataSheet.meta,
      dataSheet.configs,
    );
  }

  transformGlobals(dataSheet: IDataSheet): IGlobalSheet {
    return GlobalMapper.transformGlobals(dataSheet.globals);
  }

  transformCurrencies(dataSheet: IDataSheet): ICurrencySheet {
    return CurrencyMapper.transformCurrency(dataSheet.currency);
  }

  transformSets(dataSheet: IDataSheet): ISetsSheet {
    return SetMapper.transformSets(dataSheet.sets);
  }

  transformGroups(dataSheet: IDataSheet): IGroupsSheet {
    return GroupMapper.transformGroups(dataSheet.groups);
  }

  transformUnitDesc(dataSheet: IDataSheet): IUnitDescSheet {
    return UnitDescMapper.transformUnitDesc(dataSheet.unitDesc);
  }

  transformCostToDateRow(costToDate: ICostsToDateData, rowIndex: number): ICostsToDate {
    return CostsToDateMapper.costsToDateToFormulaTransformation(costToDate, rowIndex);
  }

  transformCostToDate(dataSheet: IDataSheet): ICostsToDateSheet {
    return CostsToDateMapper.transformCostsToDate(dataSheet.costsToDate);
  }

  transformLocation(dataSheet: IDataSheet): ILocationSheet {
    return LocationsMapper.transformLocation(dataSheet.locations);
  }

  transformFringes(dataSheet: IDataSheet): IFringesSheet {
    return FringeMapper.transformFringes(dataSheet.fringes);
  }

  transformLookups(dataSheet: IDataSheet): ILookupSheet {
    return LookupsMapper.transformLookup(dataSheet?.configs);
  }
}
