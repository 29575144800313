import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { LocalStorageVariables } from '@/enums';
import { fileUrlAtom } from '@/atoms/GlobalAtoms';

const useOpenedFiles = () => {
  const currentFileUrl = useRecoilValue(fileUrlAtom);

  const addFileInstanceToStorage = () => {
    if (!currentFileUrl) return;

    const openedFiles = JSON.parse(
      localStorage.getItem(LocalStorageVariables.OPENED_FILES) || '{}',
    ) as Record<string, number>;

    localStorage.setItem(
      LocalStorageVariables.OPENED_FILES,
      JSON.stringify({ ...openedFiles, [currentFileUrl]: (openedFiles[currentFileUrl] || 0) + 1 }),
    );
  };

  const removeOpenedFilesFromStorage = (currentFileUrl: string | null) => {
    if (!currentFileUrl) return;

    const openedFiles = JSON.parse(
      localStorage.getItem(LocalStorageVariables.OPENED_FILES) || '{}',
    ) as Record<string, number>;
    const fileInstanceCount = openedFiles[currentFileUrl] || 0;

    if (fileInstanceCount <= 1) {
      const { [currentFileUrl]: _, ...remainingOpenedFiles } = openedFiles;
      localStorage.setItem(
        LocalStorageVariables.OPENED_FILES,
        JSON.stringify(remainingOpenedFiles),
      );
    } else {
      localStorage.setItem(
        LocalStorageVariables.OPENED_FILES,
        JSON.stringify({
          ...openedFiles,
          [currentFileUrl]: fileInstanceCount - 1,
        }),
      );
    }
  };

  const checkFileAlreadyOpened = async ({
    fileNameUrl,
    onSuccess,
    onFailure,
  }: {
    fileNameUrl: string;
    onSuccess: () => void;
    onFailure: () => void;
  }) => {
    if (!fileNameUrl) return;

    const openedFiles = JSON.parse(
      localStorage.getItem(LocalStorageVariables.OPENED_FILES) || '{}',
    ) as Record<string, number>;
    const isFileAlreadyOpened = openedFiles[fileNameUrl] > 0;

    if (isFileAlreadyOpened) {
      onSuccess();
    } else {
      onFailure();
    }
  };

  useEffect(() => {
    if (!currentFileUrl) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      removeOpenedFilesFromStorage(currentFileUrl);

      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentFileUrl]);

  return {
    addFileInstanceToStorage,
    removeOpenedFilesFromStorage,
    checkFileAlreadyOpened,
  };
};

export default useOpenedFiles;
