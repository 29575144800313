/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatDate } from '@/utils';
import instanceOfCellError, { checkCellError } from '@/helpers/checkIfCellError';
import { getSelectedCurrency } from '@/helpers/generateJSONDoc';

const getDAggPercent = (fringe: any) => {
  const fringeValue = instanceOfCellError(fringe) ? fringe.value : fringe;
  if (fringeValue) {
    return (fringeValue * 100).toFixed(2) + '%';
  }
};

export const generateXMLDoc = (
  l1Data: any,
  l2Data: any,
  l3Data: any,
  formatDataSheet: any,
  selectedCurrency: string,
  defaultCurrency: string,
  fileName: string,
) => {
  const xmlDoc = document.implementation.createDocument(null, 'budget');
  const root = xmlDoc.documentElement;
  root.setAttribute('xmlns', 'https://store.entertainmentpartners.com/EPWebStore/mmb/budget.xsd');

  const createTextElement = (name: string, text: string) => {
    const element = xmlDoc.createElement(name);
    element.appendChild(xmlDoc.createTextNode(text));
    return element;
  };

  const metaData = xmlDoc.createElement('metadata');

  const createMetadataElement = (item: any) => {
    const budgetData = metaData.appendChild(xmlDoc.createElement('budgetdata'));
    budgetData.appendChild(createTextElement('BudgetName', item?.meta?.file?.title));
    budgetData.appendChild(createTextElement('BudgetUUID', ''));
    budgetData.appendChild(createTextElement('ProjectName', item?.meta?.file?.projectName));
    budgetData.appendChild(createTextElement('ProjectUUID', ''));
    budgetData.appendChild(
      createTextElement(
        'Created',
        item?.meta?.file?.created ? formatDate(item?.meta?.file?.created) : '',
      ),
    );
    budgetData.appendChild(
      createTextElement(
        'Modified',
        item?.meta?.file?.created ? formatDate(item?.meta?.file?.lastModified) : '',
      ),
    );
    budgetData.appendChild(createTextElement('BaseCurrency', defaultCurrency));
    budgetData.appendChild(
      createTextElement('ConvCurrency', getSelectedCurrency(selectedCurrency)),
    );
    budgetData.appendChild(createTextElement('CategoryCount', ''));
    budgetData.appendChild(createTextElement('DetailAccountCount', ''));
    budgetData.appendChild(createTextElement('DetailLineCount', ''));
    budgetData.appendChild(createTextElement('FringeCount', item?.fringes.length || 0));
    budgetData.appendChild(createTextElement('GlobalCount', item?.globals.length || 0));
    budgetData.appendChild(createTextElement('UnitCount', item?.unitDesc.length || 0));
    budgetData.appendChild(createTextElement('LocationCount', item?.locations.length || 0));
    budgetData.appendChild(createTextElement('SetCount', item?.sets.length || 0));
    budgetData.appendChild(createTextElement('CurrencyCount', item?.currency.length || 0));

    const defaultFields = metaData.appendChild(xmlDoc.createElement('defaultfields'));
    defaultFields.appendChild(createTextElement('Title', item?.meta?.file?.title));
    defaultFields.appendChild(createTextElement('Production', ''));
    defaultFields.appendChild(createTextElement('Season', ''));
    defaultFields.appendChild(createTextElement('EpisodeNumber', ''));
    defaultFields.appendChild(createTextElement('TotalEpisodes', ''));
    defaultFields.appendChild(createTextElement('Status', ''));
    defaultFields.appendChild(createTextElement('ProductionBaseCountry', ''));
    defaultFields.appendChild(createTextElement('ProductionBaseRegion', ''));
    defaultFields.appendChild(createTextElement('ProductionBaseCity', ''));
    defaultFields.appendChild(createTextElement('LocationShootDays', ''));
    defaultFields.appendChild(createTextElement('StageShootDays', ''));
    defaultFields.appendChild(createTextElement('StartProductionDate', ''));
    defaultFields.appendChild(createTextElement('EndProductionDate', ''));
    defaultFields.appendChild(createTextElement('ProductionYear', ''));

    return metaData;
  };

  const createCategoryElement = (item: any) => {
    const category = xmlDoc.createElement('category');

    category.appendChild(createTextElement('cID', item.rowIndex));
    category.appendChild(createTextElement('cNumber', checkCellError(item.account)));
    category.appendChild(createTextElement('cDescription', item.description));
    category.appendChild(createTextElement('cRowType', item.rowType));
    category.appendChild(createTextElement('cOriginal', checkCellError(item.comparison)));
    category.appendChild(createTextElement('cTotal', checkCellError(item.total)));
    category.appendChild(createTextElement('cVariance', checkCellError(item.variance)));
    return category;
  };

  const createAccountElement = (item: any) => {
    const account = xmlDoc.createElement('account');
    account.appendChild(createTextElement('categoryID', item.sheetIndex));
    account.appendChild(createTextElement('aID', item.rowIndex));
    account.appendChild(createTextElement('aNumber', checkCellError(item.account)));
    account.appendChild(createTextElement('aDescription', item.description));
    account.appendChild(createTextElement('aRowType', item.rowType));

    account.appendChild(createTextElement('aFringe', checkCellError(item.fringes)));

    account.appendChild(createTextElement('aOriginal', checkCellError(item.comparison)));

    account.appendChild(createTextElement('aTotal', checkCellError(item.total)));

    account.appendChild(createTextElement('aVariance', checkCellError(item.variance)));

    return account;
  };

  const createDetailElement = (item: any) => {
    const detail = xmlDoc.createElement('detail');
    detail.appendChild(createTextElement('accountID', item.sheetIndex));
    detail.appendChild(createTextElement('dAggPercent', getDAggPercent(item.fringe) || ''));
    const dFringes = xmlDoc.createElement('dFringes');
    item?.fringes?.split(',').forEach((fringe: any) => {
      dFringes.appendChild(createTextElement('dFringe', fringe));
    });
    detail.appendChild(dFringes);
    const dGroups = xmlDoc.createElement('dGroups');
    item?.groups?.split(',').forEach((group: any) => {
      dGroups.appendChild(createTextElement('dGroup', group));
    });
    detail.appendChild(dGroups);
    detail.appendChild(createTextElement('dLocation', item.loc));
    detail.appendChild(createTextElement('dSet', item.set));
    detail.appendChild(createTextElement('dDescription', item.description));
    detail.appendChild(createTextElement('dRowType', item.rowType));
    detail.appendChild(createTextElement('dAmount', checkCellError(item.units)));
    detail.appendChild(createTextElement('dUnit', item.desc));
    detail.appendChild(createTextElement('dX', item.x));
    detail.appendChild(createTextElement('dCurrency', item.cu));
    detail.appendChild(createTextElement('dRate', item.rate));
    detail.appendChild(createTextElement('dSubtotal', checkCellError(item.total)));
    return detail;
  };

  const appendElements = (
    parent: Element,
    items: any[],
    createElementFunc: (item: any) => Element,
  ) => {
    items.forEach((item) => {
      const element = createElementFunc(item);
      parent.appendChild(element);
    });
  };

  const appendElementsMeta = (item = {}, createElementFunc: (item: any) => Element) => {
    createElementFunc(item);
  };

  const categories = xmlDoc.createElement('categories');
  const accounts = xmlDoc.createElement('accounts');
  const details = xmlDoc.createElement('details');

  appendElementsMeta(formatDataSheet, createMetadataElement);
  appendElements(categories, l1Data, createCategoryElement);
  appendElements(accounts, l2Data, createAccountElement);
  appendElements(details, l3Data, createDetailElement);

  root.appendChild(metaData);
  root.appendChild(categories);
  root.appendChild(accounts);
  root.appendChild(details);

  // Serialize XML
  const serializer = new XMLSerializer();
  const xmlString = serializer.serializeToString(xmlDoc);
  const finalXmlString = `<?xml version="1.0"?>\n${xmlString}`;

  const downloadFile = (content: string, fileName: string) => {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  downloadFile(finalXmlString, `${fileName}.xml`);
};
