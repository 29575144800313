import log from 'loglevel';

// Set the log level based on the environment
if (process.env.NODE_ENV === 'development') {
  log.setLevel('debug'); // Print all logs
} else {
  log.setLevel('error'); // Print only errors
}

export const logger = log;
