import styled from '@emotion/styled';

export const DialogContentBoxText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

export const BoxButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
`;
