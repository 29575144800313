import lodash from 'lodash';
import { CostsToDateAlias, CostsToDateColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { ICostsToDateData } from '@/interfaces/ICostsToDateData';
import { ICostsToDate, ICostsToDateSheet, ICurrency } from '@/interfaces/IFormulaSheet';

export const transformCostsToDate = (costsToDates: ICostsToDateData[]) => {
  if (lodash.isEmpty(costsToDates)) {
    return [];
  }

  const costsToDateSheet: ICostsToDateSheet = costsToDates?.map(
    (costsToDate: ICostsToDateData, index) => {
      return costsToDateToFormulaTransformation(costsToDate, index);
    },
  );

  const costsToDateTotalRow: ICurrency = [
    generatedId(), // id
    '', // category
    '', // Account
    'Total', // Account Description
    `=SUM(${CostsToDateAlias.Units}0:${CostsToDateAlias.Units}${costsToDateSheet.length})`, // units
    '', // value
    0, // total
    0, // usage
    `=SUM(${CostsToDateAlias.Attachments}0:${CostsToDateAlias.Attachments}${costsToDateSheet.length})`, // attachments
    '', // notes
    'T',
    `=ROW(${CostsToDateAlias.Code}${costsToDateSheet.length + 1})-1`,
  ];

  costsToDateSheet.push(costsToDateTotalRow);

  return costsToDateSheet;
};

export const costsToDateToDataSheetTransformation = (costsToDate: ICostsToDate) => {
  return {
    id: costsToDate[CostsToDateColumnIndexes.id],
    category: costsToDate[CostsToDateColumnIndexes.category],
    code: costsToDate[CostsToDateColumnIndexes.code],
    description: costsToDate[CostsToDateColumnIndexes.description],
    units: costsToDate[CostsToDateColumnIndexes.units],
    value: costsToDate[CostsToDateColumnIndexes.value],
    total: costsToDate[CostsToDateColumnIndexes.total],
    usage: costsToDate[CostsToDateColumnIndexes.usage],
    attachments: costsToDate[CostsToDateColumnIndexes.attachments],
    notes: costsToDate[CostsToDateColumnIndexes.notes],
  } as ICostsToDateData;
};

export const costsToDateToFormulaTransformation = (
  costsToDate: ICostsToDateData,
  index: number,
) => {
  return [
    costsToDate.id,
    costsToDate.category,
    costsToDate.code,
    costsToDate.description,
    costsToDate.units,
    costsToDate.value,
    0,
    0,
    costsToDate.attachments,
    costsToDate.notes,
    'D',
    `=ROW(${CostsToDateAlias.Code}${index + 1})-1`,
  ] as ICostsToDate;
};

// mappers.ts
export const transformCtdValidationCode = (costsToDate: ICostsToDateData[]): any[] => {
  return costsToDate.map((row) => {
    return [
      row.id,
      row.category,
      row.code,
      row.description,
      parseFloat(row.units),
      parseFloat(row.value),
      row.total ? parseFloat(row.total) : 0,
      row.usage ? parseFloat(row.usage) : 0,
      row.attachments,
      row.notes,
      'D',
      0,
    ];
  });
};
