import { SYSTEM_CURRENCY_LABEL } from '@/constants';
import { RowTypes } from '@/enums';
import { formatIndividualSet, generatedId } from '@/utils';
import instanceOfCellError from '@/helpers/checkIfCellError';
import { getEmptyCostsToDate } from '@/helpers/CostsToDateSheetHelper';
import { getEmptyCurrency } from '@/helpers/CurrencyHelper';
import { getEmptyFringe } from '@/helpers/FringeSheetHelper';
import { getEmptyGlobal } from '@/helpers/GlobalSheetHelper';
import { getEmptyGroup } from '@/helpers/GroupSheetHelper';
import { getEmptyLocation } from '@/helpers/LocationHelper';
import { getEmptySet } from '@/helpers/SetSheetHelper';
import {
  removeWhiteSpaces,
  sanitizeCodeOnImport,
  sanitizeFringesOnImport,
} from '@/helpers/SheetImportHelper';
import { getEmptyUnitDesc } from '@/helpers/UnitDescHelper';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { ICurrencyMeta } from '@/interfaces/IImportData';
import { IMeta } from '@/interfaces/IMetaData';
import {
  IAccountImport,
  IDetailImport,
  IDetailImportXML,
  IL2DataImport,
  IL3DataImport,
} from '@/interfaces/ISheetImport';

export const createCurrencyObjects = (currencySet: Set<string>, currencyImport: any) => {
  const uniqueCurrencies = Array.from(currencySet);

  if (
    currencyImport.ConvCurrency !== SYSTEM_CURRENCY_LABEL &&
    !currencySet.has(currencyImport.ConvCurrency)
  ) {
    const convoCurrency = getEmptyCurrency();
    convoCurrency.category = 'Category';
    convoCurrency.code = currencyImport.ConvCurrency;
    convoCurrency.rate = '1';
    convoCurrency.default = 'N';
    uniqueCurrencies.push(convoCurrency.code);
  }

  return uniqueCurrencies.map((cur) => {
    const currency = getEmptyCurrency();
    currency.category = 'Category';
    currency.code = cur;
    currency.rate = '1';
    currency.default = currencyImport.ConvCurrency === cur ? 'Y' : 'N';
    return currency;
  });
};

export const createLocationsObjects = (locationsSet: Set<string>) => {
  const uniqueLocations = Array.from(locationsSet);

  return uniqueLocations.map((loc) => {
    const location = getEmptyLocation();
    location.category = 'Location';
    location.code = loc;
    return location;
  });
};

export const createSetsObjects = (set: Set<string>) => {
  const uniqueSets = Array.from(set);

  return uniqueSets.map((set) => {
    const newSet = getEmptySet();
    newSet.category = 'Sets';
    newSet.code = set;
    return newSet;
  });
};

export const createUnitDescObjects = (unitDescSet: Set<string>) => {
  const uniqueUnitDesc = Array.from(unitDescSet);

  return uniqueUnitDesc.map((unit) => {
    const unitDesc = getEmptyUnitDesc();
    unitDesc.code = unit;
    return unitDesc;
  });
};

export const createFringesObjects = (fringesSet: Set<string>) => {
  const uniqueFringes = Array.from(fringesSet);

  return uniqueFringes.map((fringes) => {
    const fringe = getEmptyFringe();
    fringe.code = fringes;
    return fringe;
  });
};

export const createGroupsObjects = (groupsSet: Set<string>) => {
  const uniqueGroups = Array.from(groupsSet);

  return uniqueGroups.map((groups) => {
    const group = getEmptyGroup();
    group.code = groups;
    return group;
  });
};

export const addEmptyRowToCostsToDate = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.costsToDate) && dataSheetImport.costsToDate.length === 0) {
    dataSheetImport.costsToDate.push(getEmptyCostsToDate());
  }
};

export const addEmptyRowToGroup = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.groups) && dataSheetImport.groups.length === 0) {
    dataSheetImport.groups.push(getEmptyGroup());
  }
};

export const addEmptyRowToGlobal = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.globals) && dataSheetImport.globals.length === 0) {
    dataSheetImport.globals.push(getEmptyGlobal());
  }
};

export const addEmptyRowToFringes = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.fringes) && dataSheetImport.fringes.length === 0) {
    dataSheetImport.fringes.push(getEmptyFringe());
  }
};
export const addEmptyRowToUnitDesc = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.unitDesc) && dataSheetImport.unitDesc.length === 0) {
    dataSheetImport.unitDesc.push(getEmptyUnitDesc());
  }
};
export const addEmptyRowToCurrency = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.currency) && dataSheetImport.currency.length === 0) {
    dataSheetImport.currency.push(getEmptyCurrency());
  }
};
export const addEmptyRowToLocations = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.locations) && dataSheetImport.locations.length === 0) {
    dataSheetImport.locations.push(getEmptyLocation());
  }
};
export const addEmptyRowToSets = (dataSheetImport: IDataSheet): void => {
  if (Array.isArray(dataSheetImport.sets) && dataSheetImport.sets.length === 0) {
    dataSheetImport.sets.push(getEmptySet());
  }
};

export const addEmptyRows = (dataSheet: IDataSheet, functions: any[]): void => {
  functions.forEach((func) => func(dataSheet));
};

export const emptyRowsTypes = [
  addEmptyRowToGroup,
  addEmptyRowToCostsToDate,
  addEmptyRowToFringes,
  addEmptyRowToGlobal,
  addEmptyRowToUnitDesc,
  addEmptyRowToCurrency,
  addEmptyRowToLocations,
  addEmptyRowToSets,
];

export const buildL2RowFromImportSheet = (account: IAccountImport): IL2DataImport => ({
  id: account.aID,
  account: account.aNumber,
  description: account.aDescription,
  rowType: account.aRowType ?? RowTypes.D,
  categoryId: account.categoryID,
});

export const buildL3RowJSON = (detail: IDetailImport): IL3DataImport => {
  const {
    dAggPercent,
    dFringes,
    dGroups,
    dLocation,
    dSet,
    dDescription,
    dAmount,
    dUnit,
    dX,
    dRate,
    dCurrency,
    dRowType,
    accountID,
  } = detail;

  return {
    id: generatedId(),
    range: '',
    fringe: instanceOfCellError(dAggPercent) ? dAggPercent.value : dAggPercent,
    fringes: sanitizeFringesOnImport(dFringes),
    groups: removeWhiteSpaces(dGroups),
    loc: instanceOfCellError(dLocation) ? dLocation.value : dLocation ?? '',
    set: formatIndividualSet(dSet),
    description: dDescription,
    units: instanceOfCellError(dAmount) ? dAmount.value : dAmount ?? '',
    desc: sanitizeCodeOnImport(dUnit, true),
    x: instanceOfCellError(dX) ? dX.value : dX ?? '',
    rate: instanceOfCellError(dRate) ? dRate.value : dRate ?? '',
    cu: sanitizeCodeOnImport(dCurrency),
    comparison: 0,
    rowType: dRowType ?? 'D',
    accountId: accountID,
    fringeComparison: 0,
  };
};

export const buildL3RowFromXML = (
  detail: IDetailImportXML,
  fringeDetails: string[],
  groupDetails: string[],
): IL3DataImport => {
  const {
    dAggPercent,
    dLocation,
    dSet,
    dDescription,
    dAmount,
    dUnit,
    dX,
    dRate,
    dCurrency,
    dRowType,
    accountID,
  } = detail;

  return {
    id: generatedId(),
    range: '',
    fringe: instanceOfCellError(dAggPercent) ? dAggPercent.value : dAggPercent,
    fringes: sanitizeFringesOnImport(fringeDetails),
    groups: removeWhiteSpaces(groupDetails),
    loc: instanceOfCellError(dLocation) ? dLocation.value : dLocation ?? '',
    set: formatIndividualSet(dSet, 6),
    description: dDescription,
    units: instanceOfCellError(dAmount) ? dAmount.value : dAmount ?? '',
    desc: sanitizeCodeOnImport(dUnit, true),
    x: instanceOfCellError(dX) ? dX.value : dX ?? '',
    rate: instanceOfCellError(dRate) ? dRate.value : dRate ?? '',
    cu: sanitizeCodeOnImport(dCurrency),
    comparison: 0,
    rowType: dRowType ?? 'D',
    accountId: accountID,
    fringeComparison: 0,
  };
};

export const buildMetaData = (meta: any): IMeta => {
  const budgetData = meta[0]?.budgetdata;
  return {
    notes: '',
    projectName: '',
    version: '',
    numberFormat: {
      format: '',
      rateDecimalPlaces: 0,
      unitsDecimalPlaces: 0,
      xDecimalPlaces: 0,
      varianceCalculation: '',
    },
    accountFormat: {
      categoryDigits: 4,
      accountDigits: 4,
      setDigits: 3,
      separator: '',
    },
    fringes: {
      calc: 'account',
      allocation: 'ACCOUNT',
      cutoff: 1,
    },
    options: {
      lockBudget: false,
      insertBaseCurrency: true,
      DeleteRowsWithVariances: true,
      showFormulas: false,
    },
    file: {
      projectName: budgetData?.ProjectName || '',
      lastAuthor: '',
      version: '',
      note: '',
      title: '',
      subject: '',
      keywords: '',
      category: '',
      owner: '',
      created: budgetData?.Created || '',
      lastModified: budgetData?.Modified || '',
    },
    isConflictSuppress: 0,
    currencyImport: {
      baseCurrency: budgetData?.BaseCurrency || '',
      convCurrency: budgetData?.ConvCurrency || '',
    },
    costTrackerOAuthApiUrl: '',
    costTrackerOAuthRedirectUrl: '',
    costTrackerMask: '',
  };
};

export const buildConfig = (config: any) => {
  const configData = config[0]?.budgetdata;
  return {
    dropCurrencyAtReset: configData?.ConvCurrency || '',
    varianceCalc: '',
  };
};

export const getBaseCurrencyImport = (metaData: any): ICurrencyMeta => {
  let budgetData: any;

  if (Array.isArray(metaData)) {
    budgetData = metaData[0]?.budgetdata;
  } else {
    budgetData = metaData?.budgetdata;
  }
  if (budgetData) {
    const { BaseCurrency = SYSTEM_CURRENCY_LABEL, ConvCurrency = SYSTEM_CURRENCY_LABEL } =
      budgetData;
    return { BaseCurrency, ConvCurrency };
  } else {
    return { BaseCurrency: SYSTEM_CURRENCY_LABEL, ConvCurrency: SYSTEM_CURRENCY_LABEL };
  }
};
