import lodash from 'lodash';

import { CurrencyAlias, CurrencyColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';

import { ICurrencyData } from '@/interfaces/ICurrencyData';
import { ICurrency, ICurrencySheet } from '@/interfaces/IFormulaSheet';

export const transformCurrency = (currency: ICurrencyData[]) => {
  if (lodash.isEmpty(currency)) {
    return [];
  }

  const currencySheet: ICurrencySheet = currency?.map((currency: ICurrencyData, index) => {
    return currencyDataToFormulaTransformation(currency, index);
  });

  const currencyTotalRow: ICurrency = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', // rate
    '', // default
    '', // includeFringe
    0, // total
    0, // usage
    `=SUM(${CurrencyAlias.Attachments}0:${CurrencyAlias.Attachments}${currencySheet.length})`, // attachments
    '', // notes
    'T', // row type
    `=ROW(${CurrencyAlias.Code}${currencySheet.length + 1})-1`,
  ];

  currencySheet.push(currencyTotalRow);
  return currencySheet;
};

export const currencyToDataSheetTransformation = (currency: ICurrency) => {
  return {
    id: currency[CurrencyColumnIndexes.id],
    category: currency[CurrencyColumnIndexes.category],
    code: currency[CurrencyColumnIndexes.code],
    description: currency[CurrencyColumnIndexes.description],
    rate: currency[CurrencyColumnIndexes.rate],
    default: currency[CurrencyColumnIndexes.default],
    includeFringe: currency[CurrencyColumnIndexes.includeFringe],
    total: currency[CurrencyColumnIndexes.total],
    usage: currency[CurrencyColumnIndexes.usage],
    attachments: currency[CurrencyColumnIndexes.attachments],
    notes: currency[CurrencyColumnIndexes.notes],
  } as ICurrencyData;
};

export const currencyDataToFormulaTransformation = (currency: ICurrencyData, index: number) => {
  return [
    currency.id,
    currency.category,
    currency.code,
    currency.description,
    currency.rate,
    currency.default,
    currency.includeFringe,
    0,
    0,
    currency.attachments,
    currency.notes,
    'D',
    `=ROW(${CurrencyAlias.Code}${index + 1})-1`,
  ] as ICurrency;
};
