import lodash from 'lodash';
import { formatTotalDescription } from '@/helpers/DataSheetHelper';
import { getLevel2SheetBySelectedLevel3Sheet } from '@/helpers/L2Helpers';
import { IL2DataSheet } from '@/interfaces/IDataSheet';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';
import { IPageTitleDetails } from '@/interfaces/sheetTitleTypes';

export function handleLevel3PageTitleDetails(
  fileName: string,
  level2Sheet: IL2DataSheet,
  selectedSheet: ISelectedSheet,
): IPageTitleDetails | null {
  const prevSelectLevel3Sheet = getLevel2SheetBySelectedLevel3Sheet(level2Sheet, selectedSheet);
  if (lodash.isEmpty(prevSelectLevel3Sheet)) return null;

  const prevSelectLevel3SheetTitleDetails: IPageTitleDetails = {
    fileName: fileName ?? '',
    accountNumber: prevSelectLevel3Sheet.account?.toString() ?? '',
    fullDescription: prevSelectLevel3Sheet.description?.toString() ?? '',
    selectedSheetName: prevSelectLevel3Sheet.description?.toString() ?? '',
  };
  return prevSelectLevel3SheetTitleDetails;
}

export function getLevel3Description(account: string | null, description: string | null): string {
  return formatTotalDescription(account, description);
}
