import { ThemeProvider } from '@mui/material/styles';
import {
  init,
  ErrorBoundary,
  browserTracingIntegration,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import theme from '@/app/theme/base/theme';
import { Toaster } from '@/components/common/Toaster';
import { FallbackWithFeedbackForm } from '@/components/fallbacks';
import { BaseRoutes } from '@/components/routes';
import { logger } from '@/lib/logger';
import PostHogAnalyticsProvider from '@/lib/post-hog-analytics';
import './theme/base/globals.css';

interface IConfig {
  environment: string;
  region?: string;
  baseUrl?: string;
  cognitoPoolId?: string;
  cognitoClientId?: string;
  hyperformulaLicense?: string;
  handsontableLicense?: string;
  stimulsoftLicense?: string;
  subscription?: {
    enabled: boolean;
    redirectUrl: string;
  };
}

init({
  dsn: 'https://67d33cfc05566bcab502f8eda665204b@o315375.ingest.sentry.io/4506386236702720',
  integrations: [
    browserTracingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
  replaysOnErrorSampleRate: 1.0,
});

function AppWithConfigLoader() {
  const [config, setConfig] = useState<IConfig | null>(null);

  useEffect(() => {
    fetch('/assets/config.json')
      .then((response) => response.json())
      .then((data: IConfig) => {
        setConfig(data);
        logger.info('Loaded Config:', data);
      })
      .catch((error) => logger.error('Error loading config:', error));
  }, []);

  if (!config) {
    return <div>Loading configuration...</div>;
  }

  return <App config={config} />;
}

interface AppProps {
  config: IConfig;
}

function App({ config }: AppProps) {
  logger.info('Current environment config:', config.environment);
  logger.info('env', process.env.NODE_ENV);

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <PostHogAnalyticsProvider>
          <Toaster />
          <ErrorBoundary
            fallback={({ eventId, resetError }) => (
              <FallbackWithFeedbackForm eventId={eventId} resetError={resetError} />
            )}
          >
            <Router>
              <BaseRoutes />
            </Router>
          </ErrorBoundary>
        </PostHogAnalyticsProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default AppWithConfigLoader;
