import { GroupsColumnAlias, SheetNames } from '@/enums';
import { IL3Data } from '@/interfaces/IDataSheet';
import { IGroupData } from '@/interfaces/IGroupData';

export function getSuppressGroupFormula(l3Data: IL3Data, groupsSheet: Array<IGroupData>) {
  let suppressFormula = '';

  //if groups = '' then return 1
  if (l3Data.groups === '') {
    suppressFormula = '1';
  } else {
    let groupSuppressCountFormula = '';

    l3Data.groups.split(',').forEach((group: string) => {
      const groupIndex = groupsSheet.findIndex((groupData: IGroupData) => groupData.code === group);

      if (groupIndex !== -1) {
        //if group suppress value = 'Y' then multiply by 0
        suppressFormula += `IF((${SheetNames.GROUPS}!${GroupsColumnAlias.Suppress}${
          groupIndex + 1
        }="Y"), 1, 0) * `;

        //if group suppress value = 'N' then add 1 to suppressSum
        groupSuppressCountFormula += `IF((${SheetNames.GROUPS}!${GroupsColumnAlias.Suppress}${
          groupIndex + 1
        }="N"), 1, 0) + `;
      }
    });
    groupSuppressCountFormula = groupSuppressCountFormula.slice(0, -3);
    suppressFormula = suppressFormula.slice(0, -3);

    //if all groups are not suppressed then multiply by 1 and should not care about conflict suppress
    //check it with groups.split(',').length equals to sum of all groups suppress values
    suppressFormula = `IF((${
      l3Data.groups.split(',').length
    }=${groupSuppressCountFormula}), 1, IF((CONFLICT_SUPPRESS=1), 0, IF((${suppressFormula}=0), 1, 0)))`;
  }

  return suppressFormula;
}
