import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const EditIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.81836 4.09091C10.1411 6.28332 11.8225 7.9594 13.9093 8.18182"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M10.509 2.91311L3.36998 10.6229C3.10042 10.9156 2.83955 11.4923 2.78738 11.8916L2.46564 14.7661C2.3526 15.8041 3.08302 16.5139 4.09171 16.3364L6.89167 15.8485C7.28297 15.7775 7.83079 15.4847 8.10036 15.1831L15.2394 7.47331C16.4742 6.14251 17.0307 4.6254 15.109 2.77115C13.1959 0.934653 11.7438 1.58231 10.509 2.91311Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
