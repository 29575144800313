import { FringesColumnAlias, FringesColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { IFringe, IFringesSheet } from '@/interfaces/IFormulaSheet';
import { IFringeData } from '@/interfaces/IFringeData';

export const transformFringes = (fringesData: Array<IFringeData>): IFringesSheet => {
  const fringeSheet: IFringesSheet = fringesData?.map((fringe: IFringeData, index: number) => {
    return fringeDataToFormulaTransformation(fringe, index);
  });

  const totalFringeRow = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', // rate
    '', // unitDesc
    '', // cap
    '', // cu
    `=SUM(${FringesColumnAlias.Total}0:${FringesColumnAlias.Total}${fringeSheet.length})`, // total
    `=SUM(${FringesColumnAlias.Usage}0:${FringesColumnAlias.Usage}${fringeSheet.length})`, // usage
    `=SUM(${FringesColumnAlias.Attachments}0:${FringesColumnAlias.Attachments}${fringeSheet.length})`, // attachments
    '', // notes
    'T', // row type
    `=ROW(${FringesColumnAlias.Code}${fringeSheet.length + 1})-1`,
  ];

  fringeSheet.push(totalFringeRow);
  return fringeSheet;
};

export const fringeDataToFormulaTransformation = (fringe: IFringeData, index: number) => {
  return [
    fringe.id,
    fringe.category,
    fringe.code,
    fringe.description,
    fringe.rate,
    fringe.unitDesc,
    fringe.cap,
    fringe.cu,
    0,
    0,
    fringe.attachments,
    fringe.notes,
    'D',
    `=ROW(${FringesColumnAlias.Code}${index + 1})-1`,
    `=IF(${FringesColumnAlias.UnitDescription}${index + 1}="%",${FringesColumnAlias.Rate}${
      index + 1
    }/100,IF(OR(${FringesColumnAlias.UnitDescValue}${index + 1}=0 ,${
      FringesColumnAlias.UnitDescValue
    }${index + 1}="") , 0 , ${FringesColumnAlias.Rate}${index + 1}/${
      FringesColumnAlias.UnitDescValue
    }${index + 1}))`,
    getFringesUnitDescValue(fringe.unitDesc),
    `=${fringe.cu}`,
  ] as IFringe;
};

export const fringesToDataSheetTransformation = (fringe: IFringe) => {
  return {
    id: fringe[FringesColumnIndexes.id],
    category: fringe[FringesColumnIndexes.category],
    code: fringe[FringesColumnIndexes.code],
    description: fringe[FringesColumnIndexes.description],
    rate: fringe[FringesColumnIndexes.rate],
    unitDesc: fringe[FringesColumnIndexes.unitDesc] as string,
    cap: fringe[FringesColumnIndexes.cap],
    cu: fringe[FringesColumnIndexes.cu],
    usage: fringe[FringesColumnIndexes.usage],
    attachments: fringe[FringesColumnIndexes.attachments],
    notes: fringe[FringesColumnIndexes.notes],
  } as IFringeData;
};

export const getFringesUnitDescValue = (unitDesc: string): string => {
  return `=${unitDesc === '%' ? 0 : unitDesc}`;
};
