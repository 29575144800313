import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ArrowRightIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.9333 9.61583L12.1275 7.80999L9.18496 4.86749C8.56162 4.25333 7.49829 4.69333 7.49829 5.57333V11.2842V16.4267C7.49829 17.3067 8.56162 17.7467 9.18496 17.1233L13.9333 12.375C14.6941 11.6233 14.6941 10.3767 13.9333 9.61583Z"
        fill="#fffff"
      />
    </SvgIcon>
  );
};
