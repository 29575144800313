import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BudgetLevels, SheetNames, TabIndexes, TabIndexToSheetNameMapper } from '@/enums';
import { shiftElementToFirstPosition } from '@/utils';
import { selectedSheetIdsAtom } from '@/atoms/DataSheetAtom';
import {
  budgetSheetLevelAtom,
  currentTabIndexAtom,
  IsSearchingType,
  IsSearchTriggeredType,
  SearchedQueryType,
  SearchResultsType,
  SelectedResultKeyType,
  SelectedResultType,
} from '@/atoms/GlobalAtoms';
import {
  GlobalSearchResultType,
  retrieveSearchValuesFromAllSheets,
} from '@/helpers/GlobalSearchHelper';
import globalSearchSort from './globalSearchSort';
import useGlobalSearchStateService from './useGlobalSearchStateService';

export interface GlobalSearchService {
  setSelectedResultKey: (newSelectedResultKey: SelectedResultKeyType) => void;
  getSelectedResultKey: () => SelectedResultKeyType;
  getSearchedQuery: () => SearchedQueryType;
  getSearchResults: () => SearchResultsType;
  getIsSearchTriggered: () => IsSearchTriggeredType;
  setIsSearchTriggered: (newIsSearchTriggered: IsSearchTriggeredType) => void;
  setIsSearching: (newIsSearching: IsSearchingType) => void;
  getIsSearching: () => IsSearchingType;
  setSearchedQuery: (newQuery: SearchedQueryType) => void;
  performFullSearch: (
    allSheetsValues: Record<string, Array<Array<CellValue>>>,
    navigateToFirstSearch?: boolean,
  ) => void;
  performPartialSearch: (searchedResults: GlobalSearchResultType[], sheetName: string) => void;
  setSelectedResult: (newSelectedResult: SelectedResultType) => void;
  getSelectedResult: () => SelectedResultType;
  clearAllResults: () => void;
  getFirstKeyAndValues: <T>(results: Record<string, T>) => Record<string, T> | undefined;
  getSearchResultsBySheetName: (sheetName: string) => GlobalSearchResultType[];
  moveKeyToBeginning: <T>(keyToMove: string, sourceObject: Record<string, T>) => Record<string, T>;
  removeResultKey: <T>(
    removeObject: Record<string, T>,
    sourceObject: Record<string, T>,
  ) => Record<string, T>;
  getFilteredResults: <T>(results: Record<string, T>, prefix: string) => Record<string, T>;
  getCurrentSheetName: () => string;
  clearSearchQuery: () => void;
}

const useGlobalSearchService = (): GlobalSearchService => {
  const {
    setSearchedQuery,
    getSearchedQuery,
    setSelectedResult,
    getSelectedResult,
    setIsSearchTriggered,
    getIsSearchTriggered,
    setIsSearching,
    getIsSearching,
    setSelectedResultKey,
    getSelectedResultKey,
    setSearchResults,
    getSearchResults,
  } = useGlobalSearchStateService();

  const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentTabIndexAtom);
  const selectedSheet = useRecoilValue(selectedSheetIdsAtom);
  const currentSheetLevel = useRecoilValue(budgetSheetLevelAtom);

  const getFirstKeyAndValues = <T>(results: Record<string, T>): Record<string, T> | undefined => {
    const firstKey = Object.keys(results)[0];
    if (!firstKey) return;
    const firstValues = results[firstKey];
    return { [firstKey]: firstValues };
  };

  // clear all search results
  const clearAllResults = useCallback((): void => {
    setIsSearchTriggered(false);
    setSelectedResult(undefined);
    setIsSearching(false);
    setSearchResults(undefined);
    setSelectedResultKey(undefined);
  }, [
    setIsSearchTriggered,
    setSelectedResult,
    setIsSearching,
    setSearchResults,
    setSelectedResultKey,
  ]);

  const clearSearchQuery = () => setSearchedQuery(undefined);

  const getFilteredResults = <T>(results: Record<string, T>, prefix: string): Record<string, T> => {
    return Object.keys(results)
      .filter((key) => key.startsWith(prefix))
      .reduce(
        (result, key) => {
          result[key] = results[key];
          return result;
        },
        {} as Record<string, T>,
      );
  };

  const moveKeyToBeginning = <T>(
    keyToMove: string,
    sourceObject: Record<string, T>,
  ): Record<string, T> => {
    if (!(keyToMove in sourceObject)) {
      return sourceObject; // Key not found, return the original object
    }

    const updatedObject: Record<string, T> = {};
    updatedObject[keyToMove] = sourceObject[keyToMove];

    for (const key in sourceObject) {
      if (key !== keyToMove) {
        updatedObject[key] = sourceObject[key];
      }
    }

    return updatedObject;
  };

  const removeResultKey = <T>(
    removeObject: Record<string, T>,
    sourceObject: Record<string, T>,
  ): Record<string, T> => {
    const updatedObject: Record<string, T> = lodash.cloneDeep(sourceObject);

    for (const keyToRemove in removeObject) {
      if (Object.prototype.hasOwnProperty.call(removeObject, keyToRemove)) {
        delete updatedObject[keyToRemove];
      }
    }

    return updatedObject;
  };

  const sortSearchedResults = useCallback(
    (
      searchedResults: Record<string, GlobalSearchResultType[]>,
      currentSheetName: string,
    ): Record<string, GlobalSearchResultType[]> => {
      let sortedResultKeys = Object.keys(searchedResults).sort((a, b) =>
        globalSearchSort(a, b, currentSheetName),
      );
      sortedResultKeys = shiftElementToFirstPosition<string>(sortedResultKeys, currentSheetName);
      const sortedResults: Record<string, GlobalSearchResultType[]> = {};
      for (const key of sortedResultKeys) {
        sortedResults[key] = searchedResults[key];
      }

      return sortedResults;
    },
    [],
  );

  const getSheetNameBySearchResult = (searchResult: GlobalSearchResultType): string | undefined => {
    if (searchResult.tabIndex === TabIndexes.BUDGET) {
      const budgetLevelSheetNames = {
        [BudgetLevels.FIRST_LEVEL]: SheetNames.L1,
        [BudgetLevels.SECOND_LEVEL]: `${SheetNames.L2}_${searchResult.level1Id}`,
        [BudgetLevels.THIRD_LEVEL]: `${SheetNames.L3}_${searchResult.level2Id}`,
      };

      return budgetLevelSheetNames[searchResult.level];
    }

    return TabIndexToSheetNameMapper[searchResult.tabIndex] as SheetNames;
  };

  const getCurrentSheetName = useCallback((): string => {
    if (currentTabIndex === TabIndexes.BUDGET) {
      const budgetLevelSheetNames = {
        [BudgetLevels.FIRST_LEVEL]: SheetNames.L1,
        [BudgetLevels.SECOND_LEVEL]: `${SheetNames.L2}_${selectedSheet.l1SheetId}`,
        [BudgetLevels.THIRD_LEVEL]: `${SheetNames.L3}_${selectedSheet.l2SheetId}`,
      };

      return budgetLevelSheetNames[currentSheetLevel];
    }

    return TabIndexToSheetNameMapper[currentTabIndex as TabIndexes] as SheetNames;
  }, [currentSheetLevel, currentTabIndex, selectedSheet.l1SheetId, selectedSheet.l2SheetId]);

  const groupSearchedResultsBySheetNames = useCallback(
    (
      globalSearchResults: Array<GlobalSearchResultType>,
    ): Record<string, Array<GlobalSearchResultType>> => {
      return globalSearchResults.reduce(
        (acc, globalSearchResult) => {
          const sheetName = getSheetNameBySearchResult(globalSearchResult);
          if (!sheetName) return acc;

          if (!acc[sheetName]) {
            acc[sheetName] = [];
          }

          acc[sheetName].push(globalSearchResult);

          return acc;
        },
        {} as Record<string, Array<GlobalSearchResultType>>,
      );
    },
    [],
  );

  // perform partial global search
  const performPartialSearch = useCallback(
    (searchedResults: GlobalSearchResultType[], sheetName: string): void => {
      setIsSearching(true);
      const navigationResults = getSearchResults();

      if (!navigationResults) {
        clearAllResults();
        return;
      }

      const navigationResultsClone = lodash.cloneDeep(navigationResults);
      const firstSheetNameKey = Object.keys(navigationResults)[0];

      if (searchedResults.length <= 0) {
        const navigationKeys = Object.keys(navigationResultsClone);
        let currentNavigationIndex = navigationKeys.indexOf(sheetName);

        delete navigationResultsClone[sheetName];

        if (currentNavigationIndex >= Object.keys(navigationResultsClone).length) {
          currentNavigationIndex -= 1;
        }

        // current key of deleted index
        const currentKey = Object.keys(navigationResultsClone)[currentNavigationIndex];
        const currentValue = navigationResultsClone[currentKey];
        const isCurrentKeyOrValueUndefined = !currentKey || !currentValue;
        const isCurrentKeyAndValueNotNull = currentKey && currentValue;
        const isPreviousSearchResultsEmpty = Object.keys(navigationResultsClone).length === 0;
        if (isCurrentKeyOrValueUndefined && isPreviousSearchResultsEmpty) {
          setIsSearchTriggered(false);
          setSelectedResult(undefined);
          setIsSearching(false);
          setSearchResults({});
          setSelectedResultKey(undefined);
          return;
        }
        if (isCurrentKeyAndValueNotNull) {
          setSelectedResultKey(currentKey);
          setSelectedResult({ [currentKey]: currentValue });
        }
        setSearchResults(navigationResultsClone);
        return;
      }
      const formattedSearchedResults = groupSearchedResultsBySheetNames(searchedResults);
      const sortedSearchedResults = sortSearchedResults(formattedSearchedResults, sheetName);

      const resolvedResultValue: GlobalSearchResultType[] | undefined =
        sortedSearchedResults[sheetName];
      if (resolvedResultValue === undefined) {
        return;
      }

      navigationResultsClone[sheetName] = resolvedResultValue;
      const resolvedSelectedValue = { [sheetName]: resolvedResultValue };
      const sortedResults = sortSearchedResults(navigationResultsClone, firstSheetNameKey);
      setSelectedResultKey(sheetName);
      setSelectedResult(resolvedSelectedValue);
      setSearchResults(sortedResults);
    },
    [
      setIsSearching,
      getSearchResults,
      groupSearchedResultsBySheetNames,
      sortSearchedResults,
      setSelectedResultKey,
      setSelectedResult,
      setSearchResults,
      clearAllResults,
      setIsSearchTriggered,
    ],
  );

  const handleEmptySearchResults = useCallback(() => {
    setIsSearchTriggered(false);
    setSelectedResult(undefined);
    setIsSearching(false);
    setSearchResults({});
    setSelectedResultKey(undefined);
  }, [
    setIsSearchTriggered,
    setIsSearching,
    setSearchResults,
    setSelectedResult,
    setSelectedResultKey,
  ]);

  const handleSearchResultNavigation = useCallback(
    (sortedResults: Record<string, GlobalSearchResultType[]>, currentSheetName: string) => {
      const currentSheetSearchResultValues: GlobalSearchResultType[] | undefined =
        sortedResults[currentSheetName];

      if (currentSheetSearchResultValues) {
        const firstResult = { [currentSheetName]: currentSheetSearchResultValues };
        setSelectedResultKey(currentSheetName);
        setSelectedResult(firstResult);
        setSearchResults(sortedResults);
      } else {
        const navigationKeys = Object.keys(sortedResults);
        let currentNavigationIndex = navigationKeys.indexOf(currentSheetName);

        delete sortedResults[currentSheetName];

        if (currentNavigationIndex === -1) {
          currentNavigationIndex = 0;
        }
        if (currentNavigationIndex >= Object.keys(sortedResults).length) {
          currentNavigationIndex -= 1;
        }

        // current key of deleted index
        const currentKey = Object.keys(sortedResults)[currentNavigationIndex];
        const currentValue = sortedResults[currentKey];
        const isCurrentKeyOrValueUndefined = !currentKey || !currentValue;
        const isCurrentKeyAndValueNotNull = currentKey && currentValue;
        const isPreviousSearchResultsEmpty = Object.keys(sortedResults).length === 0;
        if (isCurrentKeyOrValueUndefined && isPreviousSearchResultsEmpty) {
          handleEmptySearchResults();
        }
        if (isCurrentKeyAndValueNotNull) {
          setSelectedResultKey(currentKey);
          setSelectedResult({ [currentKey]: currentValue });
        }
        setSearchResults(sortedResults);
      }

      setIsSearchTriggered(false);
      setIsSearching(false);
    },
    [
      handleEmptySearchResults,
      setIsSearchTriggered,
      setIsSearching,
      setSearchResults,
      setSelectedResult,
      setSelectedResultKey,
    ],
  );

  const handleFirstSearch = useCallback(
    (sortedResults: Record<string, GlobalSearchResultType[]>) => {
      const firstSheetSearchResultValues: GlobalSearchResultType[] =
        Object.values(sortedResults)[0];
      const firstValueIndex = firstSheetSearchResultValues[0].tabIndex;
      const firstSheetSearchResultSheetName: string = Object.keys(sortedResults)[0];
      const firstResult = { [firstSheetSearchResultSheetName]: firstSheetSearchResultValues };
      setCurrentTabIndex(firstValueIndex);
      setSelectedResultKey(firstSheetSearchResultSheetName);
      setSelectedResult(firstResult);
      setSearchResults(sortedResults);
    },
    [setCurrentTabIndex, setSearchResults, setSelectedResult, setSelectedResultKey],
  );

  // perform global search
  const performFullSearch = useCallback(
    (
      allSheetsValues: Record<string, Array<Array<CellValue>>>,
      navigateToFirstSearch = true,
    ): void => {
      setIsSearchTriggered(true);
      setIsSearching(true);
      const searchedQuery = getSearchedQuery();
      if (!searchedQuery || searchedQuery === '') {
        clearAllResults();
        return;
      }

      const searchedResults = retrieveSearchValuesFromAllSheets({
        keyword: searchedQuery,
        valueSheet: allSheetsValues,
      });
      if (!searchedResults || searchedResults.length <= 0) {
        handleEmptySearchResults();
        return;
      }

      const currentSheetName = getCurrentSheetName();
      const formattedResults = groupSearchedResultsBySheetNames(searchedResults);
      const sortedResults = sortSearchedResults(formattedResults, currentSheetName);

      if (!navigateToFirstSearch) {
        handleSearchResultNavigation(sortedResults, currentSheetName);
      } else {
        handleFirstSearch(sortedResults);
      }
    },
    [
      setIsSearchTriggered,
      setIsSearching,
      getSearchedQuery,
      getCurrentSheetName,
      groupSearchedResultsBySheetNames,
      sortSearchedResults,
      clearAllResults,
      handleEmptySearchResults,
      handleSearchResultNavigation,
      handleFirstSearch,
    ],
  );

  const getSearchResultsBySheetName = useCallback(
    (sheetName: string): GlobalSearchResultType[] => {
      const navigationResults = getSearchResults();
      if (!navigationResults || !(sheetName in navigationResults)) return [];

      return navigationResults[sheetName];
    },
    [getSearchResults],
  );

  return {
    getSelectedResultKey,
    setSelectedResultKey,
    setSearchedQuery,
    getSearchedQuery,
    getSearchResults,
    getIsSearchTriggered,
    setIsSearchTriggered,
    getIsSearching,
    setIsSearching,
    setSelectedResult,
    getSelectedResult,
    performFullSearch,
    performPartialSearch,
    clearAllResults,
    getFirstKeyAndValues,
    getSearchResultsBySheetName,
    moveKeyToBeginning,
    removeResultKey,
    getFilteredResults,
    getCurrentSheetName,
    clearSearchQuery,
  };
};

export default useGlobalSearchService;
