import { Typography } from '@mui/material';
import { SuspenseSpinner } from '@/components/common/SuspenseSpinner';

interface IFullScreenProps {
  isLoading: boolean;
  message: string;
}

const FullScreenSpinner = ({ isLoading, message }: IFullScreenProps) => {
  if (!isLoading) return null;

  return (
    <SuspenseSpinner>
      <Typography variant="body1" component="p">
        {message}
      </Typography>
    </SuspenseSpinner>
  );
};

export default FullScreenSpinner;
