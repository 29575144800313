import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseSquareIcon } from '@/components/icon';

type DialogBoxContainerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  dialogActions?: React.ReactNode;
  dialogTitle: string;
  boxWidth?: string;
  showCloseButton?: boolean;
  onDialogBoxClose?: () => void;
} & DialogProps;

const StyledDialogTitleBar = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem;
  background-color: black;
  color: white;
  font-weight: 600;
`;

export const DialogBoxContainer = ({
  open,
  setOpen,
  onDialogBoxClose,
  children,
  dialogActions,
  dialogTitle,
  boxWidth,
  showCloseButton = true,
  ...props
}: DialogBoxContainerProps) => (
  <div>
    <Dialog
      {...props}
      open={open}
      onClose={() => {
        onDialogBoxClose?.();
        setOpen(false);
      }}
      scroll="paper"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: boxWidth,
          },
        },
      }}
    >
      <StyledDialogTitleBar>
        <div>{dialogTitle}</div>
        {showCloseButton && (
          <CloseSquareIcon
            fontSize="medium"
            onClick={() => {
              onDialogBoxClose?.();
              setOpen(false);
            }}
            sx={{ cursor: 'pointer', color: 'black', stroke: 'white' }}
          />
        )}
      </StyledDialogTitleBar>
      <DialogContent
        sx={{
          paddingBottom: '0px !important',
        }}
      >
        <Stack
          sx={{
            padding: '24px 0px 0 0px',
          }}
        >
          {children}
        </Stack>
      </DialogContent>
      {dialogActions && (
        <DialogActions
          sx={{
            padding: '15px 25px 15px 25px',
          }}
        >
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  </div>
);
