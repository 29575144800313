import { CardHeader, CardHeaderProps } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = CardHeaderProps & {
  title?: string;
  subheader?: string;
};

export const FormCardHeader = ({ title, subheader, ...props }: Props): ReactElement => (
  <CardHeader
    {...props}
    title={title}
    subheader={subheader}
    sx={{
      '& .MuiCardHeader-content': {
        display: 'flex',
      },
      background: '#262626',
      ...(props.sx || {}),
    }}
    titleTypographyProps={{
      sx: {
        flexGrow: 1,
        fontSize: '16px',
        color: '#fff',
        ...(props.titleTypographyProps?.sx || {}),
      },
    }}
    subheaderTypographyProps={{
      sx: {
        flexShrink: 0,
        fontSize: '16px',
        color: '#fff',
        ...(props.subheaderTypographyProps?.sx || {}),
      },
    }}
  />
);
