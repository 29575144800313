import type { RangeType, SimpleCellCoords } from 'handsontable/common';

/**
 * Transforms a selection range or cells into an array of individual cell coordinates.
 * This utility is used when a user intends to paste values into a single cell
 * or across a range of cells, facilitating the identification of each cell's coordinates
 * within the selected range(s).
 *
 * @example
 * // To flatten a range spanning from cell at row 2, column 5 to cell at row 5, column 5:
 * const ranges = [{ startCol: 5, startRow: 2, endCol: 5, endRow: 5 }];
 * const flattenedCoords = flattenRangeToCellCoords(ranges);
 * // flattenedCoords would be:
 * [{ row: 2, col: 5 }, { row: 3, col: 5 }, { row: 4, col: 5 }, { row: 5, col: 5 }]
 */
export const flattenRangeToCellCoords = (ranges: RangeType[]): SimpleCellCoords[] => {
  if (ranges.length === 0) return [];

  return ranges.flatMap(({ startCol, startRow, endCol, endRow }) => {
    if (endCol != null && endRow != null) {
      return Array.from(
        { length: (endRow - startRow + 1) * (endCol - startCol + 1) },
        (_, index) => {
          const row = startRow + Math.floor(index / (endCol - startCol + 1));
          const col = startCol + (index % (endCol - startCol + 1));
          return { row, col };
        },
      );
    }
    return [{ row: startRow, col: startCol }];
  });
};
