import type { Selection } from 'handsontable/plugins/contextMenu';

/**
 * Converts a zero-based column index to its corresponding spreadsheet column label.
 *
 * @param {number} columnIndex - The zero-based column index to convert.
 * @returns {string} The spreadsheet column label (e.g., "A", "B", ..., "AA").
 */
const columnIndexToLetter = (columnIndex: number) => {
  const ALPHABET_LENGTH = 26;
  let letter = '';

  while (columnIndex >= 0) {
    const remainder = columnIndex % ALPHABET_LENGTH;
    letter = String.fromCharCode(65 + remainder) + letter;
    columnIndex = Math.floor(columnIndex / ALPHABET_LENGTH) - 1;
  }

  return letter;
};

/**
 * Converts an array cell selections to a cell label representation.
 * Generates cell labels (e.g., "A1") for individual selections or a range (e.g., "A1:C1").
 */
export const selectionsToCellLabels = (selections: Selection[]) => {
  const cellAddresses: string[] = [];

  selections.forEach((selection) => {
    for (let row = selection.start.row; row <= selection.end.row; row++) {
      for (let col = selection.start.col; col <= selection.end.col; col++) {
        const columnLetter = columnIndexToLetter(col);
        const cellCoordinate = `${columnLetter}${row + 1}`;
        cellAddresses.push(cellCoordinate);
      }
    }
  });

  const firstCellAddress = cellAddresses[0];
  const lastCellAddress = cellAddresses[cellAddresses.length - 1];

  if (cellAddresses.length <= 1 || firstCellAddress === lastCellAddress) {
    return firstCellAddress;
  }

  return `${firstCellAddress}:${lastCellAddress}`;
};
