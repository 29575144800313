import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import {
  BudgetLevels,
  FringesColumnIndexes,
  L1ColumnIndexes,
  L2ColumnIndexes,
  L3ColumnIndexes,
  SheetNames,
} from '@/enums';
import { generatedId } from '@/utils';
import { FringeAllocationDataKeys } from '@/data/meta/FringeAllocationData';
import { getLevel2RequestedTypeValues } from '@/helpers/L2Helpers';
import { fringeDataToFormulaTransformation } from '@/helpers/Mappers/FringeMapper';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { IFringe, INamedExpressionData } from '@/interfaces/IFormulaSheet';
import { FringeTypes, IFringeData } from '@/interfaces/IFringeData';

export function getEmptyFringe(): IFringeData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    rate: '0',
    unitDesc: '%',
    cap: '0',
    cu: '',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newFringeData: IFringe;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newFringe: IFringeData = getEmptyFringe();
  updatedDataSheet.fringes = updatedDataSheet.fringes || [];
  updatedDataSheet.fringes.splice(index, 0, newFringe);
  const newFringeData = fringeDataToFormulaTransformation(newFringe, index);
  const updatedIds = [newFringe.id.toString()];
  return { updatedDataSheet, newFringeData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: IFringeData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.fringes = updatedDataSheet.fringes.map((fringe) => {
    if (fringe.id === record.id) {
      updatedIds.push(fringe.id.toString());
      return { ...fringe, ...record };
    }
    return fringe;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.fringes[0] = {
      ...getEmptyFringe(),
      id: updatedDataSheet.fringes[0].id,
    };
  }
  const removedFringeSheet: IFringeData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.fringes.forEach((fringeData, index) => {
    if (!removeIndexes.includes(index)) {
      removedFringeSheet.push(fringeData);
    } else {
      updatedIds.push(fringeData.id.toString());
    }
  });
  updatedDataSheet.fringes = removedFringeSheet;
  return { updatedDataSheet, updatedIds };
}

export function movedIndexRow(dataSheet: IDataSheet, movedIndexes: number[], finalIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: IFringeData[] = [];
  movedIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.fringes[index]);
  });
  if (movedElements.length === movedIndexes.length) {
    dataSheetCopy.fringes.splice(movedIndexes[0], movedIndexes.length);
    dataSheetCopy.fringes.splice(finalIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedFringeRow(copiedRow: IFringeData): IFringeData {
  const updatedRow = getEmptyFringe();

  // columns that need to be updated before pasting
  const reviseColumns = [
    FringeTypes.CATEGORY,
    FringeTypes.DESCRIPTION,
    FringeTypes.RATE,
    FringeTypes.UNIT_DESCRIPTION,
    FringeTypes.CAP,
    FringeTypes.CU,
  ];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeFringeCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  removeNamedExpressionCodes: string[];
  emptyCells: ICellCoords[];
  updatedNamedExpressions: INamedExpressionData[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const removeNamedExpressionCodes: string[] = [];
  const emptyCells: ICellCoords[] = [];
  const updatedNamedExpressions: INamedExpressionData[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.fringes.forEach((data: IFringeData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof IFringeData] = '';
        }
        if (item.col === FringesColumnIndexes.code) {
          removeNamedExpressionCodes.push(dataSheet[DateSheetAttributeType.FRINGES][item.row].code);
        }
        const code = dataSheet[DateSheetAttributeType.FRINGES][item.row].code;
        if (
          (item.col === FringesColumnIndexes.rate || item.col === FringesColumnIndexes.unitDesc) &&
          !removeNamedExpressionCodes.includes(code)
        ) {
          updatedNamedExpressions.push({ code, expression: '0' });
        }
      });
    });
  }
  return {
    updatedDataSheet,
    updatedIds,
    removeNamedExpressionCodes,
    emptyCells,
    updatedNamedExpressions,
  };
}

export interface Level1FringeValidationParams {
  fringeCalc: string;
  allSheetValues: Record<string, Array<Array<CellValue>>>;
}

export type Level1FringeValidationFunction = (
  params: Level1FringeValidationParams,
) => Record<string, Array<Array<CellValue>>>;

export const level1FringeValidation: Level1FringeValidationFunction = ({
  fringeCalc,
  allSheetValues,
}) => {
  const allSheetValuesClone = lodash.cloneDeep(allSheetValues);
  const level1CellValues = allSheetValuesClone[SheetNames.L1];

  if (FringeAllocationDataKeys.BUDGET === fringeCalc) {
    allSheetValuesClone[SheetNames.L1] = level1CellValues?.filter(
      (rowValues) => rowValues[L1ColumnIndexes.rowType] !== 'FS',
    );
    return allSheetValuesClone;
  }

  if (FringeAllocationDataKeys.PRODUCTION === fringeCalc) {
    allSheetValuesClone[SheetNames.L1] = level1CellValues?.filter(
      (rowValues) => rowValues[L1ColumnIndexes.rowType] !== 'F',
    );
    return allSheetValuesClone;
  }

  // default validation
  allSheetValuesClone[SheetNames.L1] = level1CellValues?.filter(
    (rowValues) =>
      rowValues[L1ColumnIndexes.rowType] !== 'FS' && rowValues[L1ColumnIndexes.rowType] !== 'F',
  );
  return allSheetValuesClone;
};

export interface Level2FringeValidationParams {
  fringeCalc: string;
  allSheetValues: Record<string, Array<Array<CellValue>>>;
  level2ParentIds?: Array<string>;
}

export type Level2FringeValidationFunction = (
  params: Level2FringeValidationParams,
) => Record<string, Array<Array<CellValue>>>;

export const level2FringeValidation: Level2FringeValidationFunction = ({
  fringeCalc,
  allSheetValues,
  level2ParentIds,
}) => {
  const allSheetValuesClone = lodash.cloneDeep(allSheetValues);

  if (FringeAllocationDataKeys.CATEGORY === fringeCalc) {
    return allSheetValuesClone;
  }

  // default validation
  const level1Ids =
    level2ParentIds ??
    allSheetValuesClone[SheetNames.L1]?.map((rowValues) => rowValues[L1ColumnIndexes.id]);

  level1Ids.forEach((level1Id) => {
    const level2SheetId = `${SheetNames.L2}_${level1Id}`;
    const level2CellValues = allSheetValuesClone[level2SheetId];

    allSheetValuesClone[level2SheetId] = level2CellValues?.filter((rowValues) => {
      return rowValues[L2ColumnIndexes.rowType] !== 'F';
    });
  });
  return allSheetValuesClone;
};

export interface Level3FringeValidationParams {
  fringeCalc: string;
  allSheetValues: Record<string, Array<Array<CellValue>>>;
  level3ParentIds?: Array<string>;
}

export type Level3FringeValidationFunction = (
  params: Level3FringeValidationParams,
) => Record<string, Array<Array<CellValue>>>;

export const level3FringeValidation: Level3FringeValidationFunction = ({
  fringeCalc,
  allSheetValues,
  level3ParentIds,
}) => {
  const allSheetValuesClone = lodash.cloneDeep(allSheetValues);

  if (FringeAllocationDataKeys.ACCOUNT === fringeCalc) {
    return allSheetValuesClone;
  }

  // default validation
  const level2Ids =
    level3ParentIds ??
    getLevel2RequestedTypeValues<string>(L2ColumnIndexes.id, allSheetValuesClone);
  level2Ids.forEach((level2Id) => {
    const level3SheetId = `${SheetNames.L3}_${level2Id}`;
    const level3CellValues = allSheetValuesClone[level3SheetId];

    allSheetValuesClone[level3SheetId] = level3CellValues?.filter(
      (rowValues) => rowValues[L3ColumnIndexes.rowType] !== 'F',
    );
  });

  return allSheetValuesClone;
};

export const sheetLevelToFringeValidationFunctionMap: Record<
  BudgetLevels,
  Level1FringeValidationFunction | Level2FringeValidationFunction | Level3FringeValidationFunction
> = {
  [BudgetLevels.FIRST_LEVEL]: level1FringeValidation,
  [BudgetLevels.SECOND_LEVEL]: level2FringeValidation,
  [BudgetLevels.THIRD_LEVEL]: level3FringeValidation,
};

export const getFringeValidatedAllSheetValues = (
  fringeCalc: string,
  allSheetsValues: Record<string, Array<Array<CellValue>>>,
): Record<string, Array<Array<CellValue>>> => {
  let allSheetValuesClone = lodash.cloneDeep(allSheetsValues);

  // Level 1 Fringe Validation
  allSheetValuesClone = level1FringeValidation({ fringeCalc, allSheetValues: allSheetValuesClone });

  // Level 2 Fringe Validation
  allSheetValuesClone = level2FringeValidation({ fringeCalc, allSheetValues: allSheetValuesClone });

  // Level 3 Fringe Validation
  allSheetValuesClone = level3FringeValidation({ fringeCalc, allSheetValues: allSheetValuesClone });

  return allSheetValuesClone;
};
