import { Link } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';
import { cellErrorDialogAtom } from '@/atoms/DataSheetAtom';
import { ICellError } from '@/interfaces/ICellError';
import { StyledTable, StyledTableCell, StyledTableHeader, StyledTableRow } from './style';

interface Props {
  errorDetails: ICellError[];
  errorLocation: string;
  onNavigateToError?: any;
}

export const CellErrorView = ({ errorDetails, errorLocation, onNavigateToError }: Props) => {
  const [isErrorDialog, setErrorDialog] = useRecoilState(cellErrorDialogAtom);
  const navigateToError = (errorDetail: any) => {
    onNavigateToError(errorDetail.address);
  };
  return (
    <>
      <p>
        <div>
          Oops! Some cell errors detected while generating the {`${errorLocation}`}. Please fix and
          retry.
        </div>
      </p>
      <StyledTable>
        <thead>
          <tr>
            <StyledTableHeader>Sheet Name</StyledTableHeader>
            <StyledTableHeader>Error Type</StyledTableHeader>
            <StyledTableHeader>Description</StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {errorDetails.map((errorDetail, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell
                onClick={() => {
                  navigateToError(errorDetail.address);
                  setErrorDialog(false);
                }}
              >
                <Link style={{ cursor: 'pointer' }}>{errorDetail.address}</Link>
              </StyledTableCell>
              <StyledTableCell>{errorDetail.value}</StyledTableCell>
              <StyledTableCell>{errorDetail.message}</StyledTableCell>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};
