export const checkPasswordStrength = (password: string) => {
  let score = 0;

  // Condition 1: Length should be at least 8 characters
  if (password.length >= 8) {
    score++;
  }

  // Condition 2: Contains at least 1 number
  if (/\d/.test(password)) {
    score++;
  }

  // Condition 3: Contains at least 1 special character
  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
    score++;
  }

  // Condition 4: Contains at least 1 uppercase letter
  if (/[A-Z]/.test(password)) {
    score++;
  }

  // Condition 5: Contains at least 1 lowercase letter
  if (/[a-z]/.test(password)) {
    score++;
  }

  return score * 20;
};
