import { SheetNames } from './SheetNames';

export enum TabIndexes {
  BUDGET = 0,
  //  CASHFLOW = 1,
  //  CAST_MANAGER = 2,
  GLOBALS = 1,
  GROUPS = 2,
  FRINGES = 3,
  SETS = 4,
  CTD = 5,
  LOCATIONS = 6,
  CURRENCY = 7,
  UNIT_DESC = 8,
}

export interface TabIndexToBudgetSheetName {
  [TabIndexes.BUDGET]: Array<SheetNames>;
}

export type TabIndexToListSheetName = {
  [key in Exclude<TabIndexes, TabIndexes.BUDGET>]: SheetNames | undefined;
};

export type TabIndexToSheetNameMapper = TabIndexToBudgetSheetName & TabIndexToListSheetName;

export const TabIndexToSheetNameMapper: TabIndexToSheetNameMapper = {
  [TabIndexes.BUDGET]: [SheetNames.L1, SheetNames.L2, SheetNames.L3],
  //  [TabIndexes.CASHFLOW]: undefined,
  //  [TabIndexes.CAST_MANAGER]: undefined,
  [TabIndexes.GLOBALS]: SheetNames.GLOBALS,
  [TabIndexes.GROUPS]: SheetNames.GROUPS,
  [TabIndexes.FRINGES]: SheetNames.FRINGES,
  [TabIndexes.SETS]: SheetNames.SETS,
  [TabIndexes.CTD]: SheetNames.COSTS_TO_DATE,
  [TabIndexes.LOCATIONS]: SheetNames.LOCATIONS,
  [TabIndexes.CURRENCY]: SheetNames.CURRENCY,
  [TabIndexes.UNIT_DESC]: SheetNames.UNIT_DESCRIPTIONS,
};
