import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100vw;
  background: rgba(255, 255, 255, 0.9);
`;

export const HeaderPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100px;
  background: #ffffff;
`;

export const HeaderPanelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const MainPanel = styled.div`
  display: block;
  width: 100%;
  width: 100%;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  padding: 4;
`;

export const FooterPanel = styled.div`
  display: block;
  width: 100%;
  height: 50px;
`;
