import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ReceiveSquareGrayIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        fill="none"
        viewBox="0 0 19 18"
      >
        <g
          stroke="#747474"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          opacity="0.5"
        >
          <path d="M7.25 16.5h4.5C15.5 16.5 17 15 17 11.25v-4.5C17 3 15.5 1.5 11.75 1.5h-4.5C3.5 1.5 2 3 2 6.75v4.5C2 15 3.5 16.5 7.25 16.5z"></path>
          <path d="M7.25 8.632l2.25 2.25 2.25-2.25M9.5 10.883v-6M5 12.383c2.918.975 6.082.975 9 0"></path>
        </g>
      </svg>
    </SvgIcon>
  );
};
