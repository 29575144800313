import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LocalStorageVariables, S3ObjectType } from '@/enums';
import { useField } from '@/hooks';
import { emailValidator, maxCharValidator, phoneNumberValidator } from '@/utils';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { fileUrlAtom, rootFolder } from '@/atoms/GlobalAtoms';
import {
  DialogBoxContainer,
  InputTextField,
  MainButton,
  MobileTextField,
} from '@/components/common';
import { useToaster } from '@/components/common/Toaster';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IShareFile } from '@/interfaces/IShareFile';
import DataSheetApiService from '@/services/DataSheetApiService';
import { BoxButtonRow } from './style';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ShareDialogBox = ({ open, setOpen }: Props) => {
  const snackBar = useToaster();
  const [selectedCode, setSelectedCode] = useState({ dialCode: '+61', code: 'AU' });
  const [email, setEmail, emailError] = useField<string>('', emailValidator);
  const [mobile, setMobile, mobileError] = useField<string>('', phoneNumberValidator);

  const [message, setMessage, messageError] = useField<string>('', maxCharValidator);
  const [isValid, setIsValid] = useState<boolean>(false);
  const dataSheet = useRecoilValue(dataSheetAtom);
  const fileUrl = useRecoilValue(fileUrlAtom);
  const rootPath = useRecoilValue(rootFolder);
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(!emailError && !mobileError && !messageError);
  }, [emailError, mobileError, messageError]);

  async function shareSheets(
    username: string,
    filename: string,
    requestBody: IShareFile,
  ): Promise<{ status: string; message: string }> {
    try {
      const response = await DataSheetApiService.shareSheet(username, filename, requestBody);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function shareSheetsOnSave(
    username: string,
    path: string,
    budgetSheet: IDataSheet,
  ): Promise<void> {
    try {
      const { status } = await DataSheetApiService.saveSheetByFileType(
        username,
        budgetSheet,
        path,
        S3ObjectType.JSON_FILE,
      );
      if (status !== 'SUCCESS') {
        snackBar.error('Error saving budget sheet');
        throw new Error("Couldn't save budget sheet");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const handleShare = async () => {
    if (
      email === '' ||
      mobile === '' ||
      message === '' ||
      !isValid ||
      !fileUrl ||
      !rootPath ||
      selectedCode.dialCode === '' ||
      selectedCode.dialCode === undefined
    ) {
      snackBar.error('Please fill all required fields');
      return;
    }

    setLoading(true);
    const fileName = fileUrl.split('/').reverse()[0];

    try {
      await shareSheetsOnSave(rootPath, fileName, dataSheet as IDataSheet);
      const response = await shareSheets(username!, fileUrl, {
        budgetSheet: dataSheet as IDataSheet,
        sharedUserName: email,
        phoneNumber: selectedCode.dialCode + '' + mobile,
        message: message,
        locale: selectedCode.code,
      });
      if (response.status === 'SUCCESS') {
        setOpen(false);
        setLoading(false);
        snackBar.success(`${fileName} shared with ${email}`);
      } else {
        setLoading(false);
        snackBar.error(response.message);
      }
    } catch (error) {
      setLoading(false);

      if (isAxiosError(error)) {
        snackBar.error(error.response?.data);
      } else {
        snackBar.error('Error sharing budget sheet');
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.key === 'Enter') handleShare();
  };

  return (
    <DialogBoxContainer
      open={open}
      onKeyDown={handleKeyDown}
      setOpen={setOpen}
      dialogTitle="Share"
      boxWidth={'500px'}
      dialogActions={
        <BoxButtonRow>
          <MainButton
            onClick={() => handleShare()}
            autoFocus
            color="primary"
            variant="contained"
            loading={loading}
          >
            Send
          </MainButton>
        </BoxButtonRow>
      }
    >
      <InputTextField
        labelText="Email *"
        placeholder="Enter email"
        value={email}
        onChange={setEmail}
        required
        error={emailError}
      />
      <MobileTextField
        labelText="Mobile *"
        placeholder="Enter mobile number"
        setSelectedCode={setSelectedCode}
        selectedCode={selectedCode?.dialCode}
        size="small"
        value={mobile}
        onChange={setMobile}
        error={mobileError}
      />
      <InputTextField
        labelText="Message *"
        placeholder="Enter Input text"
        multiline
        rows={3}
        size="small"
        value={message}
        onChange={setMessage}
        required
        error={messageError}
      />
    </DialogBoxContainer>
  );
};
