import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseSquareIcon } from '@/components/icon';

export interface ConfirmationDialogProps {
  open: boolean;
  onOpen: (open: boolean) => void;
  message: string | React.ReactNode;
  title: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  onClickOk?: () => void;
  isYesNoOptionAllowed: boolean;
  isHtmlMessage?: boolean;
}

export function ConfirmationDialog({
  open,
  title,
  message,
  onOpen,
  onClickNo,
  onClickOk,
  onClickYes,
  isYesNoOptionAllowed,
  isHtmlMessage = false,
}: ConfirmationDialogProps) {
  const handleClose = () => onOpen(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.key === 'Enter') {
      onClickYes?.();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        maxHeight: '500px',
        overflowX: 'hidden',
      }}
      onKeyDown={handleKeyDown}
    >
      <div
        style={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: '400',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}
      >
        <DialogTitle id="alert-dialog-title">{title || 'Message Alert'}</DialogTitle>
        <CloseSquareIcon
          fontSize="medium"
          onClick={() => onOpen(false)}
          sx={{ cursor: 'pointer', color: 'black', stroke: 'white' }}
        />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: 'black',
          }}
          id="alert-dialog-description"
        >
          {isHtmlMessage ? (
            <span dangerouslySetInnerHTML={{ __html: message as string }}></span>
          ) : (
            message
          )}
        </DialogContentText>
      </DialogContent>
      {isYesNoOptionAllowed ? (
        <DialogActions>
          <Button
            style={{ fontSize: '14px', fontWeight: '600', background: 'rgba(247, 155, 42)' }}
            variant="contained"
            onClick={onClickYes}
          >
            Yes
          </Button>
          <Button
            style={{
              fontSize: '14px',
              fontWeight: '600',
              background: '#fff',
              color: 'rgba(116, 116, 116, 1)',
            }}
            variant="contained"
            onClick={onClickNo}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            style={{ fontSize: '14px', fontWeight: '600' }}
            color="warning"
            variant="contained"
            onClick={onClickOk}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ConfirmationDialog;
