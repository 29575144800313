import AccountFormatData from '@/data/meta/AccountFormatData';
import FringeAllocationData from '@/data/meta/FringeAllocationData';

export const metaConfigOptions = [
  { prop: 'fringes.calc', values: FringeAllocationData, index: 3 },
  { prop: 'accountFormat.separator', values: AccountFormatData.separator, index: 1 },
  { prop: 'accountFormat.accountDigits', values: AccountFormatData.accountDigits, index: 2 },
  {
    prop: 'accountFormat.categoryDigits',
    values: AccountFormatData.categoryDigits,
    index: 2,
  },
  { prop: 'accountFormat.setDigits', values: AccountFormatData.setDigits, index: 2 },
  {
    prop: 'numberFormat.rateDecimalPlaces',
    values: AccountFormatData.defaultNumberValueSetWithZero,
    index: 0,
  },
  {
    prop: 'numberFormat.unitsDecimalPlaces',
    values: AccountFormatData.defaultNumberValueSetWithZero,
    index: 0,
  },
  {
    prop: 'numberFormat.xDecimalPlaces',
    values: AccountFormatData.defaultNumberValueSetWithZero,
    index: 0,
  },
];
