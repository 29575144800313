export enum GlobalColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  units = 4,
  calculation = 5,
  usage = 6,
  attachments = 7,
  notes = 8,
  rowType = 9,
  unitsFormula = 10,
  index = 11,
}

export enum VisibleGlobalColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  units = 3,
  calculation = 4,
  usage = 5,
  index = 6,
}

export const globalToVisibleColumnMap: {
  [key in GlobalColumnIndexes]?: VisibleGlobalColumnIndexes;
} = {
  [GlobalColumnIndexes.id]: VisibleGlobalColumnIndexes.id,
  [GlobalColumnIndexes.category]: VisibleGlobalColumnIndexes.category,
  [GlobalColumnIndexes.code]: VisibleGlobalColumnIndexes.code,
  [GlobalColumnIndexes.description]: VisibleGlobalColumnIndexes.description,
  [GlobalColumnIndexes.calculation]: VisibleGlobalColumnIndexes.calculation,
  [GlobalColumnIndexes.units]: VisibleGlobalColumnIndexes.units,
  [GlobalColumnIndexes.usage]: VisibleGlobalColumnIndexes.usage,
};
