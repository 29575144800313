import styled from '@emotion/styled';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DATA_SHEET_FILE_TYPES, LocalStorageVariables, S3ObjectType } from '@/enums';
import { useGlobalSearchService, useOpenedFiles } from '@/hooks';
import { removedExtensionsFilename, getFileType } from '@/utils';
import { fileUrlAtom, isNewlySavedFile, openFileTypeAtom } from '@/atoms/GlobalAtoms';
import { useToaster } from '@/components/common';
import { SaveAsDialog } from '@/components/dialogBoxes';
import { IFileResponse } from '@/components/dialogBoxes/types';
import { useCostTracker } from '@/contexts/CostTrackerContext';
import DataSheetApiService from '@/services/DataSheetApiService';
import FormulaSheet from '@/sheets/FormulaSheet';
import { ConfirmationDialog, useConfirmationDialog } from '../ConfirmationDialog';
import DialogBox from '../dialogBox/DialogBox';
import FileViewer from '../FileViewer';

export interface OpenFileDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
  onSaveAsDialogOpen?: (newFileNameUrl: string) => void;
}

export interface OpenFileFooterProps {
  selectedFile: IFileResponse | null;
  onClickOpenFile: () => void;
  setArchiveToggleStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplateToggleStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledOpenFileDialog = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
`;
const StyledOpenFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export function OpenFileDialog({
  open,
  setOpen,
  formulaSheet,
  onSaveAsDialogOpen,
}: OpenFileDialogProps) {
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const [archiveToggleStatus, setArchiveToggleStatus] = useState<boolean>(false);
  const [templateToggleStatus, setTemplateToggleStatus] = useState<boolean>(false);
  const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);

  const snackBar = useToaster();
  const { openDialog, confirmationDialogProps } = useConfirmationDialog();
  const { removeOpenedFilesFromStorage, checkFileAlreadyOpened } = useOpenedFiles();
  const { resetCostTrackerOptions } = useCostTracker();
  const setOpenFileType = useSetRecoilState(openFileTypeAtom);
  const setIsNewlySavedFile = useSetRecoilState(isNewlySavedFile);
  const [fileUrl, setFileUrl] = useRecoilState(fileUrlAtom);

  const [folderPath, setFolderPath] = useState<string>('');
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  // Global Search Service
  const globalSearchService = useGlobalSearchService();

  const clearDpfTokenAndResetOptions = () => {
    localStorage.removeItem(LocalStorageVariables.DPF_API_ACCESS_TOKEN);
    const event = new CustomEvent('dpfTokenInvalidated_silent');
    window.dispatchEvent(event);

    resetCostTrackerOptions();
  };

  const checkIsFileAlreadyOpened = (fileNameUrl: string): Promise<boolean> => {
    return new Promise((resolve) => {
      checkFileAlreadyOpened({
        fileNameUrl: fileNameUrl,
        onSuccess: () => {
          openDialog({
            message:
              'We have detected that you are trying to open the same budget file in multiple browser tabs. This may lead to unexpected overwriting of changes. Continue?',
            title: 'Warning!',
            onClickNo: () => {
              resolve(true);
              setSelectedFile(null);
            },
            onClickYes: () => {
              resolve(true);
              setSelectedFile(null);
              handleClose();
              setOpenFileType(getFileType(fileNameUrl));
              onSaveAsDialogOpen?.(fileNameUrl);
            },
            isYesNoOptionAllowed: true,
          });
        },
        onFailure: () => {
          resolve(false);
        },
      });
    });
  };

  const handleOpenFile = async () => {
    if (!selectedFile || !username) return;

    const processSelectedFile = async () => {
      clearDpfTokenAndResetOptions();
      removeOpenedFilesFromStorage(fileUrl);
      setIsNewlySavedFile(false);
      setFileUrl(fullFileNamePath);
      await formulaSheet.clearDataSheetOnCache();
      globalSearchService.clearAllResults();
      globalSearchService.clearSearchQuery();

      handleClose();
      setSelectedFile(null);
    };

    if (selectedFile.type === S3ObjectType.USERS_FOLDER) {
      const path = folderPath.concat(selectedFile.filename);
      setFolderPath(path);
      setSelectedFile(null);
      return;
    }

    if (selectedFile.type === S3ObjectType.TEMPORARY_FILE) {
      setIsSaveAsOpen(true);
      return;
    }

    const fullFileNamePath = folderPath.concat(selectedFile.filename);

    if (await checkIsFileAlreadyOpened(fullFileNamePath)) return;

    await processSelectedFile();
  };

  const handleSaveAsTempFile = async (newFileName: string, fileType: S3ObjectType) => {
    if (!selectedFile || !username) return;

    const oldFilePath = folderPath.concat(selectedFile.filename);
    const newFilePath = folderPath.concat(newFileName);

    try {
      // load the presigned URL and data sheet
      const responseFileDetails = await DataSheetApiService.loadPresignedData(
        username,
        oldFilePath,
      );
      if (!responseFileDetails.tempFilePath) {
        throw new Error('Failed to load presigned URL');
      }

      const dataSheet = await DataSheetApiService.loadSheetFromPresignedUrl(
        responseFileDetails.tempFilePath,
      );

      // save the sheet with the new file name
      const { status } = await DataSheetApiService.saveSheetByFileType(
        username,
        dataSheet,
        newFilePath,
        S3ObjectType.JSON_FILE,
      );

      if (status !== 'SUCCESS') {
        throw new Error('Failed to save the file');
      }

      // delete old temporary files and open new file
      await deleteTemporaryFiles(selectedFile.filename);
      snackBar.success('Temporary file saved successfully!');
      removeOpenedFilesFromStorage(oldFilePath);
      setIsNewlySavedFile(false);
      setFileUrl(newFilePath);
      await formulaSheet.clearDataSheetOnCache();
      globalSearchService.clearAllResults();
      globalSearchService.clearSearchQuery();
      setIsSaveAsOpen(false);
      setOpen(false);
      handleClose();
      setSelectedFile(null);
    } catch (error) {
      console.error('Error saving temporary file:', error);
      snackBar.error('Failed to save temporary file');
    }
  };

  const deleteTemporaryFiles = async (selectedFile: string): Promise<unknown> => {
    if (!username) return;

    let updatedFilename = removedExtensionsFilename(selectedFile);
    updatedFilename = updatedFilename.concat(`.${DATA_SHEET_FILE_TYPES.TEMP}`);
    const newFilePath = folderPath.concat(updatedFilename);
    await DataSheetApiService.deleteFileByFilename(username, newFilePath);
  };

  const handleSelectedFileAndOpen = async (file: IFileResponse) => {
    setSelectedFile(file);
    await handleOpenFile();
  };

  const handleClose = () => {
    setOpen(false);
    setFolderPath('');
    setSelectedFile(null);
    setTemplateToggleStatus(false);
    setArchiveToggleStatus(false);
  };

  return (
    <StyledOpenFileDialog>
      <DialogBox
        title="Open File"
        dialogBoxContent={
          <FileViewer
            folderPath={folderPath}
            setFolderPath={setFolderPath}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onSelectedFileAndOpen={handleSelectedFileAndOpen}
            archiveToggleStatus={archiveToggleStatus}
            templateToggleStatus={templateToggleStatus}
          />
        }
        dialogBoxFooter={
          <OpenFileFooter
            selectedFile={selectedFile}
            onClickOpenFile={handleOpenFile}
            setArchiveToggleStatus={setArchiveToggleStatus}
            setTemplateToggleStatus={setTemplateToggleStatus}
          />
        }
        isOpen={open}
        onClose={handleClose}
      />
      {isSaveAsOpen && (
        <SaveAsDialog
          open={isSaveAsOpen}
          suggestedNewFileName={selectedFile?.filename}
          onClose={() => setIsSaveAsOpen(false)}
          onSave={handleSaveAsTempFile}
        />
      )}
      <ConfirmationDialog {...confirmationDialogProps} />
    </StyledOpenFileDialog>
  );
}

export default OpenFileDialog;

export const OpenFileFooter = ({
  selectedFile,
  onClickOpenFile,
  setArchiveToggleStatus,
  setTemplateToggleStatus,
}: OpenFileFooterProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFile && selectedFile.type !== S3ObjectType.ARCHIVED_FILE) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedFile]);

  function handleArchiveToggle(event: React.ChangeEvent<HTMLInputElement>) {
    setArchiveToggleStatus(event.target.checked);
  }

  function handleTemplateToggle(event: React.ChangeEvent<HTMLInputElement>) {
    setTemplateToggleStatus(event.target.checked);
  }

  return (
    <StyledOpenFooter>
      <div>
        <FormControlLabel
          control={<Switch onChange={handleArchiveToggle} />}
          label={'Include Archives'}
        />
        <FormControlLabel
          control={<Switch onChange={handleTemplateToggle} />}
          label={'Include Templates'}
        />
      </div>
      <Button
        variant="contained"
        disabled={isDisabled}
        onClick={() => {
          onClickOpenFile();
        }}
        style={{ textTransform: 'none' }}
      >
        Open
      </Button>
    </StyledOpenFooter>
  );
};
