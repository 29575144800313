import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ShareIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.5466 5.65582C17.38 6.92999 18.645 8.95582 18.9016 11.2933"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.19922 11.3392C3.43755 9.01083 4.68422 6.98499 6.49922 5.70166"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50757 19.195C8.5709 19.7358 9.7809 20.0383 11.0551 20.0383C12.2834 20.0383 13.4384 19.7633 14.4742 19.2592"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0549 7.05834C12.4623 7.05834 13.6033 5.91741 13.6033 4.51C13.6033 3.1026 12.4623 1.96167 11.0549 1.96167C9.64752 1.96167 8.50659 3.1026 8.50659 4.51C8.50659 5.91741 9.64752 7.05834 11.0549 7.05834Z"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42748 18.26C5.83489 18.26 6.97582 17.1191 6.97582 15.7117C6.97582 14.3043 5.83489 13.1633 4.42748 13.1633C3.02008 13.1633 1.87915 14.3043 1.87915 15.7117C1.87915 17.1191 3.02008 18.26 4.42748 18.26Z"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5725 18.26C18.9799 18.26 20.1208 17.1191 20.1208 15.7117C20.1208 14.3043 18.9799 13.1633 17.5725 13.1633C16.1651 13.1633 15.0242 14.3043 15.0242 15.7117C15.0242 17.1191 16.1651 18.26 17.5725 18.26Z"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
