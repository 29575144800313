import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const DocumentCopyIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.5833 12.2834V15.0334C15.5833 18.7 14.1166 20.1667 10.4499 20.1667H6.96659C3.29992 20.1667 1.83325 18.7 1.83325 15.0334V11.55C1.83325 7.88335 3.29992 6.41669 6.96659 6.41669H9.71659"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5832 12.2834H12.6499C10.4499 12.2834 9.71655 11.55 9.71655 9.35002V6.41669L15.5832 12.2834Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6333 1.83331H14.3"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41675 4.58331C6.41675 3.06165 7.64508 1.83331 9.16675 1.83331H11.5684"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 7.33331V13.0075C20.1667 14.4283 19.0117 15.5833 17.5908 15.5833"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 7.33331H17.4167C15.3542 7.33331 14.6667 6.64581 14.6667 4.58331V1.83331L20.1667 7.33331Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DocumentCopyIcon;
