import IConfig from '@/interfaces/IConfig';

export const config: IConfig = {
  environment: 'DEV',
  region: 'ap-southeast-2',
  baseUrl: 'https://api.dev.hollywoodbudgets.com' /*'https://api.dev.budgets.pivotbiz.com',*/,
  cognitoPoolId: 'ap-southeast-2_XkfznstKd',
  cognitoClientId: '1uehbjogn4o85r9rmdc5dm66ir',
  hyperformulaLicense: 'internal-use-in-handsontable',
  handsontableLicense: 'bb1b5-b3d0f-3e073-04435-fcd5f',
  stimulsoftLicense:
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnYADvcVY7U2/OK46A0UTaMaP7CFyjaldb8hzkYa+nBiQfefSdFURtRbKd/bUwwOEXo8FoEz05R9rIkZ+KzotqSEf++wZ9FSDTqx2KcfRf4PlkVPr0/9p+Gh1TZwgGboOHYPYYKRoH8/JqEmDhKjPn7RHa7KlreJMBXl8RsHm9Yo/TorRLqUJQrMHs8ehwaxN5ftxUMQAyWQYwcXJv1XTb6cf+vVtQC3BAl61a/kEzZxOvnofsTxx8rzBRWmbU5bDNrNwnBFZxRplY9OO1n+E9Pz8GektFOCrgLO3TdCOlI7ZTI8hHG1IHyi18GjOPJuTLkzGEPD2s/nbbdLB3k2OAexGv2s4DzyrEnEISyYvNbVEt8uZn6xCDnw8HoSPF7aguj/O/DOHwp8T6sxEvG7Qbubv+4aulNrWHBb80c7iMxj0XAQ7lwXQ6e7kbi40DCWRukVKAtSTsA55XjNLYqtA9BqzRz2BrVLbRPWUomz3glqnFGFsXXKUJPwkBtM0cgSltMdTWOIaHJmJtqAr1oQtW4',
  subscription: {
    enabled: true,
    redirectUrl: 'https://subscriptions.zoho.com.au/portal/hollywoodbudgets',
  },
  analytics: {
    enabled: true,
    postHogKey: 'phc_OY5iaLvrFLy5FAsOhKvjGeGDCqkCCxoDk7aUZmsjOcZ',
    postHogApiHost: 'https://us.i.posthog.com',
  },
  costTracker: {
    dpfApiUrl: 'https://dpf-dev-main-apigw.digitalpaperflow.com/dev',
    dpfOAuthUrl: 'https://ouu26te79l.execute-api.ap-southeast-2.amazonaws.com/dev',
    dpfOAuthCredentials:
      'NzUxMmUyYTNlM2ZlNDU0MGFkNTM5NzQxOWM4YTU5MmY6NDNkMjQ0ZmMxNTMyNzBjNTFjOWE4Y2IxNGIyYWFlZjY=',
  },
};

// Shoot me later for commenting this.
// Kept for future reference. This was pre-handover config.
//
// export const config: IConfig = {
//   environment: 'DEV',
//   region: 'us-east-1',
//   baseUrl:
//     'https://74lwjioke9.execute-api.us-east-1.amazonaws.com/' /*'https://api.dev.budgets.pivotbiz.com',*/,
//   cognitoPoolId: 'us-east-1_H2u1KDoO7',
//   cognitoClientId: '7p2raqskikqiknagl3fb172drs',
//   hyperformulaLicense: 'internal-use-in-handsontable',
//   handsontableLicense: 'non-commercial-and-evaluation',
//   stimulsoftLicense: '',
//   subscription: {
//     enabled: true,
//     redirectUrl: 'https://subscriptions.zoho.com.au/portal/hollywoodbudgets',
//   },
// };
