export const BudgetSheetL1Header = [
  'Under - Over',
  'Account',
  'Description',
  'Fringes',
  'Total',
  'Comparison',
  'Variance',
];

export const CostTrackerSheetL1Header = [
  'Under - Over',
  'Account',
  'Description',
  'Actual this Period',
  'Actual',
  'Processing',
  'Purchase Orders',
  'Costs to Date',
  'Estimate to Complete',
  'Estimated Final Cost',
  'Budget',
  'Variance to Date',
  'Variance this Period',
  'EFCW',
];

export const BudgetSheetL2Header = [
  'Under - Over',
  'Account',
  'Description',
  'Fringes',
  'Total',
  'Comparison',
  'Variance',
];

export const CostTrackerSheetL2aHeader = [
  'Under - Over',
  'Cu',
  'Lo',
  'Account',
  'Set',
  'Description',
  'Actual this Period',
  'Actual',
  'Processing',
  'Purchase Orders',
  'Costs to Date',
  'Estimate to Complete',
  'Estimated Final Cost',
  'Budget',
  'Variance to Date',
  'Variance this Period',
  'EFCW',
];

export const CostTrackerL3TransactionsHeader = [
  'Period',
  'Chart',
  'IN',
  'F',
  'TX',
  'Date',
  'Description',
  'Amount',
  'Code',
  'Vendor Name',
  'Rec',
  'Voucher',
  'Vendor',
  'Invoice',
  'Cheque',
  'PO #',
  'Tran',
  'Line',
  'Emp',
  'Batch',
  'Vendor Address',
  'Post Code',
  'State',
];

export const BudgetSheetL3Header = [
  'Under - Over',
  'Range',
  'Fringe %',
  'Fringe $',
  'Fringes',
  'Groups',
  'Loc',
  'Set',
  'Description',
  'Units',
  'Desc',
  'X',
  'Rate',
  'Cu',
  'Total',
  'Comparison',
  'Variance',
];

export const GroupsSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Suppress',
  'Cap',
  'Cu',
  'Colour',
  'Include Fringe',
  'Total',
  'Usage',
  //'Attachments',
  // 'Notes',
];
export const GlobalSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Value',
  'Calculation',
  'Usage',
  //'Attachments',
  //'Notes',
];
export const CostToDateSheetHeader = [
  'Category',
  'Account',
  'Account Description',
  'Amount',
  'Total',
  'Usage',
  // 'Attachments',
  //'Notes',
];
export const SetsSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Include Fringe',
  'Total',
  'Usage',
  // 'Attachments',
  // 'Notes',
];
export const LocationSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Include Fringe',
  'Total',
  'Usage',
  //'Attachments',
  //'Notes',
];
export const UnitDescriptionSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Units',
  'Usage',
  //'Attachments',
  //'Notes',
];
export const FringesSheetHeader = [
  'Category',
  'Code',
  'Description',
  'Rate',
  'Unit Desc',
  'Cap',
  'Cu',
  'Total',
  'Usage',
  //'Attachments',
  // 'Notes',
];
export const CurrencySheetHeader = [
  'Category',
  'Code',
  'Description',
  'Rate',
  'Default',
  'Include Fringe',
  'Total',
  'Usage',
  //'Attachments',
  // 'Notes',
];
