import { DATA_SHEET_FILE_TYPES } from '@/enums';

export function getExtensionFromFilename(
  filename: string | undefined,
): DATA_SHEET_FILE_TYPES | undefined {
  if (!filename) return undefined;

  const extensionsToRemove = Object.values(DATA_SHEET_FILE_TYPES);

  const match = extensionsToRemove.find((ext) => new RegExp(`\\.(${ext})$`, 'i').test(filename));

  return match;
}
