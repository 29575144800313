import { Alert } from '@mui/material';
import React from 'react';

interface Props {
  message: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
}

export const AlertMessage = ({ message, severity }: Props) => {
  return (
    <Alert
      severity={severity}
      sx={{
        marginBottom: '20px',
      }}
    >
      {message}
    </Alert>
  );
};
