import {
  AlwaysSparse,
  CellValue,
  ConfigParams,
  ExportedChange,
  HyperFormula,
  RawCellContent,
  SerializedNamedExpression,
} from 'hyperformula';
import lodash from 'lodash';
import { Config } from '@/config';
import { BASE_CURRENCY, SYSTEM_CURRENCY, SYSTEM_CURRENCY_LABEL } from '@/constants';
import {
  CostsToDateColumnIndexes,
  CurrencyAlias,
  CurrencyColumnIndexes,
  FringesColumnIndexes,
  GlobalColumnIndexes,
  GroupColumnIndexes,
  L1ColumnAlias,
  L1ColumnIndexes,
  L2ColumnIndexes,
  L3ColumnAlias,
  L3ColumnIndexes,
  LocationColumnIndexes,
  LookupColumnIndexes,
  LookupRowIndexes,
  RowTypes,
  S3ObjectType,
  SetColumnIndexes,
  SheetNames,
  UnitDescriptionColumnIndexes,
} from '@/enums';
import { FringeCellOrder, splitFormulaByArithmeticOperations, WebStorage } from '@/utils';
import instanceOfCellError from '@/helpers/checkIfCellError';
import DataSheetTransformer from '@/helpers/DataSheetTransformer';
import { IAggregationMap, IAggregationMapValue } from '@/interfaces/IAggregationMap';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  IL2Data,
  IL3Data,
} from '@/interfaces/IDataSheet';
import {
  ICostsToDateSheet,
  ICurrencySheet,
  IFringesSheet,
  IGlobalSheet,
  IGroup,
  IGroupsSheet,
  IL1Sheet,
  IL2Sheet,
  IL3Sheet,
  ILocationSheet,
  INamedExpressionData,
  ISetsSheet,
  IUnitDescSheet,
} from '@/interfaces/IFormulaSheet';
import { IMeta } from '@/interfaces/IMetaData';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';
import {
  IAddSheetContent,
  ICellChange,
  IRemoveSheetItem,
  IUpdateSheetContent,
} from '@/interfaces/masterDataTypes';
import { logger } from '@/lib/logger';
import DataSheetApiService from '@/services/DataSheetApiService';

type RowType = string | number | null;

interface ISheets {
  /**
   * TODO: move this to a separate file. do not use any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IFormulaSheetChangeEvent {
  eventName: string;
  sheetName?: string;
  changes?: unknown;
}

export interface IFormulaSheetBuildEvent {
  eventName: string;
  status: string;
}

let instance: FormulaSheet;

type FormulaEvent = IFormulaSheetChangeEvent | IFormulaSheetBuildEvent;

export interface NewNamedExpressionDetail {
  row: number;
  newCode: string;
  previousCode?: string;
}

export interface NewValueNamedExpression<T> {
  row: number;
  code: string;
  sheetData: T;
  index?: number;
}

class FormulaSheet {
  private hf: HyperFormula;
  private _hf: HyperFormula; /* FOR VALIDATION PURPOSE. DO NOT INSERT ANY CONTENT HERE */
  private webStorage: WebStorage;
  private fringeCellOrder: FringeCellOrder;
  private shouldUpdate: boolean;
  private eventsListeners: Map<string, ((event: FormulaEvent) => void)[]>;
  private options: Partial<ConfigParams> = {
    licenseKey: Config.hyperformulaLicense,
    smartRounding: false,
    precisionRounding: 14,
    maxRows: 500000,
    undoLimit: 0,
    useStats: true,
  };

  constructor() {
    this.hf = HyperFormula.buildEmpty(this.options);
    this._hf = HyperFormula.buildEmpty();
    this.webStorage = new WebStorage();
    this.fringeCellOrder = new FringeCellOrder();
    this.shouldUpdate = false;
    this.eventsListeners = new Map();
  }

  static getInstance(): FormulaSheet {
    if (!instance) {
      instance = new FormulaSheet();
    }
    return instance;
  }

  private addHyperformulaSheetContent(
    sheetName: string,
    content:
      | IL1Sheet
      | IL2Sheet
      | IL3Sheet
      | ICurrencySheet
      | IGlobalSheet
      | IFringesSheet
      | ISetsSheet
      | IGroupsSheet
      | IUnitDescSheet
      | ICostsToDateSheet
      | ILocationSheet,
  ): void {
    this.hf.batch(() => {
      if (this.hf.isItPossibleToAddSheet(sheetName)) {
        this.hf.addSheet(sheetName);
      }

      this.hf.setSheetContent(this.hf.getSheetId(sheetName) as number, content || []);
    });
  }

  private addNamedExpression(name: string, expression: string): void {
    if (this.hf.isItPossibleToAddNamedExpression(name, expression)) {
      this.hf.addNamedExpression(name, expression);
    } else {
      this.changeNamedExpression(name, expression);
    }
  }

  private changeNamedExpression(name: string, expression: string) {
    if (
      this.hf.getNamedExpressionValue(name) !== expression &&
      this.hf.isItPossibleToChangeNamedExpression(name, expression)
    ) {
      this.hf.changeNamedExpression(name, expression);
    }
  }

  private addRowsAndSetContents(
    sheetId: number | undefined,
    index: number,
    content: RawCellContent[][],
    rowCount = 1,
  ) {
    if (sheetId) {
      this.hf.addRows(sheetId, [index, rowCount]);
      this.hf.setCellContents(
        {
          row: index,
          col: 0,
          sheet: sheetId,
        },
        content,
      );
    }
  }

  private setBudgetContent(row: number, sheetId: number, content: IL1Sheet | IL2Sheet | IL3Sheet) {
    this.hf.setCellContents(
      {
        row: row,
        col: 0,
        sheet: sheetId,
      },
      content,
    );
  }

  private getNamedExpressionValue(
    sheetName: string,
    sheetData: RawCellContent[],
    index?: number,
  ): string {
    let expression = '';
    switch (sheetName) {
      case SheetNames.GLOBALS:
        expression = (sheetData[GlobalColumnIndexes.units] as string) || '0';
        break;
      case SheetNames.GROUPS:
        expression = (sheetData[GroupColumnIndexes.total] as string) || '0';
        break;
      case SheetNames.COSTS_TO_DATE:
        expression = (sheetData[CostsToDateColumnIndexes.units] as string) || '0';
        break;
      case SheetNames.CURRENCY:
        expression = (sheetData[CurrencyColumnIndexes.rate] as string) || '0';
        break;
      case SheetNames.UNIT_DESCRIPTIONS:
        expression = (sheetData[UnitDescriptionColumnIndexes.units] as string) || '0';
        break;
      case SheetNames.FRINGES:
        if (sheetData[FringesColumnIndexes.unitDesc] === '%') {
          expression = ((sheetData[FringesColumnIndexes.rate] as number) /
            100) as unknown as string;
        } else {
          expression = (sheetData[FringesColumnIndexes.rate] as string) || '0';
        }
        break;
      default:
        break;
    }

    if (typeof expression === 'string' && expression.startsWith('=')) {
      expression = expression.substring(1);
    }
    return `=${expression}`;
  }

  private getGlobalsNamedExpressions(
    globals: IGlobalSheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];

    globals.forEach((global, index) => {
      const code = global[GlobalColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.GLOBALS, global, index + 1);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getGlobalsNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      global[GlobalColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);
    });

    return namedExpressions;
  }

  private getGroupsNamedExpressions(
    groups: IGroupsSheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];

    groups.forEach((group, index) => {
      const code = group[GroupColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.GROUPS, group, index + 1);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getGroupsNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      group[GroupColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);
    });

    return namedExpressions;
  }

  private getCostsToDateNamedExpressions(
    costsToDates: ICostsToDateSheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];

    costsToDates.forEach((costsToDate) => {
      const code = costsToDate[CurrencyColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.COSTS_TO_DATE, costsToDate);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getCostsToDateNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      costsToDate[CurrencyColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);
    });

    return namedExpressions;
  }

  private getCurrenciesNamedExpressions(
    currencies: ICurrencySheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];
    let hasDefaultCurrency = false;

    namedExpressions.push({
      name: SYSTEM_CURRENCY,
      expression: '=1',
    });

    currencies.forEach((currency) => {
      const code = currency[CurrencyColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.CURRENCY, currency);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getCurrenciesNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      currency[CurrencyColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);

      if ((currency[CurrencyColumnIndexes.default] as string) === 'Y') {
        namedExpressions.push({
          name: BASE_CURRENCY,
          expression: `=${parseFloat(currency[CurrencyColumnIndexes.rate] as string)}`,
        });
        hasDefaultCurrency = true;
      }
    });

    // IF there any default currency
    if (!hasDefaultCurrency) {
      namedExpressions.push({ name: BASE_CURRENCY, expression: `=${SYSTEM_CURRENCY}` });
    }

    return namedExpressions;
  }

  private getFringesNamedExpressions(
    fringes: IFringesSheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];

    fringes.forEach((fringe) => {
      const code = fringe[FringesColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.FRINGES, fringe);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getFringesNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      fringe[FringesColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);
    });

    return namedExpressions;
  }

  private getUnitDescNamedExpressions(
    unitDesc: IUnitDescSheet,
    existingNames: Set<string>,
  ): SerializedNamedExpression[] {
    const namedExpressions: SerializedNamedExpression[] = [];

    unitDesc.forEach((ud) => {
      const code = ud[UnitDescriptionColumnIndexes.code] as string;
      if (!code) return;

      const uniqueName = this.getUniqueNamedExpressionName(code, existingNames);
      const expressionValue = this.getNamedExpressionValue(SheetNames.UNIT_DESCRIPTIONS, ud);

      if (!this.isValidNamedExpression(uniqueName, expressionValue)) {
        logger.warn(`[getUnitDescNamedExpressions] Invalid named expression: "${uniqueName}"`);
        return;
      }

      const validNamedExpression = {
        name: uniqueName,
        expression: expressionValue,
      };

      ud[UnitDescriptionColumnIndexes.code] = uniqueName;
      namedExpressions.push(validNamedExpression);
    });

    return namedExpressions;
  }

  private isValidNamedExpression(code: string, expression: string): boolean {
    return this._hf.isItPossibleToAddNamedExpression(code, expression);
  }

  private getUniqueNamedExpressionName(baseName: string, existingNames: Set<string>): string {
    let uniqueName = baseName;
    let suffix = 1;

    while (existingNames.has(uniqueName)) {
      uniqueName = `${baseName}_${suffix}`;
      suffix++;
    }

    existingNames.add(uniqueName);
    return uniqueName;
  }

  private getAllNamedExpressions(sheets: ISheets, meta: IMeta): SerializedNamedExpression[] {
    const existingNames = new Set<string>();

    const namedExpressions = [
      ...this.getCurrenciesNamedExpressions(sheets[SheetNames.CURRENCY], existingNames),
      ...this.getUnitDescNamedExpressions(sheets[SheetNames.UNIT_DESCRIPTIONS], existingNames),
      ...this.getGroupsNamedExpressions(sheets[SheetNames.GROUPS], existingNames),
      ...this.getGlobalsNamedExpressions(sheets[SheetNames.GLOBALS], existingNames),
      ...this.getFringesNamedExpressions(sheets[SheetNames.FRINGES], existingNames),
      ...this.getCostsToDateNamedExpressions(sheets[SheetNames.COSTS_TO_DATE], existingNames),
    ];

    namedExpressions.push({
      name: 'CONFLICT_SUPPRESS',
      expression: `=${meta?.isConflictSuppress ?? 0}`,
    });

    return namedExpressions;
  }

  getL3FringesCellOrder(): FringeCellOrder {
    return this.fringeCellOrder;
  }

  getCurrencyList() {
    return this.hf.getSheetSerialized(this.getSheetId(SheetNames.CURRENCY));
  }

  getGroupList() {
    return this.hf.getSheetSerialized(this.getSheetId(SheetNames.GROUPS));
  }

  getLocationsList() {
    return this.hf.getSheetSerialized(this.getSheetId(SheetNames.LOCATIONS));
  }

  getSetsList() {
    return this.hf.getSheetSerialized(this.getSheetId(SheetNames.SETS));
  }

  async rebuildAndRecalculate(dataSheet: IDataSheet): Promise<void> {
    await this.fromDataSheet(dataSheet);
    return Promise.resolve();
  }

  isItPossibleToAddNamedExpression(
    sheetName: string,
    code: string,
    sheetData: RowType[],
    index?: number,
  ): boolean {
    const expression = this.getNamedExpressionValue(sheetName, sheetData, index);
    if (this.hf.isItPossibleToAddNamedExpression(code, expression, undefined)) {
      return true;
    }
    return false;
  }

  removeNamedExpressions(name: string[], useBatchOperation = true): void {
    const executeRemoveNamedExpression = () => {
      name.forEach((value) => {
        if (this.hf.isItPossibleToRemoveNamedExpression(value)) {
          this.hf.removeNamedExpression(value);
        }
      });
    };
    if (useBatchOperation) {
      this.hf.batch(() => {
        executeRemoveNamedExpression();
      });
    } else {
      executeRemoveNamedExpression();
    }
  }

  updateNamedExpression(
    sheetName: string,
    index: number,
    previousCode: string,
    newCode: string,
    serializedSheet?: RawCellContent[][],
  ): void {
    const serializedSheetContent =
      serializedSheet ?? this.hf.getSheetSerialized(this.getSheetId(sheetName));
    const updatedSheetValue = serializedSheetContent[index];
    const expression =
      this.hf.getNamedExpressionFormula(String(previousCode)) ??
      this.getNamedExpressionValue(sheetName, updatedSheetValue, index);
    if (expression && expression !== '' && newCode && newCode !== '') {
      if (this.hf.isItPossibleToAddNamedExpression(newCode, expression)) {
        if (previousCode && this.hf.isItPossibleToRemoveNamedExpression(previousCode)) {
          this.hf.removeNamedExpression(previousCode);
        }
        this.addNamedExpression(newCode, expression);
      }
    }
  }

  updateMultipleNamedExpressions(
    sheetName: string,
    newNamedExpressionDetailList: NewNamedExpressionDetail[],
  ): void {
    if (newNamedExpressionDetailList.length === 0) return;

    const serializedSheet = this.hf.getSheetSerialized(this.getSheetId(sheetName));

    const updateNamedExpressionsInBatch = (): void => {
      newNamedExpressionDetailList.forEach(({ row, newCode, previousCode = '' }) => {
        this.updateNamedExpression(sheetName, row, previousCode, newCode, serializedSheet);
      });
    };

    this.hf.batch(updateNamedExpressionsInBatch);
  }

  refactorNamedExpressionValues(previousCode: string, newCode: string): void {
    // update namedExpression which are used as value.
    const namedExpressions = this.hf.getAllNamedExpressionsSerialized();
    const previousCodeRegex = new RegExp(`\\b${previousCode}\\b`, 'g');
    namedExpressions.forEach((namedExpression) => {
      if (
        namedExpression.expression &&
        splitFormulaByArithmeticOperations(namedExpression.expression as string).includes(
          previousCode,
        )
      ) {
        const expression = `${namedExpression.expression}`.replace(previousCodeRegex, newCode);
        this.changeNamedExpression(namedExpression.name, expression);
      }
    });
  }

  updateNamedExpressionValue(
    sheetName: string,
    code: string,
    sheetData: RowType[],
    index?: number,
  ): void {
    const expression = this.getNamedExpressionValue(sheetName, sheetData, index);
    this.changeNamedExpression(code, expression);
  }

  updateMultipleNamedExpressionValues<T>(
    sheetName: string,
    newValueNamedExpressionList: NewValueNamedExpression<T>[],
  ): void {
    if (newValueNamedExpressionList.length === 0) return;

    const updateNamedExpressionValuesInBatch = (): void => {
      newValueNamedExpressionList.forEach(({ code, sheetData, index }) => {
        if (code === '') return;

        const expression = this.getNamedExpressionValue(sheetName, sheetData as RowType[], index);
        if (this.hf.isItPossibleToChangeNamedExpression(code, expression)) {
          this.hf.changeNamedExpression(code, expression);
        }
      });
    };

    this.hf.batch(updateNamedExpressionValuesInBatch);
  }

  private updateGroupsNamedExpressionsValue(groupsAfterAggregation: RawCellContent[][]): void {
    if (groupsAfterAggregation.length === 0) return;

    const updatedNamedExpressionGroups = groupsAfterAggregation.map(
      (group, index) =>
        ({
          code: group[GroupColumnIndexes.code],
          row: index,
          sheetData: group,
        }) as NewValueNamedExpression<IGroup>,
    );

    this.hf.batch(() => {
      updatedNamedExpressionGroups.forEach(({ code, sheetData }) => {
        if (!code) return;
        const value = this.getNamedExpressionValue(SheetNames.GROUPS, sheetData);

        if (this.hf.isItPossibleToChangeNamedExpression(code, value)) {
          this.hf.changeNamedExpression(code, value);
        }
      });
    });
  }

  getHyperformulaInstance(): HyperFormula {
    return this.hf;
  }

  getSheetId(sheetName: string): number {
    return this.hf.getSheetId(sheetName) as number;
  }

  updateL1Transform(
    dataSheet: IDataSheet,
    updatedData?: {
      l1DataIds?: string[];
    },
  ): void {
    const transformer = new DataSheetTransformer();
    if (updatedData?.l1DataIds && updatedData?.l1DataIds?.length > 0) {
      updatedData?.l1DataIds?.forEach((l1DataId) => {
        const matchedLevel1Data = dataSheet[DateSheetAttributeType.L1].find(
          (level1) => level1.id === l1DataId,
        );
        const hasRowTypeD = matchedLevel1Data?.rowType === RowTypes.D;
        if (hasRowTypeD) this.updateL2Transform(dataSheet, [l1DataId]);
      });
    }
    this.hf.setSheetContent(this.getSheetId('l1'), transformer.transformL1Sheets(dataSheet));
  }

  removeL1Rows(
    dataSheet: IDataSheet,
    removeIndexes: number[],
    l1Ids: string[],
    l2Ids: string[],
    isDeleteAllDRows: boolean,
    useBatchOperation = true,
  ) {
    const executeRemoveL1Rows = () => {
      const transformer = new DataSheetTransformer();
      const l1Sheet = transformer.transformL1Sheets(dataSheet);

      const l1SheetId = this.hf.getSheetId('l1') as number;
      const l1SheetLength = l1Sheet.length;
      const lastRemoveIndex = Math.min.apply(null, removeIndexes);
      const subtotalIndex = l1Sheet.findIndex(
        (l1Data, index) =>
          lastRemoveIndex <= index && l1Data[L1ColumnIndexes.rowType] === RowTypes.S,
      );

      l2Ids?.forEach((l2Id) => {
        this.removeSheet(`l3_${l2Id}`);
      });
      l1Ids?.forEach((l1Id) => {
        this.removeSheet(`l2_${l1Id}`);
      });
      removeIndexes.forEach((l1Index) => {
        this.removeRow(SheetNames.L1, l1Index, 1);
      });

      if (isDeleteAllDRows) {
        const l1Id = l1Sheet[0][L1ColumnIndexes.id] as string;
        const l2Sheet = transformer.transformL2Sheet(dataSheet, l1Id);
        const l2SheetId = this.hf.getSheetId(`l2_${l1Id}`) as number;
        const l2Id = l2Sheet[0][L2ColumnIndexes.id] as string;
        const l2SheetDimensions = this.hf.getSheetDimensions(l2SheetId);
        const l3SheetId = this.hf.getSheetId(`l3_${l2Id}`) as number;
        const l3SheetDimensions = this.hf.getSheetDimensions(l3SheetId);
        const l3Sheet = transformer.transformL3Sheet(dataSheet, l2Id, this.fringeCellOrder);

        this.hf.removeRows(l3SheetId, [0, l3SheetDimensions.height]);
        this.hf.removeRows(l2SheetId, [0, l2SheetDimensions.height]);

        this.setBudgetContent(0, l1SheetId, [l1Sheet[0]]);
        this.setBudgetContent(0, l2SheetId, l2Sheet);
        this.setBudgetContent(0, l3SheetId, l3Sheet);
      }

      if (l1SheetId) {
        if (subtotalIndex > 0) {
          //Fringe Total
          this.setBudgetContent(subtotalIndex - 1, l1SheetId, [l1Sheet[subtotalIndex - 1]]);
          // Subtotal
          this.setBudgetContent(subtotalIndex, l1SheetId, [l1Sheet[subtotalIndex]]);
        }
        this.setBudgetContent(l1SheetLength - 1, l1SheetId, [l1Sheet[l1SheetLength - 1]]);
        this.setBudgetContent(l1SheetLength - 2, l1SheetId, [l1Sheet[l1SheetLength - 2]]);
      }
    };

    if (useBatchOperation) {
      this.hf.batch(() => {
        executeRemoveL1Rows();
      });
    } else {
      executeRemoveL1Rows();
    }
  }

  removeL2Rows(
    dataSheet: IDataSheet,
    l1Id: string,
    removeIndexes: number[],
    l2Ids: string[],
    isDeleteAllDRows: boolean,
    useBatchOperation = true,
  ): void {
    const executeRemoveL2Rows = () => {
      const transFormer = new DataSheetTransformer();
      const l2Sheet = transFormer.transformL2Sheet(dataSheet, l1Id);
      const l2SheetLength = l2Sheet.length;
      const lastRemoveIndex = Math.min.apply(null, removeIndexes);
      const subtotalIndex: number = l2Sheet.findIndex(
        (l2Data, index) =>
          lastRemoveIndex <= index && l2Data[L2ColumnIndexes.rowType] === RowTypes.S,
      );
      const l2SheetId = this.hf.getSheetId(`l2_${l1Id}`) as number;
      removeIndexes.forEach((index) => {
        this.removeRow(`l2_${l1Id}`, index, 1);
      });
      l2Ids.forEach((l2Id) => {
        this.removeSheet(`l3_${l2Id}`);
      });
      // If all row deleted update first one row data
      if (isDeleteAllDRows) {
        const l2Id = l2Sheet[0][L2ColumnIndexes.id] as string;
        const l3SheetId = this.hf.getSheetId(`l3_${l2Id}`) as number;
        const l3SheetDimensions = this.hf.getSheetDimensions(l3SheetId);
        const l3Sheet = transFormer.transformL3Sheet(dataSheet, l2Id, this.fringeCellOrder);
        this.hf.removeRows(l3SheetId, [0, l3SheetDimensions.height]);
        this.setBudgetContent(0, l3SheetId, l3Sheet);
        this.setBudgetContent(0, l2SheetId, [l2Sheet[0]]);
      }
      // Update next subtotal Row
      if (subtotalIndex > 0) {
        this.setBudgetContent(subtotalIndex, l2SheetId, [l2Sheet[subtotalIndex]]);
      }
      // Update the Total Row
      this.setBudgetContent(l2SheetLength - 1, l2SheetId, [l2Sheet[l2SheetLength - 1]]);
      // Update the Fringe Total Row
      this.setBudgetContent(l2SheetLength - 2, l2SheetId, [l2Sheet[l2SheetLength - 2]]);
    };
    if (useBatchOperation) {
      this.hf.batch(() => {
        executeRemoveL2Rows();
      });
    } else {
      executeRemoveL2Rows();
    }
  }

  removeL3Rows(
    dataSheet: IDataSheet,
    l2RowId: string,
    removeIndexes: number[],
    updateContentIndex: number[],
    isDeleteAllDRows: boolean,
    useBatchOperation = true,
  ): void {
    const transFormer = new DataSheetTransformer();

    const executeRemoveL3Rows = () => {
      removeIndexes.forEach((index) => {
        this.removeRow(`l3_${l2RowId}`, index, 1);
      });
      const l3Sheet = transFormer.transformL3Sheet(dataSheet, l2RowId, this.fringeCellOrder);
      const l3SheetLength = l3Sheet.length;
      const l3SheetId = this.hf.getSheetId(`l3_${l2RowId}`) as number;
      const lastRemoveIndex = Math.min.apply(null, removeIndexes);
      const subtotalIndex: number = l3Sheet.findIndex(
        (l3Data, index) =>
          lastRemoveIndex <= index && l3Data[L3ColumnIndexes.rowType] === RowTypes.S,
      );
      updateContentIndex.forEach((index) => {
        this.setBudgetContent(index, l3SheetId, [l3Sheet[index]]);
      });
      if (isDeleteAllDRows) {
        this.setBudgetContent(0, l3SheetId, [l3Sheet[0]]);
      }
      // Update next subtotal Row
      if (subtotalIndex > 0) {
        this.setBudgetContent(subtotalIndex, l3SheetId, [l3Sheet[subtotalIndex]]);
      }
      // Update the Total Row
      this.setBudgetContent(l3SheetLength - 1, l3SheetId, [l3Sheet[l3SheetLength - 1]]);
      // Update the Fringe Total Row
      this.setBudgetContent(l3SheetLength - 2, l3SheetId, [l3Sheet[l3SheetLength - 2]]);
    };

    if (useBatchOperation) {
      this.hf.batch(() => {
        executeRemoveL3Rows();
      });
    } else {
      executeRemoveL3Rows();
    }
  }

  pasteL1Cells(changeValues: ICellChange[]): void {
    const l1SheetId = this.hf.getSheetId('l1') as number;
    if (l1SheetId) {
      this.hf.batch(() => {
        changeValues.forEach((changeValue) => {
          this.hf.setCellContents({ ...changeValue, sheet: l1SheetId }, [[changeValue.value]]);
        });
      });
    }
  }

  pasteL2Cells(l1Id: string, changeValues: ICellChange[]): void {
    const l2SheetId = this.hf.getSheetId(`l2_${l1Id}`) as number;
    if (l2SheetId) {
      this.hf.batch(() => {
        changeValues.forEach((changeValue) => {
          this.hf.setCellContents({ ...changeValue, sheet: l2SheetId }, [[changeValue.value]]);
        });
      });
    }
  }

  pasteL3Cells(dataSheet: IDataSheet, l2Id: string, changeValues: ICellChange[]): void {
    const l3SheetId = this.hf.getSheetId(`l3_${l2Id}`) as number;
    this.hf.batch(() => {
      changeValues.forEach((changeValue) => {
        this.hf.setCellContents({ ...changeValue, sheet: l3SheetId }, [[changeValue.value]]);
      });
    });
  }

  updateL2Transform(dataSheet: IDataSheet, level1Ids: string[], transformChildSheet = true): void {
    const transformer = new DataSheetTransformer();
    level1Ids.forEach((level1Id) => {
      const l2Sheet: IL2Sheet = transformer.transformL2Sheet(dataSheet, level1Id);
      const level2SheetName = `l2_${level1Id}`;
      if (this.hf.isItPossibleToAddSheet(level2SheetName)) {
        this.hf.addSheet(level2SheetName);
      }
      if (transformChildSheet) {
        l2Sheet?.forEach((l2Data) => {
          if (l2Data[0] && dataSheet.l3[l2Data[0]]) {
            const l3SheetName = `l3_${l2Data[0]}`;
            if (this.hf.isItPossibleToAddSheet(l3SheetName)) {
              this.hf.addSheet(l3SheetName);
            }
            this.updateL3Transform(dataSheet, [l2Data[0]] as string[]);
          }
        });
      }
      this.addHyperformulaSheetContent(level2SheetName, l2Sheet);
    });
  }

  /**
   * Highly inefficient method of updating data in hyperformula
   *
   * TODO: Move hyperformula with data and performance heavy operations to web worker
   */
  updateL3Transform(dataSheet: IDataSheet, level2Ids: string[]): Map<string, IL3Sheet> {
    const transformer = new DataSheetTransformer();
    const sheets = new Map<string, IL3Sheet>();
    level2Ids.forEach((level2Id) => {
      const l3Sheet: IL3Sheet = transformer.transformL3Sheet(
        dataSheet,
        level2Id,
        this.fringeCellOrder,
      );
      sheets.set(level2Id, l3Sheet);
      this.addHyperformulaSheetContent(`l3_${level2Id}`, l3Sheet);
    });

    return sheets;
  }

  async transformBudgetSheets(dataSheet: IDataSheet): Promise<ISheets> {
    const sheets: ISheets = {};
    const transformer = new DataSheetTransformer();
    dataSheet?.sheetNames?.l3.forEach((name: string) => {
      const sheetName = `l3_${name}`;
      const l3DataSheets = transformer.transformL3Sheet(dataSheet, name, this.fringeCellOrder);
      sheets[sheetName] = l3DataSheets;
    });

    dataSheet?.sheetNames?.l2.forEach((name: string) => {
      const sheetName = `l2_${name}`;
      sheets[sheetName] = transformer.transformL2Sheet(dataSheet, name);
    });

    sheets[SheetNames.L1] = transformer.transformL1Sheets(dataSheet);

    return Promise.resolve(sheets);
  }

  //
  updateSheets(dataSheet: IDataSheet, sheetNames: string[]): void {
    const transformer = new DataSheetTransformer();
    if (sheetNames.indexOf(SheetNames.GLOBALS) > -1) {
      this.addHyperformulaSheetContent(SheetNames.GLOBALS, transformer.transformGlobals(dataSheet));
    }

    if (sheetNames.indexOf(SheetNames.LOOKUP) > -1) {
      this.addHyperformulaSheetContent(SheetNames.LOOKUP, transformer.transformLookups(dataSheet));
    }

    if (sheetNames.indexOf(SheetNames.CURRENCY) > -1) {
      this.addHyperformulaSheetContent(
        SheetNames.CURRENCY,
        transformer.transformCurrencies(dataSheet),
      );
    }

    if (sheetNames.indexOf(SheetNames.FRINGES) > -1) {
      this.addHyperformulaSheetContent(SheetNames.FRINGES, transformer.transformFringes(dataSheet));
    }

    if (sheetNames.indexOf(SheetNames.SETS) > -1) {
      this.addHyperformulaSheetContent(SheetNames.SETS, transformer.transformSets(dataSheet));
    }

    if (sheetNames.indexOf(SheetNames.GROUPS) > -1) {
      this.addHyperformulaSheetContent(SheetNames.GROUPS, transformer.transformGroups(dataSheet));
    }

    if (sheetNames.indexOf(SheetNames.UNIT_DESCRIPTIONS) > -1) {
      this.addHyperformulaSheetContent(
        SheetNames.UNIT_DESCRIPTIONS,
        transformer.transformUnitDesc(dataSheet),
      );
    }

    if (sheetNames.indexOf(SheetNames.COSTS_TO_DATE) > -1) {
      this.addHyperformulaSheetContent(
        SheetNames.COSTS_TO_DATE,
        transformer.transformCostToDate(dataSheet),
      );
      this.shouldUpdate = false;
    }

    if (sheetNames.indexOf(SheetNames.LOCATIONS) > -1) {
      this.addHyperformulaSheetContent(
        SheetNames.LOCATIONS,
        transformer.transformLocation(dataSheet),
      );
    }
  }

  setShouldUpdateSheets(shouldUpdate: boolean): void {
    this.shouldUpdate = shouldUpdate;
  }

  shouldUpdateSheets(): boolean {
    return this.shouldUpdate;
  }

  async fromDataSheet(dataSheet: IDataSheet): Promise<void> {
    this.fire('Build', {
      eventName: 'Build',
      status: 'TRANSFORMING',
    });

    this.fringeCellOrder = new FringeCellOrder();
    dataSheet.fringes.forEach((fringe) => {
      if (fringe.code !== '') {
        this.fringeCellOrder.push(fringe.code);
      }
    });

    const transformer = new DataSheetTransformer();
    const budgetSheets = await this.transformBudgetSheets(dataSheet);

    const sheets: ISheets = {
      [SheetNames.CURRENCY]: transformer.transformCurrencies(dataSheet),
      [SheetNames.UNIT_DESCRIPTIONS]: transformer.transformUnitDesc(dataSheet),
      [SheetNames.LOOKUP]: transformer.transformLookups(dataSheet),
      [SheetNames.GROUPS]: transformer.transformGroups(dataSheet),
      [SheetNames.FRINGES]: transformer.transformFringes(dataSheet),
      [SheetNames.GLOBALS]: transformer.transformGlobals(dataSheet),
      [SheetNames.COSTS_TO_DATE]: transformer.transformCostToDate(dataSheet),
      [SheetNames.SETS]: transformer.transformSets(dataSheet),
      [SheetNames.LOCATIONS]: transformer.transformLocation(dataSheet),
      ...budgetSheets,
    };

    this.fire('Build', {
      eventName: 'Build',
      status: 'BUILDING',
    });

    const namedExpressions = this.getAllNamedExpressions(sheets, dataSheet.meta);

    const newHfInstance = HyperFormula.buildFromSheets(
      sheets,
      { ...this.options, chooseAddressMappingPolicy: new AlwaysSparse() },
      namedExpressions,
    );

    if (this.hf) this.hf.destroy();
    this.hf = newHfInstance;

    this.fire('Build', {
      eventName: 'Build',
      status: 'COMPLETED',
    });

    //#Events
    this.hf.on('sheetAdded', (removedSheetDisplayName: string) => {
      if (removedSheetDisplayName.startsWith('l3_')) {
        this.fire('L3SheetAdded', {
          eventName: 'L3SheetAdded',
          sheetName: removedSheetDisplayName,
          changes: null,
        } as IFormulaSheetChangeEvent);
      }
    });

    this.hf.on('sheetRemoved', (removedSheetDisplayName: string, changes: ExportedChange[]) => {
      if (removedSheetDisplayName.startsWith('l3_')) {
        this.fire('L3SheetRemoved', {
          eventName: 'L3SheetRemoved',
          sheetName: removedSheetDisplayName,
          changes,
        } as IFormulaSheetChangeEvent);
      }
    });

    return Promise.resolve();
  }

  getSheetValues(sheetName: string): Record<string, Array<Array<CellValue>>> {
    if (this.hf.doesSheetExist(sheetName)) {
      const sheetId: number = this.hf.getSheetId(sheetName) as number;
      return { [sheetName]: this.hf.getSheetValues(sheetId) } as Record<
        string,
        Array<Array<CellValue>>
      >;
    }

    return { [sheetName]: [] } as Record<string, Array<Array<CellValue>>>;
  }

  getAllSheetsSerialized(): Record<string, RawCellContent[][]> {
    return this.hf.getAllSheetsSerialized();
  }

  getAllSheetValues(): Record<string, Array<Array<CellValue>>> {
    return this.hf.getAllSheetsValues();
  }

  getSheetSums(sheetName: string, columnId: string, includeFringeSum = false): number {
    let sum = 0;
    let fringeSum = 0;
    if (this.hf.doesSheetExist(sheetName)) {
      const sheetId: number = this.hf.getSheetId(sheetName) as number;

      const rowType = L1ColumnAlias.RowType;
      const sheetHeight = this.hf.getSheetDimensions(sheetId).height - 1;

      sum = this.hf.calculateFormula(
        `=SUMIF(${sheetName}!${rowType}1:${rowType}${sheetHeight},"D",${sheetName}!${columnId}1:${columnId}${sheetHeight})`,
        sheetId,
      ) as number;

      if (includeFringeSum) {
        fringeSum = this.hf.calculateFormula(
          `=SUMIF(${sheetName}!${rowType}1:${rowType}${sheetHeight},"F",${sheetName}!${columnId}1:${columnId}${sheetHeight})`,
          sheetId,
        ) as number;
      }
    }

    if (isNaN(sum)) {
      sum = 0;
    }
    if (isNaN(fringeSum)) {
      fringeSum = 0;
    }
    return sum + fringeSum;
  }

  executeFormula(sheetName: string, formula: string): number {
    let result = 0;
    if (this.hf.doesSheetExist(sheetName)) {
      const sheetId: number = this.hf.getSheetId(sheetName) as number;

      result = this.hf.calculateFormula(formula, sheetId) as number;
    }

    return result;
  }

  isValidFormula(formula: string): boolean {
    return this.hf.validateFormula(formula);
  }

  getSheetMaxVariance(sheetName: string): number {
    const columnId: string = L3ColumnAlias.Variance;
    let maxVariance = 0;
    if (this.hf.doesSheetExist(sheetName)) {
      const sheetId: number = this.hf.getSheetId(sheetName) as number;

      maxVariance = this.hf.calculateFormula(
        `=MAX(ABS(${sheetName}!${columnId}1:${columnId}${
          this.hf.getSheetDimensions(sheetId).height
        }))`,
        sheetId,
      ) as number;
    }

    return maxVariance;
  }

  updateBaseCurrency(baseCurrency: string): void {
    const sheetId = this.getSheetId(SheetNames.CURRENCY);
    if (sheetId === undefined) return;

    const currencies: RawCellContent[][] = this.hf.getSheetSerialized(sheetId);

    if (baseCurrency === SYSTEM_CURRENCY_LABEL) {
      this.changeNamedExpression(BASE_CURRENCY, '=1');
      return;
    }

    for (const [index, currency] of currencies.entries()) {
      if (currency[CurrencyColumnIndexes.code] === baseCurrency) {
        this.changeNamedExpression(
          BASE_CURRENCY,
          `=${SheetNames.CURRENCY}!$${CurrencyAlias.Rate}$${index + 1}`,
        );
        break;
      }
    }
  }

  updateConflictSuppress(conflictSuppress: number): void {
    this.changeNamedExpression('CONFLICT_SUPPRESS', `=${conflictSuppress}`);
  }

  async getSetsAggregationContents(
    setSheet: CellValue[][],
    sets: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      const setUpdates: IUpdateSheetContent[] = [];
      let netUsage = 0;
      let netTotal = 0;
      setSheet.forEach((set, row) => {
        const code = `${set[SetColumnIndexes.code]}`;
        const usage = sets.get(code)?.count ?? 0;
        const total = [
          ...(sets.get(code)?.total ?? []),
          ...(set[SetColumnIndexes.includeFringe] === 'Y' ? sets.get(code)?.fringeTotal ?? [] : []),
        ];
        const aggregationTotal = lodash.sum(total);

        setUpdates.push({
          row,
          col: SetColumnIndexes.total,
          content: aggregationTotal,
        });
        setUpdates.push({
          row,
          col: SetColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (set[SetColumnIndexes.rowType] === RowTypes.T) {
          setUpdates.push({
            row,
            col: SetColumnIndexes.total,
            content: netTotal,
          });
          setUpdates.push({
            row,
            col: SetColumnIndexes.usage,
            content: netUsage,
          });
        }
      });
      resolve(setUpdates);
    });
  }

  async getFringesAggregationContents(
    fringeSheet: CellValue[][],
    fringes: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      const fringeUpdates: IUpdateSheetContent[] = [];
      let netUsage = 0;
      let netTotal = 0;
      fringeSheet.forEach((fringe, row) => {
        const code = `${fringe[FringesColumnIndexes.code]}`;
        const usage = fringes.get(code)?.count ?? 0;

        const aggregationTotal = lodash.sum(fringes.get(code)?.total ?? []) as number;

        fringeUpdates.push({
          row,
          col: FringesColumnIndexes.total,
          content: aggregationTotal,
        });
        fringeUpdates.push({
          row,
          col: FringesColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (fringe[FringesColumnIndexes.rowType] === RowTypes.T) {
          fringeUpdates.push({
            row,
            col: FringesColumnIndexes.total,
            content: netTotal,
          });
          fringeUpdates.push({
            row,
            col: FringesColumnIndexes.usage,
            content: netUsage,
          });
        }
      });
      resolve(fringeUpdates);
    });
  }

  async getLocationsAggregationContents(
    locationSheet: CellValue[][],
    locations: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const locationsUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    let netTotal = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      locationSheet.forEach((location, row) => {
        const code = location[LocationColumnIndexes.code] as string;
        const usage = locations.get(code)?.count ?? 0;
        const total = [
          ...(locations.get(code)?.total ?? []),
          ...(location[LocationColumnIndexes.includeFringe] === 'Y'
            ? locations.get(code)?.fringeTotal ?? []
            : []),
        ];
        const aggregationTotal = lodash.sum(total);

        locationsUpdates.push({
          row,
          col: LocationColumnIndexes.total,
          content: aggregationTotal,
        });
        locationsUpdates.push({
          row,
          col: LocationColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (location[LocationColumnIndexes.rowType] === RowTypes.T) {
          locationsUpdates.push({
            row,
            col: LocationColumnIndexes.total,
            content: netTotal,
          });
          locationsUpdates.push({
            row,
            col: LocationColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(locationsUpdates);
    });
  }

  async getUnitDescriptionAggregationContents(
    unitDescriptionSheet: CellValue[][],
    unitDescriptions: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const unitDescriptionsUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      unitDescriptionSheet.forEach((unitDescription, row) => {
        const code = `${unitDescription[UnitDescriptionColumnIndexes.code]}`;
        const usage = unitDescriptions.get(code)?.count ?? 0;

        unitDescriptionsUpdates.push({
          row,
          col: UnitDescriptionColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        if (unitDescription[UnitDescriptionColumnIndexes.rowType] === RowTypes.T) {
          unitDescriptionsUpdates.push({
            row,
            col: UnitDescriptionColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(unitDescriptionsUpdates);
    });
  }

  async getUpdateCurrencyAggregationContents(
    currenciesSheet: CellValue[][],
    currencies: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const currenciesUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    let netTotal = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      currenciesSheet.forEach((currency, row) => {
        const code = currency[CurrencyColumnIndexes.code] as string;
        const usage = currencies.get(code)?.count ?? 0;
        const total = [
          ...(currencies.get(code)?.total ?? []),
          ...(currency[CurrencyColumnIndexes.includeFringe] === 'Y'
            ? currencies.get(code)?.fringeTotal ?? []
            : []),
        ];
        const aggregationTotal = lodash.sum(total);

        currenciesUpdates.push({
          row,
          col: CurrencyColumnIndexes.total,
          content: aggregationTotal,
        });
        currenciesUpdates.push({
          row,
          col: CurrencyColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (currency[CurrencyColumnIndexes.rowType] === RowTypes.T) {
          currenciesUpdates.push({
            row,
            col: CurrencyColumnIndexes.total,
            content: netTotal,
          });
          currenciesUpdates.push({
            row,
            col: CurrencyColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(currenciesUpdates);
    });
  }

  async getUpdateGroupAggregationContents(
    groupSheet: CellValue[][],
    groups: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const groupsUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    let netTotal = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      groupSheet.forEach((group, row) => {
        const code = group[GroupColumnIndexes.code] as string;
        const usage = groups.get(code)?.count ?? 0;
        const total = [
          ...(groups.get(code)?.total ?? []),
          ...(group[GroupColumnIndexes.includeFringe] === 'Y'
            ? groups.get(code)?.fringeTotal ?? []
            : []),
        ];
        const aggregationTotal = lodash.sum(total);

        groupsUpdates.push({
          row,
          col: GroupColumnIndexes.total,
          content: aggregationTotal,
        });
        groupsUpdates.push({
          row,
          col: GroupColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (group[GroupColumnIndexes.rowType] === RowTypes.T) {
          groupsUpdates.push({
            row,
            col: GroupColumnIndexes.total,
            content: netTotal,
          });
          groupsUpdates.push({
            row,
            col: GroupColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(groupsUpdates);
    });
  }

  async getUpdateCostToDateAggregationContents(
    costToDateSheet: CellValue[][],
    costToDates: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const costToDateUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    let netTotal = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      costToDateSheet.forEach((costToDate, row) => {
        const code = costToDate[CostsToDateColumnIndexes.code] as string;
        const usage = costToDates.get(code)?.count ?? 0;
        const aggregationTotal = lodash.sum(costToDates.get(code)?.total as number[]);

        costToDateUpdates.push({
          row,
          col: CostsToDateColumnIndexes.total,
          content: aggregationTotal,
        });
        costToDateUpdates.push({
          row,
          col: CostsToDateColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        netTotal += aggregationTotal;
        if (costToDate[CostsToDateColumnIndexes.rowType] === RowTypes.T) {
          costToDateUpdates.push({
            row,
            col: CostsToDateColumnIndexes.total,
            content: netTotal,
          });
          costToDateUpdates.push({
            row,
            col: CostsToDateColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(costToDateUpdates);
    });
  }

  async getUpdateGlobalAggregationContents(
    globalSheet: CellValue[][],
    globals: Map<string, IAggregationMapValue>,
  ): Promise<IUpdateSheetContent[]> {
    const globalUpdates: IUpdateSheetContent[] = [];
    let netUsage = 0;
    return new Promise<IUpdateSheetContent[]>((resolve) => {
      globalSheet.forEach((global, row) => {
        const code = global[GlobalColumnIndexes.code] as string;
        const usage = globals.get(code)?.count ?? 0;

        globalUpdates.push({
          row,
          col: GlobalColumnIndexes.usage,
          content: usage,
        });
        netUsage += usage;
        if (global[GlobalColumnIndexes.rowType] === RowTypes.T) {
          globalUpdates.push({
            row,
            col: GlobalColumnIndexes.usage,
            content: netUsage,
          });
        }
      });

      resolve(globalUpdates);
    });
  }

  async updateAggregations(aggregationMap: IAggregationMap): Promise<void> {
    const { sets, locations, unitDescriptions, currencies, fringes, groups, variables } =
      aggregationMap;

    // get value sheet from the formula sheet
    const setSheet = this.getSheetValues(SheetNames.SETS)[SheetNames.SETS];
    const fringeSheet = this.getSheetValues(SheetNames.FRINGES)[SheetNames.FRINGES];
    const locationSheet = this.getSheetValues(SheetNames.LOCATIONS)[SheetNames.LOCATIONS];
    const currencySheet = this.getSheetValues(SheetNames.CURRENCY)[SheetNames.CURRENCY];
    const groupSheet = this.getSheetValues(SheetNames.GROUPS)[SheetNames.GROUPS];
    const costToDateSheet = this.getSheetValues(SheetNames.COSTS_TO_DATE)[SheetNames.COSTS_TO_DATE];
    const globalList = this.getSheetValues(SheetNames.GLOBALS)[SheetNames.GLOBALS];
    const unitDescriptionSheet = this.getSheetValues(SheetNames.UNIT_DESCRIPTIONS)[
      SheetNames.UNIT_DESCRIPTIONS
    ];

    // get the aggregation sheet contents
    const setUpdateContents = await this.getSetsAggregationContents(setSheet, sets);
    const fringeUpdateContents = await this.getFringesAggregationContents(fringeSheet, fringes);
    const unitDescriptionContents = await this.getUnitDescriptionAggregationContents(
      unitDescriptionSheet,
      unitDescriptions,
    );
    const groupsUpdatesContents = await this.getUpdateGroupAggregationContents(groupSheet, groups);
    const costToDateUpdateContents = await this.getUpdateCostToDateAggregationContents(
      costToDateSheet,
      variables,
    );
    const globalUpdateContents = await this.getUpdateGlobalAggregationContents(
      globalList,
      variables,
    );
    const currenciesUpdateContents = await this.getUpdateCurrencyAggregationContents(
      currencySheet,
      currencies,
    );
    const locationsUpdateContents = await this.getLocationsAggregationContents(
      locationSheet,
      locations,
    );

    this.hf.batch(() => {
      this.updateSheetContents(SheetNames.SETS, setUpdateContents, false);
      this.updateSheetContents(SheetNames.FRINGES, fringeUpdateContents, false);
      this.updateSheetContents(SheetNames.UNIT_DESCRIPTIONS, unitDescriptionContents, false);
      this.updateSheetContents(SheetNames.CURRENCY, currenciesUpdateContents, false);
      this.updateSheetContents(SheetNames.GROUPS, groupsUpdatesContents, false);
      this.updateSheetContents(SheetNames.COSTS_TO_DATE, costToDateUpdateContents, false);
      this.updateSheetContents(SheetNames.GLOBALS, globalUpdateContents, false);
      this.updateSheetContents(SheetNames.LOCATIONS, locationsUpdateContents, false);
    });

    this.updateGroupsNamedExpressionsValue(this.getGroupList());

    this.fire('UpdateAggregations', {
      eventName: 'UpdateAggregations',
    } as IFormulaSheetChangeEvent);

    return Promise.resolve();
  }

  async loadDataSheetFromPresignedUrl(presignedUrl: string): Promise<IDataSheet> {
    const dataSheet: IDataSheet = await DataSheetApiService.loadSheetFromPresignedUrl(presignedUrl);
    return dataSheet;
  }

  async saveDataSheetInFolderOnRemote(
    username: string,
    savePath: string,
    fileType: S3ObjectType,
    dataSheet?: IDataSheet,
    previousPath?: string,
  ): Promise<void> {
    try {
      if (dataSheet) {
        await DataSheetApiService.saveSheetByFileType(
          username,
          dataSheet,
          savePath,
          fileType,
          previousPath,
        );
      } else {
        const cachedDataSheet: IDataSheet = await this.getLocalDataSheetOnCache();
        await DataSheetApiService.saveSheetByFileType(
          username,
          cachedDataSheet,
          savePath,
          fileType,
          previousPath,
        );
      }
    } catch (err) {
      console.error('Error saving changes');
    }
  }

  addNewFringeToCellOrder(fringe: string) {
    this.fringeCellOrder.push(fringe);
  }

  renameFringeCellOrder(oldCode: string, newCode: string) {
    this.fringeCellOrder.rename(oldCode, newCode);
  }

  addRow(
    sheetName: string,
    row: number,
    cellContents: (string | number | null)[],
    useBatchOperation = true,
  ): void {
    const executeAddRow = () => {
      const sheetId = this.hf.getSheetId(sheetName);
      if (sheetId !== undefined) {
        this.hf.addRows(sheetId, [row, 1]);
        this.hf.setCellContents(
          {
            row,
            col: 0,
            sheet: sheetId,
          },
          [cellContents],
        );
      }
    };
    if (useBatchOperation) {
      this.hf.batch(() => {
        executeAddRow();
      });
    } else {
      executeAddRow();
    }
  }

  addRows(sheetName: string, addContents: IAddSheetContent[], useBatchOperation = true): void {
    const executeAddRows = () => {
      const sheetId = this.hf.getSheetId(sheetName);
      if (sheetId !== undefined) {
        addContents.sort(function (a, b) {
          return a.row - b.row;
        });
        addContents.forEach((addContent) => {
          this.hf.addRows(sheetId, [addContent.row, 1]);
          this.hf.setCellContents(
            {
              row: addContent.row,
              col: 0,
              sheet: sheetId,
            },
            [addContent.content],
          );
        });
      }
    };
    if (useBatchOperation) {
      this.hf.batch(() => {
        executeAddRows();
      });
    } else {
      executeAddRows();
    }
  }

  addAllRowsInSheet = (sheetId: number, sheet: IL2Sheet | IL3Sheet) => {
    lodash.forEach(sheet, (row, i) => {
      if (sheetId) {
        this.addRowsAndSetContents(sheetId, i, [row]);
      }
    });
  };

  addL1Row(dataSheet: IDataSheet, index: number): void {
    const transformer = new DataSheetTransformer();
    const l1Sheet = transformer.transformL1Sheets(dataSheet);
    const l1RowId = l1Sheet[index][0] as string;
    const l2DataSheet = dataSheet.l2[l1RowId] as Array<IL2Data>;
    const l2RowId = l2DataSheet[0].id as string;

    const l2Sheet = transformer.transformL2Sheet(dataSheet, l1RowId);
    const l3Sheet = transformer.transformL3Sheet(dataSheet, l2RowId, this.fringeCellOrder);

    const l1SheetId = this.hf.getSheetId(SheetNames.L1);
    const subTotalIndex = l1Sheet.findIndex(
      (l1Data, currentIndex) =>
        index < currentIndex && l1Data[L1ColumnIndexes.rowType] === RowTypes.S,
    );
    const grandTotalIndex = l1Sheet.findIndex(
      (l1Data, currentIndex) =>
        index < currentIndex && l1Data[L1ColumnIndexes.rowType] === RowTypes.G,
    );
    this.hf.batch(() => {
      this.hf.addSheet(`l3_${l2RowId}`);
      this.hf.addSheet(`l2_${l1RowId}`);
      const l3SheetId = this.hf.getSheetId(`l3_${l2RowId}`);
      const l2SheetId = this.hf.getSheetId(`l2_${l1RowId}`);
      if (l3SheetId) {
        this.addAllRowsInSheet(l3SheetId, l3Sheet);
      }
      if (l2SheetId) {
        this.addAllRowsInSheet(l2SheetId, l2Sheet);
      }
      this.addRowsAndSetContents(l1SheetId, index, [l1Sheet[index]]);
      if (l1SheetId) {
        if (subTotalIndex >= 0) {
          this.setBudgetContent(subTotalIndex, l1SheetId, [l1Sheet[subTotalIndex]]);
        }
        if (grandTotalIndex >= 0) {
          this.setBudgetContent(grandTotalIndex, l1SheetId, [l1Sheet[grandTotalIndex]]);
        }
      }
    });
  }

  addL2Row(dataSheet: IDataSheet, l1RowId: string, l2DataRow: IL2Data, index: number): void {
    const transformer = new DataSheetTransformer();
    const l2RowId = l2DataRow.id as string;

    const l2Sheet = transformer.transformL2Sheet(dataSheet, l1RowId);
    const l3Sheet = transformer.transformL3Sheet(dataSheet, l2RowId, this.fringeCellOrder);
    const subTotalIndex = l2Sheet.findIndex(
      (l2Data, currentIndex) =>
        index < currentIndex && l2Data[L2ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      this.hf.addSheet(`l3_${l2RowId}`);
      const l3SheetId = this.hf.getSheetId(`l3_${l2RowId}`);
      const l2SheetId = this.hf.getSheetId(`l2_${l1RowId}`);
      if (l3SheetId) {
        this.addAllRowsInSheet(l3SheetId, l3Sheet);
      }
      if (l2SheetId) {
        this.addRowsAndSetContents(l2SheetId, index, [l2Sheet[index]]);
        if (subTotalIndex >= 0) {
          this.setBudgetContent(subTotalIndex, l2SheetId, [l2Sheet[subTotalIndex]]);
        }
      }
    });
  }

  addL3Row(dataSheet: IDataSheet, l2RowId: string, index: number): void {
    const transformer = new DataSheetTransformer();

    const l3Sheet = transformer.transformL3Sheet(dataSheet, l2RowId, this.fringeCellOrder);
    const subTotalIndex = l3Sheet.findIndex(
      (l3Data, currentIndex) =>
        index < currentIndex && l3Data[L3ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      const l3SheetId = this.hf.getSheetId(`l3_${l2RowId}`);
      if (l3SheetId) {
        this.addRowsAndSetContents(l3SheetId, index, [l3Sheet[index]]);
        if (subTotalIndex >= 0) {
          this.setBudgetContent(subTotalIndex, l3SheetId, [l3Sheet[subTotalIndex]]);
        }
      }
    });
  }

  addL1GrandTotalRow(dataSheet: IDataSheet, grandTotalAddIndex: number): void {
    const transform = new DataSheetTransformer();
    const l1Sheet = transform.transformL1Sheets(dataSheet);

    const nextGrandTotalIndex = lodash.findIndex(
      l1Sheet,
      (l1Data, index) =>
        grandTotalAddIndex < index && l1Data[L1ColumnIndexes.rowType] === RowTypes.G,
    );
    const l1SheetId = this.hf.getSheetId('l1');
    this.hf.batch(() => {
      if (l1SheetId) {
        this.addRowsAndSetContents(l1SheetId, grandTotalAddIndex, [l1Sheet[grandTotalAddIndex]]);
        if (nextGrandTotalIndex > 0) {
          this.setBudgetContent(nextGrandTotalIndex, l1SheetId, [l1Sheet[nextGrandTotalIndex]]);
        }
      }
    });
  }

  addL1SubTotalRow(dataSheet: IDataSheet, subTotalAddIndex: number): void {
    const transform = new DataSheetTransformer();
    const l1Sheet = transform.transformL1Sheets(dataSheet);

    const index = l1Sheet.findIndex(
      (l1Data, index) =>
        subTotalAddIndex + 1 < index && l1Data[L1ColumnIndexes.rowType] === RowTypes.S,
    );
    const l1SheetId = this.hf.getSheetId('l1');
    this.hf.batch(() => {
      if (l1SheetId) {
        this.addRowsAndSetContents(l1SheetId, subTotalAddIndex, [l1Sheet[subTotalAddIndex]]); // Fringe subtotal
        this.addRowsAndSetContents(l1SheetId, subTotalAddIndex + 1, [
          l1Sheet[subTotalAddIndex + 1],
        ]);
        if (index > 0) {
          this.setBudgetContent(index - 1, l1SheetId, [l1Sheet[index - 1]]);
          this.setBudgetContent(index, l1SheetId, [l1Sheet[index]]);
        }
      }
    });
  }

  addL2SubTotalRow(dataSheet: IDataSheet, l2RowId: string, subTotalAddIndex: number): void {
    const transform = new DataSheetTransformer();
    const l2Sheet = transform.transformL2Sheet(dataSheet, l2RowId);

    const index = l2Sheet.findIndex(
      (l2Data, index) => subTotalAddIndex < index && l2Data[L2ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      const l2SheetId = this.hf.getSheetId(`l2_${l2RowId}`);
      if (l2SheetId) {
        this.addRowsAndSetContents(l2SheetId, subTotalAddIndex, [l2Sheet[subTotalAddIndex]]);
        if (index > 0) {
          this.setBudgetContent(index, l2SheetId, [l2Sheet[index]]);
        }
      }
    });
  }

  addL3SubTotalRow(dataSheet: IDataSheet, l3RowId: string, subTotalAddIndex: number): void {
    const transform = new DataSheetTransformer();
    const l3Sheet = transform.transformL3Sheet(dataSheet, l3RowId, this.fringeCellOrder);

    const index = l3Sheet.findIndex(
      (l3Data, index) => subTotalAddIndex < index && l3Data[L3ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      const l3SheetId = this.hf.getSheetId(`l3_${l3RowId}`);
      if (l3SheetId) {
        this.addRowsAndSetContents(l3SheetId, subTotalAddIndex, [l3Sheet[subTotalAddIndex]]);
        if (index > 0) {
          this.setBudgetContent(index, l3SheetId, [l3Sheet[index]]);
        }
      }
    });
  }

  pasteL1Rows(dataSheet: IDataSheet, pasteIndexes: number[]): void {
    const transformer = new DataSheetTransformer();
    const l1Sheet = transformer.transformL1Sheets(dataSheet);
    const l1SheetLength = l1Sheet.length;
    const lastPasteIndex = Math.max.apply(null, pasteIndexes);
    const subtotalIndex = l1Sheet.findIndex(
      (l1Data, index) => lastPasteIndex < index && l1Data[L1ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      const l1SheetId = this.hf.getSheetId('l1');
      pasteIndexes.forEach((index) => {
        this.hf.addSheet(`l2_${l1Sheet[index][0]}`);
        if (l1Sheet[index][L1ColumnIndexes.rowType] === RowTypes.D) {
          const l2SheetId = this.hf.getSheetId(`l2_${l1Sheet[index][0]}`);
          const l2Sheet = transformer.transformL2Sheet(
            dataSheet,
            l1Sheet[index][0] as string,
          ) as unknown as RawCellContent[][];
          l2Sheet.forEach((l2Data, index) => {
            if (l2Data[L2ColumnIndexes.rowType] === RowTypes.D) {
              this.hf.addSheet(`l3_${l2Data[0]}`);
              const l3SheetId = this.hf.getSheetId(`l3_${l2Data[0]}`);
              const l3Sheet = transformer.transformL3Sheet(
                dataSheet,
                l2Sheet[index][0] as string,
                this.fringeCellOrder,
              );
              this.addRowsAndSetContents(l3SheetId, 0, l3Sheet, l3Sheet.length);
            }
          });
          if (l2SheetId) {
            this.addRowsAndSetContents(l2SheetId, 0, l2Sheet, l2Sheet.length);
          }
        }
        this.addRowsAndSetContents(l1SheetId, index, [l1Sheet[index]]);
      });

      if (l1SheetId) {
        // Fringe subtotal row
        if (
          subtotalIndex > 0 &&
          !pasteIndexes.includes(subtotalIndex - 1) &&
          !pasteIndexes.includes(subtotalIndex)
        ) {
          this.setBudgetContent(subtotalIndex - 1, l1SheetId, [l1Sheet[subtotalIndex - 1]]);
          this.setBudgetContent(subtotalIndex, l1SheetId, [l1Sheet[subtotalIndex]]);
        }
        this.setBudgetContent(l1SheetLength - 2, l1SheetId, [l1Sheet[l1SheetLength - 2]]);
        this.setBudgetContent(l1SheetLength - 1, l1SheetId, [l1Sheet[l1SheetLength - 1]]);
      }
    });
  }

  pasteL2Rows(dataSheet: IDataSheet, l1RowId: string, pasteIndexes: number[]): void {
    const transformer = new DataSheetTransformer();
    const l2Sheet = transformer.transformL2Sheet(dataSheet, l1RowId);
    const l2SheetLength = l2Sheet.length;
    const lastPasteIndex = Math.max.apply(null, pasteIndexes);
    const subtotalIndex = l2Sheet.findIndex(
      (l2Data, index) => lastPasteIndex < index && l2Data[L2ColumnIndexes.rowType] === RowTypes.S,
    );
    this.hf.batch(() => {
      const l2SheetId = this.hf.getSheetId(`l2_${l1RowId}`);

      pasteIndexes.forEach((index) => {
        if (l2Sheet[index][L2ColumnIndexes.rowType] === RowTypes.D) {
          this.hf.addSheet(`l3_${l2Sheet[index][0]}`);
          const l3SheetId = this.hf.getSheetId(`l3_${l2Sheet[index][0]}`);
          const l3Sheet = transformer.transformL3Sheet(
            dataSheet,
            l2Sheet[index][0] as string,
            this.fringeCellOrder,
          );
          if (l3SheetId) {
            this.addRowsAndSetContents(l3SheetId, 0, l3Sheet, l3Sheet.length);
          }
        }
        this.addRowsAndSetContents(l2SheetId, index, [l2Sheet[index]]);
      });

      if (l2SheetId) {
        if (subtotalIndex > 0 && !pasteIndexes.includes(subtotalIndex)) {
          this.setBudgetContent(subtotalIndex, l2SheetId, [l2Sheet[subtotalIndex]]);
        }
        this.setBudgetContent(l2SheetLength - 2, l2SheetId, [l2Sheet[l2SheetLength - 2]]);
        this.setBudgetContent(l2SheetLength - 1, l2SheetId, [l2Sheet[l2SheetLength - 1]]);
      }
    });
  }

  updateTotalDescription(
    dataSheet: IDataSheet,
    rowId: string,
    col: number,
    changeValue: string | number | null,
    sheetName: string,
  ) {
    let sheet: IL2Data[] | IL3Data[];
    let sheetId: number | undefined;
    let row: number | undefined;
    if (sheetName === SheetNames.L2) {
      sheet = dataSheet.l2[rowId];
      sheetId = this.hf.getSheetId(`l2_${rowId}`);
      row = sheet ? sheet.length - 1 : 0;
    }

    if (sheetName === SheetNames.L3) {
      sheet = dataSheet.l3[rowId];
      sheetId = this.hf.getSheetId(`l3_${rowId}`);
      row = sheet ? sheet.length - 1 : 0;
    }

    const totalDescription = changeValue ? `TOTAL FOR ${changeValue}` : 'TOTAL';

    if (sheetId && row) {
      this.hf.setCellContents(
        {
          row: row,
          col: col,
          sheet: sheetId,
        },
        [[totalDescription.toUpperCase()]],
      );
    }
  }

  pasteL3Rows(
    dataSheet: IDataSheet,
    selectedSheet: ISelectedSheet,
    pasteIndexes: number[],
    updateContentIndex: number[],
  ): void {
    const { l1SheetId: l1RowId, l2SheetId: l2RowId } = selectedSheet;
    const transformer = new DataSheetTransformer();
    const l3Sheet = transformer.transformL3Sheet(dataSheet, l2RowId, this.fringeCellOrder);
    const l2Sheet = transformer.transformL2Sheet(dataSheet, l1RowId);
    const l2SheetId = this.hf.getSheetId(`l2_${l1RowId}`);
    const l3SheetLength = l3Sheet.length;
    const lastPasteIndex = Math.max.apply(null, pasteIndexes);
    const subtotalIndex = l3Sheet.findIndex(
      (l3Data, index) => lastPasteIndex < index && l3Data[L3ColumnIndexes.rowType] === RowTypes.S,
    );

    this.hf.batch(() => {
      const l3SheetId = this.hf.getSheetId(`l3_${l2RowId}`);
      if (l3SheetId) {
        // Paste new element added
        pasteIndexes.forEach((index) => {
          this.addRowsAndSetContents(l3SheetId, index, [l3Sheet[index]]);
        });
        // Update Fringe range rows
        updateContentIndex.forEach((index) => {
          this.setBudgetContent(index, l3SheetId, [l3Sheet[index]]);
        });
        if (
          subtotalIndex > 0 &&
          !pasteIndexes.includes(subtotalIndex) &&
          !updateContentIndex.includes(subtotalIndex)
        ) {
          this.setBudgetContent(subtotalIndex, l3SheetId, [l3Sheet[subtotalIndex]]);
        }
        this.setBudgetContent(l3SheetLength - 2, l3SheetId, [l3Sheet[l3SheetLength - 2]]);
        this.setBudgetContent(l3SheetLength - 1, l3SheetId, [l3Sheet[l3SheetLength - 1]]);
      }
      if (l2SheetId) {
        const l2Index = l2Sheet.findIndex((l2Data) => l2Data[0] === l2RowId);
        this.setBudgetContent(l2Index, l2SheetId, [l2Sheet[l2Index]]);
      }
    });
  }

  removeRows(sheetName: string, removeItems: IRemoveSheetItem[], useBatchOperation = true): void {
    const sheetId = this.hf.getSheetId(sheetName);
    const executeRemoveRows = () => {
      if (sheetId !== undefined) {
        removeItems.forEach((removeItem) => {
          this.hf.removeRows(sheetId, [removeItem.index, 1]);
          if (removeItem?.code && this.hf.isItPossibleToRemoveNamedExpression(removeItem?.code)) {
            this.hf.removeNamedExpression(removeItem?.code);
          }
        });
      }
    };
    if (useBatchOperation) {
      this.hf.batch(() => {
        executeRemoveRows();
      });
    } else {
      executeRemoveRows();
    }
  }

  removeRow(sheetName: string, row: number, numberOfRows: number): void {
    const sheetId = this.hf.getSheetId(sheetName);
    if (sheetId !== undefined) {
      this.hf.removeRows(sheetId, [row, numberOfRows]);
    }
  }

  moveRows(sheetName: string, startRow: number, numberOfRows: number, targetRow: number): void {
    const sheetId = this.hf.getSheetId(sheetName);
    if (
      sheetId !== undefined &&
      this.hf.isItPossibleToMoveRows(sheetId, startRow, numberOfRows, targetRow)
    ) {
      this.hf.moveRows(sheetId, startRow, numberOfRows, targetRow);
    }
  }

  removeSheet(sheetName: string) {
    const sheetId = this.getSheetId(sheetName);
    if (sheetId !== undefined) {
      this.hf.removeSheet(sheetId);
    }
  }

  setSheetContent(
    sheetName: string,
    row: number,
    col: number,
    value: string | number | null,
  ): void {
    const sheetId = this.hf.getSheetId(sheetName);
    if (sheetId !== undefined) {
      this.hf.setCellContents(
        {
          row,
          col,
          sheet: sheetId,
        },
        [[value]],
      );

      if (sheetName.startsWith('l3_')) {
        this.fire('L3SheetModified', {
          eventName: 'L3SheetModified',
          sheetName,
          changes: [row, col, [value]],
        } as IFormulaSheetChangeEvent);
      }
    }
  }

  updateRemovedCells(
    sheetName: string,
    cells: ICellCoords[],
    updatedNamedExpressions?: INamedExpressionData[],
    removeNamedExpressionCodes?: string[],
  ): void {
    const sheetId = this.hf.getSheetId(sheetName);
    if (sheetId !== undefined) {
      this.hf.batch(() => {
        cells.forEach((cell) => {
          this.hf.setCellContents(
            {
              row: cell.row,
              col: cell.col,
              sheet: sheetId,
            },
            [['']],
          );
        });
        updatedNamedExpressions?.forEach((namedExpression) => {
          if (
            this.hf.isItPossibleToChangeNamedExpression(
              namedExpression.code,
              namedExpression.expression,
            )
          ) {
            this.hf.changeNamedExpression(namedExpression.code, namedExpression.expression);
          }
        });
        removeNamedExpressionCodes?.forEach((code) => {
          if (this.hf.isItPossibleToRemoveNamedExpression(code)) {
            this.hf.removeNamedExpression(code);
          }
        });
      });
    }
  }

  updateSheetContents(
    sheetName: string,
    updatedContents: IUpdateSheetContent[],
    useBatchOperation = true,
  ) {
    const executeUpdateSheetContents = () => {
      const sheetId = this.hf.getSheetId(sheetName);
      if (sheetId !== undefined) {
        updatedContents.forEach((updatedContent) => {
          this.hf.setCellContents(
            {
              row: updatedContent.row,
              col: updatedContent.col,
              sheet: sheetId,
            },
            [[updatedContent.content]],
          );
        });
      }
    };

    if (useBatchOperation) {
      this.hf.batch(executeUpdateSheetContents);
    } else {
      executeUpdateSheetContents();
    }
  }

  async resetVariance(dataSheet: IDataSheet): Promise<void> {
    const sheetId = this.hf.getSheetId(SheetNames.LOOKUP);

    if (sheetId !== undefined) {
      const value = `=${dataSheet.configs.dropCurrencyAtReset}`;

      this.hf.setCellContents(
        {
          row: LookupRowIndexes.dropCurrencyAtReset - 1,
          col: LookupColumnIndexes.value,
          sheet: sheetId,
        },
        [[value]],
      );
    }

    Object.keys(dataSheet.l3).forEach((key) => {
      const sheetName = `l3_${key}`;
      const sheetId = this.hf.getSheetId(sheetName);
      if (sheetId) {
        const sheetValues = this.hf.getSheetValues(sheetId);

        if (sheetValues) {
          this.hf.batch(() => {
            sheetValues.forEach((row, index) => {
              const total = row[L3ColumnIndexes.total];

              if (!instanceOfCellError(total)) {
                this.hf.setCellContents(
                  {
                    row: index,
                    col: L3ColumnIndexes.comparison,
                    sheet: sheetId,
                  },
                  [[total as RawCellContent]],
                );
              }
            });
          });
        }
      }
    });

    return Promise.resolve();
  }

  getAllNamedExpression() {
    return this.hf.listNamedExpressions();
  }

  /** Saving to local and cloud */
  async saveDataSheetOnCache(dataSheet: IDataSheet): Promise<void> {
    return await this.webStorage.save(dataSheet);
  }

  async clearDataSheetOnCache(): Promise<void> {
    await this.webStorage.removeDataSheet();
    await this.webStorage.removeReports();
  }

  async turnOffRemoteSyncing(): Promise<void> {
    await this.webStorage.turnOffSyncing();
  }

  async getLocalDataSheetOnCache(): Promise<IDataSheet> {
    return await this.webStorage.getDataSheet();
  }

  async isDataSheetPendingSyncing(): Promise<boolean> {
    return await this.webStorage.isDataSheetPendingSyncing();
  }

  async isDataSheetAvailableOnCache(): Promise<boolean> {
    return await this.webStorage.isItDataSheetExists();
  }

  getAllNamedExpressionsSerialized() {
    return this.hf.getAllNamedExpressionsSerialized();
  }

  /**
   * FORMULA SHEET EVENTS
   */

  private fire(eventName: string, event: FormulaEvent): void {
    const fns = this.eventsListeners.get(eventName);
    if (fns && fns.length > 0) {
      fns.forEach((fn) =>
        fn({
          ...event,
        }),
      );
    }
  }

  on(
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: (event: FormulaEvent) => void = () => {},
  ): void {
    const events = [
      'Build',
      'UpdateAggregations',
      'L3SheetAdded',
      'L3SheetModified',
      'L3SheetRemoved',
    ];

    if (events.indexOf(eventName) < 0) {
      throw new Error('Invalid event name: ' + eventName);
    }

    const existingEvents = this.eventsListeners.get(eventName) ?? ([] as (() => void)[]);
    this.eventsListeners.set(eventName, [...existingEvents, callback]);
  }

  off(eventName: string, callback: (event: FormulaEvent) => void): void {
    const existingEvents = this.eventsListeners.get(eventName) ?? ([] as (() => void)[]);
    if (existingEvents.length > 1) {
      this.eventsListeners.set(
        eventName,
        existingEvents?.filter((fn) => fn !== callback),
      );
    } else {
      this.eventsListeners.delete(eventName);
    }
  }
}

export default FormulaSheet;
