import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCostTracker } from '@/contexts/CostTrackerContext';

const validateMask = (value: string | undefined, useDetaAsSet: boolean): boolean => {
  if (value === '') return true;
  if (!value) return false;

  const cleanValue = value.replace(/[-\s._]/g, '');

  const prodMatches = cleanValue.match(/P/g) || [];
  const detlMatches = cleanValue.match(/D/g) || [];
  const setMatches = cleanValue.match(/S/g) || [];

  const isValidProd = prodMatches.length <= 4;
  const isValidDetl = detlMatches.length <= 4;
  const isValidSet = setMatches.length <= 3;

  const patternRegex = useDetaAsSet
    ? /^(P{0,4}[-\s._]*S{0,3})?$/
    : /^(P{0,4}[-\s._]*D{0,4}[-\s._]*S{0,3})?$/;
  const isValidPattern = patternRegex.test(cleanValue);

  if (useDetaAsSet) {
    return isValidProd && isValidSet && !cleanValue.includes('D') && isValidPattern;
  }

  return isValidProd && isValidDetl && isValidSet && isValidPattern;
};

const OptionFormSchema = yup
  .object({
    mask: yup
      .string()
      .when('detaAsSets', ([detaAsSets], schema) =>
        schema.test('mask-validation', 'Invalid mask format', (value) =>
          validateMask(value, detaAsSets),
        ),
      ),
    episodeCode: yup.string().max(3, 'Episode code must be 3 characters or less'),
    costTrackerMode: yup.boolean().required(),
    viewTransactionsLevel3: yup.boolean().required(),
    includeUnpostedData: yup.boolean().required(),
    detaAsSets: yup.boolean().required(),
  })
  .required();

export type OptionFormState = yup.InferType<typeof OptionFormSchema>;

export const useOptionForm = () => {
  const { costTrackerOptions } = useCostTracker();
  const {
    mask,
    episodeCode,
    costTrackerMode,
    viewTransactionsLevel3,
    includeUnpostedData,
    detaAsSets,
  } = costTrackerOptions;

  return useForm<OptionFormState>({
    mode: 'onChange',
    resolver: yupResolver(OptionFormSchema),
    defaultValues: {
      mask,
      episodeCode,
      costTrackerMode,
      viewTransactionsLevel3,
      includeUnpostedData,
      detaAsSets,
    },
  });
};
