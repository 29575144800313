import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useField } from '@/hooks';
import { getSession } from '@/utils';
import { phoneNumberValidator } from '@/utils';
import { rootFolder } from '@/atoms/GlobalAtoms';
import { UserChangePasswordAtom, UserDetailAtom } from '@/atoms/UserAccountAtom';
import { DialogBoxContainer, MainButton } from '@/components/common';
import { useToaster } from '@/components/common/Toaster';
import { splitPhoneNumber } from '@/helpers/splitPhoneNumber';
import { getUser, updateUser } from '@/services/UserApiService';
import { ChangePassword } from './ChangePassword';
import { BoxButtonRow } from './style';
import { UserDetails } from './UserDetails';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AccountDialogBox = ({ open, setOpen }: Props) => {
  const snackBar = useToaster();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useRecoilState(UserDetailAtom);
  const [changePassword, setChangePassword] = useRecoilState(UserChangePasswordAtom);
  const userEmail = useRecoilValue(rootFolder);
  const [mobile, setMobile, mobileError] = useField<string>('', phoneNumberValidator);

  useEffect(() => {
    getUserHandler();
  }, []);

  const getUserHandler = async () => {
    try {
      setUserDetail((prevState) => ({
        ...prevState,
        loading: true,
      }));
      if (userEmail) {
        const response = await getUser(userEmail);
        const [countryCode, remainingNumber] = splitPhoneNumber(response);
        setUserDetail((prevState) => ({
          ...prevState,
          countryCode,
          mobileOnly: remainingNumber,
          mobileNumber: response.phoneNumber,
          locale: response.locale,
          loading: false,
          preMobileNumber: response.phoneNumber,
        }));
        setMobile(remainingNumber);
      } else {
        snackBar.error('Error Retrieving User Details');
        setUserDetail((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      snackBar.error('Error Retrieving User Details');
      setUserDetail((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleUpdateUserDetails = async () => {
    if (userDetail.mobileNumber === '' || !userDetail.isValid || !userEmail) {
      snackBar.error('Please fill all required fields');
      return;
    }
    if (userDetail.mobileNumber === userDetail.preMobileNumber) {
      snackBar.info('No changes found');
      return;
    }
    try {
      setUserDetail((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await updateUser(userEmail, {
        mobile: userDetail.mobileNumber,
      });
      if (response.status === 'SUCCESS') {
        snackBar.success('Successfully Updated');
        setUserDetail((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setOpen(false);
      } else if (response.status === 'FAILED') {
        snackBar.error(response.message);
        setUserDetail((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      snackBar.error('Error Updating User Details');
      setUserDetail((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handlePasswordChange = async () => {
    const { oldPassword, newPassword, confirmPassword, isValid } = changePassword;

    if (oldPassword === '' || newPassword === '' || confirmPassword === '' || !isValid) {
      snackBar.error('Please fill all required fields');
      return;
    }

    try {
      setChangePassword((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const session = await getSession();

      if (!session || !session.user) {
        throw new Error('User session not found');
      }

      session.user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          setChangePassword((prevState) => ({
            ...prevState,
            loading: false,
            oldPasswordNetworkError: 'Incorrect password',
          }));
          return;
        }
        snackBar.success('Successfully changed your password');
        setShowChangePassword(false);
        setChangePassword((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
    } catch (error: any) {
      snackBar.error(error.message);
      setChangePassword((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <DialogBoxContainer
      open={open}
      onKeyDown={handleKeyDown}
      setOpen={setOpen}
      dialogTitle="Account"
      boxWidth={'500px'}
      dialogActions={
        <BoxButtonRow>
          {showChangePassword ? (
            <>
              <MainButton variant="text" onClick={() => setShowChangePassword(false)} autoFocus>
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() => handlePasswordChange()}
                loading={changePassword.loading}
              >
                Save
              </MainButton>
            </>
          ) : (
            <MainButton
              variant="contained"
              onClick={() => handleUpdateUserDetails()}
              autoFocus
              loading={userDetail.loading}
            >
              Save
            </MainButton>
          )}
        </BoxButtonRow>
      }
    >
      {showChangePassword ? (
        <ChangePassword />
      ) : (
        <UserDetails
          onClickChangePassword={() => {
            setShowChangePassword(true);
          }}
          mobile={mobile}
          setMobile={setMobile}
          mobileError={mobileError}
        />
      )}
    </DialogBoxContainer>
  );
};
