import lodash from 'lodash';
import { UnitDescAlias, UnitDescriptionColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { IUnitDesc, IUnitDescSheet } from '@/interfaces/IFormulaSheet';
import { IUnitDescriptionData } from '@/interfaces/IUnitDescriptionData';

export const transformUnitDesc = (unitDescs: IUnitDescriptionData[]) => {
  if (lodash.isEmpty(unitDescs)) {
    return [];
  }
  const unitDescSheet: IUnitDescSheet = unitDescs?.map((unitDesc: IUnitDescriptionData, index) => {
    return unitDescDataToFormulaTransformation(unitDesc, index);
  });

  const unitDescTotalRow: IUnitDesc = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', // units
    0, // usage
    `=SUM(${UnitDescAlias.Attachments}0:${UnitDescAlias.Attachments}${unitDescSheet.length})`, // attachments
    '', // notes
    'T', // row type
    `=ROW(${UnitDescAlias.Code}${unitDescSheet.length + 1})-1`,
  ];

  unitDescSheet.push(unitDescTotalRow);

  return unitDescSheet;
};

export const unitDescsToDataSheetTransformation = (unitDesc: IUnitDesc) => {
  return {
    id: unitDesc[UnitDescriptionColumnIndexes.id],
    category: unitDesc[UnitDescriptionColumnIndexes.category],
    code: unitDesc[UnitDescriptionColumnIndexes.code],
    description: unitDesc[UnitDescriptionColumnIndexes.description],
    units: unitDesc[UnitDescriptionColumnIndexes.units],
    usage: unitDesc[UnitDescriptionColumnIndexes.usage],
    attachments: unitDesc[UnitDescriptionColumnIndexes.attachments],
    notes: unitDesc[UnitDescriptionColumnIndexes.notes],
  } as IUnitDescriptionData;
};

export const unitDescDataToFormulaTransformation = (
  unitDesc: IUnitDescriptionData,
  index: number,
) => {
  return [
    unitDesc.id,
    unitDesc.category,
    unitDesc.code,
    unitDesc.description,
    unitDesc.units,
    0,
    unitDesc.attachments,
    unitDesc.notes,
    'D',
    `=ROW(${UnitDescAlias.Code}${index + 1})-1`,
  ] as IUnitDesc;
};
