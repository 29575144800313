/**
 * NOTE:
 * This was implemented because handsontable doesn't
 * contain an interface for cell types. It only contains namespace
 *
 */

import Handsontable from 'handsontable';

export type IHandsontableCellType = keyof typeof Handsontable.cellTypes;

export const HandsontableCellTypeArray: Array<IHandsontableCellType> = Object.keys(
  Handsontable.cellTypes,
) as Array<IHandsontableCellType>;

export const HandsontableCellType: { [key in IHandsontableCellType]: IHandsontableCellType } =
  HandsontableCellTypeArray.reduce((accumulator, currentValue) => {
    accumulator[currentValue as IHandsontableCellType] = currentValue as IHandsontableCellType;
    return accumulator;
  }, {} as { [key in IHandsontableCellType]: IHandsontableCellType });
