import { CardContent } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import React, { ReactElement } from 'react';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
}

export const FormCardContent = ({ children, loading = false }: Props): ReactElement => (
  <CardContent style={{ padding: '0px', width: '600px' }}>
    {loading && (
      <LinearProgress
        sx={{
          height: '5px',
        }}
      />
    )}
    <Stack p={6}>{children}</Stack>
  </CardContent>
);
