export const requiredXmlFields: Array<string> = [
  'categories',
  'accounts',
  'details',
  'categories.category',
  'details.detail',
  'accounts.account',
];
export const expectedKeys: Array<string> = ['categories', 'accounts', 'details', 'metadata'];

export const formatIndividualSet = (value: string, maxlength = 6): string => {
  const onlyNumbers = value.replace(/[^0-9.]/g, '');
  const num = onlyNumbers;
  if (isNaN(Number(num))) return '';
  const numStr = num.toString();
  if (numStr.length > maxlength) {
    return ''; // Don't allow the value to be entered
  }

  return numStr;
};

// A function to format each value in a Set
export const formatSets = (inputSet: Set<string>, maxlength: number): Set<string> => {
  const formattedSet = new Set<string>();
  for (const value of inputSet) {
    for (const value of inputSet) {
      const formattedValue = formatIndividualSet(value, maxlength);
      if (formattedValue) {
        formattedSet.add(formattedValue);
      }
    }
  }

  return formattedSet;
};

export const formatValue = (value: string, allowPercent: boolean = false): string => {
  // Unicode normalize the string
  const normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Define the regex pattern to use based on the allowPercent flag
  const pattern = allowPercent ? /[^a-zA-Z0-9_ %]/g : /[^a-zA-Z0-9_ ]/g;

  // Temporarily allow spaces along with alphanumeric characters and underscores
  let formattedValue = normalized.replace(pattern, '');

  // Replace spaces with underscores
  formattedValue = formattedValue.replace(/\s/g, '_');

  // Add underscore if the string starts or ends with a digit
  const startsOrEndsWithDigit = /^\d/.test(formattedValue) || /\d$/.test(formattedValue);
  if (startsOrEndsWithDigit && !formattedValue.startsWith('_')) {
    formattedValue = `_${formattedValue}`;
  }

  return formattedValue;
};
