import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';

import { StyledMenu, StyledMenuItem, StyledMenuItemText } from './Menu.styled';
import { type MenuProps } from './Menu.types';

const Menu = ({ menuOptions }: MenuProps) => (
  <StyledMenu>
    <MenuList style={{ paddingTop: '30px', paddingInline: '15px' }}>
      {menuOptions.map((option) => (
        <StyledMenuItem key={option.id}>
          <MenuItem
            onClick={() => {
              if (option.action && !option.disabled) {
                option.action();
              }
            }}
            disabled={option.disabled}
            style={{ padding: 0, paddingInline: '10px' }}
          >
            <ListItemIcon>
              <IconButton style={{ color: '#f1f3f4' }}>{option.icon} </IconButton>
            </ListItemIcon>
            <ListItemText>
              <StyledMenuItemText>{option.label}</StyledMenuItemText>
            </ListItemText>
          </MenuItem>
        </StyledMenuItem>
      ))}
    </MenuList>
  </StyledMenu>
);

export default Menu;
