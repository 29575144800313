/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
const COGNITO_VERIFY_CODE_LENGTH = 6;

Yup.setLocale({
  mixed: {
    required: 'This field cannot be empty',
  },
  string: {
    min: 'This field must be at least ${min} characters',
    max: 'This field must be at most ${max} characters',
  },
});

export type Validator<T> = (value: T) => string | boolean;

export const createYupValidator =
  <T>(schema: Yup.AnySchema) =>
  (value: T): string | boolean => {
    try {
      schema.validateSync(value);
      return true;
    } catch (err) {
      if (err instanceof Error) {
        return err.message;
      }
      return false;
    }
  };

export const emailValidator = createYupValidator(
  Yup.string().email('This must be a valid email').required() as any,
);

const passwordSchema = Yup.string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters long')
  .matches(/\d/, 'Password must contain at least one number')
  .matches(
    /[\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
    'Password must contain at least one special character',
  )
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter');

export const passwordValidator = createYupValidator<string>(passwordSchema as any);

export const authCodeValidator = createYupValidator<string>(
  Yup.string()
    .required()
    .length(COGNITO_VERIFY_CODE_LENGTH, 'This must be exactly 6 characters') as any,
);

export const maxCharValidator = createYupValidator(
  Yup.string()
    .required('This field is required')
    .max(300, 'Maximum characters allowed is 300') as any,
);

export const cannotBeEmptyValidator = createYupValidator(
  Yup.string().trim().min(1, 'This field is required').required('This field is required'),
);

export const reportInputValidator = createYupValidator(
  Yup.string()
    .required('This field is required')
    .max(50, 'Maximum characters allowed is 50') as any,
);

export const phoneNumberValidator = createYupValidator(
  Yup.string()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Please enter a valid phone number') as any,
);
