import lodash from 'lodash';
import { SYSTEM_CURRENCY_LABEL, metaConfigOptions } from '@/constants';
import { APP_PATH, TabIndexes } from '@/enums';
import { varianceCalcOptions } from '@/data/meta/AccountFormatData';
import { ICurrencyData } from '@/interfaces/ICurrencyData';
import { DateSheetAttributeType, IDataSheet } from '@/interfaces/IDataSheet';

export const returnFileName = (method: 'manual' | 'auto', fileName: string): string => {
  if (method === 'manual') return fileName;

  return `/${fileName?.replace(/\.[^/.]+$/, '')}.temp`;
};

export const getValidatedDefaultCurrencyCode = (currencies: Array<ICurrencyData>): string => {
  const defaultCurrency = currencies.find((curr) => curr.default === 'Y');

  return defaultCurrency?.code ?? SYSTEM_CURRENCY_LABEL;
};

export const validateSheetMetaData = (dataSheet: IDataSheet) => {
  for (const option of metaConfigOptions) {
    const { prop, values, index } = option;
    const propValue = lodash.get(dataSheet, `meta.${prop}`) ?? '';
    const hasMatchedValues = values.some((x) => x.value === propValue.toString());

    if (!hasMatchedValues) {
      lodash.set(dataSheet, `meta.${prop}`, values[index].value);
    }
  }

  const hasVarianceCalcOption = varianceCalcOptions.some(
    (x) => x.value === dataSheet?.configs?.varianceCalc,
  );

  const defaultCurrencyCode = getValidatedDefaultCurrencyCode(
    dataSheet[DateSheetAttributeType.CURRENCY],
  );

  dataSheet.configs = {
    dropCurrencyAtReset: defaultCurrencyCode,
    varianceCalc: hasVarianceCalcOption
      ? dataSheet.configs.varianceCalc
      : varianceCalcOptions[0].value,
  };

  return { dataSheet, defaultCurrencyCode };
};

export const getTabs = (isCostTrackerEnabled: boolean) => [
  {
    index: TabIndexes.BUDGET,
    label: isCostTrackerEnabled ? 'Cost Tracker' : 'Budget',
    href: APP_PATH.budget,
    topBarTitle: 'Top Sheet',
  },
  {
    index: TabIndexes.GLOBALS,
    label: 'Globals',
    href: APP_PATH.globals,
    topBarTitle: 'Globals',
  },
  {
    index: TabIndexes.GROUPS,
    label: 'Groups',
    href: APP_PATH.groups,
    topBarTitle: 'Groups',
  },
  {
    index: TabIndexes.FRINGES,
    label: 'Fringes',
    href: APP_PATH.fringes,
    topBarTitle: 'Fringes',
  },
  {
    index: TabIndexes.SETS,
    label: 'Sets',
    href: APP_PATH.sets,
    topBarTitle: 'Sets',
  },
  {
    index: TabIndexes.CTD,
    label: 'CTD',
    href: APP_PATH.ctd,
    topBarTitle: 'CTD',
  },
  {
    index: TabIndexes.LOCATIONS,
    label: 'Locations',
    href: APP_PATH.locations,
    topBarTitle: 'Locations',
  },
  {
    index: TabIndexes.CURRENCY,
    label: 'Currency',
    href: APP_PATH.currency,
    topBarTitle: 'Currency',
  },
  {
    index: TabIndexes.UNIT_DESC,
    label: 'Unit Desc',
    href: APP_PATH.unitDesc,
    topBarTitle: 'Unit Desc',
  },
];
