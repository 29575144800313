import {
  CostsToDateColumnIndexes,
  CurrencyColumnIndexes,
  FringesColumnIndexes,
  GlobalColumnIndexes,
  GroupColumnIndexes,
  LocationColumnIndexes,
  SetColumnIndexes,
  SheetNames,
  UnitDescriptionColumnIndexes,
} from '@/enums';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IFileAttachment } from '@/interfaces/IFileAttachments';
import FormulaSheet from '@/sheets/FormulaSheet';

function addAttachmentToSets(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.sets[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.sets[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.sets[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.sets[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.SETS,
          selectedRowIndex,
          SetColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.sets[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.SETS,
          selectedRowIndex,
          SetColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}

function addAttachmentsToCTD(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.costsToDate[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.costsToDate[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.costsToDate[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.costsToDate[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.COSTS_TO_DATE,
          selectedRowIndex,
          CostsToDateColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.costsToDate[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.COSTS_TO_DATE,
          selectedRowIndex,
          CostsToDateColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}
function addAttachmentsToCurrency(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.currency[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.currency[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.currency[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.currency[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.CURRENCY,
          selectedRowIndex,
          CurrencyColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.currency[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.CURRENCY,
          selectedRowIndex,
          CurrencyColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}

function addAttachmentsToFringes(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.fringes[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.fringes[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.fringes[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.fringes[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.FRINGES,
          selectedRowIndex,
          FringesColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.fringes[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.FRINGES,
          selectedRowIndex,
          FringesColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}

function addAttachmentsToGlobals(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.globals[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.globals[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.globals[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.globals[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.GLOBALS,
          selectedRowIndex,
          GlobalColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.globals[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.GLOBALS,
          selectedRowIndex,
          GlobalColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}
function addAttachmentsToGroups(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.groups[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.groups[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.groups[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.groups[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.GROUPS,
          selectedRowIndex,
          GroupColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.groups[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.GROUPS,
          selectedRowIndex,
          GroupColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}

function addAttachmentsToLocations(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.locations[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.locations[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.locations[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.locations[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.LOCATIONS,
          selectedRowIndex,
          LocationColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.locations[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.LOCATIONS,
          selectedRowIndex,
          LocationColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}
function addAttachmentsToUnitDescription(
  currentDataSheetCopy: IDataSheet,
  selectedRowIndexes: number[],
  formulaSheet: FormulaSheet,
  files: IFileAttachment[],
) {
  if (selectedRowIndexes) {
    selectedRowIndexes.forEach((selectedRowIndex) => {
      const rowId = currentDataSheetCopy.unitDesc[selectedRowIndex].id;

      const data = {
        [rowId]: files,
      };

      if (currentDataSheetCopy.fileAttachments) {
        if (rowId in currentDataSheetCopy.fileAttachments) {
          const existingRow: IFileAttachment[] = currentDataSheetCopy.fileAttachments[rowId];
          const newArr = existingRow.concat(files);
          currentDataSheetCopy.fileAttachments[rowId] = newArr;
        } else {
          currentDataSheetCopy.fileAttachments[rowId] = [];
          for (const file of files) {
            currentDataSheetCopy.fileAttachments[rowId].push({ ...file });
          }
        }
      } else {
        currentDataSheetCopy.fileAttachments = data;
      }
      if (currentDataSheetCopy.unitDesc[selectedRowIndex].attachments) {
        //increment count
        const attachmentCount = currentDataSheetCopy.unitDesc[selectedRowIndex].attachments;
        const newCount = files.length + parseInt(attachmentCount);
        currentDataSheetCopy.unitDesc[selectedRowIndex].attachments = newCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.UNIT_DESCRIPTIONS,
          selectedRowIndex,
          UnitDescriptionColumnIndexes.attachments,
          newCount,
        );
      } else {
        //add attachment property and increase count
        const filesCount = files.length;
        currentDataSheetCopy.unitDesc[selectedRowIndex].attachments = filesCount.toString();
        formulaSheet?.setSheetContent(
          SheetNames.UNIT_DESCRIPTIONS,
          selectedRowIndex,
          UnitDescriptionColumnIndexes.attachments,
          filesCount,
        );
      }
    });
  }
}
export {
  addAttachmentToSets,
  addAttachmentsToCTD,
  addAttachmentsToCurrency,
  addAttachmentsToFringes,
  addAttachmentsToGlobals,
  addAttachmentsToGroups,
  addAttachmentsToLocations,
  addAttachmentsToUnitDescription,
};
