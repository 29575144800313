export enum AUTH_PATH {
  main = '/',
  login = '/login',
  signup = '/sign-up',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  changePassword = '/change-password',
  multiFactorAuth = '/multiFactorAuth',
  userVerification = '/confirmUser',
  forceDetailsChange = '/force-details-change',
  subscriptionRenew = '/subscription-renew',
  dpfAuthSuccess = '/dpf-auth-success',
}

export enum APP_PATH {
  budget = '/budget',
  globals = '/globals',
  groups = '/groups',
  fringes = '/fringes',
  sets = '/sets',
  ctd = '/ctd',
  locations = '/locations',
  currency = '/currency',
  unitDesc = '/unit-desc',
  welcome = '/welcome',
  reportPreview = '/report-preview',
  transactions = '/transactions',
}
