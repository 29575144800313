import {
  createContext,
  useContext,
  useState,
  useEffect,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LocalStorageVariables } from '@/enums';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { costTrackerOptionsAtom, subscriptionLevelAtom } from '@/atoms/GlobalAtoms';
import { useToaster } from '@/components/common';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { logger } from '@/lib/logger';
import {
  SUBSCRIPTION_COST_TRACKER_LEVEL,
  SUBSCRIPTION_DEFAULT_LEVEL,
} from '../constants/subscription';

interface CostTrackerContextType {
  isDpfConnected: boolean;
  costTrackerOptions: CostTrackerOptions;
  setIsDpfConnected: (isConnected: boolean) => void;
  setCostTrackerOptions: Dispatch<SetStateAction<CostTrackerOptions>>;
  openAndMonitorOAuthWindow: (data: OAuthWindowParams) => void;
  resetCostTrackerOptions: () => void;
}

interface OAuthWindowParams {
  apiUrl: string;
  redirectUrl: string;
}

export interface CostTrackerOptions {
  mask: string;
  episodeCode: string;
  costTrackerMode: boolean;
  viewTransactionsLevel3: boolean;
  includeUnpostedData: boolean;
  detaAsSets: boolean;
}

const getDefaultMaskBasedOnAccountDigits = (dataSheet: IDataSheet | null) => {
  const DEFAULT_MASK = 'PPPPDDDDSSS';
  const accountDigits = dataSheet?.meta.accountFormat.accountDigits;

  if (accountDigits === undefined) {
    return 'PPPP';
  }

  return DEFAULT_MASK.slice(0, accountDigits);
};

const CostTrackerContext = createContext<CostTrackerContextType | undefined>(undefined);

export const CostTrackerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const initialDpfConnectionState = !!window.localStorage.getItem(
    LocalStorageVariables.DPF_API_ACCESS_TOKEN,
  );
  const [isDpfConnected, setIsDpfConnected] = useState<boolean>(initialDpfConnectionState);
  const [costTrackerOptions, setCostTrackerOptions] =
    useRecoilState<CostTrackerOptions>(costTrackerOptionsAtom);
  const dataSheet = useRecoilValue(dataSheetAtom);
  const subscriptionLevel = useRecoilValue(subscriptionLevelAtom);
  const snackbar = useToaster();
  const defaultMask =
    dataSheet?.meta.costTrackerMask ?? getDefaultMaskBasedOnAccountDigits(dataSheet);

  const resetCostTrackerOptions = () =>
    setCostTrackerOptions({
      mask: defaultMask,
      episodeCode: '',
      costTrackerMode: false,
      viewTransactionsLevel3: false,
      includeUnpostedData: false,
      detaAsSets: false,
    });

  const openAndMonitorOAuthWindow = (oAuthWindowParams: OAuthWindowParams) => {
    window.localStorage.removeItem(LocalStorageVariables.DPF_API_ACCESS_TOKEN); // clear existing tokens

    const { apiUrl, redirectUrl } = oAuthWindowParams;
    const windowFeatures =
      'popup=true,height=640,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no';

    const url = new URL(apiUrl);
    const params = new URLSearchParams(url.search);
    params.set('redirect_uri', redirectUrl);
    url.search = params.toString();

    const oAuthWindow = window.open(url.toString(), '_blank', windowFeatures);

    // Poll for OAuth window close to check if auth was successful.
    const pollIntervalId = setInterval(() => {
      if (oAuthWindow?.closed) {
        // Successful auth stores the DPF token in ls; if missing token in ls means authorization failure
        const isAuthSuccessful = !!window.localStorage.getItem(
          LocalStorageVariables.DPF_API_ACCESS_TOKEN,
        );
        setIsDpfConnected(isAuthSuccessful);
        clearInterval(pollIntervalId);
      }
    }, 1500);
  };

  useEffect(() => {
    if (subscriptionLevel === SUBSCRIPTION_DEFAULT_LEVEL) return;

    if (
      costTrackerOptions.costTrackerMode &&
      subscriptionLevel !== SUBSCRIPTION_COST_TRACKER_LEVEL
    ) {
      setCostTrackerOptions({
        ...costTrackerOptions,
        costTrackerMode: false,
      });
      logger.info('Cost Tracker mode disabled due to insufficient subscription level.');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costTrackerOptions.costTrackerMode]);

  useEffect(() => {
    const handleDpfTokenInvalidated = (showSnackBar: boolean = true) => {
      if (showSnackBar) snackbar.error('Eclipse token invalidated. Please reconnect to DPF.');
      setIsDpfConnected(false);
      resetCostTrackerOptions();
    };

    const handleTokenInvalidated = () => handleDpfTokenInvalidated(true);
    const handleTokenInvalidatedSilent = () => handleDpfTokenInvalidated(false);

    // Listens for DPF token invalidation events from axios interceptor when API responds with a 403 status
    window.addEventListener('dpfTokenInvalidated', handleTokenInvalidated);
    window.addEventListener('dpfTokenInvalidated_silent', handleTokenInvalidatedSilent);
    return () => {
      window.removeEventListener('dpfTokenInvalidated', handleTokenInvalidated);
      window.addEventListener('dpfTokenInvalidated_silent', handleTokenInvalidatedSilent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetCostTrackerOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CostTrackerContext.Provider
      value={{
        isDpfConnected,
        costTrackerOptions,
        setIsDpfConnected,
        setCostTrackerOptions,
        openAndMonitorOAuthWindow,
        resetCostTrackerOptions,
      }}
    >
      {children}
    </CostTrackerContext.Provider>
  );
};

export const useCostTracker = (): CostTrackerContextType => {
  const context = useContext(CostTrackerContext);
  if (context === undefined) {
    throw new Error('useCostTracker must be used within a CostTrackerProvider');
  }
  return context;
};
