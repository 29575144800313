import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ArrowLeftIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.8151 4.87668L9.87255 7.81918L8.06672 9.61585C7.30589 10.3767 7.30589 11.6142 8.06672 12.375L12.8151 17.1233C13.4384 17.7467 14.5017 17.2975 14.5017 16.4267V11.2842V5.57335C14.5017 4.69335 13.4384 4.25335 12.8151 4.87668Z"
        fill="#fffff"
      />
    </SvgIcon>
  );
};
