import { SvgIcon } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  size?: number;
};

export const UndoIcon = ({ size }: Props): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{
        width: size || 18,
        height: size || 18,
        fill: 'none',
      }}
    >
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5.941 15.258h6.667c2.3 0 4.167-1.866 4.167-4.166 0-2.3-1.867-4.167-4.167-4.167H3.441"
      />
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.358 9.008L3.225 6.875l2.133-2.133"
      />
    </SvgIcon>
  );
};
