import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';
import './styles.css';
import theme from '@/app/theme/base/theme';
import { countries } from '../../../constants/countries';

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  error?: string | boolean;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme>;
  labelText?: string;
  setSelectedCode: ({ dialCode, code }: { dialCode: string; code: string }) => void;
  selectedCode?: string;
};

export const MobileTextField = ({
  labelText,
  sx,
  onChange,
  error,
  setSelectedCode,
  selectedCode,
  ...props
}: Props): ReactElement => {
  const [touched, setTouched] = useState(false);

  const onTouch = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  return (
    <FormControl>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        {labelText}
      </Typography>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Autocomplete
          size="small"
          autoHighlight
          options={countries}
          onChange={(e, value) => {
            setSelectedCode(
              (value && { dialCode: value?.dial_code, code: value?.code }) || {
                dialCode: '',
                code: '',
              },
            );
          }}
          getOptionLabel={(option) => option.code + ' (' + option.dial_code + ')'}
          value={countries.find((country) => country.dial_code === selectedCode) || null}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.code} ({option.dial_code})
            </Box>
          )}
          sx={{
            mb: 1,
            height: '40px',
            width: '200px',
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Country"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px 0 0 4px',
                  fontSize: '14px',
                  paddingRight: '30px !important',
                },
                fontSize: '14px',
              }}
            />
          )}
        />

        <TextField
          {...props}
          error={touched && !!error}
          onBlur={onTouch}
          onChange={
            onChange
              ? (e) => {
                onChange(e.target.value);
              }
              : undefined
          }
          sx={{
            mb: 2,
            mt: 0.5,
            '& .MuiFormHelperText-root.Mui-error': {
              fontSize: '14px !important',
              margin: '5px 0 0 0 !important',
              padding: '0 !important',
            },
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '0 4px 4px 0',
              fontSize: '14px',
            },
          }}
        />
      </Stack>
      <Typography
        sx={{
          mt: -1.5,
          mb: 2,
          fontSize: 14,
          color: '#d32f2f',
        }}
      >
        {touched && typeof error === 'string' ? error : undefined}
      </Typography>
    </FormControl>
  );
};
