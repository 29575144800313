export const insertOptions = [
  {
    value: 'fringes',
    label: 'Fringes',
  },
  {
    value: 'groups',
    label: 'Groups',
  },
  {
    value: 'loc',
    label: 'Location',
  },
  {
    value: 'set',
    label: 'Set',
  },
  {
    value: 'cu',
    label: 'Currency',
  },
];
