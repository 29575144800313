import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LocalStorageVariables, UserTypes } from '@/enums';
import { getSession } from '@/utils';
import { isAuthAtom, rootFolder, userRoleAtom } from '@/atoms/GlobalAtoms';
import { IGetSessionCognito } from '@/interfaces/types';

interface Props {
  children: JSX.Element;
}

const useUserRole = () => {
  const [userRole, setUserRole] = useRecoilState(userRoleAtom);

  const setUserRoleFromSession = (session: any) => {
    const userGroups = session.accessToken.payload['cognito:groups'];
    let isAdmin = false;
    if (userGroups) {
      isAdmin = userGroups.includes('Admin');
    }
    isAdmin ? setUserRole(UserTypes.ADMIN) : setUserRole(UserTypes.USER);
  };

  return { userRole, setUserRoleFromSession };
};

export const ProtectedRoute = ({ children }: Props) => {
  const [isAuth, setIsAuth] = useRecoilState(isAuthAtom);
  const setRootFolder = useSetRecoilState(rootFolder);
  const posthog = usePostHog();
  const { setUserRoleFromSession } = useUserRole();

  React.useEffect(() => {
    getSession()
      .then((data) => {
        setIsAuth(true);
        const currentUserSession = data as IGetSessionCognito;
        setUserRoleFromSession(currentUserSession);
        setRootFolder(currentUserSession.email);
        localStorage.setItem(LocalStorageVariables.ROOT_PATH, currentUserSession.email);
        posthog?.identify(currentUserSession.sub, {
          email: currentUserSession.email,
        });
      })
      .catch((err) => {
        console.error('failed to get session: ', err);
        setIsAuth(false);
      });
  }, []);

  return isAuth ? children : <Navigate to="/login" />;
};
