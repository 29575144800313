export enum L3ColumnIndexes {
  sheetId = -1,
  id = 0,
  underOver = 1,
  range = 2,
  fringe = 3,
  fringes = 4,
  groups = 5,
  loc = 6,
  set = 7,
  description = 8,
  units = 9,
  desc = 10,
  x = 11,
  rate = 12,
  cu = 13,
  total = 14,
  comparison = 15,
  variance = 16,
  maxVariance = 17,
  rowType = 18,
  fringeTotal = 19,
  groupSuppress = 20,
  index = 21,
  unitFormula = 22,
  xFormula = 23,
  rateFormula = 24,
  concatenateUnitXRate = 25,
  fringeComparison = 26,
}

export enum VisibleL3ColumnIndexes {
  sheetId = -2,
  id = -1,
  underOver = 0,
  range = 1,
  fringe = 2,
  fringeTotal = 3,
  fringes = 4,
  groups = 5,
  loc = 6,
  set = 7,
  description = 8,
  units = 9,
  desc = 10,
  x = 11,
  rate = 12,
  cu = 13,
  total = 14,
  comparison = 15,
  variance = 16,
  index = 17,
  // maxVariance = 17,
  rowType = 18,
  fringeComparison = 19,
}

export enum L3TransactionsColumnIndexes {
  period = 0,
  chart = 1,
  in = 2,
  f = 3,
  tx = 4,
  date = 5,
  description = 6,
  amount = 7,
  code = 8,
  vendorName = 9,
  rec = 10,
  voucher = 11,
  vendor = 12,
  invoice = 13,
  cheque = 14,
  po = 15,
  tran = 16,
  line = 17,
  emp = 18,
  batch = 19,
  vendorAddress = 20,
  postCode = 21,
  state = 22,
  index = 23, // hidden
  rowType = 24, // hidden
}

export const level3ToVisibleColumnMap: { [key in L3ColumnIndexes]?: VisibleL3ColumnIndexes } = {
  [L3ColumnIndexes.sheetId]: VisibleL3ColumnIndexes.sheetId,
  [L3ColumnIndexes.id]: VisibleL3ColumnIndexes.id,
  [L3ColumnIndexes.underOver]: VisibleL3ColumnIndexes.underOver,
  [L3ColumnIndexes.range]: VisibleL3ColumnIndexes.range,
  [L3ColumnIndexes.fringe]: VisibleL3ColumnIndexes.fringe,
  [L3ColumnIndexes.fringeTotal]: VisibleL3ColumnIndexes.fringeTotal,
  [L3ColumnIndexes.fringes]: VisibleL3ColumnIndexes.fringes,
  [L3ColumnIndexes.groups]: VisibleL3ColumnIndexes.groups,
  [L3ColumnIndexes.loc]: VisibleL3ColumnIndexes.loc,
  [L3ColumnIndexes.set]: VisibleL3ColumnIndexes.set,
  [L3ColumnIndexes.description]: VisibleL3ColumnIndexes.description,
  [L3ColumnIndexes.units]: VisibleL3ColumnIndexes.units,
  [L3ColumnIndexes.desc]: VisibleL3ColumnIndexes.desc,
  [L3ColumnIndexes.x]: VisibleL3ColumnIndexes.x,
  [L3ColumnIndexes.rate]: VisibleL3ColumnIndexes.rate,
  [L3ColumnIndexes.cu]: VisibleL3ColumnIndexes.cu,
  [L3ColumnIndexes.total]: VisibleL3ColumnIndexes.total,
  [L3ColumnIndexes.comparison]: VisibleL3ColumnIndexes.comparison,
  [L3ColumnIndexes.variance]: VisibleL3ColumnIndexes.variance,
  [L3ColumnIndexes.index]: VisibleL3ColumnIndexes.index,
  [L3ColumnIndexes.rowType]: VisibleL3ColumnIndexes.rowType,
  [L3ColumnIndexes.fringeComparison]: VisibleL3ColumnIndexes.fringeComparison,
};
