import { Button, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import { captureUserFeedback } from '@sentry/browser';
import { UserFeedback } from '@sentry/types';
import { Component } from 'react';
import { APP_PATH, LocalStorageVariables, S3ObjectType } from '@/enums';
import { TextareaField } from '@/components/common';
import { logger } from '@/lib/logger';
import FormulaSheet from '@/sheets/FormulaSheet';

interface FallbackWithFeedbackFormProps {
  eventId: string;
  resetError: () => void;
}

interface State {
  isSendingFeedback: boolean;
  isSavingDataOnRemote: boolean;
  message: string;
  isValid: boolean;
}

class FallbackWithFeedbackForm extends Component<FallbackWithFeedbackFormProps, State> {
  constructor(props: FallbackWithFeedbackFormProps) {
    super(props);
    localStorage.removeItem(LocalStorageVariables.OPENED_FILES);

    this.saveCachedDataOnRemote();
    this.state = {
      isSendingFeedback: false,
      isSavingDataOnRemote: false,
      message: '',
      isValid: false,
    };
  }

  private saveCachedDataOnRemote = async () => {
    const formulaSheet = FormulaSheet.getInstance();
    const isDataCached = await formulaSheet.isDataSheetAvailableOnCache();

    if (!isDataCached) {
      logger.info('Data not available in cache - skipping remote save.');
      return;
    }

    try {
      this.setState({ isSavingDataOnRemote: true });
      const dataSheet = await formulaSheet.getLocalDataSheetOnCache();
      const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
      const fileName = localStorage.getItem(LocalStorageVariables.FILE_NAME);

      if (!dataSheet || !username || !fileName) {
        throw new Error('Insufficient data to save data sheet to remote!');
      }

      await formulaSheet.saveDataSheetInFolderOnRemote(
        username,
        fileName,
        S3ObjectType.JSON_FILE,
        dataSheet,
      );

      localStorage.removeItem(LocalStorageVariables.FILE_NAME);
      formulaSheet.clearDataSheetOnCache();
    } catch (error) {
      if (error instanceof Error) {
        logger.error(`Failed to save data to remote: ${error.message}`);
      } else {
        logger.error('An unknown error occurred during remote save.');
      }
    } finally {
      this.setState({ isSavingDataOnRemote: false });
    }
  };

  private handleInputChange = (value: string, key: 'message') => {
    this.setState((prevState) => ({ ...prevState, [key]: value }), this.validateForm);
  };

  private validateForm = () => {
    const { message } = this.state;
    const isValid = message.trim() !== '';
    this.setState({ isValid });
  };

  private sendFeedback = (formData: { message: string }): Promise<boolean> => {
    const { eventId } = this.props;

    return new Promise((resolve) => {
      try {
        const userEmail = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
        if (!userEmail) {
          throw new Error('User email not found in local storage');
        }
        const userFeedback: UserFeedback = {
          comments: formData.message,
          email: userEmail,
          name: userEmail.split('@')[0],
          event_id: eventId,
        };
        captureUserFeedback(userFeedback);
        resolve(true);
      } catch (error) {
        logger.error(error);
        resolve(false);
      }
    });
  };

  private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { message } = this.state;
    this.setState({ isSendingFeedback: true });
    await this.sendFeedback({ message });
    this.props.resetError();
    this.setState({ isSendingFeedback: false });

    this.handleBackToHomePage();
  };

  private handleBackToHomePage = () => {
    window.location.replace(APP_PATH.welcome);
  };

  render() {
    const { isSendingFeedback, isSavingDataOnRemote, isValid, message } = this.state;

    return (
      <Container
        maxWidth="lg"
        sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper sx={{ p: 8 }} variant="elevation" elevation={2} square={false}>
          <Stack spacing={2} direction="column" useFlexGap flexWrap="wrap" alignItems="center">
            <Stack spacing={1} direction="column" useFlexGap flexWrap="wrap" alignItems="center">
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                Ops! It looks like we're having some internal issue
              </Typography>
              <Typography variant="caption" sx={{ textAlign: 'center' }}>
                Our team has been notified. If you'd like to help, tell us what happened below.
              </Typography>
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Stack
              width="100%"
              spacing={5}
              direction="column"
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <form style={{ width: '100%' }} onSubmit={this.onSubmit}>
                <TextareaField
                  fullWidth
                  labelText="What happened?"
                  required
                  value={message}
                  onChange={(value) => this.handleInputChange(value.toString(), 'message')}
                  disabled={isSendingFeedback}
                  minRows={3}
                  maxRows={5}
                />
                <Stack
                  spacing={2}
                  direction="column"
                  useFlexGap
                  flexWrap="nowrap"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ maxWidth: '75%', mx: 'auto' }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    disabled={!isValid || isSendingFeedback}
                    variant="contained"
                  >
                    Submit Crash Report
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    disabled={isSavingDataOnRemote}
                    onClick={this.handleBackToHomePage}
                    color="primary"
                    size="large"
                  >
                    Back To Home Page
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    );
  }
}

export default FallbackWithFeedbackForm;
