// Calculate password strength function
export const calculatePasswordStrength = (password: string): number => {
  let strength = 0;

  // Check for minimum length requirement
  if (password.length >= 8) {
    strength += 25;
  }

  // Check for uppercase letters
  if (/[A-Z]/.test(password)) {
    strength += 25;
  }

  // Check for lowercase letters
  if (/[a-z]/.test(password)) {
    strength += 25;
  }

  // Check for digits
  if (/[0-9]/.test(password)) {
    strength += 25;
  }

  return strength;
};
