export enum UnitDescriptionColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  units = 4,
  usage = 5,
  attachments = 6,
  notes = 7,
  rowType = 8,
  index = 9,
}

export enum VisibleUnitDescriptionColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  units = 3,
  usage = 4,
  index = 5,
}

export const unitDescriptionToVisibleColumnMap: {
  [key in UnitDescriptionColumnIndexes]?: VisibleUnitDescriptionColumnIndexes;
} = {
  [UnitDescriptionColumnIndexes.id]: VisibleUnitDescriptionColumnIndexes.id,
  [UnitDescriptionColumnIndexes.category]: VisibleUnitDescriptionColumnIndexes.category,
  [UnitDescriptionColumnIndexes.code]: VisibleUnitDescriptionColumnIndexes.code,
  [UnitDescriptionColumnIndexes.description]: VisibleUnitDescriptionColumnIndexes.description,
  [UnitDescriptionColumnIndexes.units]: VisibleUnitDescriptionColumnIndexes.units,
  [UnitDescriptionColumnIndexes.usage]: VisibleUnitDescriptionColumnIndexes.usage,
};
