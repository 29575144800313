/* eslint-disable react/jsx-no-useless-fragment */
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TabIndexes } from '@/enums';
import { generatedId, generateRandomNameWithExtension } from '@/utils';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { currentTabIndexAtom, fileUrlAtom } from '@/atoms/GlobalAtoms';
import { AttachmentStatus } from '@/components/common';
import { useToaster } from '@/components/common/Toaster';
import AttachmentFileViewer from '@/components/dialogBoxes/AttachmentFileViewer';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import { DownloadIcon } from '@/components/icon';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IFileAttachment, IFileAttachmentList } from '@/interfaces/IFileAttachments';
import DataSheetApiService from '@/services/DataSheetApiService';
import FormulaSheet from '@/sheets/FormulaSheet';
import {
  addAttachmentsToCTD,
  addAttachmentsToCurrency,
  addAttachmentsToFringes,
  addAttachmentsToGlobals,
  addAttachmentsToGroups,
  addAttachmentsToLocations,
  addAttachmentsToUnitDescription,
  addAttachmentToSets,
} from './AddAttachments';
import {
  handleDeleteAttachmentsFromCTD,
  handleDeleteAttachmentsFromCurrency,
  handleDeleteAttachmentsFromFringes,
  handleDeleteAttachmentsFromGlobals,
  handleDeleteAttachmentsFromGroups,
  handleDeleteAttachmentsFromLocations,
  handleDeleteAttachmentsFromSets,
  handleDeleteAttachmentsFromUnitDescription,
} from './RemoveAttachments';

export interface OpenAttachmentDialogProps {
  openAttachmentDialog: boolean;
  setOpen: (open: boolean) => void;
  selectedRowIndex: number | undefined;
  selectedRowIndexes?: number[] | undefined;
  attachments: IFileAttachment[] | undefined;
  tabName?: string;
  formulaSheet?: FormulaSheet;
}

export interface UploadableFile {
  id: string;
  file: File;
  errors: FileError[];
  url?: string;
}

export interface IHandleDeleteAttachment {
  deleteFileName: string;
  contentType: string;
}

interface IPresignedBody {
  uploadFileName: string;
  contentType: string;
}

export interface IFileAttachmentDetails {
  id: string;
  file: File;
  errors: FileError[];
  url?: string;
}

export interface IHandleGetAttachment {
  getFileName: string;
}

const StyledOpenAttachmentDialog = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
`;

const FileUploadContainer = styled.div`
  border-style: dotted;
  border-width: 1px;
  padding: 3px;
  border-radius: 10px;
  background-color: #f0f0f0ff;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 12vh;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
`;

const DragAndDropTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  color: white;
  margin-top: 5px;
  margin-left: 2px;
  font-size: 25px;
  border-color: green;
`;

const DragAndDropText = styled.p`
  font-family: hellix;
  font-weight: 400;
  font-size: 16px;
  color: #747474;
`;

const StyledOpenAttachmentFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
  align-items: center;
  align-self: flex-end;
`;

const StyledContentDiv = styled.div`
  min-width: 460px;
`;

export function OpenAttachmentDialog({
  openAttachmentDialog,
  setOpen,
  selectedRowIndex,
  attachments,
  selectedRowIndexes,
  tabName,
  formulaSheet,
}: OpenAttachmentDialogProps) {
  const [dataSheet, setDataSheet] = useRecoilState(dataSheetAtom);
  const tabIndex = useRecoilValue(currentTabIndexAtom);
  const fileUrl = useRecoilValue(fileUrlAtom);
  const [uploadFileAttachments, setUploadFileAttachments] = useState<UploadableFile[]>([]);
  const [acceptedFiles, setAcceptedFiles] = useState<UploadableFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadBtnText, setUploadBtnText] = useState<string>('Upload');
  const [deleting, setDeleting] = useState<boolean>(false);
  const [getting, setGetting] = useState<boolean>(false);
  const snackBar = useToaster();

  const handleAddAttachments = {
    [TabIndexes.SETS]: addAttachmentToSets,
    [TabIndexes.CTD]: addAttachmentsToCTD,
    [TabIndexes.BUDGET]: null,
    //  [TabIndexes.CASHFLOW]: null,
    //  [TabIndexes.CAST_MANAGER]: null,
    [TabIndexes.CURRENCY]: addAttachmentsToCurrency,
    [TabIndexes.FRINGES]: addAttachmentsToFringes,
    [TabIndexes.GLOBALS]: addAttachmentsToGlobals,
    [TabIndexes.GROUPS]: addAttachmentsToGroups,
    [TabIndexes.LOCATIONS]: addAttachmentsToLocations,
    [TabIndexes.UNIT_DESC]: addAttachmentsToUnitDescription,
  };

  const handleRemoveAttachment = {
    [TabIndexes.SETS]: handleDeleteAttachmentsFromSets,
    [TabIndexes.CTD]: handleDeleteAttachmentsFromCTD,
    [TabIndexes.BUDGET]: null,
    //  [TabIndexes.CASHFLOW]: null,
    //  [TabIndexes.CAST_MANAGER]: null,
    [TabIndexes.CURRENCY]: handleDeleteAttachmentsFromCurrency,
    [TabIndexes.FRINGES]: handleDeleteAttachmentsFromFringes,
    [TabIndexes.GLOBALS]: handleDeleteAttachmentsFromGlobals,
    [TabIndexes.GROUPS]: handleDeleteAttachmentsFromGroups,
    [TabIndexes.LOCATIONS]: handleDeleteAttachmentsFromLocations,
    [TabIndexes.UNIT_DESC]: handleDeleteAttachmentsFromUnitDescription,
  };

  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet) as IDataSheet;

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const mapAccepted = acceptedFiles.map((file) => ({
      file,
      errors: [],
      id: generatedId(),
    }));
    const mapRejected = rejectedFiles.map((r) => ({ ...r, id: generatedId() }));

    setUploadFileAttachments((currentFiles) => [...currentFiles, ...mapAccepted, ...mapRejected]);
    setAcceptedFiles((currentFiles) => [...currentFiles, ...mapAccepted]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxSize: 5242880, //5MB
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/rtf': ['.rtf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/pdf': ['.pdf'],
    },
    noClick: true,
  });

  const setFileDetails = (files: UploadableFile[]) => {
    const body: IPresignedBody[] = [];
    const fileDetails: Array<IFileAttachment> = [];
    const filesToBeUploaded: Array<UploadableFile> = [];
    const date = new Date();

    files.map((file) => {
      const fileName = generateRandomNameWithExtension(file.file.name);

      body.push({ uploadFileName: fileName, contentType: file.file.type });

      fileDetails.push({
        id: file.id,
        name: fileName,
        displayName: file.file.name,
        path: '/aws/s3/pivotBudget',
        lastModified: date.toString(),
      });

      const updatedFile = new File([file.file], fileName, { type: file.file.type });
      filesToBeUploaded.push({ ...file, file: updatedFile });
    });

    return { body, fileDetails, filesToBeUploaded };
  };

  const handleUpload = async () => {
    const { body, fileDetails, filesToBeUploaded } = setFileDetails(acceptedFiles);

    if (selectedRowIndexes && formulaSheet && fileUrl) {
      try {
        setUploading(true);
        setUploadBtnText('Uploading...');
        await DataSheetApiService.loadPreSignedUrlForAttachmentUpload(
          body,
          filesToBeUploaded,
          fileUrl,
        );
        if (
          dataSheetCopy &&
          selectedRowIndexes !== undefined &&
          selectedRowIndexes.length > 0 &&
          tabName
        ) {
          const currentAddAttchment = handleAddAttachments[tabIndex as TabIndexes];
          if (!lodash.isNull(currentAddAttchment)) {
            currentAddAttchment(dataSheetCopy, selectedRowIndexes, formulaSheet, fileDetails);
          }
        }

        snackBar.success('Upload Successful');

        setDataSheet(dataSheetCopy);
      } catch (err) {
        //setError(true);
        snackBar.error('Sorry! something went wrong ');
      } finally {
        setUploadFileAttachments([]);
        setAcceptedFiles([]);
        setUploading(false);
        setUploadBtnText('Upload');
      }
    }
  };

  const findOnHowManyRowsAttachmentExists = (
    attachments: IFileAttachmentList,
    attachmentIds: string[],
  ): string[] => {
    const rowIds = Object.keys(attachments).filter((key) =>
      attachments[key].some((attachment) => attachmentIds.includes(attachment.id)),
    );
    return rowIds;
  };

  const handleDeleteAttachment = async (
    deleteAttachments: IHandleDeleteAttachment[],
    attachmentIds: string[],
  ) => {
    if (!dataSheet || !fileUrl) {
      return;
    }

    const rowIds = findOnHowManyRowsAttachmentExists(dataSheet.fileAttachments, attachmentIds);

    if (!selectedRowIndexes || !rowIds) {
      return;
    }

    if (selectedRowIndexes.length < rowIds.length) {
      const currentRemoveAttachments = handleRemoveAttachment[tabIndex as TabIndexes];
      if (currentRemoveAttachments) {
        currentRemoveAttachments(dataSheetCopy!, attachmentIds, selectedRowIndexes, formulaSheet!);
        setDataSheet(dataSheetCopy!);
      }
      return;
    }

    try {
      setDeleting(true);
      await DataSheetApiService.deleteAttachment(deleteAttachments, fileUrl);
      const currentRemoveAttachments = handleRemoveAttachment[tabIndex as TabIndexes];
      if (currentRemoveAttachments) {
        currentRemoveAttachments(dataSheetCopy!, attachmentIds, selectedRowIndexes, formulaSheet!);
        setDataSheet(dataSheetCopy!);
      }
      snackBar.success('Successfully deleted.');
    } catch (error) {
      snackBar.error('Deletion failed, an error occurred.');
    } finally {
      setDeleting(false);
    }
  };

  const handleGetAttachment = async (body: IHandleGetAttachment[]) => {
    if (!fileUrl) return;
    try {
      setGetting(true);
      const res = await DataSheetApiService.getAttachment(body, fileUrl);

      for (const attach of res.data) {
        window.open(attach.presignedUrl, '_blank');
      }
    } catch (error) {
      snackBar.error('Unable to retrieve the file. An error has occurred.');
    } finally {
      setGetting(false);
    }
  };

  const handleRemoveFileFromFilesToUpload = (index: number) => {
    setUploadFileAttachments((attach) => attach.filter((item, i) => i !== index));
    setAcceptedFiles((attach) => attach.filter((item, i) => i !== index));
  };

  useEffect(() => {
    setUploadFileAttachments([]);
    setAcceptedFiles([]);
  }, [tabIndex, selectedRowIndexes]);

  return (
    <StyledOpenAttachmentDialog>
      <DialogBox
        title="Attachments"
        dialogBoxContent={
          <StyledContentDiv>
            {uploadFileAttachments.length > 0 ? (
              <AttachmentStatus
                files={uploadFileAttachments}
                onClickClose={(index) => handleRemoveFileFromFilesToUpload(index)}
                btnDisabled={uploading}
              />
            ) : (
              <AttachmentFileViewer
                selectedRowIndex={selectedRowIndex}
                fileAttachments={attachments}
                selectedRowIndexes={selectedRowIndexes}
                deleteAttachment={handleDeleteAttachment}
                getAttachment={handleGetAttachment}
                deleting={deleting}
                getting={getting}
              />
            )}
          </StyledContentDiv>
        }
        isOpen={openAttachmentDialog}
        onClose={() => setOpen(false)}
        dialogBoxFooter={
          <StyledOpenAttachmentFooter>
            {uploadFileAttachments.length > 0 && (
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                disabled={acceptedFiles.length < 1 || uploading}
                fullWidth
                onClick={handleUpload}
              >
                {uploadBtnText}
              </Button>
            )}
            <FileUploadContainer {...getRootProps()}>
              <DragAndDropTextContainer>
                <DragAndDropText>Drag & Drop </DragAndDropText> <StyledDownloadIcon />
              </DragAndDropTextContainer>
              <input {...getInputProps()} />
              <Button variant="text" sx={{ flex: 1, maxHeight: 30 }} onClick={open}>
                Open File Dialog
              </Button>
            </FileUploadContainer>
          </StyledOpenAttachmentFooter>
        }
        customHeight={uploadFileAttachments.length > 0 ? '35vh' : '38vh'}
        customFooterHeight={uploadFileAttachments.length > 0 ? '17vh' : '13vh'}
      />
    </StyledOpenAttachmentDialog>
  );
}

export default OpenAttachmentDialog;
