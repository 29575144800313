/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SimpleCellCoords } from 'handsontable/common';
import lodash, { cloneDeep } from 'lodash';
import {
  pasteSpecialOptions as pasteSpecialOptionsConstant,
  SYSTEM_CURRENCY,
  SYSTEM_CURRENCY_LABEL,
} from '@/constants';
import {
  BudgetLevels,
  L1ColumnIndexes,
  L2ColumnIndexes,
  L3ColumnIndexes,
  RowTypes,
  SheetNames,
  VisibleL1ColumnIndexes,
  VisibleL2ColumnIndexes,
  VisibleL3ColumnIndexes,
} from '@/enums';
import { generatedId, getEnumKeyByValue } from '@/utils';
import { IDataSheet, IL1Data, IL2Data, IL3Data } from '@/interfaces/IDataSheet';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';
import { ICellChange, ICopiedData } from '@/interfaces/masterDataTypes';
import FormulaSheet from '@/sheets/FormulaSheet';

type UnionDataType = IL1Data | IL2Data | IL3Data;
type UnionRowType = string | number | null;

interface CellReference {
  rowIndex: number;
  colName: string;
}

interface IReferencesMap {
  [referenceValue: string]: Array<CellReference>;
}

export function getEmptyL1Data(): IL1Data {
  return {
    id: generatedId(),
    account: '',
    description: '',
    rowType: 'D',
  };
}

export function getL1GrandTotalRow(): IL1Data {
  return {
    id: generatedId(),
    account: '',
    description: 'Grand Total',
    rowType: 'G',
  };
}

export function getL1SystemSubTotalRow(): IL1Data {
  return {
    id: generatedId(),
    account: '',
    description: 'Sub-total',
    rowType: 'S',
  };
}

export function getL1SubFringesRow(): IL1Data {
  return {
    id: generatedId(),
    account: '',
    description: 'TOTAL FRINGES',
    rowType: 'FS',
  };
}

export function getEmptyL2Data(): IL2Data {
  return {
    id: generatedId(),
    account: '',
    description: '',
    rowType: 'D',
  };
}

export function getL2SystemTotalRow(): IL2Data {
  return {
    id: generatedId(),
    account: '',
    description: 'Total',
    rowType: 'T',
  };
}

export function getL2SystemTotalFringesRow(): IL2Data {
  return {
    id: generatedId(),
    account: '',
    description: 'Total Fringes',
    rowType: 'F',
  };
}

export function getL2SystemSubTotalRow(): IL2Data {
  return {
    id: generatedId(),
    account: '',
    description: 'Sub-total',
    rowType: 'S',
  };
}

export function getEmptyL3Data(dataSheet?: IDataSheet | null): IL3Data {
  let baseCurrency = '';

  if (dataSheet?.meta?.options.insertBaseCurrency) {
    baseCurrency = (dataSheet.currency.find((curr) => curr.default === 'Y')?.code as string) ?? '';
  }

  return {
    id: generatedId(),
    description: '',
    range: '',
    fringe: '',
    fringes: '',
    groups: '',
    loc: '',
    set: '',
    units: '',
    desc: '',
    x: '',
    rate: '',
    cu: baseCurrency,
    comparison: 0,
    rowType: 'D',
    fringeComparison: 0,
  };
}

export function getL3SystemTotalRow(): IL3Data {
  return {
    id: generatedId(),
    description: 'Total',
    range: '',
    fringe: '',
    fringes: '',
    groups: '',
    loc: '',
    set: '',
    units: '',
    desc: '',
    x: '',
    rate: '',
    cu: '',
    comparison: '',
    rowType: 'T',
    fringeComparison: '',
  };
}

export function getL3SystemSubTotalRow(): IL3Data {
  return {
    id: generatedId(),
    description: 'Sub-total',
    range: '',
    fringe: '',
    fringes: '',
    groups: '',
    loc: '',
    set: '',
    units: '',
    desc: '',
    x: '',
    rate: '',
    cu: '',
    comparison: '',
    rowType: 'S',
    fringeComparison: '',
  };
}

export function getL3SystemTotalFringesRow(): IL3Data {
  return {
    id: generatedId(),
    description: 'Total Fringes',
    range: '',
    fringe: '',
    fringes: '',
    groups: '',
    loc: '',
    set: '',
    units: '',
    desc: '',
    x: '',
    rate: '',
    cu: '',
    comparison: 0,
    rowType: 'F',
    fringeComparison: 0,
  };
}

export function addL1Row(
  dataSheetCopy: IDataSheet,
  index: number,
  newL1Row: IL1Data,
): { updatedDataSheet: IDataSheet; l1AddRowId: string } {
  if (dataSheetCopy) {
    const newL2Row: IL2Data = getEmptyL2Data();
    const newL2SystemTotalRow: IL2Data = getL2SystemTotalRow();
    const newL2SystemTotalFringesRow: IL2Data = getL2SystemTotalFringesRow();
    const newL3Row: IL3Data = getEmptyL3Data(dataSheetCopy);
    const newL3SystemTotalRow: IL3Data = getL3SystemTotalRow();
    const newL3SystemTotalFringesRow: IL3Data = getL3SystemTotalFringesRow();

    dataSheetCopy.l1.splice(index, 0, newL1Row);

    if (!dataSheetCopy.l2[newL1Row.id]) {
      dataSheetCopy.sheetNames.l2.push(newL1Row.id as string);
      dataSheetCopy.l2[newL1Row.id] = [];
    }

    dataSheetCopy.l2[newL1Row.id].push(newL2Row);
    dataSheetCopy.l2[newL1Row.id].push(newL2SystemTotalFringesRow);
    dataSheetCopy.l2[newL1Row.id].push(newL2SystemTotalRow);

    if (!dataSheetCopy.l3[newL2Row.id]) {
      dataSheetCopy.sheetNames.l3.push(newL2Row.id as string);
      dataSheetCopy.l3[newL2Row.id] = [];
    }
    dataSheetCopy.l3[newL2Row.id].push(newL3Row);
    dataSheetCopy.l3[newL2Row.id].push(newL3SystemTotalFringesRow);
    dataSheetCopy.l3[newL2Row.id].push(newL3SystemTotalRow);
  }
  return { updatedDataSheet: dataSheetCopy, l1AddRowId: newL1Row.id as string };
}

export function pasteL1RowWithChildren(
  dataSheetCopy: IDataSheet,
  selectedIndexes: Array<number>,
  copiedRow: ICopiedData,
): {
  updatedDataSheet: IDataSheet;
  pastedNewL1DataIds: string[];
  pastedNewL2DataIds: string[];
  pastedNewL1DataIndexes: number[];
} {
  const pastedNewL1DataIds: string[] = [];
  const pastedNewL2DataIds: string[] = [];
  const pastedNewL1DataIndexes: number[] = [];

  const fringeRowIndex = lodash.findIndex(dataSheetCopy.l1, { rowType: RowTypes.F });
  let pasteIndex = fringeRowIndex;
  if (selectedIndexes.length > 0 && selectedIndexes[0] <= fringeRowIndex - 1) {
    pasteIndex = selectedIndexes[0];
  }

  const copiedL1Data: IL1Data[] = [];
  let isNeedSkip = false;
  dataSheetCopy.l1.forEach((l1Data: IL1Data, index: number) => {
    const isNextSelectedSubtotalRow =
      l1Data.rowType === RowTypes.FS &&
      copiedL1Data[copiedL1Data.length - 1]?.rowType === RowTypes.S;
    const isSubtotalRowPrevious =
      pasteIndex !== 0 &&
      copiedL1Data.length === 0 &&
      dataSheetCopy.l1[pasteIndex - 1]?.rowType === RowTypes.S &&
      l1Data.rowType === RowTypes.S;
    const isFirstRowTypeFS =
      pasteIndex === 0 && copiedL1Data.length === 0 && l1Data.rowType === RowTypes.FS;
    const isCopyLastRowTypeFS =
      index === copiedRow.selectedRows[copiedRow.selectedRows.length - 2] &&
      l1Data.rowType === RowTypes.FS &&
      dataSheetCopy.l1[pasteIndex].rowType === RowTypes.FS;
    if (
      isNextSelectedSubtotalRow ||
      isCopyLastRowTypeFS ||
      isFirstRowTypeFS ||
      isSubtotalRowPrevious
    ) {
      isNeedSkip = true;
    }
    if (l1Data.rowType === RowTypes.D) {
      isNeedSkip = false;
    }
    if (
      copiedRow.selectedRows?.includes(index) &&
      enableMovableAndPasteForData(l1Data.rowType) &&
      !isNeedSkip
    ) {
      copiedL1Data.push(l1Data);
      isNeedSkip = false;
    }
  });

  copiedL1Data.forEach((l1Data: IL1Data, l1DataIndex: number) => {
    const l1NewDataId = generatedId();
    if (l1Data.rowType === RowTypes.D) {
      pastedNewL1DataIds.push(l1NewDataId);
    }
    pastedNewL1DataIndexes.push(pasteIndex);
    dataSheetCopy.l1.splice(pasteIndex, 0, {
      ...l1Data,
      id: l1NewDataId,
    });

    const copiedL1DataL2Data: IL2Data[] = cloneDeep(
      dataSheetCopy.l2[l1Data.id] ?? ([] as IL2Data[]),
    );

    copiedL1DataL2Data.forEach((l2Data: IL2Data, l2DataIndex: number) => {
      if (l2Data) {
        const l2NewDataId = generatedId();
        dataSheetCopy.l2[l1NewDataId] =
          l2DataIndex === 0
            ? [{ ...l2Data, id: l2NewDataId }]
            : [...dataSheetCopy.l2[l1NewDataId], { ...l2Data, id: l2NewDataId }];
        // L2 data's copied L3 data
        const copiedL2DataL3Data: IL3Data[] = dataSheetCopy.l3[l2Data.id] ?? ([] as IL3Data[]);
        copiedL2DataL3Data.forEach((l3Data: IL3Data, l3DataIndex: number) => {
          if (l3DataIndex === 0) {
            dataSheetCopy.l3[l2NewDataId] = [];
          }
          if (l3Data) {
            const l3NewDataId = generatedId();
            dataSheetCopy.l3[l2NewDataId] = [
              ...dataSheetCopy.l3[l2NewDataId],
              { ...l3Data, id: l3NewDataId, comparison: 0 },
            ];
          }
        });
        dataSheetCopy.sheetNames.l3.push(l2NewDataId);
        pastedNewL2DataIds.push(l2NewDataId);
      }
    });
    if (l1Data.rowType === RowTypes.D) {
      dataSheetCopy.sheetNames.l2.push(l1NewDataId);
    }
    pasteIndex++;
  });
  return {
    updatedDataSheet: dataSheetCopy,
    pastedNewL1DataIds,
    pastedNewL1DataIndexes,
    pastedNewL2DataIds,
  };
}

export function addL2Row(
  dataSheetCopy: IDataSheet,
  index: number,
  parentId: string,
  newL2Row: IL2Data,
): IDataSheet {
  if (dataSheetCopy) {
    const newL3Row: IL3Data = getEmptyL3Data(dataSheetCopy);
    const newL3SystemTotalRow: IL3Data = getL3SystemTotalRow();
    const newL3SystemTotalFringesRow: IL3Data = getL3SystemTotalFringesRow();

    if (!dataSheetCopy.l2[parentId]) {
      dataSheetCopy.sheetNames.l2.push(parentId);
      dataSheetCopy.l2[parentId] = [];
    }

    dataSheetCopy.l2[parentId].splice(index, 0, newL2Row);

    if (!dataSheetCopy.l3[newL2Row.id]) {
      dataSheetCopy.sheetNames.l3.push(newL2Row.id as string);
      dataSheetCopy.l3[newL2Row.id] = [];
    }

    dataSheetCopy.l3[newL2Row.id].push(newL3Row);
    dataSheetCopy.l3[newL2Row.id].push(newL3SystemTotalFringesRow);
    dataSheetCopy.l3[newL2Row.id].push(newL3SystemTotalRow);
  }
  return dataSheetCopy;
}

export function pasteL2RowWithChildren(
  dataSheetCopy: IDataSheet,
  selectedIndexes: Array<number>,
  selectedSheet: {
    l1SheetId: string;
    l2SheetId: string;
  },
  copiedRow: ICopiedData,
): { updatedDataSheet: IDataSheet; pastedL2Ids: string[]; pastedL2Indexes: number[] } {
  /* Checking if the copiedRow has a copiedSheetId. If it does, it will use that. If it doesn't, it
  will use the selectedSheet's l1SheetId. */

  const pastedL2Indexes: number[] = [];
  const pastedL2Ids: string[] = [];
  const copySourceId =
    copiedRow?.copiedSheetId !== '' ? copiedRow?.copiedSheetId : selectedSheet.l1SheetId;
  let selectedL2DataSheet: IL2Data[];
  if (copySourceId) {
    selectedL2DataSheet = dataSheetCopy.l2[copySourceId];
  } else {
    selectedL2DataSheet = dataSheetCopy.l2[selectedSheet.l1SheetId];
  }
  const pasteL2DataSheet = dataSheetCopy.l2[selectedSheet.l1SheetId];

  const fringeRowIndex = lodash.findIndex(pasteL2DataSheet, { rowType: RowTypes.F });
  let pasteIndex = fringeRowIndex;
  if (selectedIndexes.length > 0 && selectedIndexes[0] <= fringeRowIndex - 1) {
    pasteIndex = selectedIndexes[0];
  }

  const copiedL2Data: IL2Data[] = [];
  selectedL2DataSheet.forEach((l2Data: IL2Data, index: number) => {
    const isNextSelectedSubtotalRow =
      l2Data.rowType === RowTypes.S &&
      copiedL2Data[copiedL2Data.length - 1]?.rowType === RowTypes.S;
    const isSubtotalRowPrevious =
      pasteIndex !== 0 &&
      copiedL2Data.length === 0 &&
      pasteL2DataSheet[pasteIndex - 1]?.rowType === RowTypes.S &&
      l2Data.rowType === RowTypes.S;
    const isFirstRowSubtotal =
      pasteIndex === 0 && copiedL2Data.length === 0 && l2Data.rowType === RowTypes.S;
    const isLastRowTypeS =
      index === copiedRow.selectedRows[copiedRow.selectedRows.length - 1] &&
      l2Data.rowType === RowTypes.S &&
      pasteL2DataSheet[pasteIndex]?.rowType === RowTypes.S;
    if (
      copiedRow.selectedRows?.includes(index) &&
      enableMovableAndPasteForData(l2Data.rowType) &&
      !isNextSelectedSubtotalRow &&
      !isFirstRowSubtotal &&
      !isLastRowTypeS &&
      !isSubtotalRowPrevious
    ) {
      copiedL2Data.push(l2Data);
    }
  });

  copiedL2Data.forEach((l2Data: IL2Data, index: number) => {
    const l2NewDataId = generatedId();
    // Update existing L2 data with copied data.
    pasteL2DataSheet.splice(pasteIndex, 0, {
      ...l2Data,
      id: l2NewDataId,
    });
    pastedL2Indexes.push(pasteIndex);
    pastedL2Ids.push(l2NewDataId);
    // add L3 data sheet with copied data l2's l3 data sheet.
    const l3DataCopiedL2Data = dataSheetCopy.l3[l2Data.id] ?? [];
    l3DataCopiedL2Data.forEach((l3Data: IL3Data, index: number) => {
      const newL3Id = generatedId();
      if (index === 0) {
        dataSheetCopy.l3[l2NewDataId] = [];
      }
      dataSheetCopy.l3[l2NewDataId].push({
        ...l3Data,
        id: newL3Id,
        comparison: 0,
      });
    });
    dataSheetCopy.sheetNames.l3.push(l2NewDataId);
    pasteIndex++;
  });
  return { updatedDataSheet: dataSheetCopy, pastedL2Ids, pastedL2Indexes };
}

export function pasteL3Row(
  dataSheetCopy: IDataSheet,
  selectedIndexes: Array<number>,
  selectedSheet: {
    l1SheetId: string;
    l2SheetId: string;
  },
  copiedRow: ICopiedData,
  selectedValue: string[],
): {
  updatedDataSheet: IDataSheet;
  pasteIds: string[];
  pasteIndexes: number[];
  rangeId: string;
} {
  if (copiedRow.selectedRows.length === 0) {
    return { updatedDataSheet: dataSheetCopy, pasteIds: [], pasteIndexes: [], rangeId: '' };
  }
  const copySourceId =
    copiedRow?.copiedSheetId !== '' ? copiedRow?.copiedSheetId : selectedSheet.l2SheetId;
  let selectedL3DataSheet: IL3Data[];
  const pasteIndexes: number[] = [];
  const pasteIds: string[] = [];
  if (copySourceId) {
    selectedL3DataSheet = dataSheetCopy.l3[copySourceId];
  } else {
    selectedL3DataSheet = dataSheetCopy.l3[selectedSheet.l2SheetId];
  }
  const pasteL3DataSheet = dataSheetCopy.l3[selectedSheet.l2SheetId];

  const fringeRowIndex = lodash.findIndex(pasteL3DataSheet, { rowType: RowTypes.F });
  let pasteIndex = fringeRowIndex;
  if (selectedIndexes.length > 0 && selectedIndexes[0] <= fringeRowIndex - 1) {
    pasteIndex = selectedIndexes[0];
  }

  const pasteStartElement = pasteL3DataSheet[pasteIndex]; // Determine the paste start element

  let rangeId = '';
  if (pasteStartElement.range !== '') {
    const firstIndex = dataSheetCopy.l3[selectedSheet.l2SheetId].findIndex(
      (l3Data: IL3Data) => l3Data.range === pasteStartElement.range,
    );
    if (pasteIndex !== firstIndex) {
      rangeId = pasteStartElement.range;
    }
  }

  // Filter and copy only the selected rows indicated by cutData.selectedRows (if available)
  const copiedL3Data: IL3Data[] = [];

  selectedL3DataSheet.forEach((l3Data: IL3Data, index: number) => {
    const isNextSelectedSubtotalRow =
      l3Data.rowType === RowTypes.S &&
      copiedL3Data[copiedL3Data.length - 1]?.rowType === RowTypes.S;
    const isFirstRowSubtotal =
      pasteIndex === 0 && copiedL3Data.length === 0 && l3Data.rowType === RowTypes.S;
    const isSubtotalRowPrevious =
      pasteIndex !== 0 &&
      copiedL3Data.length === 0 &&
      pasteL3DataSheet[pasteIndex - 1]?.rowType === RowTypes.S &&
      l3Data.rowType === RowTypes.S;
    const isLastRowTypeS =
      index === copiedRow.selectedRows[copiedRow.selectedRows.length - 1] &&
      l3Data.rowType === RowTypes.S &&
      pasteL3DataSheet[pasteIndex]?.rowType === RowTypes.S;
    if (
      copiedRow.selectedRows?.includes(index) &&
      enableMovableAndPasteForData(l3Data.rowType) &&
      !isNextSelectedSubtotalRow &&
      !isFirstRowSubtotal &&
      !isLastRowTypeS &&
      !isSubtotalRowPrevious
    ) {
      copiedL3Data.push({ ...l3Data, range: rangeId });
    }
  });

  copiedL3Data.forEach((l3Data: IL3Data, copiedIndex: number) => {
    const newId = generatedId();
    const pasteL3Data: IL3Data =
      selectedValue.length > 0 && l3Data.rowType === RowTypes.D
        ? {
            ...getPasteSpecialL3Data(dataSheetCopy, pasteStartElement, l3Data, selectedValue),
            range: l3Data.range,
            id: newId,
            comparison: 0,
            rowType: l3Data.rowType,
          }
        : { ...l3Data, id: newId, comparison: 0 };
    pasteL3DataSheet.splice(pasteIndex, 0, pasteL3Data);
    pasteIndexes.push(pasteIndex);
    pasteIds.push(newId);

    pasteIndex++;
  });
  return { updatedDataSheet: dataSheetCopy, pasteIds, pasteIndexes, rangeId };
}

export const pasteL1Cells = (
  dataSheet: IDataSheet,
  copiedCellData: (string | number)[][],
  selectedCell: SimpleCellCoords,
): { updatedDataSheet: IDataSheet; changeValues: ICellChange[]; updatedIds: string[] } => {
  const dataSheetClone = lodash.cloneDeep(dataSheet);

  const selectedL1Sheet = dataSheetClone.l1;
  const changeValues = [] as ICellChange[];
  const updatedIds: string[] = [];

  let fringeTotalIndex = 0;

  for (let rowIndex = 0; rowIndex < copiedCellData.length; rowIndex++) {
    const copiedRow = copiedCellData[rowIndex];
    for (let columnIndex = 0; columnIndex < copiedRow.length; columnIndex++) {
      const copiedCell = copiedRow[columnIndex];
      // select the Cell
      let rowPointer = selectedCell.row + rowIndex + fringeTotalIndex;
      let colPointer = selectedCell.col + columnIndex;
      // Determine the current column type
      const currentCol: keyof IL1Data = VisibleL1ColumnIndexes[colPointer] as keyof IL1Data;
      colPointer = L1ColumnIndexes[currentCol as keyof typeof L1ColumnIndexes] as number;
      // Determine the  row type
      let rowType = selectedL1Sheet[rowPointer]?.rowType;
      if (rowType === RowTypes.T) {
        return { updatedDataSheet: dataSheetClone, changeValues, updatedIds };
      }
      if (rowType === RowTypes.FS && dataSheetClone.meta.fringes.calc !== 'production') {
        fringeTotalIndex += 1;
        rowPointer += 1;
        rowType = selectedL1Sheet[rowPointer]?.rowType;
      }

      const shouldPasteCellFringeSubtotalRow =
        rowType === RowTypes.FS &&
        dataSheetClone.meta.fringes.calc === 'production' &&
        isEditableL1FringeTotalColumn(currentCol);

      const shouldPasteCellFringeTotalRow =
        rowType === RowTypes.F &&
        dataSheetClone.meta.fringes.calc === 'budget' &&
        isEditableL1FringeTotalColumn(currentCol);

      const shouldPasteCellRows =
        (rowType === RowTypes.D || rowType === RowTypes.S || rowType === RowTypes.G) &&
        isEditableL1Column(currentCol);
      // Update Change values
      if (
        shouldPasteCellFringeSubtotalRow ||
        shouldPasteCellFringeTotalRow ||
        shouldPasteCellRows
      ) {
        changeValues.push({
          row: rowPointer,
          col: colPointer,
          value: copiedCell,
          oldValue: selectedL1Sheet[rowPointer][currentCol],
        });
        updatedIds.push(selectedL1Sheet[rowPointer].id.toString());
        selectedL1Sheet[rowPointer][currentCol] = copiedCell as string;
      }
    }
  }
  return { updatedDataSheet: dataSheetClone, changeValues, updatedIds };
};

export const pasteL2Cells = (
  dataSheet: IDataSheet,
  copiedCellData: (string | number)[][],
  selectedCell: SimpleCellCoords,
  selectedSheet: ISelectedSheet,
): { updatedDataSheet: IDataSheet; changeValues: ICellChange[]; updatedIds: string[] } => {
  const dataSheetClone = lodash.cloneDeep(dataSheet);

  const selectedL2Sheet = dataSheetClone.l2[selectedSheet.l1SheetId];
  const changeValues = [] as ICellChange[];
  const updatedIds: string[] = [];

  for (let rowIndex = 0; rowIndex < copiedCellData.length; rowIndex++) {
    const copiedRow = copiedCellData[rowIndex];
    for (let columnIndex = 0; columnIndex < copiedRow.length; columnIndex++) {
      const copiedCell = copiedRow[columnIndex];
      // select the Cell
      const rowPointer = selectedCell.row + rowIndex;
      let colPointer = selectedCell.col + columnIndex;
      // Determine the current column type
      const currentCol: keyof IL2Data = VisibleL2ColumnIndexes[colPointer] as keyof IL2Data;
      colPointer = L2ColumnIndexes[currentCol as keyof typeof L2ColumnIndexes] as number;
      // Determine the row type
      const rowType = selectedL2Sheet[rowPointer]?.rowType;
      if (rowType === RowTypes.T) {
        return { updatedDataSheet: dataSheetClone, changeValues, updatedIds };
      }

      // Update Change values
      const shouldPasteCellFringeTotalRow =
        rowType === RowTypes.F &&
        dataSheetClone.meta.fringes.calc === 'category' &&
        isEditableL2FringeTotalColumn(currentCol);
      const shouldPasteCellRows =
        (rowType === RowTypes.D || rowType === RowTypes.S) && isEditableL2Column(currentCol);

      if (shouldPasteCellFringeTotalRow || shouldPasteCellRows) {
        changeValues.push({
          row: rowPointer,
          col: colPointer,
          value: copiedCell,
          oldValue: selectedL2Sheet[rowPointer][currentCol],
        });
        updatedIds.push(selectedL2Sheet[rowPointer].id.toString());
        selectedL2Sheet[rowPointer][currentCol] = copiedCell as string;
      }
      // Update the Sheet
    }
  }
  return { updatedDataSheet: dataSheetClone, changeValues, updatedIds };
};

export const pasteL3Cells = (
  dataSheet: IDataSheet,
  copiedCellData: (string | number)[][],
  selectedCells: SimpleCellCoords[],
  selectedSheet: ISelectedSheet,
): {
  updatedDataSheet: IDataSheet;
  changeValues: ICellChange[];
  isNeedTransform: boolean;
  updatedIds: string[];
} => {
  const dataSheetClone = lodash.cloneDeep(dataSheet);

  const selectedL3Sheet = dataSheetClone.l3[selectedSheet.l2SheetId];
  const changeValues = [] as ICellChange[];
  const updatedIds: string[] = [];

  let isNeedTransform = false;

  (selectedCells ?? []).forEach((selectedCell) => {
    for (let rowIndex = 0; rowIndex < copiedCellData.length; rowIndex++) {
      const copiedRow = copiedCellData[rowIndex];

      for (let columnIndex = 0; columnIndex < copiedRow.length; columnIndex++) {
        const copiedCell = copiedRow[columnIndex];

        // select the Cell
        const rowPointer = selectedCell.row + rowIndex;
        let colPointer = selectedCell.col + columnIndex;

        // Determine the current column type
        const currentCol: keyof IL3Data = VisibleL3ColumnIndexes[colPointer] as keyof IL3Data;
        colPointer = L3ColumnIndexes[currentCol as keyof typeof L3ColumnIndexes] as number;
        const rowType = selectedL3Sheet[rowPointer]?.rowType;

        // Determine the  row type
        if (rowType === RowTypes.T) {
          return { updatedDataSheet: dataSheetClone, changeValues, isNeedTransform, updatedIds };
        }
        // Update Change values
        let canPasteCell = false;
        if (
          rowType === RowTypes.F &&
          dataSheetClone.meta.fringes.calc === 'account' &&
          isEditableL3FringeTotalColumn(currentCol) &&
          isPossibleToUpdateValueInL3Column(dataSheetClone, currentCol, copiedCell)
        ) {
          canPasteCell = true;
          isNeedTransform = true;
        }
        if (
          rowType === RowTypes.D &&
          isEditableL3Column(currentCol) &&
          isPossibleToUpdateValueInL3Column(dataSheetClone, currentCol, copiedCell)
        ) {
          // remove description column
          if (getEditableL3Columns().slice(1)?.includes(currentCol)) {
            isNeedTransform = true;
          }
          canPasteCell = true;
        } else if (rowType === RowTypes.S && currentCol === getEditableL3Columns()[0]) {
          canPasteCell = true;
        }

        if (canPasteCell) {
          changeValues.push({
            row: rowPointer,
            col: colPointer,
            value: copiedCell,
            oldValue: selectedL3Sheet[rowPointer][currentCol],
          });
          updatedIds.push(selectedL3Sheet[rowPointer].id.toString());
          selectedL3Sheet[rowPointer][currentCol] = copiedCell as string;
        }
      }
    }
  });

  return { updatedDataSheet: dataSheetClone, changeValues, isNeedTransform, updatedIds };
};

export const getPasteSpecialL3Data = (
  dataSheetCopy: IDataSheet,
  pasteStartElement: IL3Data,
  l3Data: IL3Data,
  pasteSpecialOptions: string[],
): IL3Data => {
  let copyElement = lodash.cloneDeep(pasteStartElement);
  if (!pasteSpecialOptions.includes('paste-special')) return { ...l3Data };
  const pasteL3Data = { ...l3Data };
  const selectedSpecialOptions = pasteSpecialOptionsConstant
    .map((options) => options.value)
    .filter((option) => pasteSpecialOptions.includes(option));
  if (copyElement.rowType !== RowTypes.D) {
    copyElement = getEmptyL3Data(dataSheetCopy);
  }
  Object.keys(pasteL3Data).forEach(function (key: any) {
    if (!selectedSpecialOptions.includes(key)) {
      pasteL3Data[key as keyof IL3Data] = copyElement[key as keyof IL3Data] as string;
    }
  });
  return { ...pasteL3Data };
};

export function buildL3ReferencesMap(
  dataSheet: IDataSheet,
  parentId: keyof IDataSheet['l3'],
): IReferencesMap {
  const referencesMap: IReferencesMap = {};

  const fs = FormulaSheet.getInstance();
  const namedExpressions = fs.getAllNamedExpression();

  const addReference = (referenceValue: string, cellReference: CellReference) => {
    if (!referencesMap[referenceValue]) {
      referencesMap[referenceValue] = [];
    }
    referencesMap[referenceValue].push(cellReference);
  };

  const referenceRegex = /=([$]?[A-Qa-q][$]?\d+)/g;
  const editableL3Columns: (keyof IL3Data)[] = ['description', 'units', 'desc', 'x', 'rate'];

  for (const [rowIndex, rowData] of dataSheet.l3[parentId].entries()) {
    for (const columnName of editableL3Columns) {
      const fieldValue = rowData[columnName];

      if (typeof fieldValue !== 'string') continue;

      let match: RegExpExecArray | null;
      while ((match = referenceRegex.exec(fieldValue)) !== null) {
        const rawFieldValue = match.input.length > 0 ? match.input.substring(1) : match.input;
        if (
          namedExpressions.length > 0 &&
          namedExpressions.some((expression) => rawFieldValue.includes(expression))
        )
          continue;
        addReference(match[0], {
          rowIndex: rowIndex,
          colName: columnName as string,
        });
      }

      referenceRegex.lastIndex = 0;
    }
  }

  return referencesMap;
}

function splitCellReference(cellReference: string): [string, number] {
  const cleanedReference = cellReference.replace(/^=|\$/g, '');

  const match = cleanedReference.match(/^([A-Za-z]+)(\d+)$/);
  if (!match) {
    throw new Error(`Invalid cell reference: ${cellReference}`);
  }

  const [, column, row] = match;
  return [column, parseInt(row, 10)];
}

export function addL3Row(
  dataSheetCopy: IDataSheet,
  newRowIndex: number,
  parentSheetId: string,
  newL3RowData: IL3Data,
): IDataSheet {
  let updatedDataSheetCopy: IDataSheet = { ...dataSheetCopy };

  if (!updatedDataSheetCopy.l3[parentSheetId]) {
    updatedDataSheetCopy.sheetNames.l3.push(parentSheetId);
    updatedDataSheetCopy.l3[parentSheetId] = [];
  }

  const referencesMap = buildL3ReferencesMap(dataSheetCopy, parentSheetId);
  updatedDataSheetCopy = updateL3References(
    referencesMap,
    updatedDataSheetCopy,
    parentSheetId,
    newRowIndex,
    'insert',
  );

  updatedDataSheetCopy.l3[parentSheetId].splice(newRowIndex, 0, newL3RowData);

  return updatedDataSheetCopy;
}

function updateL3References(
  referencesMap: IReferencesMap,
  dataSheet: IDataSheet,
  parentSheetId: string,
  operationIndex: number | number[],
  operation: 'insert' | 'delete' = 'insert',
) {
  if (Object.keys(referencesMap).length === 0) return dataSheet;

  const isInsertOperation = operation === 'insert';
  const indexes = Array.isArray(operationIndex)
    ? operationIndex.map((idx) => (idx += 1))
    : [operationIndex];

  for (const referenceValue of Object.keys(referencesMap)) {
    const referenceCoords = referencesMap[referenceValue];
    const [, referenceRow] = splitCellReference(referenceValue);
    for (const coords of referenceCoords) {
      const fieldValue = dataSheet.l3[parentSheetId][coords.rowIndex][
        coords.colName as keyof IL3Data
      ] as string;

      if (!isInsertOperation && indexes.includes(referenceRow)) {
        dataSheet.l3[parentSheetId][coords.rowIndex][coords.colName as keyof IL3Data] = '#REF!';
        continue;
      }

      const referenceRegex = /([$]?[A-Za-z]+[$]?\d+)/g;
      const updatedFieldValue = fieldValue.replace(referenceRegex, (match) => {
        const [matchCol, matchRow] = splitCellReference(match);

        if (matchRow - 1 >= indexes[0]) {
          const adjustment = isInsertOperation ? indexes.length : -indexes.length;
          const newReferenceRow = matchRow + adjustment;
          return `${matchCol}${newReferenceRow}`;
        }

        return match;
      });

      dataSheet.l3[parentSheetId][coords.rowIndex][coords.colName as keyof IL3Data] =
        updatedFieldValue;
    }
  }

  return dataSheet;
}

export function validatedRowId(rowId: string | number): string {
  if (lodash.isNumber(rowId)) return rowId.toString();
  return rowId;
}

export function editL1Row(dataSheetCopy: IDataSheet, editL1Row: IL1Data): IDataSheet {
  if (dataSheetCopy) {
    dataSheetCopy.l1 = dataSheetCopy.l1.map((l1Data: IL1Data) => {
      const editedRowId = validatedRowId(editL1Row.id);
      const currentDataSheetRowId = validatedRowId(l1Data.id);

      if (lodash.isEqual(editedRowId, currentDataSheetRowId)) {
        return { ...l1Data, ...editL1Row };
      }
      return l1Data;
    });
  }
  return dataSheetCopy;
}

export function editL2Row(
  dataSheetCopy: IDataSheet,
  parentId: string,
  editL2Row: IL2Data,
): IDataSheet {
  if (dataSheetCopy) {
    dataSheetCopy.l2[parentId] = dataSheetCopy.l2[parentId].map((l2Data: IL2Data) => {
      const editedRowId = validatedRowId(editL2Row.id);
      const currentDataSheetRowId = validatedRowId(l2Data.id);

      if (lodash.isEqual(editedRowId, currentDataSheetRowId)) {
        return { ...l2Data, ...editL2Row };
      }
      return l2Data;
    });
  }

  return dataSheetCopy;
}

export function editL3Row(
  dataSheetCopy: IDataSheet,
  parentId: string,
  editL3Row: IL3Data,
): IDataSheet {
  if (dataSheetCopy) {
    dataSheetCopy.l3[parentId] = dataSheetCopy.l3[parentId].map((l3Data: IL3Data) => {
      const editedRowId = validatedRowId(editL3Row.id);
      const currentDataSheetRowId = validatedRowId(l3Data.id);

      //DO NOT MODIFY COMPARISON.
      const comparison = l3Data.comparison;

      if (lodash.isEqual(editedRowId, currentDataSheetRowId)) {
        return { ...l3Data, ...editL3Row, comparison: comparison };
      }
      return l3Data;
    });
  }
  return dataSheetCopy;
}

export function removeL1Row(
  dataSheetCopy: IDataSheet,
  removeIndexes: number[],
): {
  updatedDataSheet: IDataSheet;
  removeL1Indexes: number[];
  removedL1Ids: string[];
  removedL2Ids: string[];
  removedL3Ids: string[];
  isDeleteAllDRows: boolean;
} {
  let removeL1Indexes: number[] = [];
  let removedL1Ids: string[] = [];
  let isDeleteAllDRows = false;
  const removedL2Ids: string[] = [];
  const removedL3Ids: string[] = [];
  if (dataSheetCopy) {
    // is remove all data rows
    isDeleteAllDRows = isRemoveAllRows(dataSheetCopy, BudgetLevels.FIRST_LEVEL, removeIndexes);
    if (isDeleteAllDRows) {
      removeIndexes = removeIndexes.filter((removeIndex) => removeIndex !== 0);
      const subTotalIndex = lodash.findLastIndex(dataSheetCopy.l1, {
        rowType: RowTypes.S,
      });
      if (subTotalIndex === dataSheetCopy.l1.length - 3) {
        removeIndexes.push(subTotalIndex);
        removeIndexes.push(subTotalIndex - 1);
      }
    }

    removeIndexes.forEach((removeIndex: number) => {
      const rowData = dataSheetCopy.l1[removeIndex];

      if (enableDeleteActionForData(rowData?.rowType)) {
        const currentRowType = rowData?.rowType;
        const currentRowId = rowData?.id as string;

        if (
          currentRowType === RowTypes.FS &&
          dataSheetCopy.l1[removeIndex + 1]?.rowType === RowTypes.S
        ) {
          removedL1Ids.push(currentRowId, dataSheetCopy.l1[removeIndex + 1]?.id as string);
          removeL1Indexes.push(removeIndex, removeIndex + 1);
        } else if (
          currentRowType === RowTypes.S &&
          dataSheetCopy.l1[removeIndex - 1]?.rowType === RowTypes.FS
        ) {
          removedL1Ids.push(dataSheetCopy.l1[removeIndex - 1]?.id as string, currentRowId);
          removeL1Indexes.push(removeIndex - 1, removeIndex);
        } else {
          removedL1Ids.push(currentRowId);
          removeL1Indexes.push(removeIndex);
        }
      }
    });

    const { removedRowIds, removedRowIndexes } = removeRowIdsAndIndexes(
      dataSheetCopy.l1,
      removeL1Indexes,
    );

    removedL1Ids = removedRowIds;
    removeL1Indexes = removedRowIndexes;

    dataSheetCopy.l1 = dataSheetCopy.l1.filter(
      (l1Data: IL1Data) => !removedL1Ids.includes(l1Data.id as string),
    );

    dataSheetCopy.sheetNames.l2 = dataSheetCopy.sheetNames.l2.filter(
      (id) => !removedL1Ids.includes(id),
    );
    removedL1Ids.forEach((removedL1Id) => {
      dataSheetCopy.l2[removedL1Id]?.forEach((l2Data: IL2Data) => {
        removedL2Ids.push(l2Data.id as string);
      });
      delete dataSheetCopy.l2[removedL1Id];
    });

    if (dataSheetCopy.l1.length === 2) {
      const newL1Row = getEmptyL1Data();
      const { updatedDataSheet: newDataSheetCopy } = addL1Row(dataSheetCopy, 0, newL1Row);
      dataSheetCopy = newDataSheetCopy;
    }
    if (isDeleteAllDRows) {
      dataSheetCopy.l1[0] = {
        ...getEmptyL1Data(),
        id: dataSheetCopy.l1[0].id,
      };
      const l1DataId = dataSheetCopy.l1[0].id;
      dataSheetCopy.l2[l1DataId][0] = {
        ...getEmptyL2Data(),
        id: dataSheetCopy.l2[l1DataId][0].id,
      };

      dataSheetCopy.l2[l1DataId] = dataSheetCopy.l2[l1DataId].filter(
        (l2Data: IL2Data, index: number) => {
          if (index !== 0 && enableDeleteActionForData(l2Data.rowType)) {
            removedL2Ids.push(l2Data.id as string);
            return false;
          }
          return true;
        },
      );

      const l2DataId = dataSheetCopy.l2[l1DataId][0].id;

      dataSheetCopy.l3[l2DataId][0] = {
        ...getEmptyL3Data(dataSheetCopy),
        id: dataSheetCopy.l3[l2DataId][0].id,
      };
      dataSheetCopy.l3[l2DataId] = dataSheetCopy.l3[l2DataId].filter(
        (l3Data: IL3Data, index: number) => {
          if (index !== 0 && enableDeleteActionForData(l3Data.rowType)) {
            removedL3Ids.push(l3Data.id as string);
            return false;
          }
          return true;
        },
      );
    }

    removedL2Ids?.forEach((removedL2Id) => {
      const l3DataIds =
        dataSheetCopy.l3[removedL2Id]?.map((l3Data: IL3Data) => {
          return l3Data.id as string;
        }) ?? [];
      removedL3Ids.push(...l3DataIds);
      delete dataSheetCopy.l3[removedL2Id];
    });

    dataSheetCopy.sheetNames.l3 = dataSheetCopy.sheetNames.l3.filter(
      (id) => !removedL2Ids.includes(id),
    );
  }
  return {
    updatedDataSheet: dataSheetCopy,
    removeL1Indexes,
    removedL1Ids,
    removedL2Ids,
    removedL3Ids,
    isDeleteAllDRows,
  };
}

export function removeL2Row(
  dataSheetCopy: IDataSheet,
  parentId: string,
  removeIndexes: number[],
  isRemoveAll?: boolean,
): {
  updatedDataSheet: IDataSheet;
  removedL2Indexes: number[];
  removedL2Ids: string[];
  removedL3Ids: string[];
  isDeleteAllDRows: boolean;
} {
  let removedL2Indexes: number[] = [];
  let removedL2Ids: string[] = [];
  const removedL3Ids: string[] = [];
  let isDeleteAllDRows = false;
  if (dataSheetCopy) {
    // is remove all data rows
    isDeleteAllDRows = isRemoveAllRows(dataSheetCopy, BudgetLevels.SECOND_LEVEL, removeIndexes, {
      l1SheetId: parentId,
      l2SheetId: '',
    });
    if (isDeleteAllDRows) {
      removeIndexes = removeIndexes.filter((removeIndex) => removeIndex !== 0);
      const subTotalIndex = lodash.findLastIndex(dataSheetCopy.l2[parentId], {
        rowType: RowTypes.S,
      });
      if (subTotalIndex === dataSheetCopy.l2[parentId].length - 3) {
        removeIndexes.push(subTotalIndex);
      }
    }

    removeIndexes.forEach((removedL2Index) => {
      if (enableDeleteActionForData(dataSheetCopy.l2[parentId][removedL2Index].rowType)) {
        removedL2Ids.push(dataSheetCopy.l2[parentId][removedL2Index]?.id as string);
        removedL2Indexes.push(removedL2Index);
      }
    });

    if (isRemoveAll && dataSheetCopy.l2[parentId]?.length >= 0) {
      removedL2Ids = dataSheetCopy.l2[parentId]?.map((l2Data: IL2Data) => {
        return l2Data.id as string;
      });
    }

    const { removedRowIds, removedRowIndexes } = removeRowIdsAndIndexes(
      dataSheetCopy.l2[parentId],
      removeIndexes,
    );
    removedL2Ids = removedRowIds;
    removedL2Indexes = removedRowIndexes;

    dataSheetCopy.l2[parentId] = dataSheetCopy.l2[parentId]?.filter(
      (l2Data: IL2Data) => !removedL2Ids.includes(l2Data.id as string),
    );

    dataSheetCopy.sheetNames.l3 = dataSheetCopy.sheetNames.l3.filter(
      (id) => !removedL2Ids.includes(id),
    );

    if (isDeleteAllDRows) {
      dataSheetCopy.l2[parentId][0] = {
        ...getEmptyL2Data(),
        id: dataSheetCopy.l2[parentId][0].id,
      };
      const l2DataId = dataSheetCopy.l2[parentId][0].id;

      dataSheetCopy.l3[l2DataId][0] = {
        ...getEmptyL3Data(dataSheetCopy),
        id: dataSheetCopy.l3[l2DataId][0].id,
      };
      dataSheetCopy.l3[l2DataId] = dataSheetCopy.l3[l2DataId].filter(
        (l3Data: IL3Data, index: number) => {
          if (index !== 0 && enableDeleteActionForData(l3Data.rowType)) {
            removedL3Ids.push(l3Data.id as string);
            return false;
          }
          return true;
        },
      );
    }

    removedL2Ids.forEach((removedL2Id) => {
      const l3DataIds =
        dataSheetCopy.l3[removedL2Id]?.map((l3Data: IL3Data) => {
          return l3Data.id as string;
        }) ?? [];
      removedL3Ids.push(...l3DataIds);
      delete dataSheetCopy.l3[removedL2Id];
    });
  }

  if (isRemoveAll) {
    delete dataSheetCopy.l2[parentId];
  }
  return {
    updatedDataSheet: dataSheetCopy,
    removedL2Indexes,
    removedL2Ids,
    removedL3Ids,
    isDeleteAllDRows,
  };
}

export function removeL3Row(
  dataSheetCopy: IDataSheet,
  parentId: string,
  removeIndexes: number[],
): {
  updatedDataSheet: IDataSheet;
  removedL3Indexes: number[];
  removedL3Ids: string[];
  isDeleteAllDRows: boolean;
} {
  let removedL3Ids: string[] = [];
  let removedL3Indexes: number[] = [];
  let isDeleteAllDRows = false;
  const removedFringeRangeId: (string | number)[] = [];
  if (dataSheetCopy) {
    isDeleteAllDRows = isRemoveAllRows(dataSheetCopy, BudgetLevels.THIRD_LEVEL, removeIndexes, {
      l1SheetId: '',
      l2SheetId: parentId,
    });
    if (isDeleteAllDRows) {
      removeIndexes = removeIndexes.filter((removeIndex) => removeIndex !== 0);
      const subTotalIndex = lodash.findLastIndex(dataSheetCopy.l3[parentId], {
        rowType: RowTypes.S,
      });
      if (subTotalIndex === dataSheetCopy.l3[parentId].length - 3) {
        removeIndexes.push(subTotalIndex);
      }
    }
    removeIndexes.forEach((index) => {
      if (enableDeleteActionForData(dataSheetCopy.l3[parentId][index]?.rowType)) {
        removedL3Ids.push(dataSheetCopy.l3[parentId][index]?.id as string);
        if (
          dataSheetCopy.l3[parentId][index]?.range &&
          (!dataSheetCopy.l3[parentId][index - 1] ||
            dataSheetCopy.l3[parentId][index - 1]?.range === '')
        ) {
          removedFringeRangeId.push(dataSheetCopy.l3[parentId][index]?.range);
        }
      }
    });

    const referencesMap = buildL3ReferencesMap(dataSheetCopy, parentId);
    dataSheetCopy = updateL3References(
      referencesMap,
      dataSheetCopy,
      parentId,
      removeIndexes,
      'delete',
    );
    const { removedRowIds, removedRowIndexes } = removeRowIdsAndIndexes(
      dataSheetCopy.l3[parentId],
      removeIndexes,
    );

    dataSheetCopy.l3[parentId] = dataSheetCopy.l3[parentId].reduce((result: IL3Data[], l3Data) => {
      if (!removedRowIds.includes(l3Data.id as string)) {
        if (removedFringeRangeId.includes(l3Data.range)) {
          result.push({
            ...l3Data,
            range: '',
          });
        } else {
          result.push({ ...l3Data });
        }
      }
      return result;
    }, []);

    if (isDeleteAllDRows) {
      dataSheetCopy.l3[parentId][0] = {
        ...getEmptyL3Data(dataSheetCopy),
        id: dataSheetCopy.l3[parentId][0].id,
      };
    }
    removedL3Indexes = removedRowIndexes;
    removedL3Ids = removedRowIds;
  }
  return { updatedDataSheet: dataSheetCopy, removedL3Indexes, removedL3Ids, isDeleteAllDRows };
}

export function cutPasteL1Row(
  dataSheetCopy: IDataSheet,
  cutData: ICopiedData,
  pasteIndex: number,
): { updatedDataSheet: IDataSheet; pasteLevel1Ids: string[] } {
  const pasteLevel1Ids: string[] = [];
  const updateL1DataSheet = dataSheetCopy.l1;
  const finalMovedLevel1RowData = updateL1DataSheet[pasteIndex];
  const cutRowIndexes = cutData.selectedRows;
  let destinationRowType = dataSheetCopy.l1[pasteIndex]?.rowType;

  const cutL1DataIds: string[] = [];

  cutRowIndexes.forEach((rowIndex) => {
    if (enableMovableAndPasteForData(updateL1DataSheet[rowIndex].rowType)) {
      cutL1DataIds.push(updateL1DataSheet[rowIndex].id as string);
    }
  });

  cutL1DataIds.forEach((cutL1DataId, index) => {
    const cutL1DataIndex = updateL1DataSheet.findIndex((l1Data) => l1Data.id === cutL1DataId);
    const cutL1Data = updateL1DataSheet.splice(cutL1DataIndex, 1)[0];
    const previousCutL1Data = updateL1DataSheet[cutL1DataIndex - 1];
    const destinationL1DataSheetIndex = updateL1DataSheet.findIndex(
      (l1Data) => l1Data.id === finalMovedLevel1RowData.id,
    );
    destinationRowType = dataSheetCopy.l1[destinationL1DataSheetIndex]?.rowType;
    const destinationPreviousRowType = dataSheetCopy.l1[destinationL1DataSheetIndex - 1]?.rowType;
    let isPossibleToPaste = false;
    const canPasteRow = cutL1Data.rowType === RowTypes.D || cutL1Data.rowType === RowTypes.G;
    const isSubtotalNextNotFSRow =
      cutL1Data.rowType === RowTypes.FS &&
      destinationRowType !== RowTypes.S &&
      destinationL1DataSheetIndex > 0;
    const isFringeTotalRowNextSubtotalRow =
      cutL1Data.rowType === RowTypes.S &&
      destinationL1DataSheetIndex > 0 &&
      destinationPreviousRowType === RowTypes.FS;
    const isSystemFringeTotalRowPreviousDRow =
      destinationRowType === RowTypes.F &&
      destinationPreviousRowType === RowTypes.S &&
      (cutL1Data.rowType === RowTypes.D || cutL1Data.rowType === RowTypes.G);
    if (
      canPasteRow ||
      isSubtotalNextNotFSRow ||
      isFringeTotalRowNextSubtotalRow ||
      isSystemFringeTotalRowPreviousDRow
    ) {
      isPossibleToPaste = true;
    }

    if (isPossibleToPaste) {
      updateL1DataSheet.splice(destinationL1DataSheetIndex, 0, cutL1Data);
      pasteLevel1Ids.push(cutL1Data.id as string);
    }

    const isPasteAbove = destinationL1DataSheetIndex < cutL1DataIndex;

    if (
      isPasteAbove &&
      previousCutL1Data?.rowType === RowTypes.S &&
      !cutL1DataIds.includes(updateL1DataSheet[cutL1DataIndex + 1].id as string) &&
      updateL1DataSheet[cutL1DataIndex + 1]?.rowType === RowTypes.FS
    ) {
      updateL1DataSheet.splice(cutL1DataIndex + 1, 2);
    } else if (
      previousCutL1Data?.rowType === RowTypes.S &&
      !cutL1DataIds.includes(updateL1DataSheet[cutL1DataIndex].id as string) &&
      updateL1DataSheet[cutL1DataIndex]?.rowType === RowTypes.FS
    ) {
      updateL1DataSheet.splice(cutL1DataIndex, 2);
    }
    if (cutL1DataIndex === 0 && updateL1DataSheet[0]?.rowType === RowTypes.FS) {
      updateL1DataSheet.splice(0, 2);
    }
  });

  const firstCutL1DataRow = updateL1DataSheet.findIndex((l1) => l1.id === pasteLevel1Ids[0]);
  if (
    updateL1DataSheet[firstCutL1DataRow]?.rowType === RowTypes.FS &&
    updateL1DataSheet[firstCutL1DataRow - 1]?.rowType === RowTypes.S
  ) {
    updateL1DataSheet.splice(firstCutL1DataRow, 2);
  }
  const lastCutL1DataRow = updateL1DataSheet.findIndex(
    (l1) => l1.id === pasteLevel1Ids[pasteLevel1Ids.length - 1],
  );
  if (
    updateL1DataSheet[lastCutL1DataRow]?.rowType === RowTypes.S &&
    updateL1DataSheet[lastCutL1DataRow + 1]?.rowType === RowTypes.FS
  ) {
    updateL1DataSheet.splice(lastCutL1DataRow + 1, 2);
  }

  return { updatedDataSheet: dataSheetCopy, pasteLevel1Ids };
}

export function cutPasteL2Row(
  dataSheetCopy: IDataSheet,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
  cutData: ICopiedData,
  pasteIndex: number,
): { updatedDataSheet: IDataSheet; pasteLevel2Ids: string[] } {
  const pasteLevel2Ids: string[] = [];
  const sourceL2DataSheet = dataSheetCopy.l2[cutData.copiedSheetId];
  const destinationL2DataSheet = dataSheetCopy.l2[selectedSheet.l1SheetId];
  const destinationRow = dataSheetCopy.l2[selectedSheet.l1SheetId][pasteIndex];
  const destinationRowType = destinationRow?.rowType;

  const cutSourceL2DataIds: string[] = [];

  cutData.selectedRows.forEach((rowIndex) => {
    if (enableMovableAndPasteForData(sourceL2DataSheet[rowIndex].rowType)) {
      cutSourceL2DataIds.push(sourceL2DataSheet[rowIndex].id as string);
    }
  });

  cutSourceL2DataIds.forEach((cutL2DataId, index) => {
    const cutL2DataIndex = sourceL2DataSheet.findIndex((l2Data) => l2Data.id === cutL2DataId);
    const cutL2Data = sourceL2DataSheet.splice(cutL2DataIndex, 1)[0];
    const previousCutL2Data = sourceL2DataSheet[cutL2DataIndex - 1];
    const destinationL2DataSheetIndex = destinationL2DataSheet.findIndex(
      (l2Data) => l2Data.id === destinationRow.id,
    );
    let isPossibleToPaste = false;

    const isDRow = cutL2Data.rowType === RowTypes.D;
    const isPossibleToAddSubTotal =
      destinationRowType !== RowTypes.S &&
      destinationL2DataSheet[destinationL2DataSheetIndex - 1]?.rowType !== RowTypes.S &&
      destinationL2DataSheet[destinationL2DataSheetIndex + 1]?.rowType !== RowTypes.S &&
      cutL2Data.rowType === RowTypes.S &&
      destinationL2DataSheetIndex > 0;

    if (isDRow || isPossibleToAddSubTotal) {
      isPossibleToPaste = true;
    }

    if (isPossibleToPaste) {
      destinationL2DataSheet.splice(destinationL2DataSheetIndex, 0, cutL2Data);
      pasteLevel2Ids.push(cutL2Data.id as string);
    }

    const isPasteAbove = destinationL2DataSheetIndex < cutL2DataIndex;
    if (
      isPasteAbove &&
      previousCutL2Data?.rowType === RowTypes.S &&
      !cutSourceL2DataIds.includes(sourceL2DataSheet[cutL2DataIndex + 1].id as string) &&
      sourceL2DataSheet[cutL2DataIndex + 1]?.rowType === RowTypes.S
    ) {
      sourceL2DataSheet.splice(cutL2DataIndex + 1, 1);
    } else if (
      previousCutL2Data?.rowType === RowTypes.S &&
      !cutSourceL2DataIds.includes(sourceL2DataSheet[cutL2DataIndex].id as string) &&
      sourceL2DataSheet[cutL2DataIndex]?.rowType === RowTypes.S
    ) {
      sourceL2DataSheet.splice(cutL2DataIndex, 1);
    }
  });
  if (sourceL2DataSheet[0]?.rowType === RowTypes.S) {
    sourceL2DataSheet.splice(0, 1);
  }
  // IF source cut paste all to destination sheet
  const sourceDRowCount = sourceL2DataSheet.filter(
    (l2Data) => l2Data.rowType === RowTypes.D,
  ).length;
  if (cutData.copiedSheetId !== selectedSheet.l2SheetId && sourceDRowCount === 0) {
    dataSheetCopy = addL2Row(dataSheetCopy, 0, cutData.copiedSheetId, getEmptyL2Data());
  }
  return { updatedDataSheet: dataSheetCopy, pasteLevel2Ids };
}

export function cutPasteL3Row(
  dataSheetCopy: IDataSheet,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
  cutData: ICopiedData,
  pasteIndex: number,
  selectedValue: string[],
): { updatedDataSheet: IDataSheet; pasteLevel3Ids: string[] } {
  const pasteLevel3Ids: string[] = [];
  const sourceL3DataSheet = dataSheetCopy.l3[cutData.copiedSheetId];
  const destinationL3DataSheet = dataSheetCopy.l3[selectedSheet.l2SheetId];
  const destinationRow = dataSheetCopy.l3[selectedSheet.l2SheetId][pasteIndex];
  const destinationRowType = destinationRow?.rowType;
  let pasteL3DataFringeRange = destinationRow?.range;

  // If the first fringe range not needed, reset the pasteL3DataFringeRange
  if (
    pasteIndex ===
    destinationL3DataSheet.findIndex((l3Data) => l3Data.range === pasteL3DataFringeRange)
  ) {
    pasteL3DataFringeRange = '';
  }
  const cutSourceL3DataIds: string[] = [];

  cutData.selectedRows.forEach((rowIndex) => {
    if (enableMovableAndPasteForData(sourceL3DataSheet[rowIndex].rowType)) {
      cutSourceL3DataIds.push(sourceL3DataSheet[rowIndex].id as string);
    }
  });

  cutSourceL3DataIds.forEach((cutL3DataId, index) => {
    const cutL3DataIndex = sourceL3DataSheet.findIndex((l3Data) => l3Data.id === cutL3DataId);
    let cutL3Data = sourceL3DataSheet.splice(cutL3DataIndex, 1)[0];
    const previousCutL3Data = sourceL3DataSheet[cutL3DataIndex - 1];
    const destinationL3DataSheetIndex = destinationL3DataSheet.findIndex(
      (l3Data) => l3Data.id === destinationRow.id,
    );
    let isPossibleToPaste = false;

    if (cutL3Data.rowType === RowTypes.D) {
      isPossibleToPaste = true;
      cutL3Data = {
        ...getPasteSpecialL3Data(dataSheetCopy, destinationRow, cutL3Data, selectedValue),
        id: cutL3Data.id,
        comparison: cutL3Data.comparison,
        rowType: cutL3Data.rowType,
        range: pasteL3DataFringeRange,
      };
    } else if (
      destinationRowType !== RowTypes.S &&
      destinationL3DataSheet[destinationL3DataSheetIndex - 1]?.rowType !== RowTypes.S &&
      destinationL3DataSheet[destinationL3DataSheetIndex + 1]?.rowType !== RowTypes.S &&
      cutL3Data.rowType === RowTypes.S &&
      destinationL3DataSheetIndex > 0
    ) {
      isPossibleToPaste = true;
    }

    if (isPossibleToPaste) {
      destinationL3DataSheet.splice(destinationL3DataSheetIndex, 0, cutL3Data);
      pasteLevel3Ids.push(cutL3Data.id as string);
    }

    const isPasteAbove = destinationL3DataSheetIndex < cutL3DataIndex;
    if (
      isPasteAbove &&
      previousCutL3Data?.rowType === RowTypes.S &&
      !cutSourceL3DataIds.includes(sourceL3DataSheet[cutL3DataIndex + 1].id as string) &&
      sourceL3DataSheet[cutL3DataIndex + 1].rowType === RowTypes.S &&
      previousCutL3Data.id !== sourceL3DataSheet[cutL3DataIndex + 1].id
    ) {
      sourceL3DataSheet.splice(cutL3DataIndex + 1, 1);
    } else if (
      previousCutL3Data?.rowType === RowTypes.S &&
      !cutSourceL3DataIds.includes(sourceL3DataSheet[cutL3DataIndex].id as string) &&
      sourceL3DataSheet[cutL3DataIndex].rowType === RowTypes.S &&
      previousCutL3Data.id !== sourceL3DataSheet[cutL3DataIndex].id
    ) {
      sourceL3DataSheet.splice(cutL3DataIndex, 1);
    }
  });
  if (sourceL3DataSheet[0]?.rowType === RowTypes.S) {
    sourceL3DataSheet.splice(0, 1);
  }

  // IF source cut paste all to destination sheet
  const sourceDRowCount = sourceL3DataSheet.filter(
    (l3Data) => l3Data.rowType === RowTypes.D,
  ).length;
  if (cutData.copiedSheetId !== selectedSheet.l2SheetId && sourceDRowCount === 0) {
    dataSheetCopy = addL3Row(
      dataSheetCopy,
      0,
      cutData.copiedSheetId,
      getEmptyL3Data(dataSheetCopy),
    );
  }

  return { updatedDataSheet: dataSheetCopy, pasteLevel3Ids };
}

export function movedIndexL1Row(
  dataSheetCopy: IDataSheet,
  movedIndexes: number[],
  finalIndex: number,
): { updatedDataSheet: IDataSheet; movedLevel1RowIds: string[] } {
  const movedLevel1RowIds: string[] = [];
  const movedL1Data: IL1Data[] = [];
  const updateL1DataSheet = dataSheetCopy.l1;
  const previousMovedData = dataSheetCopy.l1[movedIndexes[0] - 1] ?? updateL1DataSheet[0].id;
  const finalMovedLevel1RowData = updateL1DataSheet[finalIndex];
  movedIndexes.forEach((rowIndex) => {
    const movedRowData = updateL1DataSheet[rowIndex];
    if (!movedRowData) return;
    movedLevel1RowIds.push(movedRowData.id.toString());
  });

  const movingL1DataIds = movedIndexes.map((rowIndex) => updateL1DataSheet[rowIndex].id);

  movingL1DataIds.forEach((l1DataId, index) => {
    const movedRowData = updateL1DataSheet.find((l1Data) => l1Data.id === l1DataId);
    const movedRowIndex = updateL1DataSheet.findIndex((l1Data) => l1Data.id === l1DataId);
    if (!movedRowData) return;
    if (!enableMovableAndPasteForData(movedRowData.rowType)) return;
    movedL1Data.push(updateL1DataSheet.splice(movedRowIndex, 1)[0]);
  });
  const destinationIndex = updateL1DataSheet.findIndex(
    (l1Data) => l1Data.id === finalMovedLevel1RowData.id,
  );
  updateL1DataSheet.splice(destinationIndex, 0, ...movedL1Data);
  if (finalIndex === 0 && updateL1DataSheet[0]?.rowType === RowTypes.FS) {
    updateL1DataSheet.splice(0, 2);
  }
  const movedPreviousIndex = updateL1DataSheet.findIndex(
    (l1Data) => l1Data.id === previousMovedData.id,
  );

  const isNextSubtotal =
    updateL1DataSheet[movedPreviousIndex + 1]?.rowType === RowTypes.FS &&
    updateL1DataSheet[movedPreviousIndex]?.rowType === RowTypes.S;

  if (isNextSubtotal) {
    updateL1DataSheet.splice(movedPreviousIndex, 2);
  }
  if (
    movedL1Data[0]?.rowType === RowTypes.FS &&
    updateL1DataSheet[destinationIndex - 1]?.rowType === RowTypes.S
  ) {
    updateL1DataSheet.splice(destinationIndex, 2);
  }

  if (
    movedL1Data[movedL1Data.length - 1]?.rowType === RowTypes.S &&
    updateL1DataSheet[destinationIndex + movedL1Data.length]?.rowType === RowTypes.FS
  ) {
    updateL1DataSheet.splice(destinationIndex + movedL1Data.length, 2);
  }

  return { updatedDataSheet: dataSheetCopy, movedLevel1RowIds };
}

export function movedIndexL2Row(
  dataSheetCopy: IDataSheet,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
  movedIndexes: number[],
  finalIndex: number,
): { updatedDataSheet: IDataSheet; movedLevel2RowIds: string[] } {
  const movedL2DataSheet = dataSheetCopy.l2[selectedSheet.l1SheetId];
  const previousMovedData = movedL2DataSheet[movedIndexes[0] - 1] ?? movedL2DataSheet[0];
  const movedLevel2RowIds: string[] = [];
  const movedL2Data: IL2Data[] = [];

  movedIndexes.forEach((rowIndex) => {
    const movedRowData = movedL2DataSheet[rowIndex];
    if (!movedRowData) return;
    movedLevel2RowIds.push(movedRowData.id.toString());
  });

  const movingL2DataIds = movedIndexes.map((rowIndex) => movedL2DataSheet[rowIndex].id);

  const finalMovedLevel2RowData = movedL2DataSheet[finalIndex];

  movingL2DataIds.forEach((l2DataId, index) => {
    const movedRowData = movedL2DataSheet.find((l2Data) => l2Data.id === l2DataId);
    const movedRowIndex = movedL2DataSheet.findIndex((l2Data) => l2Data.id === l2DataId);
    if (!movedRowData) return;
    if (!enableMovableAndPasteForData(movedRowData.rowType)) return;
    movedL2Data.push(movedL2DataSheet.splice(movedRowIndex, 1)[0]);
  });
  const destinationIndex = movedL2DataSheet.findIndex(
    (l2Data) => l2Data.id === finalMovedLevel2RowData.id,
  );

  movedL2DataSheet.splice(destinationIndex, 0, ...movedL2Data);
  const { updateDataSheet } = removeSubtotalRows(
    destinationIndex,
    movedL2DataSheet,
    previousMovedData,
    movedL2Data,
    movedIndexes,
  );
  dataSheetCopy.l2[selectedSheet.l1SheetId] = updateDataSheet as IL2Data[];

  return { updatedDataSheet: dataSheetCopy, movedLevel2RowIds };
}

export function movedIndexL3Row(
  dataSheetCopy: IDataSheet,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
  movedIndexes: number[],
  finalIndex: number,
): {
  updatedDataSheet: IDataSheet;
  movedLevel3RowIds: string[];
} {
  const selectedL2SheetId: string = selectedSheet.l2SheetId;
  const movedLevel3RowIds: string[] = [];
  const movedL3DataSheet = dataSheetCopy.l3[selectedSheet.l2SheetId];
  const previousMovedData = movedL3DataSheet[movedIndexes[0] - 1] ?? movedL3DataSheet[0];
  const movedL3Data: IL3Data[] = [];

  const finalMovedLevel3RowData = dataSheetCopy.l3[selectedL2SheetId][finalIndex];
  if (finalMovedLevel3RowData && finalMovedLevel3RowData.rowType === RowTypes.T) {
    finalIndex = dataSheetCopy.l3[selectedL2SheetId].length - 1;
  }

  const firstMovedLevel3RowData = dataSheetCopy.l3[selectedL2SheetId][movedIndexes[0]];
  if (movedIndexes.length === 1 && !enableMovableAndPasteForData(firstMovedLevel3RowData.rowType)) {
    return {
      updatedDataSheet: dataSheetCopy,
      movedLevel3RowIds,
    };
  }

  let isMovable = true;

  // final placement after the cut paste operation done. TO get the Range id
  const offset = finalIndex > movedIndexes[0] ? movedIndexes.length : 0;
  const finalElement = dataSheetCopy.l3[selectedL2SheetId][finalIndex - 1 + offset];

  // getting the moving elements Range Id
  const rangeId: string | null = finalElement?.range ?? '';

  // getting the moving elements array IL3Data
  const movingL3DataIds = movedIndexes.map((rowIndex) => movedL3DataSheet[rowIndex].id);

  movingL3DataIds.forEach((l3DataId, index) => {
    const movedRowData = movedL3DataSheet.find((l3Data) => l3Data.id === l3DataId);
    const movedRowIndex = movedL3DataSheet.findIndex((l3Data) => l3Data.id === l3DataId);
    if (!movedRowData) return;
    if (!enableMovableAndPasteForData(movedRowData.rowType)) {
      isMovable = false;
      return;
    }
    const l3Data = movedL3DataSheet.splice(movedRowIndex, 1)[0];
    movedL3Data.push({ ...l3Data, range: rangeId });
  });

  if (!isMovable) return { updatedDataSheet: dataSheetCopy, movedLevel3RowIds };

  const destinationIndex = movedL3DataSheet.findIndex(
    (l3Data) => l3Data.id === finalMovedLevel3RowData.id,
  );
  movedL3DataSheet.splice(destinationIndex, 0, ...(movedL3Data as IL3Data[]));

  const { updateDataSheet } = removeSubtotalRows(
    destinationIndex,
    movedL3DataSheet,
    previousMovedData,
    movedL3Data,
    movedIndexes,
  );
  dataSheetCopy.l3[selectedSheet.l2SheetId] = updateDataSheet as IL3Data[];

  return { updatedDataSheet: dataSheetCopy, movedLevel3RowIds };
}

export const removeSubtotalRows = (
  destinationIndex: number,
  movedDataSheet: (IL3Data | IL2Data)[],
  previousMovedData: IL3Data | IL2Data,
  movedL3Data: (IL3Data | IL2Data)[],
  movedIndexes: number[],
): { updateDataSheet: (IL3Data | IL2Data)[] } => {
  const updateL3DataSheet = cloneDeep(movedDataSheet);
  if (destinationIndex === 0 && updateL3DataSheet[0]?.rowType === RowTypes.S) {
    updateL3DataSheet.splice(0, 1);
  }
  const movedPreviousIndex = updateL3DataSheet.findIndex(
    (l3Data) => l3Data.id === previousMovedData?.id,
  );

  const isNextSubtotal =
    updateL3DataSheet[movedPreviousIndex + 1]?.rowType === RowTypes.S &&
    updateL3DataSheet[movedPreviousIndex]?.rowType === RowTypes.S;
  if (isNextSubtotal) {
    updateL3DataSheet.splice(movedPreviousIndex + 1, 1);
  }

  if (
    movedL3Data[0]?.rowType === RowTypes.S &&
    updateL3DataSheet[destinationIndex - 1]?.rowType === RowTypes.S
  ) {
    updateL3DataSheet.splice(destinationIndex, 1);
  }

  if (
    movedL3Data[movedL3Data.length - 1]?.rowType === RowTypes.S &&
    updateL3DataSheet[destinationIndex + movedL3Data.length]?.rowType === RowTypes.S
  ) {
    updateL3DataSheet.splice(destinationIndex + movedL3Data.length - 1, 1);
  }

  return { updateDataSheet: updateL3DataSheet };
};

export const enableMovableAndPasteForData = (rowType: UnionRowType) => {
  const enableMovableAndPasteActionsRowTypes: RowTypes[] = [
    RowTypes.D,
    RowTypes.S,
    RowTypes.FS,
    RowTypes.G,
  ];
  return enableMovableAndPasteActionsRowTypes.includes(rowType as RowTypes);
};

export const enableActionForData = (rowType: UnionRowType) => {
  return rowType === RowTypes.D;
};

export const enableDeleteActionForData = (rowType: UnionRowType) => {
  const enableDeleteActionRowTypes: RowTypes[] = [RowTypes.D, RowTypes.S, RowTypes.FS, RowTypes.G];
  return enableDeleteActionRowTypes.includes(rowType as RowTypes);
};

export const addGrandTotalL1Row = (
  dataSheetCopy: IDataSheet,
  addGrandTotalRowIndex: number,
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1Ids: string[];
} => {
  if (dataSheetCopy) {
    dataSheetCopy.l1.splice(addGrandTotalRowIndex, 0, getL1GrandTotalRow());
  }

  const updatedLevel1Ids = dataSheetCopy.l1.map((l1Data) => l1Data.id.toString());

  return { updatedDataSheet: dataSheetCopy, updatedLevel1Ids };
};

export const addSubTotalL1Row = (
  dataSheet: IDataSheet,
  addSubTotalRowIndex: number,
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1Ids: string[];
} => {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  if (dataSheetCopy) {
    const fringeTotalRow: IL1Data = getL1SubFringesRow();
    const systemTotalRow: IL1Data = getL1SystemSubTotalRow();
    const insertSubtotal = [fringeTotalRow, systemTotalRow];
    dataSheetCopy.l1.splice(addSubTotalRowIndex, 0, ...insertSubtotal);
  }
  const updatedLevel1Ids = dataSheetCopy.l1.map((l1Data) => l1Data.id.toString());

  return { updatedDataSheet: dataSheetCopy, updatedLevel1Ids };
};

export const addSubTotalL2Row = (
  dataSheetCopy: IDataSheet,
  addSubTotalRowIndex: number,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1Id: string;
  updatedLevel2Ids: string[];
} => {
  const updatedLevel1Id = selectedSheet.l1SheetId;

  const selectedL2DataSheet = dataSheetCopy.l2[updatedLevel1Id];
  selectedL2DataSheet.splice(addSubTotalRowIndex, 0, getL2SystemSubTotalRow());
  const updatedLevel2Ids = selectedL2DataSheet.map((level2Data) => level2Data.id.toString());

  return { updatedDataSheet: dataSheetCopy, updatedLevel1Id, updatedLevel2Ids };
};
export const addSubTotalL3Row = (
  dataSheetCopy: IDataSheet,
  addSubTotalRowIndex: number,
  selectedSheet: { l1SheetId: string; l2SheetId: string },
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1Id: string;
  updatedLevel2Id: string;
  updatedLevel3Ids: string[];
} => {
  const updatedLevel1Id = selectedSheet.l1SheetId;
  const updatedLevel2Id = selectedSheet.l2SheetId;

  const selectedL3DataSheet = dataSheetCopy.l3[selectedSheet.l2SheetId];
  selectedL3DataSheet.splice(addSubTotalRowIndex, 0, getL3SystemSubTotalRow());

  const updatedLevel3Ids = selectedL3DataSheet.map((level3Data) => level3Data.id.toString());

  return { updatedDataSheet: dataSheetCopy, updatedLevel1Id, updatedLevel2Id, updatedLevel3Ids };
};

export const addFringeRowToLevel3Row = (
  dataSheet: IDataSheet,
  selectedSheet: ISelectedSheet,
  selectedRowIndexes: number[],
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1RowId: string;
  updatedLevel2RowId: string;
  updatedLevel3RowIds: string[];
  updatedSelectedRowIndexes: number[];
} => {
  const newFringeId = generatedId();

  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedLevel1RowId: string = selectedSheet.l1SheetId;
  const updatedLevel2RowId: string = selectedSheet.l2SheetId;
  const updatedLevel3RowIds: string[] = [];
  const updatedSelectedRowIndexes: number[] = selectedRowIndexes;

  updatedSelectedRowIndexes.forEach((index) => {
    updatedDataSheet.l3[updatedLevel2RowId][index].range = newFringeId;
    updatedLevel3RowIds.push(updatedDataSheet.l3[updatedLevel2RowId][index].id.toString());
  });

  return {
    updatedDataSheet,
    updatedLevel1RowId,
    updatedLevel2RowId,
    updatedLevel3RowIds,
    updatedSelectedRowIndexes,
  };
};

export const removeFringeRowToLevel3Row = (
  dataSheet: IDataSheet,
  selectedSheet: ISelectedSheet,
  selectedRowIndexes: number[],
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1RowId: string;
  updatedLevel2RowId: string;
  updatedLevel3RowIds: string[];
  removeFringeIdsIndexes: number[];
} => {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedLevel1RowId: string = selectedSheet.l1SheetId;
  const updatedLevel2RowId: string = selectedSheet.l2SheetId;
  const updatedLevel3RowIds: string[] = [];
  const removeFringeIdsIndexes: number[] = selectedRowIndexes;

  removeFringeIdsIndexes.forEach((index) => {
    const existingRangeId = updatedDataSheet.l3[updatedLevel2RowId][index].range;
    updatedLevel3RowIds.push(updatedDataSheet.l3[updatedLevel2RowId][index].id.toString());
    removeFringeIdsIndexes.push(index);
    updatedDataSheet.l3[selectedSheet.l2SheetId].forEach((row, l3DataIndex) => {
      if (row.range === existingRangeId) {
        row.range = '';
        updatedLevel3RowIds.push(updatedDataSheet.l3[updatedLevel2RowId][index].id.toString());
        removeFringeIdsIndexes.push(l3DataIndex);
      }
    });
  });

  return {
    updatedDataSheet,
    updatedLevel1RowId,
    updatedLevel2RowId,
    updatedLevel3RowIds,
    removeFringeIdsIndexes,
  };
};

const removeSubTotalIds = (sheet: UnionDataType[]): { removedSubTotalIds: string[] } => {
  const subTotalIndexes = sheet
    ?.reduce(
      (subTotal: number[], sheetData: UnionDataType, index: number) =>
        sheetData.rowType === RowTypes.S ? (subTotal.push(index), subTotal) : subTotal,
      [],
    )
    .reverse();

  let nextSubtotalIndex: number;
  let removeSubTotalIndexes: number[] = [];
  const removedSubTotalIds: string[] = [];
  subTotalIndexes?.forEach((subTotalIndex: number) => {
    if (subTotalIndex === 0 || nextSubtotalIndex - subTotalIndex === 1) {
      removeSubTotalIndexes.push(subTotalIndex);
      removedSubTotalIds.push(sheet[subTotalIndex].id as string);
      sheet.splice(subTotalIndex, 1);
      nextSubtotalIndex = subTotalIndex + 1;
    } else {
      nextSubtotalIndex = subTotalIndex;
    }
    // if the fringe subtotal row
    if (sheet[subTotalIndex + 1] && sheet[subTotalIndex + 1].rowType === RowTypes.FS) {
      removeSubTotalIndexes = [...removeSubTotalIndexes, subTotalIndex, subTotalIndex + 1];
      removedSubTotalIds.push(
        sheet[subTotalIndex].id as string,
        sheet[subTotalIndex + 1].id as string,
      );
      sheet.splice(subTotalIndex, 2);
    }
    if (subTotalIndex - 1 === 0 && sheet[subTotalIndex - 1]?.rowType === RowTypes.FS) {
      removeSubTotalIndexes = [subTotalIndex - 1, subTotalIndex, ...removeSubTotalIndexes];
      removedSubTotalIds.push(
        sheet[subTotalIndex].id as string,
        sheet[subTotalIndex - 1].id as string,
      );

      sheet.splice(subTotalIndex - 1, 2);
    }
  });
  return { removedSubTotalIds };
};

export const removeRowIdsAndIndexes = (
  sheet: UnionDataType[],
  selectedRemoveIndexes: number[],
): { removedRowIds: string[]; removedRowIndexes: number[] } => {
  let removedRowIds: string[] = [] as string[];
  let removeSheets = cloneDeep(sheet);
  sheet.forEach((sheetData: UnionDataType, index: number) => {
    if (selectedRemoveIndexes.includes(index)) {
      removedRowIds.push(sheetData.id as string);
    }
  });

  removeSheets = removeSheets.filter((sheetData: UnionDataType, index: number) => {
    return !selectedRemoveIndexes.includes(index);
  });
  const { removedSubTotalIds } = removeSubTotalIds(removeSheets);
  removedRowIds = [...removedRowIds, ...removedSubTotalIds];

  const removedRowIndexes: number[] = [];
  removedRowIds.forEach((id) => {
    const index = sheet.findIndex((sheetData: UnionDataType) => {
      return (sheetData.id as string) === id;
    });
    removedRowIndexes.push(index);
  });
  removedRowIndexes.sort((a, b) => b - a);
  return { removedRowIds, removedRowIndexes };
};

const getColumnIndexes = (level: string) => {
  switch (level) {
    case SheetNames.L1:
      return L1ColumnIndexes;
    case SheetNames.L2:
      return L2ColumnIndexes;
    case SheetNames.L3:
      return L3ColumnIndexes;
    default:
      throw new Error('Wrong level');
  }
};

export const resetVariance = (
  dataSheetCopy: IDataSheet,
  formulaSheet: FormulaSheet,
  baseCurrency: string,
) => {
  const resetCurrency = baseCurrency === SYSTEM_CURRENCY_LABEL ? SYSTEM_CURRENCY : baseCurrency;

  if (dataSheetCopy.configs === undefined) {
    dataSheetCopy.configs = {
      dropCurrencyAtReset: resetCurrency,
      varianceCalc: 'under',
    };
  } else {
    dataSheetCopy.configs.dropCurrencyAtReset = resetCurrency;
  }

  // L2 & L3 data sheets structure is object based
  [SheetNames.L3, SheetNames.L2].forEach((level) => {
    Object.keys(dataSheetCopy[level]).forEach((key) => {
      const sheetName = `${level}_${key}`;
      const TotalColumnIndex = getColumnIndexes(level).total;
      const IdColumnIndex = getColumnIndexes(level).id;

      const data: Array<IL1Data | IL2Data | IL3Data> = dataSheetCopy[level][key];
      const values = formulaSheet.getSheetValues(sheetName);
      data?.forEach((record, index) => {
        if (`${record.id}` === `${values?.[sheetName]?.[index]?.[IdColumnIndex]}`) {
          record.comparison = values?.[sheetName]?.[index]?.[TotalColumnIndex] as number;
        }
      });
    });
  });

  // L1 data sheets structure is array based with each object "id" field as key
  dataSheetCopy.l1.forEach((el, index) => {
    const TotalColumnIndex = getColumnIndexes(SheetNames.L1).total;
    const ComparisonColumnIndex = getColumnIndexes(SheetNames.L1).comparison;
    const { l1: l1ValuesArrayOfArrays } = formulaSheet.getSheetValues(SheetNames.L1);
    const rowIndex = l1ValuesArrayOfArrays.findIndex((l1ValuesArray) => l1ValuesArray[0] === el.id);
    const row = l1ValuesArrayOfArrays.find((l1ValuesArray) => l1ValuesArray[0] === el.id);
    const totalValue = row?.[TotalColumnIndex] as number;

    if (row) {
      // Update the comparison value in hyperformula sheet
      formulaSheet.updateSheetContents(
        SheetNames.L1,
        [
          {
            row: rowIndex,
            col: ComparisonColumnIndex,
            content: totalValue,
          },
        ],
        false,
      );
    }
  });

  return dataSheetCopy;
};

export const updateFringesL3DataSheet = (
  dataSheetCopy: IDataSheet,
  selectedSheet: ISelectedSheet,
  selectedIndexes: number[],
  fringeCode: string,
  isChecked: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1RowId: string;
  updatedLevel2RowId: string;
  updatedLevel3RowIds: string[];
  updatedSelectedRowIndexes: number[];
} => {
  type key = keyof typeof dataSheetCopy.l3;

  const updatedLevel1RowId = selectedSheet.l1SheetId;
  const updatedLevel2RowId = selectedSheet.l2SheetId;
  const updatedSelectedRowIndexes = selectedIndexes;

  dataSheetCopy.l3[updatedLevel2RowId as key] = dataSheetCopy.l3[updatedLevel2RowId as key]?.map(
    (l3Data, index) => {
      if (l3Data.rowType === 'D' && selectedIndexes.includes(index)) {
        let fringes = l3Data.fringes.split(',');
        if (isChecked) {
          fringes = fringes.filter((fringe) => fringe !== fringeCode);
        } else if (!isChecked && !fringes.includes(fringeCode)) {
          fringes.push(fringeCode);
        }
        l3Data.fringes = fringes.filter((fringe) => fringe !== '').join();
        return l3Data;
      } else {
        return l3Data;
      }
    },
  );
  const updatedLevel3RowIds = dataSheetCopy.l3[updatedLevel2RowId as key].map((l3Data) =>
    l3Data.id.toString(),
  );
  return {
    updatedDataSheet: dataSheetCopy,
    updatedLevel1RowId,
    updatedLevel2RowId,
    updatedLevel3RowIds,
    updatedSelectedRowIndexes,
  };
};

export const detectGroupsReferenceCycle = (
  l3Row: IL3Data,
  value: string,
  action: 'addGroup' | 'addReferenceGroup',
  onReferenceCycleDetected: (groupCode: string) => void,
) => {
  const normalizedValue = value.toLowerCase();

  const actions: Record<'addGroup' | 'addReferenceGroup', () => void> = {
    addGroup: () => {
      const { units, x, rate } = l3Row;
      for (const data of Object.values({ units, x, rate })) {
        const normalizedData = data.toString().toLowerCase();
        if (normalizedData.includes(normalizedValue)) {
          onReferenceCycleDetected(value.toUpperCase());
          return;
        }
      }
    },
    addReferenceGroup: () => {
      const groups = l3Row.groups.toLowerCase().split(',');
      const valueWithoutFormula = normalizedValue.replace(/=(.*?)/, '');

      for (const group of groups) {
        if (group === valueWithoutFormula) {
          onReferenceCycleDetected(valueWithoutFormula.toUpperCase());
          return;
        }
      }
    },
  };

  actions[action]();
};

export const updateGroupsL3DataSheet = (
  dataSheetCopy: IDataSheet,
  selectedSheet: ISelectedSheet,
  selectedIndexes: number[],
  groupsCode: string,
  isChecked: boolean,
  onReferenceCycleDetected: (groupCode: string) => void,
): {
  updatedDataSheet: IDataSheet;
  updatedLevel1RowId: string;
  updatedLevel2RowId: string;
  updatedLevel3RowIds: string[];
  updatedSelectedRowIndexes: number[];
} => {
  type key = keyof typeof dataSheetCopy.l3;

  const updatedLevel1RowId = selectedSheet.l1SheetId;
  const updatedLevel2RowId = selectedSheet.l2SheetId;
  const updatedSelectedRowIndexes = selectedIndexes;
  dataSheetCopy.l3[updatedLevel2RowId as key] = dataSheetCopy.l3[updatedLevel2RowId as key]?.map(
    (l3Data, index) => {
      if (l3Data.rowType === RowTypes.D && updatedSelectedRowIndexes.includes(index)) {
        detectGroupsReferenceCycle(l3Data, groupsCode, 'addGroup', onReferenceCycleDetected);

        let groups = l3Data?.groups?.split(',') ?? [];
        if (isChecked) {
          groups = groups.filter((group) => group !== groupsCode);
        } else if (!isChecked && !groups.includes(groupsCode)) {
          groups.push(groupsCode);
        }
        l3Data.groups = groups.filter((fringe) => fringe !== '').join();
        return l3Data;
      } else {
        return l3Data;
      }
    },
  );
  const updatedLevel3RowIds = dataSheetCopy.l3[updatedLevel2RowId as key].map((l3Data) =>
    l3Data.id.toString(),
  );
  return {
    updatedDataSheet: dataSheetCopy,
    updatedLevel1RowId,
    updatedLevel2RowId,
    updatedLevel3RowIds,
    updatedSelectedRowIndexes,
  };
};

export function formatTotalDescription(account: string | null, description: string | null): string {
  let totalDescription = 'TOTAL';

  if (lodash.isEmpty(description)) {
    return totalDescription;
  }

  if (lodash.isEmpty(account)) {
    totalDescription = `TOTAL FOR ${description?.toUpperCase()}`;
    return totalDescription;
  }

  totalDescription = `TOTAL FOR ${description?.toUpperCase()} [${account}]`;
  return totalDescription;
}

export function removeL1CellData(
  dataSheetCopy: IDataSheet,
  selectedCellData: SimpleCellCoords[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  rowNumbers: number[];
} {
  const updatedIds: string[] = [];
  const rowNumbers: number[] = [];

  if (dataSheetCopy) {
    selectedCellData.forEach((item: SimpleCellCoords) => {
      const colHeader = getEnumKeyByValue(VisibleL1ColumnIndexes, item.col);
      if (!colHeader) return;
      dataSheetCopy.l1.forEach((l1Data: IL1Data, key: number) => {
        if (item.row === key) {
          updatedIds.push(l1Data.id.toString());
          rowNumbers.push(key);
          l1Data[colHeader as keyof IL1Data] = '';
        }
      });
    });
  }
  return { updatedDataSheet: dataSheetCopy, rowNumbers, updatedIds };
}

export function removeL2CellData(
  dataSheetCopy: IDataSheet,
  selectedCellData: SimpleCellCoords[],
  parentId: string,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  rowNumbers: number[];
} {
  const updatedIds: string[] = [];
  const rowNumbers: number[] = [];

  if (dataSheetCopy) {
    selectedCellData.forEach((item: SimpleCellCoords) => {
      const colHeader = getEnumKeyByValue(VisibleL2ColumnIndexes, item.col);
      if (!colHeader) return;
      dataSheetCopy.l2[parentId].forEach((l2Data: IL2Data, key: number) => {
        if (item.row === key) {
          updatedIds.push(l2Data.id.toString());
          rowNumbers.push(key);
          l2Data[colHeader as keyof IL2Data] = '';
        }
      });
    });
  }
  return { updatedDataSheet: dataSheetCopy, rowNumbers, updatedIds };
}

export function removeL3CellData(
  dataSheetCopy: IDataSheet,
  selectedCellData: SimpleCellCoords[],
  parentId: string,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  rowNumbers: number[];
} {
  const updatedIds: string[] = [];
  const rowNumbers: number[] = [];

  if (dataSheetCopy) {
    selectedCellData.forEach((item: SimpleCellCoords) => {
      const colHeader = getEnumKeyByValue(VisibleL3ColumnIndexes, item.col);
      if (!colHeader) return;
      dataSheetCopy.l3[parentId].forEach((l3Data: IL3Data, key: number) => {
        if (item.row === key) {
          updatedIds.push(l3Data.id.toString());
          rowNumbers.push(key);
          l3Data[colHeader as keyof IL3Data] = '';
        }
      });
    });
  }
  return { updatedDataSheet: dataSheetCopy, rowNumbers, updatedIds };
}

export const isRemoveAllRows = (
  dataSheet: IDataSheet,
  selectedLevel: string,
  selectedIndexes: number[],
  selectedSheet?: ISelectedSheet,
): boolean => {
  if (selectedLevel === BudgetLevels.THIRD_LEVEL && selectedSheet) {
    if (dataSheet.l3[selectedSheet.l2SheetId]) {
      const totalDRowCount = dataSheet.l3[selectedSheet.l2SheetId].filter((l3Data: IL3Data) => {
        return l3Data.rowType === RowTypes.D;
      }).length;

      const selectedDRowCount = dataSheet.l3[selectedSheet.l2SheetId].filter(
        (l3Data: IL3Data, index: number) => {
          return selectedIndexes.includes(index) && l3Data.rowType === RowTypes.D;
        },
      ).length;

      if (totalDRowCount === selectedDRowCount) {
        return true;
      }
    }
  }
  if (selectedLevel === BudgetLevels.SECOND_LEVEL && selectedSheet) {
    if (dataSheet.l2[selectedSheet.l1SheetId]) {
      const totalDRowCount = dataSheet.l2[selectedSheet.l1SheetId].filter((l2Data: IL2Data) => {
        return l2Data.rowType === RowTypes.D;
      }).length;

      const selectedDRowCount = dataSheet.l2[selectedSheet.l1SheetId].filter(
        (l2Data: IL2Data, index: number) => {
          return selectedIndexes.includes(index) && l2Data.rowType === RowTypes.D;
        },
      ).length;

      if (totalDRowCount === selectedDRowCount) {
        return true;
      }
    }
  }
  if (selectedLevel === BudgetLevels.FIRST_LEVEL) {
    if (dataSheet.l1) {
      const totalDRowCount = dataSheet.l1.filter((l1Data: IL1Data) => {
        return l1Data.rowType === RowTypes.D;
      }).length;

      const selectedDRowCount = dataSheet.l1.filter((l1Data: IL1Data, index: number) => {
        return selectedIndexes.includes(index) && l1Data.rowType === RowTypes.D;
      }).length;

      if (totalDRowCount === selectedDRowCount) {
        return true;
      }
    }
  }
  return false;
};

export const getTotalRowSelected = (
  dataSheet: IDataSheet,
  selectedLevel: string,
  selectedIndexes: number[],
  selectedSheet?: ISelectedSheet,
): number => {
  let totalRows = selectedIndexes.length;

  const addL3RowsCount = (l2Data: IL2Data) => {
    if (dataSheet.l3[l2Data.id]) {
      totalRows += dataSheet.l3[l2Data.id].length;
    }
  };
  if (selectedLevel === BudgetLevels.SECOND_LEVEL && selectedSheet) {
    dataSheet.l2[selectedSheet.l1SheetId]?.forEach((l2Data: IL2Data, index: number) => {
      if (selectedIndexes.includes(index)) {
        addL3RowsCount(l2Data);
      }
    });
  }
  if (selectedLevel === BudgetLevels.FIRST_LEVEL && selectedSheet) {
    dataSheet.l1.forEach((l1Data: IL1Data, index: number) => {
      if (selectedIndexes.includes(index)) {
        dataSheet.l2[l1Data.id]?.forEach(addL3RowsCount);
      }
    });
  }
  return totalRows;
};

export const getAllSelectedRows = (l1Data: IL1Data[], selectedIndexes: number[]): number[] => {
  const selectedIndexesCopy: number[] = [...selectedIndexes];
  selectedIndexes.forEach((index: number) => {
    if (l1Data[index].rowType === RowTypes.S && !selectedIndexes.includes(index - 1)) {
      selectedIndexesCopy.push(index - 1);
    }
    if (l1Data[index].rowType === RowTypes.FS && !selectedIndexes.includes(index + 1)) {
      selectedIndexesCopy.push(index + 1);
    }
  });
  return selectedIndexesCopy.sort((a, b) => a - b);
};

export const getEditableL1Columns = (): string[] => {
  return ['account', 'description'];
};
export const getEditableL2Columns = (): string[] => {
  return ['account', 'description'];
};

export const getEditableL3Columns = (): string[] => {
  return ['description', 'fringes', 'groups', 'loc', 'set', 'units', 'desc', 'x', 'rate', 'cu'];
};

export const getDeletableL3Columns = (): string[] => {
  return ['description', 'loc', 'set', 'units', 'desc', 'x', 'rate', 'cu'];
};
export const getEditableL3FringeTotalColumns = (): string[] => {
  return ['loc', 'set'];
};

export const isEditableL1Column = (column: string): boolean => {
  return getEditableL1Columns().includes(column);
};

export const isEditableL1FringeTotalColumn = (column: string): boolean => {
  return getEditableL1Columns()[0] === column; // account column only editable
};

export const isEditableL2Column = (column: string): boolean => {
  return getEditableL2Columns().includes(column);
};
export const isEditableL2FringeTotalColumn = (column: string): boolean => {
  return getEditableL2Columns()[0] === column; // account column only editable
};

export const isEditableL3Column = (column: string): boolean => {
  return getEditableL3Columns().includes(column);
};

export const isEditableL3FringeTotalColumn = (column: string): boolean => {
  return getEditableL3FringeTotalColumns().includes(column); // account column only editable
};

export const isPossibleToUpdateValueInL3Column = (
  dataSheet: IDataSheet,
  column: string,
  value: string | number,
): boolean => {
  let valueArray;
  const { fringes, groups, locations, sets, unitDesc, currency } = dataSheet;

  if (value === '') {
    return true;
  }

  switch (column) {
    case 'fringes':
      valueArray = String(value).split(',');
      return valueArray.every((code) => fringes.findIndex((fringe) => fringe.code === code) !== -1);
    case 'groups':
      valueArray = String(value).split(',');
      return valueArray.every((code) => groups.findIndex((group) => group.code === code) !== -1);
    case 'loc':
      return (
        locations.findIndex((location) => location.code.toString() === value.toString()) !== -1
      );
    case 'set':
      return sets.findIndex((set) => set.code.toString() === value.toString()) !== -1;
    case 'desc':
      return unitDesc.findIndex((des) => des.code === value) !== -1 || value === '%';
    case 'cu':
      return currency.findIndex((curr) => curr.code === value) !== -1;
    case 'units':
    case 'x':
    case 'rate':
      return String(value).startsWith('=') || !Number.isNaN(Number(value));
    default:
      break;
  }

  return true;
};
