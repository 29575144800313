import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const RotateLeftIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.592 4.233A8.35 8.35 0 0110 3.875a7.223 7.223 0 017.225 7.225A7.223 7.223 0 0110 18.325 7.223 7.223 0 012.775 11.1c0-1.483.45-2.867 1.217-4.017M6.558 4.433l2.408-2.766M6.558 4.433l2.808 2.05"
      />
    </SvgIcon>
  );
};
