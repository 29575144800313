import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const PrinterIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.64587 6.41668H15.3542V4.58334C15.3542 2.75001 14.6667 1.83334 12.6042 1.83334H9.39587C7.33337 1.83334 6.64587 2.75001 6.64587 4.58334V6.41668Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 13.75V17.4167C14.6667 19.25 13.75 20.1667 11.9167 20.1667H10.0834C8.25004 20.1667 7.33337 19.25 7.33337 17.4167V13.75H14.6667Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 9.16666V13.75C19.25 15.5833 18.3333 16.5 16.5 16.5H14.6667V13.75H7.33333V16.5H5.5C3.66667 16.5 2.75 15.5833 2.75 13.75V9.16666C2.75 7.33332 3.66667 6.41666 5.5 6.41666H16.5C18.3333 6.41666 19.25 7.33332 19.25 9.16666Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5833 13.75H14.4741H6.41663"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41663 10.0833H9.16663"
        stroke="#747474"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
