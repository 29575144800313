import styled from '@emotion/styled';
import lodash from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CostsToDateColumnIndexes,
  LocationColumnIndexes,
  SetColumnIndexes,
  SheetNames,
  TabIndexes,
  CurrencyColumnIndexes,
  FringesColumnIndexes,
  GroupColumnIndexes,
  GlobalColumnIndexes,
  UnitDescriptionColumnIndexes,
} from '@/enums';
import { generatedId } from '@/utils';
import { dataSheetAtom, selectedSheetIdsAtom } from '@/atoms/DataSheetAtom';
import { budgetSheetLevelAtom, currentTabIndexAtom } from '@/atoms/GlobalAtoms';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import NotesFileViewer from '@/components/dialogBoxes/NotesFileViewer';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { INotes } from '@/interfaces/INotes';
import FormulaSheet from '@/sheets/FormulaSheet';

/* eslint-disable-next-line */
export interface OpenNotesDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRowIndex: number | undefined;
  selectedRowIndexes?: number[] | undefined;
  notes: INotes[] | undefined;
  tabName?: string;
  formulaSheet?: FormulaSheet;
}

const StyledOpenAttachmentDialog = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
`;

export function OpenNotesDialog({
  open,
  setOpen,
  selectedRowIndex,
  notes,
  selectedRowIndexes,
  tabName,
  formulaSheet,
}: OpenNotesDialogProps) {
  const selectedSheetIds = useRecoilValue(selectedSheetIdsAtom);
  const [dataSheet, setDataSheet] = useRecoilState(dataSheetAtom);
  const tabIndex = useRecoilValue(currentTabIndexAtom);
  const budgetSheetLevel = useRecoilValue(budgetSheetLevelAtom);

  const setNoteToUpload = (note: string) => {
    const date = new Date();

    const noteToUpload: INotes = {
      id: generatedId(),
      note: note,
      lastModified: date.toString(),
    };

    return noteToUpload;
  };

  function handleAddNotesToCTD(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.costsToDate[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.costsToDate[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.costsToDate[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.costsToDate[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.costsToDate[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.COSTS_TO_DATE,
              selectedRowIndex,
              CostsToDateColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.costsToDate[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.COSTS_TO_DATE,
              selectedRowIndex,
              CostsToDateColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }

  function handleUpdateNoteFromCTD(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.costsToDate[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }

  function handleDeleteNotesFromCTD(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.costsToDate[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.costsToDate[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.COSTS_TO_DATE,
                  selectedRowIndex,
                  CostsToDateColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToBudgetSheet(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);
      let rowId: string | number = '';

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (budgetSheetLevel === 'THIRD_LEVEL') {
          rowId = currentDataSheetCopy.l3[selectedSheetIds.l2SheetId][selectedRowIndex].id;
        }
        const notesArray: INotes[] = [];
        notesArray.push(noteToUpload);

        const data = {
          [rowId]: notesArray,
        };

        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
            const newArr = existingRow.concat(noteToUpload);
            currentDataSheetCopy.notes[rowId] = newArr;
          } else {
            currentDataSheetCopy.notes[rowId] = notesArray;
          }
        } else {
          currentDataSheetCopy.notes = data;
        }
      });
    }
  }
  function handleUpdateNoteFromBudgetSheet(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.l3[selectedSheetIds.l2SheetId][selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }

  function handleDeleteNotesFromBudgetSheet(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.l3[selectedSheetIds.l2SheetId][selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToSets(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.sets[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.sets[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.sets[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.sets[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.sets[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.SETS,
              selectedRowIndex,
              SetColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.sets[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.SETS,
              selectedRowIndex,
              SetColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }
  function handleUpdateNoteFromSets(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.sets[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromSets(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.sets[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.sets[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.SETS,
                  selectedRowIndex,
                  SetColumnIndexes.notes,
                  count.toString(),
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToLocations(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.locations[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.locations[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.locations[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.locations[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.locations[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.LOCATIONS,
              selectedRowIndex,
              LocationColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.locations[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.LOCATIONS,
              selectedRowIndex,
              LocationColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }
  function handleUpdateNoteFromLocations(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.locations[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromLocations(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.locations[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.locations[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.LOCATIONS,
                  selectedRowIndex,
                  LocationColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToCurrency(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.currency[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.currency[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.currency[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.currency[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.currency[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.CURRENCY,
              selectedRowIndex,
              CurrencyColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.currency[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.CURRENCY,
              selectedRowIndex,
              CurrencyColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }

  function handleUpdateNoteFromCurrency(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.currency[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromCurrency(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.currency[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.currency[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.CURRENCY,
                  selectedRowIndex,
                  CurrencyColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }
  function handleAddNotesToFringes(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.fringes[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.fringes[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.fringes[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.fringes[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.fringes[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.FRINGES,
              selectedRowIndex,
              FringesColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.fringes[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.FRINGES,
              selectedRowIndex,
              FringesColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }
  function handleUpdateNoteFromFringes(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.fringes[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromFringes(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.fringes[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.fringes[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.FRINGES,
                  selectedRowIndex,
                  FringesColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToGroups(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.groups[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.groups[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.groups[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.groups[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.groups[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.GROUPS,
              selectedRowIndex,
              GroupColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.groups[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.GROUPS,
              selectedRowIndex,
              GroupColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }
  function handleUpdateNoteFromGroups(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.groups[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromGroups(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.groups[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.groups[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.GROUPS,
                  selectedRowIndex,
                  GroupColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToGlobals(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.globals[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.globals[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.globals[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.globals[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.globals[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.GLOBALS,
              selectedRowIndex,
              GlobalColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.globals[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.GLOBALS,
              selectedRowIndex,
              GlobalColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }
  function handleUpdateNoteFromGlobals(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.globals[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromGlobals(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.globals[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.globals[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.GLOBALS,
                  selectedRowIndex,
                  GlobalColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  function handleAddNotesToUnitDesc(currentDataSheetCopy: IDataSheet, notes: string) {
    if (selectedRowIndexes) {
      const noteToUpload: INotes = setNoteToUpload(notes);

      selectedRowIndexes.forEach((selectedRowIndex) => {
        if (currentDataSheetCopy.unitDesc[selectedRowIndex]) {
          const rowId = currentDataSheetCopy.unitDesc[selectedRowIndex].id;
          const notesArray: INotes[] = [];
          notesArray.push(noteToUpload);
          const data = {
            [rowId]: notesArray,
          };

          if (currentDataSheetCopy.notes) {
            if (rowId in currentDataSheetCopy.notes) {
              const existingRow: INotes[] = currentDataSheetCopy.notes[rowId];
              const newArr = existingRow.concat(noteToUpload);
              currentDataSheetCopy.notes[rowId] = newArr;
            } else {
              currentDataSheetCopy.notes[rowId] = notesArray;
            }
          } else {
            currentDataSheetCopy.notes = data;
          }
          if (currentDataSheetCopy.unitDesc[selectedRowIndex].notes) {
            //increment count
            const notesCount = currentDataSheetCopy.unitDesc[selectedRowIndex].notes;
            const newCount = notesArray.length + parseInt(notesCount);
            currentDataSheetCopy.unitDesc[selectedRowIndex].notes = newCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.UNIT_DESCRIPTIONS,
              selectedRowIndex,
              UnitDescriptionColumnIndexes.notes,
              newCount,
            );
          } else {
            //add notes property and increase count
            const notesCount = notesArray.length;
            currentDataSheetCopy.unitDesc[selectedRowIndex].notes = notesCount.toString();
            formulaSheet?.setSheetContent(
              SheetNames.UNIT_DESCRIPTIONS,
              selectedRowIndex,
              UnitDescriptionColumnIndexes.notes,
              notesCount,
            );
          }
        }
      });
    }
  }

  function handleUpdateNoteFromUnitDesc(currentDataSheetCopy: IDataSheet, noteToUpdate: INotes) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.unitDesc[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const allNotesInThisRow = currentDataSheetCopy.notes[rowId];

            allNotesInThisRow.forEach((note) => {
              if (note.id === noteToUpdate.id) {
                note.note = noteToUpdate.note;
              }
            });
          }
        }
      });
    }
  }
  function handleDeleteNotesFromUnitDesc(currentDataSheetCopy: IDataSheet, noteIds: string[]) {
    if (selectedRowIndexes) {
      selectedRowIndexes.forEach((selectedRowIndex) => {
        const rowId = currentDataSheetCopy.unitDesc[selectedRowIndex].id;
        if (currentDataSheetCopy.notes) {
          if (rowId in currentDataSheetCopy.notes) {
            const currentRow: INotes[] = currentDataSheetCopy.notes[rowId];
            let update: INotes[] = [];
            currentRow.forEach((note, index) => {
              if (noteIds.includes(note.id)) {
                currentDataSheetCopy.notes[rowId].splice(index, 1);
                update = currentRow.filter((note) => !noteIds.includes(note.id));
                currentDataSheetCopy.notes[rowId] = update;
                const count = currentDataSheetCopy.notes[rowId].length;
                currentDataSheetCopy.unitDesc[selectedRowIndex].notes = count.toString();
                formulaSheet?.setSheetContent(
                  SheetNames.UNIT_DESCRIPTIONS,
                  selectedRowIndex,
                  UnitDescriptionColumnIndexes.notes,
                  count,
                );
              }
            });
          }
        }
      });
    }
  }

  const handleAddNotes = {
    [TabIndexes.SETS]: handleAddNotesToSets,
    [TabIndexes.CTD]: handleAddNotesToCTD,
    [TabIndexes.BUDGET]: handleAddNotesToBudgetSheet,
  //  [TabIndexes.CASHFLOW]: null,
  //  [TabIndexes.CAST_MANAGER]: null,
    [TabIndexes.CURRENCY]: handleAddNotesToCurrency,
    [TabIndexes.FRINGES]: handleAddNotesToFringes,
    [TabIndexes.GLOBALS]: handleAddNotesToGlobals,
    [TabIndexes.GROUPS]: handleAddNotesToGroups,
    [TabIndexes.LOCATIONS]: handleAddNotesToLocations,
    [TabIndexes.UNIT_DESC]: handleAddNotesToUnitDesc,
  };
  const handleDeleteNotes = {
    [TabIndexes.SETS]: handleDeleteNotesFromSets,
    [TabIndexes.CTD]: handleDeleteNotesFromCTD,
    [TabIndexes.BUDGET]: handleDeleteNotesFromBudgetSheet,
  //  [TabIndexes.CASHFLOW]: null,
  //  [TabIndexes.CAST_MANAGER]: null,
    [TabIndexes.CURRENCY]: handleDeleteNotesFromCurrency,
    [TabIndexes.FRINGES]: handleDeleteNotesFromFringes,
    [TabIndexes.GLOBALS]: handleDeleteNotesFromGlobals,
    [TabIndexes.GROUPS]: handleDeleteNotesFromGroups,
    [TabIndexes.LOCATIONS]: handleDeleteNotesFromLocations,
    [TabIndexes.UNIT_DESC]: handleDeleteNotesFromUnitDesc,
  };

  const handleUpdateNote = {
    [TabIndexes.SETS]: handleUpdateNoteFromSets,
    [TabIndexes.CTD]: handleUpdateNoteFromCTD,
    [TabIndexes.BUDGET]: handleUpdateNoteFromBudgetSheet,
  //  [TabIndexes.CASHFLOW]: null,
  //  [TabIndexes.CAST_MANAGER]: null,
    [TabIndexes.CURRENCY]: handleUpdateNoteFromCurrency,
    [TabIndexes.FRINGES]: handleUpdateNoteFromFringes,
    [TabIndexes.GLOBALS]: handleUpdateNoteFromGlobals,
    [TabIndexes.GROUPS]: handleUpdateNoteFromGroups,
    [TabIndexes.LOCATIONS]: handleUpdateNoteFromLocations,
    [TabIndexes.UNIT_DESC]: handleUpdateNoteFromUnitDesc,
  };

  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet) as IDataSheet;
  const addNotes = (notes: string) => {
    if (!dataSheetCopy || !selectedRowIndexes || selectedRowIndexes.length === 0 || !tabName) {
      return;
    }
    const currentAddNotes = handleAddNotes[tabIndex as TabIndexes];
    if (!currentAddNotes) {
      return;
    }
    currentAddNotes(dataSheetCopy, notes);
    setDataSheet(dataSheetCopy);
  };

  const deleteNotes = (noteIds: string[]) => {
    if (!dataSheetCopy || !selectedRowIndexes || selectedRowIndexes.length === 0 || !tabName) {
      return;
    }
    const currentRemoveNotes = handleDeleteNotes[tabIndex as TabIndexes];
    if (!currentRemoveNotes) {
      return;
    }
    currentRemoveNotes(dataSheetCopy, noteIds);
    setDataSheet(dataSheetCopy);
  };

  const updateNote = (note: INotes) => {
    if (!dataSheetCopy || !selectedRowIndexes || selectedRowIndexes.length === 0 || !tabName) {
      return;
    }
    const currentUpdateNote = handleUpdateNote[tabIndex as TabIndexes];
    if (!currentUpdateNote) {
      return;
    }
    currentUpdateNote(dataSheetCopy, note);
    setDataSheet(dataSheetCopy);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledOpenAttachmentDialog>
      <DialogBox
        title="Notes"
        dialogBoxContent={
          <NotesFileViewer
            selectedRowIndex={selectedRowIndex}
            selectedRowIndexes={selectedRowIndexes}
            addNotes={addNotes}
            deleteNotes={deleteNotes}
            updateNote={updateNote}
            notes={notes}
          />
        }
        isOpen={open}
        onClose={handleClose}
      />
    </StyledOpenAttachmentDialog>
  );
}

export default OpenNotesDialog;
