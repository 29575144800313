import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { LocalStorageVariables, S3ObjectType } from '@/enums';
import { useFetchFiles } from '@/hooks';
import { filterFilesByTypeAndName } from '@/utils';
import { getExtensionFromFilename, removedExtensionsFilename } from '@/utils';
import { useToaster } from '@/components/common/Toaster';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import { IFileResponse } from '@/components/dialogBoxes/types';
import { removeLastSlash } from '@/helpers/folderPath';
import DataSheetApiService from '@/services/DataSheetApiService';

interface IRenameDialogBox {
  open: boolean;
  setOpen: (open: boolean) => void;
  onFileRefetch: ReturnType<typeof useFetchFiles>['refetch'];
  folderPath?: string;
  selectedFile: IFileResponse | null;
  setSelectedFile: (selectedFile: IFileResponse | null) => void;
  fetchedFiles: IFileResponse[] | undefined;
  setFileIsOpened: (opened: boolean) => void;
}

interface IRenameFileFolder {
  contentName: string;
  contentType: S3ObjectType;
  newContentName: string;
  rootPath: string;
}

const BoxContent = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-top: 40px;
  padding: 2px;
`;

export function RenameDialogBox({
  open,
  setOpen,
  folderPath,
  onFileRefetch,
  selectedFile,
  setSelectedFile,
  fetchedFiles,
}: IRenameDialogBox) {
  const [newName, setNewName] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('Update');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const snackBar = useToaster();

  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  function handleClose() {
    setOpen(false);
    setNewName('');
    setError(false);
  }

  const handleChange = (val: string) => {
    setNewName(val);
    setError(false);
  };

  function addTrailingSlash(text: string) {
    if (!text.endsWith('/')) {
      return text + '/';
    }
    return text;
  }

  const onClickRename = async () => {
    if (selectedFile && username && fetchedFiles) {
      let contentName = selectedFile.filename;
      let nameToBeValidated = newName;
      let feedbackMessage = '';
      const fileTypeToBeFiltered = [];

      if (selectedFile.type === S3ObjectType.USERS_FOLDER) {
        contentName = removeLastSlash(selectedFile.filename);
        nameToBeValidated = addTrailingSlash(newName);
        fileTypeToBeFiltered.push(S3ObjectType.USERS_FOLDER);

        feedbackMessage = 'A folder in this name already exists. Please choose another name.';
        setError(true);
      } else {
        contentName = removedExtensionsFilename(selectedFile?.filename);
        const newNameWithoutExtension = removedExtensionsFilename(newName);
        const extension = getExtensionFromFilename(selectedFile.filename);
        nameToBeValidated = `${newNameWithoutExtension}.${extension}`;
        fileTypeToBeFiltered.push(
          S3ObjectType.JSON_FILE,
          S3ObjectType.ARCHIVED_FILE,
          S3ObjectType.TEMPLATE_FILE,
          S3ObjectType.TEMPORARY_FILE,
        );
        feedbackMessage = 'A file in this name already exists. Please choose another name';
        setError(true);
      }

      const filesWithSameName = filterFilesByTypeAndName(
        fetchedFiles,
        fileTypeToBeFiltered,
        nameToBeValidated,
      );

      const body = {
        contentName: contentName,
        contentType: selectedFile.type,
        newContentName: removedExtensionsFilename(newName),
        rootPath: folderPath ?? '',
      };

      if (filesWithSameName.length > 0) {
        snackBar.error(feedbackMessage);
      } else {
        handleRename(username, body);
      }
    }
  };

  const handleRename = async (username: string, body: IRenameFileFolder) => {
    setLoading(true);
    setError(false);
    setButtonText('Updating..');
    try {
      await DataSheetApiService.renameFileFolder(username, body);
      snackBar.success('Successfully Renamed');
      await onFileRefetch();
      setSelectedFile(null);
    } catch (err: any) {
      if (err.response?.status === 409) {
        snackBar.error('Invalid Folder Name');
        setError(true);
      } else {
        snackBar.error('Sorry Something Went Wrong');
      }
    } finally {
      setLoading(false);
      setButtonText('Update');
    }
  };

  return (
    <DialogBox
      title="Rename"
      customHeight="220px"
      isOpen={open}
      onClose={handleClose}
      dialogBoxContent={
        <BoxContent>
          <TextField
            error={error}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            value={newName}
            onChange={(e) => handleChange(e.target.value)}
            autoFocus
            placeholder="New Name"
          />
          <Button
            variant="contained"
            sx={{ alignSelf: 'flex-end', mt: 2 }}
            onClick={() => onClickRename()}
            disabled={newName.length < 1 || loading}
          >
            {buttonText}
          </Button>
        </BoxContent>
      }
    />
  );
}
