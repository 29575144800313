import Alert from '@mui/material/Alert';
import { nameShortener } from '@/utils';
import { CloseIconButton } from '@/components/common/AttachmentStatus/CloseIconButton';
import { UploadableFile } from '@/components/dialogBoxes/OpenAttachmentDialog/OpenAttachmentDialog';

interface IAttachmentStatus {
  files: UploadableFile[];
  onClickClose: (index: number) => void;
  btnDisabled: boolean;
}

export const AttachmentStatus = ({ files, onClickClose, btnDisabled }: IAttachmentStatus) => {
  return (
    <>
      {files.map((file, index) => {
        const fileName = nameShortener(file.file.name);
        if (file.errors.length > 0) {
          return (
            <Alert
              severity="error"
              sx={{ mt: 1 }}
              key={file.id}
              action={
                <CloseIconButton onClick={() => onClickClose(index)} disabled={btnDisabled} />
              }
            >
              {fileName} - {file.errors[0].code}
            </Alert>
          );
        } else {
          return (
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              key={file.id}
              action={
                <CloseIconButton onClick={() => onClickClose(index)} disabled={btnDisabled} />
              }
            >
              {fileName} - Is Ready To Upload
            </Alert>
          );
        }
      })}
    </>
  );
};
