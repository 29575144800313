import FormControlLabel, { type FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import * as React from 'react';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.primary,
      '& + .MuiSwitch-track': {
        backgroundColor: '#f79b2a3b',
        opacity: 1,
        border: 0,
        color: theme.palette.primary,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    // color: '#D8D9E0',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#F1F3F4',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

type ThemeSwitchProps = {
  label: string;
  checked?: boolean;
  onChange?: ((event: React.SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined;
} & Omit<FormControlLabelProps, 'label' | 'checked' | 'onChange' | 'control' | 'sx'>;

export const ThemeSwitch = ({ label, checked, onChange, ...props }: ThemeSwitchProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        {...props}
        control={<IOSSwitch sx={{ m: 1 }} checked={checked ?? false} />}
        label={label}
        onChange={onChange}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
          },
        }}
      />
    </FormGroup>
  );
};
