import { Stack } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const PageContainer = ({ children }: Props): ReactElement => {
  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: '#F5F5F5',
      }}
    >
      {children}
    </Stack>
  );
};
