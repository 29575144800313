export function generateRandomNameWithExtension(filename: string) {
  const extension = filename.split('.').pop(); // get the extension from the filename
  const length = 8; // the length of the random name
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // the characters to choose from
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result + '.' + extension;
}
