import { makeClasses } from '@/utils';

export const classes = makeClasses({
  progressBar: {
    borderRadius: 50,
    background: 'none',
    width: '40%',
    marginBottom: 2,
    height: 5,
  },
});
