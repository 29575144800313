import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import {
  BudgetLevels,
  CostsToDateColumnIndexes,
  ctdToVisibleColumnMap,
  CurrencyColumnIndexes,
  currencyToVisibleColumnMap,
  FringesColumnIndexes,
  fringesToVisibleColumnMap,
  GlobalColumnIndexes,
  globalToVisibleColumnMap,
  GroupColumnIndexes,
  groupToVisibleColumnMap,
  L1ColumnIndexes,
  L2ColumnIndexes,
  L3ColumnIndexes,
  level1ToVisibleColumnMap,
  level2ToVisibleColumnMap,
  level3ToVisibleColumnMap,
  LocationColumnIndexes,
  locationToVisibleColumnMap,
  SelectedVisibleL1ColumnIndexes,
  SetColumnIndexes,
  setsToVisibleColumnMap,
  SheetNames,
  TabIndexes,
  UnitDescriptionColumnIndexes,
  unitDescriptionToVisibleColumnMap,
} from '@/enums';
import { splitFormulaByArithmeticOperations } from '@/utils';
import { getLevel2RequestedTypeValues } from '@/helpers/L2Helpers';

export type GlobalSearchResultType =
  | GlobalSearchBudgetSheetResultType
  | GlobalSearchGlobalResultType
  | GlobalSearchGroupsResultType
  | GlobalSearchFringesResultType
  | GlobalSearchSetsResultType
  | GlobalSearchCTDResultType
  | GlobalSearchLocationsResultType
  | GlobalSearchCurrencyResultType
  | GlobalSearchUnitDescriptionResultType;

export type GlobalSearchBudgetSheetResultType =
  | GlobalSearchLevel1ResultType
  | GlobalSearchLevel2ResultType
  | GlobalSearchLevel3ResultType;

export interface GlobalSearchLevel1ResultType {
  tabIndex: TabIndexes.BUDGET;
  level: BudgetLevels.FIRST_LEVEL;
  level1Id: string;
  level2Id: string;
  level3Id: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
  searchValue: string;
  account: string;
}

export interface GlobalSearchLevel2ResultType {
  tabIndex: TabIndexes.BUDGET;
  level: BudgetLevels.SECOND_LEVEL;
  level1Id: string;
  level2Id: string;
  level3Id: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
  searchValue: string;
  account: string;
}

export interface GlobalSearchLevel3ResultType {
  tabIndex: TabIndexes.BUDGET;
  level: BudgetLevels.THIRD_LEVEL;
  level1Id: string;
  level2Id: string;
  level3Id: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
  searchValue: string;
}

export interface GlobalSearchGlobalResultType {
  tabIndex: TabIndexes.GLOBALS;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchGroupsResultType {
  tabIndex: TabIndexes.GROUPS;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchFringesResultType {
  tabIndex: TabIndexes.FRINGES;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchSetsResultType {
  tabIndex: TabIndexes.SETS;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchCTDResultType {
  tabIndex: TabIndexes.CTD;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchLocationsResultType {
  tabIndex: TabIndexes.LOCATIONS;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchCurrencyResultType {
  tabIndex: TabIndexes.CURRENCY;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchUnitDescriptionResultType {
  tabIndex: TabIndexes.UNIT_DESC;
  searchValue: string;
  description: string;
  resultValue: string;
  row: number;
  column: number;
}

export interface GlobalSearchResultsSearchParams {
  currentTabIndex: TabIndexes;
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
  searchResults: GlobalSearchResultType[];
}

export type GlobalSearchResultsSearchFunction = (
  params: GlobalSearchResultsSearchParams,
) => GlobalSearchResultType[];

type HandleSearchType = (
  rowValues: CellValue[],
  keyword: string,
  searchResult: GlobalSearchResultType,
  searchResults: Array<GlobalSearchResultType>,
) => void;

const handleFormulaSearch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const formula = rowValues[columnIndex]?.toString() ?? '';
    if (!formula.toString().startsWith('=')) return;
    const formulaList: Array<string> = splitFormulaByArithmeticOperations(formula.toString());
    const lowercaseFormulaList = formulaList.map((item) => item.toLowerCase());
    const hasExactMatch = lowercaseFormulaList.includes(keyword.toLowerCase());
    if (hasExactMatch) {
      searchResult.resultValue = formula.toString();
      searchResults.push(searchResult);
    }
  };
};

const handleCodeSearch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const code = rowValues[columnIndex]?.toString() ?? '';
    const codeList: Array<string> = code.split(',');
    const lowercaseCodeList = codeList.map((item) => item.toLowerCase());
    const hasExactMatch = lowercaseCodeList.includes(keyword.toLowerCase());
    if (hasExactMatch) {
      searchResult.resultValue = code.toString();
      searchResults.push(searchResult);
    }
  };
};

const handleExactStringMatch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const value = rowValues[columnIndex];
    if (value && value.toString().toLowerCase() === keyword.toLowerCase()) {
      searchResult.resultValue = value.toString();
      searchResults.push(searchResult);
    }
  };
};

const handleStringSearch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const value = rowValues[columnIndex];
    if (value && value.toString().toLowerCase().includes(keyword.toLowerCase())) {
      searchResult.resultValue = value.toString();
      searchResults.push(searchResult);
    }
  };
};

const handleNumberMatch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const value = rowValues[columnIndex];
    if (typeof value !== 'undefined' && value !== null && value.toString() === keyword.toString()) {
      searchResult.resultValue = value.toString();
      searchResults.push(searchResult);
    }
  };
};

/**
 * TODO: This function should be removed after handle decimal places from budget options
 * [PB-727] - https://swiveltech.atlassian.net/browse/PB-727
 */
const handleNumberRoundMatch = (columnIndex: number, decimalPlaces = 0): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const rowValue = rowValues[columnIndex]?.toString() ?? 0;
    const convertedNumber = parseFloat(rowValue.toString());
    const roundedRowValue = convertedNumber.toFixed(decimalPlaces);
    const hasKeywordMatched = roundedRowValue.toString() === keyword.toString();
    if (hasKeywordMatched) {
      searchResult.resultValue = rowValue.toString();
      searchResults.push(searchResult);
    }
  };
};

const handleFringePercentageSearch = (columnIndex: number): HandleSearchType => {
  return (
    rowValues: CellValue[],
    keyword: string,
    searchResult: GlobalSearchResultType,
    searchResults: GlobalSearchResultType[],
  ): void => {
    const fringeValue = rowValues[columnIndex];
    if (fringeValue) {
      let fringePercentage = parseFloat(fringeValue.toString()) * 100;
      fringePercentage = parseFloat(fringePercentage.toFixed(2));
      if (fringePercentage === parseFloat(keyword)) {
        searchResult.resultValue = fringeValue.toString();
        searchResults.push(searchResult);
      }
    }
  };
};

const allowedLevel1SearchColumnIndexes = [
  L1ColumnIndexes.account,
  L1ColumnIndexes.description,
  L1ColumnIndexes.fringes,
  L1ColumnIndexes.total,
  L1ColumnIndexes.comparison,
  L1ColumnIndexes.variance,
];

const level1SearchHandlers: { [key in L1ColumnIndexes]?: Array<HandleSearchType> } = {
  [L1ColumnIndexes.account]: [handleExactStringMatch(L1ColumnIndexes.account)],
  [L1ColumnIndexes.description]: [handleStringSearch(L1ColumnIndexes.description)],
  [L1ColumnIndexes.fringes]: [handleNumberRoundMatch(L1ColumnIndexes.fringes)],
  [L1ColumnIndexes.total]: [handleNumberRoundMatch(L1ColumnIndexes.total)],
  [L1ColumnIndexes.comparison]: [handleNumberRoundMatch(L1ColumnIndexes.comparison)],
  [L1ColumnIndexes.variance]: [handleNumberRoundMatch(L1ColumnIndexes.variance)],
};

export type GlobalSearchBudgetSheetLevelsFunction =
  | GlobalSearchBudgetSheetLevel1Function
  | GlobalSearchBudgetSheetLevel2Function
  | GlobalSearchBudgetSheetLevel3Function;

export type GlobalSearchBudgetSheetLevelsParams =
  | GlobalSearchBudgetSheetLevel1Params
  | GlobalSearchBudgetSheetLevel2Params
  | GlobalSearchBudgetSheetLevel3Params;

export type GlobalSearchBudgetSheetLevel1Function = (
  params: GlobalSearchBudgetSheetLevel1Params,
) => GlobalSearchResultType[];

export interface GlobalSearchBudgetSheetLevel1Params {
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
}

export const getLevel1GlobalSearch: GlobalSearchBudgetSheetLevel1Function = ({
  keyword,
  valueSheet,
}) => {
  const level1SheetValues: CellValue[][] = valueSheet[SheetNames.L1];
  const level1SearchResults: Array<GlobalSearchLevel1ResultType> = [];

  for (const [rowNumber, rowValues] of level1SheetValues.entries()) {
    for (const columnNumber of allowedLevel1SearchColumnIndexes) {
      const level1SearchResult: GlobalSearchLevel1ResultType = {
        tabIndex: TabIndexes.BUDGET,
        searchValue: keyword,
        level: BudgetLevels.FIRST_LEVEL,
        level1Id: '',
        level2Id: '',
        level3Id: '',
        description: rowValues[L1ColumnIndexes.description]
          ? rowValues[L1ColumnIndexes.description].toString()
          : `Level 1 - ${rowValues[L1ColumnIndexes.id]?.toString()}`,
        resultValue: '',
        account: `${rowValues[L1ColumnIndexes.account]}`,
        row: rowNumber,
        column: level1ToVisibleColumnMap[columnNumber as SelectedVisibleL1ColumnIndexes],
      };
      const searchHandlers = level1SearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandler of searchHandlers) {
          searchHandler(rowValues, keyword, level1SearchResult, level1SearchResults);
        }
      }
    }
  }
  return level1SearchResults;
};

const allowedLevel2SearchColumnIndexes = [
  L2ColumnIndexes.account,
  L2ColumnIndexes.description,
  L2ColumnIndexes.fringes,
  L2ColumnIndexes.total,
  L2ColumnIndexes.comparison,
  L2ColumnIndexes.variance,
];

const level2SearchHandlers: { [key in L2ColumnIndexes]?: Array<HandleSearchType> } = {
  [L2ColumnIndexes.account]: [handleExactStringMatch(L2ColumnIndexes.account)],
  [L2ColumnIndexes.description]: [handleStringSearch(L2ColumnIndexes.description)],
  [L2ColumnIndexes.fringes]: [handleNumberRoundMatch(L2ColumnIndexes.fringes)],
  [L2ColumnIndexes.total]: [handleNumberRoundMatch(L2ColumnIndexes.total)],
  [L2ColumnIndexes.comparison]: [handleNumberRoundMatch(L2ColumnIndexes.comparison)],
  [L2ColumnIndexes.variance]: [handleNumberRoundMatch(L2ColumnIndexes.variance)],
};

export type GlobalSearchBudgetSheetLevel2Function = (
  params: GlobalSearchBudgetSheetLevel2Params,
) => GlobalSearchResultType[];

export interface GlobalSearchBudgetSheetLevel2Params {
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
  currentLevel1Ids?: string[];
}

export const getLevel2GlobalSearch: GlobalSearchBudgetSheetLevel2Function = ({
  keyword,
  valueSheet,
  currentLevel1Ids,
}) => {
  const level1Ids =
    currentLevel1Ids ?? valueSheet[SheetNames.L1].map((rowValues) => rowValues[L1ColumnIndexes.id]);
  const level2SearchResults: Array<GlobalSearchLevel2ResultType> = [];

  for (const level1Id of level1Ids) {
    if (!level1Id) continue;
    const level2SheetValues: CellValue[][] | undefined = valueSheet[`${SheetNames.L2}_${level1Id}`];
    if (!level2SheetValues) continue;

    for (const [rowNumber, rowValues] of level2SheetValues.entries()) {
      for (const columnNumber of allowedLevel2SearchColumnIndexes) {
        const level2SearchResult: GlobalSearchLevel2ResultType = {
          tabIndex: TabIndexes.BUDGET,
          searchValue: keyword,
          level: BudgetLevels.SECOND_LEVEL,
          level1Id: level1Id.toString(),
          level2Id: '',
          level3Id: '',
          description: rowValues[L2ColumnIndexes.description]
            ? rowValues[L2ColumnIndexes.description].toString()
            : `Level 2 - ${rowValues[L2ColumnIndexes.id]?.toString()}`,
          resultValue: '',
          account: `${rowValues[L2ColumnIndexes.account]}`,
          row: rowNumber,
          column: level2ToVisibleColumnMap[columnNumber],
        };
        const searchHandlers = level2SearchHandlers[columnNumber];

        if (searchHandlers) {
          for (const searchHandler of searchHandlers) {
            searchHandler(rowValues, keyword, level2SearchResult, level2SearchResults);
          }
        }
      }
    }
  }
  return level2SearchResults;
};

const getLevel1IdByLevel2Id = (
  level2Id: string,
  allValueSheetValues: Record<string, CellValue[][]>,
): string | undefined => {
  const level1SheetValues = allValueSheetValues[SheetNames.L1];
  const level1Ids = level1SheetValues.map((rowValues) => rowValues[L1ColumnIndexes.id]);

  const matchedLevel1Id = level1Ids.find((level1Id) => {
    if (!level1Id) return false;
    const level2SheetValues = allValueSheetValues[`${SheetNames.L2}_${level1Id}`];
    if (!level2SheetValues) return false;
    return level2SheetValues.some(
      (level2RowValues) => level2RowValues[L2ColumnIndexes.id] === level2Id,
    );
  });

  return matchedLevel1Id ? matchedLevel1Id.toString() : undefined;
};

const allowedLevel3SearchColumnIndexes = [
  L3ColumnIndexes.fringe,
  L3ColumnIndexes.fringeTotal,
  L3ColumnIndexes.fringes,
  L3ColumnIndexes.groups,
  L3ColumnIndexes.loc,
  L3ColumnIndexes.set,
  L3ColumnIndexes.description,
  L3ColumnIndexes.units,
  L3ColumnIndexes.desc,
  L3ColumnIndexes.x,
  L3ColumnIndexes.rate,
  L3ColumnIndexes.cu,
  L3ColumnIndexes.total,
  L3ColumnIndexes.comparison,
  L3ColumnIndexes.variance,
];

const level3SearchHandlers: { [key in L3ColumnIndexes]?: Array<HandleSearchType> } = {
  [L3ColumnIndexes.fringe]: [handleFringePercentageSearch(L3ColumnIndexes.fringe)],
  [L3ColumnIndexes.fringeTotal]: [handleNumberRoundMatch(L3ColumnIndexes.fringeTotal, 2)],
  [L3ColumnIndexes.fringes]: [handleCodeSearch(L3ColumnIndexes.fringes)],
  [L3ColumnIndexes.groups]: [handleCodeSearch(L3ColumnIndexes.groups)],
  [L3ColumnIndexes.loc]: [handleExactStringMatch(L3ColumnIndexes.loc)],
  [L3ColumnIndexes.set]: [handleExactStringMatch(L3ColumnIndexes.set)],
  [L3ColumnIndexes.description]: [handleStringSearch(L3ColumnIndexes.description)],
  [L3ColumnIndexes.units]: [
    handleFormulaSearch(L3ColumnIndexes.unitFormula),
    handleNumberMatch(L3ColumnIndexes.units),
  ],
  [L3ColumnIndexes.desc]: [handleExactStringMatch(L3ColumnIndexes.desc)],
  [L3ColumnIndexes.x]: [
    handleFormulaSearch(L3ColumnIndexes.xFormula),
    handleNumberMatch(L3ColumnIndexes.x),
  ],
  [L3ColumnIndexes.rate]: [
    handleFormulaSearch(L3ColumnIndexes.rateFormula),
    handleNumberMatch(L3ColumnIndexes.rate),
  ],
  [L3ColumnIndexes.cu]: [handleExactStringMatch(L3ColumnIndexes.cu)],
  [L3ColumnIndexes.total]: [handleNumberRoundMatch(L3ColumnIndexes.total)],
  [L3ColumnIndexes.comparison]: [handleNumberRoundMatch(L3ColumnIndexes.comparison)],
  [L3ColumnIndexes.variance]: [handleNumberRoundMatch(L3ColumnIndexes.variance)],
};

export type GlobalSearchBudgetSheetLevel3Function = (
  params: GlobalSearchBudgetSheetLevel3Params,
) => GlobalSearchResultType[];

export interface GlobalSearchBudgetSheetLevel3Params {
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
  currentLevel1Id?: string;
  currentLevel2Ids?: string[];
}

export const getLevel3GlobalSearch: GlobalSearchBudgetSheetLevel3Function = ({
  keyword,
  valueSheet,
  currentLevel1Id,
  currentLevel2Ids,
}) => {
  const level2Ids =
    currentLevel2Ids ?? getLevel2RequestedTypeValues<string>(L2ColumnIndexes.id, valueSheet);
  const level3SearchResults: Array<GlobalSearchLevel3ResultType> = [];

  for (const level2Id of level2Ids) {
    if (!level2Id) continue;
    const level3SheetValues: CellValue[][] | undefined = valueSheet[`${SheetNames.L3}_${level2Id}`];
    if (!level3SheetValues) continue;

    for (const [rowNumber, rowValues] of level3SheetValues.entries()) {
      for (const columnNumber of allowedLevel3SearchColumnIndexes) {
        const level1Id = currentLevel1Id ?? getLevel1IdByLevel2Id(level2Id.toString(), valueSheet);
        const level3SearchResult: GlobalSearchLevel3ResultType = {
          tabIndex: TabIndexes.BUDGET,
          searchValue: keyword,
          level: BudgetLevels.THIRD_LEVEL,
          level1Id: level1Id?.toString() ?? '',
          level2Id: level2Id.toString(),
          level3Id: rowValues[L3ColumnIndexes.id]?.toString() ?? '',
          description: rowValues[L3ColumnIndexes.description]
            ? rowValues[L3ColumnIndexes.description].toString()
            : `Level 3 - ${rowValues[L3ColumnIndexes.id]?.toString()}`,
          resultValue: '',
          row: rowNumber,
          column: level3ToVisibleColumnMap[columnNumber],
        } as GlobalSearchLevel3ResultType;
        const searchHandlers = level3SearchHandlers[columnNumber];

        if (searchHandlers) {
          for (const searchHandler of searchHandlers) {
            searchHandler(rowValues, keyword, level3SearchResult, level3SearchResults);
          }
        }
      }
    }
  }
  return level3SearchResults;
};

export interface GlobalSearchResultsBudgetSheetSearchParams
  extends GlobalSearchResultsSearchParams {
  currentTabIndex: TabIndexes.BUDGET;
  sheetLevel?: BudgetLevels;
}

export type GlobalSearchResultsSearchBudgetSheetFunction = (
  params: GlobalSearchResultsBudgetSheetSearchParams,
) => GlobalSearchResultType[];

export const getBudgetSheetSearchResults: GlobalSearchResultsSearchBudgetSheetFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
  sheetLevel,
}) => {
  if (TabIndexes.BUDGET !== currentTabIndex) return searchResults;
  const searchFunctionMap: Record<BudgetLevels, GlobalSearchBudgetSheetLevelsFunction> = {
    [BudgetLevels.FIRST_LEVEL]: getLevel1GlobalSearch,
    [BudgetLevels.SECOND_LEVEL]: getLevel2GlobalSearch,
    [BudgetLevels.THIRD_LEVEL]: getLevel3GlobalSearch,
  };

  let searchResultsClone = lodash.cloneDeep(searchResults);

  if (sheetLevel !== undefined) {
    const searchFunction = searchFunctionMap[sheetLevel];
    if (searchFunction) {
      const params: GlobalSearchBudgetSheetLevelsParams = {
        keyword,
        valueSheet,
      };
      searchResultsClone = searchResultsClone.concat(searchFunction(params));
    } else {
      // Handle unsupported tab index
      console.error(
        "[getBudgetSheetSearchResults] Doesn't support provided tab index: ",
        sheetLevel,
      );
    }
  } else {
    // Loop through all tab indexes and trigger search results for each tab
    for (const sheetLevel in searchFunctionMap) {
      if (Object.prototype.hasOwnProperty.call(searchFunctionMap, sheetLevel)) {
        const sheetLevelIndex = sheetLevel as BudgetLevels;
        const searchFunction = searchFunctionMap[sheetLevelIndex];
        if (searchFunction) {
          const params: GlobalSearchBudgetSheetLevelsParams = {
            keyword,
            valueSheet,
          };
          searchResultsClone = searchResultsClone.concat(searchFunction(params));
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedGlobalsSearchColumnIndexes = [
  GlobalColumnIndexes.category,
  GlobalColumnIndexes.code,
  GlobalColumnIndexes.description,
  GlobalColumnIndexes.calculation,
  GlobalColumnIndexes.units,
  GlobalColumnIndexes.usage,
];

const globalSearchHandlers: { [key in GlobalColumnIndexes]?: Array<HandleSearchType> } = {
  [GlobalColumnIndexes.category]: [handleStringSearch(GlobalColumnIndexes.category)],
  [GlobalColumnIndexes.code]: [handleExactStringMatch(GlobalColumnIndexes.code)],
  [GlobalColumnIndexes.description]: [handleStringSearch(GlobalColumnIndexes.description)],
  [GlobalColumnIndexes.calculation]: [
    handleFormulaSearch(GlobalColumnIndexes.calculation),
    handleExactStringMatch(GlobalColumnIndexes.calculation),
    handleNumberMatch(GlobalColumnIndexes.calculation),
  ],
  [GlobalColumnIndexes.units]: [
    handleFormulaSearch(GlobalColumnIndexes.unitsFormula),
    handleExactStringMatch(GlobalColumnIndexes.unitsFormula),
    handleNumberMatch(GlobalColumnIndexes.units),
  ],
  [GlobalColumnIndexes.usage]: [handleNumberRoundMatch(GlobalColumnIndexes.usage)],
};

export const getGlobalSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.GLOBALS !== currentTabIndex)
    return searchResults as Array<GlobalSearchGlobalResultType>;

  const searchResultsClone = lodash.cloneDeep(searchResults as Array<GlobalSearchGlobalResultType>);
  const globalSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.GLOBALS];

  for (const [rowNumber, rowValues] of globalSheetValues.entries()) {
    for (const columnNumber of allowedGlobalsSearchColumnIndexes) {
      const globalSearchResult: GlobalSearchGlobalResultType = {
        tabIndex: TabIndexes.GLOBALS,
        searchValue: keyword,
        description: rowValues[GlobalColumnIndexes.description]
          ? rowValues[GlobalColumnIndexes.description].toString()
          : `Globals - ${rowValues[GlobalColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: globalToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = globalSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, globalSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedGroupsSearchColumnIndexes = [
  GroupColumnIndexes.category,
  GroupColumnIndexes.code,
  GroupColumnIndexes.description,
  GroupColumnIndexes.suppress,
  GroupColumnIndexes.cap,
  GroupColumnIndexes.cu,
  GroupColumnIndexes.color,
  GroupColumnIndexes.includeFringe,
  GroupColumnIndexes.total,
  GroupColumnIndexes.usage,
];

const groupSearchHandlers: { [key in GroupColumnIndexes]?: Array<HandleSearchType> } = {
  [GroupColumnIndexes.category]: [handleStringSearch(GroupColumnIndexes.category)],
  [GroupColumnIndexes.code]: [handleExactStringMatch(GroupColumnIndexes.code)],
  [GroupColumnIndexes.description]: [handleStringSearch(GroupColumnIndexes.description)],
  [GroupColumnIndexes.suppress]: [handleExactStringMatch(GroupColumnIndexes.suppress)],
  [GroupColumnIndexes.cu]: [handleStringSearch(GroupColumnIndexes.cu)],
  [GroupColumnIndexes.includeFringe]: [handleExactStringMatch(GroupColumnIndexes.includeFringe)],
  [GroupColumnIndexes.cap]: [handleNumberMatch(GroupColumnIndexes.cap)],
  [GroupColumnIndexes.total]: [handleNumberRoundMatch(GroupColumnIndexes.total)],
  [GroupColumnIndexes.usage]: [handleNumberRoundMatch(GroupColumnIndexes.usage)],
};

export const getGroupSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.GROUPS !== currentTabIndex)
    return searchResults as Array<GlobalSearchGroupsResultType>;

  const searchResultsClone = lodash.cloneDeep(searchResults as Array<GlobalSearchGroupsResultType>);
  const groupSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.GROUPS];

  for (const [rowNumber, rowValues] of groupSheetValues.entries()) {
    for (const columnNumber of allowedGroupsSearchColumnIndexes) {
      const groupSearchResult: GlobalSearchGroupsResultType = {
        tabIndex: TabIndexes.GROUPS,
        searchValue: keyword,
        description: rowValues[GroupColumnIndexes.description]
          ? rowValues[GroupColumnIndexes.description].toString()
          : `Groups - ${rowValues[GroupColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: groupToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = groupSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, groupSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedFringesSearchColumnIndexes = [
  FringesColumnIndexes.category,
  FringesColumnIndexes.code,
  FringesColumnIndexes.description,
  FringesColumnIndexes.rate,
  FringesColumnIndexes.unitDesc,
  FringesColumnIndexes.cap,
  FringesColumnIndexes.cu,
  FringesColumnIndexes.total,
  FringesColumnIndexes.usage,
];

const fringeSearchHandlers: { [key in FringesColumnIndexes]?: Array<HandleSearchType> } = {
  [FringesColumnIndexes.category]: [handleStringSearch(FringesColumnIndexes.category)],
  [FringesColumnIndexes.code]: [handleExactStringMatch(FringesColumnIndexes.code)],
  [FringesColumnIndexes.description]: [handleStringSearch(FringesColumnIndexes.description)],
  [FringesColumnIndexes.unitDesc]: [handleExactStringMatch(FringesColumnIndexes.unitDesc)],
  [FringesColumnIndexes.cu]: [handleExactStringMatch(FringesColumnIndexes.cu)],
  [FringesColumnIndexes.cap]: [handleNumberMatch(FringesColumnIndexes.cap)],
  [FringesColumnIndexes.rate]: [handleNumberMatch(FringesColumnIndexes.rate)],
  [FringesColumnIndexes.total]: [handleNumberRoundMatch(FringesColumnIndexes.total)],
  [FringesColumnIndexes.usage]: [handleNumberRoundMatch(FringesColumnIndexes.usage)],
};

export const getFringeSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.FRINGES !== currentTabIndex)
    return searchResults as Array<GlobalSearchFringesResultType>;

  const searchResultsClone = lodash.cloneDeep(
    searchResults as Array<GlobalSearchFringesResultType>,
  );
  const fringeSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.FRINGES];

  for (const [rowNumber, rowValues] of fringeSheetValues.entries()) {
    for (const columnNumber of allowedFringesSearchColumnIndexes) {
      const fringeSearchResult: GlobalSearchFringesResultType = {
        tabIndex: TabIndexes.FRINGES,
        searchValue: keyword,
        description: rowValues[FringesColumnIndexes.description]
          ? rowValues[FringesColumnIndexes.description].toString()
          : `Fringes - ${rowValues[FringesColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: fringesToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = fringeSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, fringeSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedSetsSearchColumnIndexes = [
  SetColumnIndexes.category,
  SetColumnIndexes.code,
  SetColumnIndexes.description,
  SetColumnIndexes.includeFringe,
  SetColumnIndexes.total,
  SetColumnIndexes.usage,
];

const setSearchHandlers: { [key in SetColumnIndexes]?: Array<HandleSearchType> } = {
  [SetColumnIndexes.category]: [handleStringSearch(SetColumnIndexes.category)],
  [SetColumnIndexes.code]: [handleExactStringMatch(SetColumnIndexes.code)],
  [SetColumnIndexes.description]: [handleStringSearch(SetColumnIndexes.description)],
  [SetColumnIndexes.includeFringe]: [handleExactStringMatch(SetColumnIndexes.includeFringe)],
  [SetColumnIndexes.total]: [handleNumberRoundMatch(SetColumnIndexes.total)],
  [SetColumnIndexes.usage]: [handleNumberRoundMatch(SetColumnIndexes.usage)],
};

export const getSetSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.SETS !== currentTabIndex)
    return searchResults as Array<GlobalSearchSetsResultType>;

  const searchResultsClone = lodash.cloneDeep(searchResults as Array<GlobalSearchSetsResultType>);
  const setSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.SETS];

  for (const [rowNumber, rowValues] of setSheetValues.entries()) {
    for (const columnNumber of allowedSetsSearchColumnIndexes) {
      const setSearchResult: GlobalSearchSetsResultType = {
        tabIndex: TabIndexes.SETS,
        searchValue: keyword,
        description: rowValues[SetColumnIndexes.description]
          ? rowValues[SetColumnIndexes.description].toString()
          : `Sets - ${rowValues[SetColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: setsToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = setSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, setSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedCTDSearchColumnIndexes = [
  CostsToDateColumnIndexes.category,
  CostsToDateColumnIndexes.code,
  CostsToDateColumnIndexes.description,
  CostsToDateColumnIndexes.units,
  CostsToDateColumnIndexes.total,
  CostsToDateColumnIndexes.usage,
];

const ctdSearchHandlers: { [key in CostsToDateColumnIndexes]?: Array<HandleSearchType> } = {
  [CostsToDateColumnIndexes.category]: [handleStringSearch(CostsToDateColumnIndexes.category)],
  [CostsToDateColumnIndexes.code]: [handleExactStringMatch(CostsToDateColumnIndexes.code)],
  [CostsToDateColumnIndexes.description]: [
    handleStringSearch(CostsToDateColumnIndexes.description),
  ],
  [CostsToDateColumnIndexes.units]: [handleNumberMatch(CostsToDateColumnIndexes.units)],
  [CostsToDateColumnIndexes.total]: [handleNumberRoundMatch(CostsToDateColumnIndexes.total)],
  [CostsToDateColumnIndexes.usage]: [handleNumberRoundMatch(CostsToDateColumnIndexes.usage)],
};

export const getCTDSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.CTD !== currentTabIndex) return searchResults as Array<GlobalSearchCTDResultType>;

  const searchResultsClone = lodash.cloneDeep(searchResults as Array<GlobalSearchCTDResultType>);
  const ctdSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.COSTS_TO_DATE];

  for (const [rowNumber, rowValues] of ctdSheetValues.entries()) {
    for (const columnNumber of allowedCTDSearchColumnIndexes) {
      const ctdSearchResult: GlobalSearchCTDResultType = {
        tabIndex: TabIndexes.CTD,
        searchValue: keyword,
        description: rowValues[CostsToDateColumnIndexes.description]
          ? rowValues[CostsToDateColumnIndexes.description].toString()
          : `Costs To Date - ${rowValues[CostsToDateColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: ctdToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = ctdSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, ctdSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedLocationsSearchColumnIndexes = [
  LocationColumnIndexes.category,
  LocationColumnIndexes.code,
  LocationColumnIndexes.description,
  LocationColumnIndexes.includeFringe,
  LocationColumnIndexes.total,
  LocationColumnIndexes.usage,
];

const locationSearchHandlers: { [key in LocationColumnIndexes]?: Array<HandleSearchType> } = {
  [LocationColumnIndexes.category]: [handleStringSearch(LocationColumnIndexes.category)],
  [LocationColumnIndexes.code]: [handleExactStringMatch(LocationColumnIndexes.code)],
  [LocationColumnIndexes.description]: [handleStringSearch(LocationColumnIndexes.description)],
  [LocationColumnIndexes.includeFringe]: [
    handleExactStringMatch(LocationColumnIndexes.includeFringe),
  ],
  [LocationColumnIndexes.total]: [handleNumberRoundMatch(LocationColumnIndexes.total)],
  [LocationColumnIndexes.usage]: [handleNumberRoundMatch(LocationColumnIndexes.usage)],
};

export const getLocationSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.LOCATIONS !== currentTabIndex)
    return searchResults as Array<GlobalSearchLocationsResultType>;

  const searchResultsClone = lodash.cloneDeep(
    searchResults as Array<GlobalSearchLocationsResultType>,
  );
  const locationSheetValues: CellValue[][] | undefined = valueSheet[SheetNames.LOCATIONS];

  for (const [rowNumber, rowValues] of locationSheetValues.entries()) {
    for (const columnNumber of allowedLocationsSearchColumnIndexes) {
      const locationSearchResult: GlobalSearchLocationsResultType = {
        tabIndex: TabIndexes.LOCATIONS,
        searchValue: keyword,
        description: rowValues[LocationColumnIndexes.description]
          ? rowValues[LocationColumnIndexes.description].toString()
          : `Locations - ${rowValues[LocationColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: locationToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = locationSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, locationSearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedCurrencySearchColumnIndexes = [
  CurrencyColumnIndexes.category,
  CurrencyColumnIndexes.code,
  CurrencyColumnIndexes.description,
  CurrencyColumnIndexes.rate,
  CurrencyColumnIndexes.default,
  CurrencyColumnIndexes.includeFringe,
  CurrencyColumnIndexes.total,
  CurrencyColumnIndexes.usage,
];

const currencySearchHandlers: { [key in CurrencyColumnIndexes]?: Array<HandleSearchType> } = {
  [CurrencyColumnIndexes.category]: [handleStringSearch(CurrencyColumnIndexes.category)],
  [CurrencyColumnIndexes.code]: [handleExactStringMatch(CurrencyColumnIndexes.code)],
  [CurrencyColumnIndexes.description]: [handleStringSearch(CurrencyColumnIndexes.description)],
  [CurrencyColumnIndexes.rate]: [handleNumberMatch(CurrencyColumnIndexes.rate)],
  [CurrencyColumnIndexes.default]: [handleExactStringMatch(CurrencyColumnIndexes.default)],
  [CurrencyColumnIndexes.includeFringe]: [
    handleExactStringMatch(CurrencyColumnIndexes.includeFringe),
  ],
  [CurrencyColumnIndexes.total]: [handleNumberRoundMatch(CurrencyColumnIndexes.total)],
  [CurrencyColumnIndexes.usage]: [handleNumberRoundMatch(CurrencyColumnIndexes.usage)],
};

export const getCurrencySheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.CURRENCY !== currentTabIndex)
    return searchResults as Array<GlobalSearchCurrencyResultType>;

  const searchResultsClone = lodash.cloneDeep(
    searchResults as Array<GlobalSearchCurrencyResultType>,
  );
  const currencySheetValues: CellValue[][] | undefined = valueSheet[SheetNames.CURRENCY];

  for (const [rowNumber, rowValues] of currencySheetValues.entries()) {
    for (const columnNumber of allowedCurrencySearchColumnIndexes) {
      const currencySearchResult: GlobalSearchCurrencyResultType = {
        tabIndex: TabIndexes.CURRENCY,
        searchValue: keyword,
        description: rowValues[CurrencyColumnIndexes.description]
          ? rowValues[CurrencyColumnIndexes.description].toString()
          : `Currency - ${rowValues[CurrencyColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: currencyToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = currencySearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(rowValues, keyword, currencySearchResult, searchResultsClone);
        }
      }
    }
  }
  return searchResultsClone;
};

const allowedUnitDescriptionSearchColumnIndexes = [
  UnitDescriptionColumnIndexes.category,
  UnitDescriptionColumnIndexes.code,
  UnitDescriptionColumnIndexes.description,
  UnitDescriptionColumnIndexes.units,
  UnitDescriptionColumnIndexes.usage,
];

const unitDescriptionSearchHandlers: {
  [key in UnitDescriptionColumnIndexes]?: Array<HandleSearchType>;
} = {
  [UnitDescriptionColumnIndexes.category]: [
    handleStringSearch(UnitDescriptionColumnIndexes.category),
  ],
  [UnitDescriptionColumnIndexes.code]: [handleExactStringMatch(UnitDescriptionColumnIndexes.code)],
  [UnitDescriptionColumnIndexes.description]: [
    handleStringSearch(UnitDescriptionColumnIndexes.description),
  ],
  [UnitDescriptionColumnIndexes.units]: [
    handleNumberRoundMatch(UnitDescriptionColumnIndexes.units),
  ],
  [UnitDescriptionColumnIndexes.usage]: [
    handleNumberRoundMatch(UnitDescriptionColumnIndexes.usage),
  ],
};

export const getUnitDescriptionSheetSearchResults: GlobalSearchResultsSearchFunction = ({
  currentTabIndex,
  keyword,
  valueSheet,
  searchResults,
}) => {
  if (TabIndexes.UNIT_DESC !== currentTabIndex)
    return searchResults as Array<GlobalSearchUnitDescriptionResultType>;

  const searchResultsClone = lodash.cloneDeep(
    searchResults as Array<GlobalSearchUnitDescriptionResultType>,
  );
  const unitDescriptionSheetValues: CellValue[][] | undefined =
    valueSheet[SheetNames.UNIT_DESCRIPTIONS];

  for (const [rowNumber, rowValues] of unitDescriptionSheetValues.entries()) {
    for (const columnNumber of allowedUnitDescriptionSearchColumnIndexes) {
      const unitDescriptionSearchResult: GlobalSearchUnitDescriptionResultType = {
        tabIndex: TabIndexes.UNIT_DESC,
        searchValue: keyword,
        description: rowValues[UnitDescriptionColumnIndexes.description]
          ? rowValues[UnitDescriptionColumnIndexes.description].toString()
          : `Unit Description - ${rowValues[UnitDescriptionColumnIndexes.id]?.toString()}`,
        resultValue: '',
        row: rowNumber,
        column: unitDescriptionToVisibleColumnMap[columnNumber] ?? -1,
      };
      const searchHandlers = unitDescriptionSearchHandlers[columnNumber];

      if (searchHandlers) {
        for (const searchHandlerFunction of searchHandlers) {
          searchHandlerFunction(
            rowValues,
            keyword,
            unitDescriptionSearchResult,
            searchResultsClone,
          );
        }
      }
    }
  }
  return searchResultsClone;
};

export type GlobalSearchFunctionMap = Record<
  TabIndexes,
  GlobalSearchResultsSearchFunction | GlobalSearchResultsSearchBudgetSheetFunction | undefined
>;

const searchFunctionMap: GlobalSearchFunctionMap = {
  [TabIndexes.BUDGET]: getBudgetSheetSearchResults,
//  [TabIndexes.CASHFLOW]: undefined,
//  [TabIndexes.CAST_MANAGER]: undefined,
  [TabIndexes.GLOBALS]: getGlobalSheetSearchResults,
  [TabIndexes.GROUPS]: getGroupSheetSearchResults,
  [TabIndexes.FRINGES]: getFringeSheetSearchResults,
  [TabIndexes.SETS]: getSetSheetSearchResults,
  [TabIndexes.CTD]: getCTDSheetSearchResults,
  [TabIndexes.LOCATIONS]: getLocationSheetSearchResults,
  [TabIndexes.CURRENCY]: getCurrencySheetSearchResults,
  [TabIndexes.UNIT_DESC]: getUnitDescriptionSheetSearchResults,
};

export type GetGlobalSearchResultsByTabIndexFunction = (
  params: GetGlobalSearchResultsByTabIndexParams,
) => GlobalSearchResultType[];

export interface GetGlobalSearchResultsByTabIndexParams {
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
  tabIndexToSearch: TabIndexes;
  sheetLevel?: BudgetLevels;
}

export const getGlobalSearchResultsByTabIndex: GetGlobalSearchResultsByTabIndexFunction = ({
  keyword,
  valueSheet,
  tabIndexToSearch,
  sheetLevel,
}) => {
  let searchResults: GlobalSearchResultType[] = [];
  const searchFunction = searchFunctionMap[tabIndexToSearch];
  if (!searchFunction) return searchResults;

  if (tabIndexToSearch === TabIndexes.BUDGET) {
    const params: GlobalSearchResultsBudgetSheetSearchParams = {
      currentTabIndex: TabIndexes.BUDGET,
      keyword,
      valueSheet,
      searchResults,
      sheetLevel,
    };
    searchResults = (searchFunction as GlobalSearchResultsSearchBudgetSheetFunction)(params);
  } else {
    const params: GlobalSearchResultsSearchParams = {
      currentTabIndex: tabIndexToSearch,
      keyword,
      valueSheet,
      searchResults,
    };
    searchResults = (searchFunction as GlobalSearchResultsSearchFunction)(params);
  }

  return searchResults;
};

export interface RetrieveSearchValuesFromAllSheetsParams {
  keyword: string;
  valueSheet: Record<string, CellValue[][]>;
}

export type RetrieveSearchValuesFromAllSheetsFunction = (
  params: RetrieveSearchValuesFromAllSheetsParams,
) => GlobalSearchResultType[];

export const retrieveSearchValuesFromAllSheets: RetrieveSearchValuesFromAllSheetsFunction = ({
  keyword,
  valueSheet,
}) => {
  let searchResults: GlobalSearchResultType[] = [];

  // Loop through all tab indexes and trigger search results for each tab
  for (const tabIndex in searchFunctionMap) {
    if (Object.prototype.hasOwnProperty.call(searchFunctionMap, tabIndex)) {
      const tabEnumIndex = Number(tabIndex) as TabIndexes;
      const searchFunction = searchFunctionMap[tabEnumIndex];
      if (searchFunction) {
        if (tabEnumIndex === TabIndexes.BUDGET) {
          const params: GlobalSearchResultsBudgetSheetSearchParams = {
            currentTabIndex: TabIndexes.BUDGET,
            keyword,
            valueSheet,
            searchResults,
          };
          searchResults = (searchFunction as GlobalSearchResultsSearchBudgetSheetFunction)(params);
        } else {
          const params: GlobalSearchResultsSearchParams = {
            currentTabIndex: tabEnumIndex,
            keyword,
            valueSheet,
            searchResults,
          };

          searchResults = (searchFunction as GlobalSearchResultsSearchFunction)(params);
        }
      }
    }
  }
  return searchResults;
};
export type ISearchQueryResult = {
  row: number;
  col: number;
  data: any;
};

export type ISearchQueryResultGroupByRow = {
  [key in number]: ISearchQueryResult;
};

export const updateSearchQueryResultCellStyles = (
  TD: HTMLTableCellElement,
  row: number,
  column: number,
  searchResults: Array<GlobalSearchResultType> | undefined,
): HTMLTableCellElement => {
  if (!searchResults || searchResults.length === 0) {
    TD.style.backgroundColor = '';
    TD.style.color = '';
    return TD;
  }

  searchResults.forEach((searchResult) => {
    if (searchResult.row === row && searchResult.column === column) {
      TD.style.backgroundColor = '#FCEDD9';
      TD.style.color = '#583707';
    }
  });

  return TD;
};
