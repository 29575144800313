import styled from '@emotion/styled';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LinkProps, SxProps, Theme } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface BackLinkProps extends LinkProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  arrowLink: boolean;
  path: string;
  color?: string;
  textDecoration?: string;
  onClick?: () => void;
}

const StyledLink = styled(Link)<{ color?: string; textDecoration: string }>`
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.textDecoration};
  margin-bottom: 20px;
  font-family: 'Hellix';
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 5px;

  &:hover {
    text-decoration: none;
  }
`;

export const FormLink = ({
  children,
  arrowLink,
  color,
  textDecoration = 'none',
  path,
  onClick,
}: BackLinkProps): ReactElement => (
  <StyledLink to={path} color={color} textDecoration={textDecoration} onClick={onClick}>
    {arrowLink ? <KeyboardBackspaceIcon /> : null}
    {children}
  </StyledLink>
);
