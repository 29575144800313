import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { LocalStorageVariables } from '@/enums';
import { useFetchFiles } from '@/hooks';
import { useToaster } from '@/components/common/Toaster';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import DataSheetApiService from '@/services/DataSheetApiService';

interface IAddFolderDialogBox {
  open: boolean;
  setOpen: (open: boolean) => void;
  onFileRefetch: ReturnType<typeof useFetchFiles>['refetch'];
  folderPath?: string;
}

const BoxContent = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-top: 40px;
  padding: 2px;
`;

export function AddFolderDialogBox({
  open,
  setOpen,
  folderPath,
  onFileRefetch,
}: IAddFolderDialogBox) {
  const [folderName, setFolderName] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('Create Folder');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const snackBar = useToaster();

  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  function handleClose() {
    setOpen(false);
    setFolderName('');
    setError(false);
  }

  const handleChange = (value: string) => {
    setError(false);
    setFolderName(value);
  };

  async function handleAddFolder() {
    let body = { foldername: folderName };

    if (folderPath) {
      const foldername = folderPath.concat(folderName);
      body = { foldername };
    }

    if (username) {
      try {
        setLoading(true);
        setButtonText('Creating Folder...');
        await DataSheetApiService.addFolder(body);
        handleClose();
        snackBar.success('Folder created successfully');
        await onFileRefetch();
      } catch (err: any) {
        if (err.response?.status === 409) {
          setError(true);
          snackBar.error('Invalid folder name');
        } else {
          snackBar.error('Sorry something went wrong');
        }
      } finally {
        setLoading(false);
        setButtonText('Create Folder');
      }
    }
  }

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    const handleArrowKeys = (event: KeyboardEvent) => {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleArrowKeys);
    }

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [open]);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <DialogBox
      title="Add Folder"
      customHeight="160px"
      isOpen={open}
      onClose={handleClose}
      dialogBoxContent={
        <BoxContent>
          <TextField
            inputRef={inputRef}
            error={error}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            value={folderName}
            onChange={(e) => handleChange(e.target.value)}
            autoFocus
            placeholder="File Name"
            onKeyDown={handleInputKeyDown}
          />
          <Button
            variant="contained"
            sx={{ alignSelf: 'flex-end', mt: 2 }}
            onClick={handleAddFolder}
            disabled={folderName.length < 1 || loading}
          >
            {buttonText}
          </Button>
        </BoxContent>
      }
    />
  );
}
