// Exported arrow function to retrieve the requested token from local storage
export const retrieveTokenFromLocalStorage = (tokenType: string): string | null => {
  // Check if local storage is supported by the browser
  if (typeof Storage !== 'undefined') {
    let requestedToken: string | null = null;

    // Iterate through all the keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key is not null and matches the pattern for the requested token type
      if (key && key.includes('CognitoIdentityServiceProvider') && key.includes(tokenType)) {
        requestedToken = localStorage.getItem(key);
        break;
      }
    }

    return requestedToken;
  } else {
    // Local storage is not supported
    console.log('Local storage is not supported by this browser');
    return null;
  }
};
