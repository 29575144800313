import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
  checked?: boolean;
  value?: boolean;
  label: ReactNode;
  onChange?: (e: boolean) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  name?: string;
  required?: boolean;
}

export const CheckboxField = ({
  name,
  value,
  checked,
  label,
  onChange,
  disabled,
  sx,
  required,
}: Props): ReactElement => (
  <FormControlLabel
    label={label}
    sx={sx}
    disabled={disabled}
    control={
      <Checkbox
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange && onChange(e.target.checked)}
        required={required}
      />
    }
  />
);
