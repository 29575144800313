import IConfig from '@/interfaces/IConfig';
import { config as dev } from './dev';
import { config as prod } from './prod';
import { config as qa } from './qa';
import { config as uat } from './uat';

const environment = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'DEV';
    case 'dev.hollywoodbudgets.com':
      return 'DEV';
    case 'qa-budgets.pivotbiz.com':
      return 'QA';
    case 'uat.hollywoodbudgets.com':
      return 'UAT';
    case 'app.hollywoodbudgets.com':
      return 'PROD';
  }
};

export const Config: IConfig = (() => {
  const env = environment();
  console.log('ENV', env);

  switch (env) {
    case 'QA':
      return qa;
    case 'UAT':
      return uat;
    case 'PROD':
      return prod;
    default:
      return dev;
  }
})();
