import { L1ColumnAlias, L2ColumnAlias } from '@/enums';
import { IDataSheetConfigs, IL1Data, IL1DataSheet, IL2DataSheet } from '@/interfaces/IDataSheet';
import { IL1, IL1Sheet } from '@/interfaces/IFormulaSheet';
import { IMeta } from '@/interfaces/masterDataTypes';

const getRowTypeIndexes = (level1Data: IL1Data[], rowType: string) => {
  return level1Data.reduce((acc: number[], curr: IL1Data, index: number) => {
    if (curr.rowType === rowType) {
      acc.push(index);
    }
    return acc;
  }, []);
};

const getPrevIndexes = (index: number, rowTypeIndex: number[]) => {
  const prevIndex = rowTypeIndex.findIndex((i) => i === index - 1);
  if (prevIndex === -1) {
    return -1;
  } else {
    return rowTypeIndex[prevIndex - 1] + 2;
  }
};

export const transformL1Sheet = (
  l1DataSheet: IL1DataSheet,
  l2DataSheet: IL2DataSheet,
  metaData: IMeta,
  configData: IDataSheetConfigs,
): IL1Sheet => {
  const fringeCalc = metaData?.fringes?.calc || 'account';

  const subTotalRowsIndex = getRowTypeIndexes(l1DataSheet, 'S');
  const fsRowsIndex = getRowTypeIndexes(l1DataSheet, 'FS');

  const formulaL1Sheet: IL1Sheet = l1DataSheet?.map((l1Data: IL1Data, li: number) => {
    const index: number = li + 1;
    const prevIndex = index - 1;
    const prevSubTotalIndex = getPrevIndexes(index, subTotalRowsIndex);
    const prevFSIndex = getPrevIndexes(index, fsRowsIndex);
    const startIndex = prevIndex === subTotalRowsIndex[0] ? 1 : prevSubTotalIndex;
    const fringeStartIndex = prevIndex === fsRowsIndex[0] ? 1 : prevFSIndex;

    const l1RowType = L1ColumnAlias.RowType;
    const l1TotalColumn = L1ColumnAlias.Total;
    const l1ComparisonColumn = L1ColumnAlias.Comparison;
    const l1VarianceColumn = L1ColumnAlias.Variance;
    const l2RowType = L2ColumnAlias.RowType;
    const l2TotalColumn = L2ColumnAlias.Total;
    const l2ComparisonColumn = L2ColumnAlias.Comparison;

    const subTotal = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${l1TotalColumn}${startIndex}:${l1TotalColumn}${prevIndex})`;
    const subTotalWithFringes = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${l1TotalColumn}${startIndex}:${l1TotalColumn}${prevIndex})+SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${L1ColumnAlias.Fringes}${startIndex}:${L1ColumnAlias.Fringes}${prevIndex})`;
    const grandTotal = `=SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"D",${l1TotalColumn}0:${l1TotalColumn}${prevIndex})`;
    const grandTotalWithFringes = `=SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"D",${l1TotalColumn}0:${l1TotalColumn}${prevIndex})+SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"FS",${L1ColumnAlias.Total}0:${L1ColumnAlias.Total}${prevIndex})`;
    const grandComparison = `=SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"D",${l1ComparisonColumn}0:${l1ComparisonColumn}${prevIndex})`;
    const grandComparisonWithFringes = `=SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"D",${l1ComparisonColumn}0:${l1ComparisonColumn}${prevIndex})+SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"FS",${L1ColumnAlias.Comparison}0:${L1ColumnAlias.Comparison}${prevIndex})`;
    // const grandVariance = `=SUMIF(${l1RowType}0:${l1RowType}${prevIndex},"D",${l1VarianceColumn}0:${l1VarianceColumn}${prevIndex})`;
    // const varianceTotal = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${l1VarianceColumn}${startIndex}:${l1VarianceColumn}${prevIndex})`;
    const comparisonTotal = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${l1ComparisonColumn}${startIndex}:${l1ComparisonColumn}${prevIndex})`;
    const comparisonTotalWithFringes = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${l1ComparisonColumn}${startIndex}:${l1ComparisonColumn}${prevIndex})+SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${L1ColumnAlias.FringeComparison}${startIndex}:${L1ColumnAlias.FringeComparison}${prevIndex})`;
    const subFringeTotal = `=SUMIF(${l1RowType}${startIndex}:${l1RowType}${prevIndex},"D",${L1ColumnAlias.Fringes}${startIndex}:${L1ColumnAlias.Fringes}${prevIndex})`;
    const subFringesTotalForFS = `=SUMIF(${l1RowType}${fringeStartIndex}:${l1RowType}${prevIndex},"D",${L1ColumnAlias.Fringes}${fringeStartIndex}:${L1ColumnAlias.Fringes}${prevIndex})`;
    const subFringesComparison = `=SUMIF(${l1RowType}${fringeStartIndex}:${l1RowType}${prevIndex},"D",${L1ColumnAlias.FringeComparison}${fringeStartIndex}:${L1ColumnAlias.FringeComparison}${prevIndex})`;

    const isNegativeVariance = configData?.varianceCalc === 'under';
    const sign = isNegativeVariance ? 1 : -1;
    const variance = `=ABS(${l1TotalColumn}${index}-${l1ComparisonColumn}${index})*IF(${l1TotalColumn}${index}>${l1ComparisonColumn}${index}, ${sign}, ${-sign})`;

    const underOver = `=IF(AND(${l1VarianceColumn}${index}>-1,${l1VarianceColumn}${index}<1), 0, INT((${l1VarianceColumn}${index}/${L1ColumnAlias.MaxVariance}${index}) * 100))`;

    // MAXIMUM VARIANCE CALCULATION
    const maxIntVariance = `=MAX(-MINIFS(${l1VarianceColumn}:${l1VarianceColumn},${l2RowType}:${l2RowType},"D"),MAXIFS(${l1VarianceColumn}:${l1VarianceColumn},${l2RowType}:${l2RowType},"D"))`;

    const getNetValue = (column: string) => {
      return `=INDEX(l2_${l1Data.id}!${column}:${column}, MATCH("T", l2_${l1Data.id}!${l2RowType}:${l2RowType}, 0))`;
    };

    const getColumnTotalValue = (column: string) => {
      return `=SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"D",${column}1:${column}${
        l1DataSheet.length - 1
      })`;
    };

    const getFSTotalValue = (column: string) => {
      return `SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"FS",${column}1:${column}${
        l1DataSheet.length - 1
      })`;
    };

    const fringes = `=SUMIF(l2_${l1Data.id}!${L2ColumnAlias.RowType}:${L2ColumnAlias.RowType},"D",l2_${l1Data.id}!${L2ColumnAlias.Fringes}:${L2ColumnAlias.Fringes})`;

    const getTotalWithFringes = (column: string) => {
      return `=SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"D",${column}1:${column}${
        l1DataSheet.length - 1
      })+SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"D",${L1ColumnAlias.Fringes}1:${
        L1ColumnAlias.Fringes
      }${l1DataSheet.length - 1})`;
    };

    const getComparisonWithFringes = (column: string) => {
      return `=SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"D",${column}1:${column}${
        l1DataSheet.length - 1
      })+SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"F",${column}1:${column}${
        l1DataSheet.length - 1
      })`;
    };

    let l1: IL1;

    switch (l1Data.rowType) {
      case 'S':
        l1 = [
          l1Data.id,
          '',
          l1Data.account,
          l1Data.description,
          fringeCalc === 'production' ? '' : subFringeTotal,
          fringeCalc === 'production' ? subTotalWithFringes : subTotal, //total
          fringeCalc === 'production' ? comparisonTotalWithFringes : comparisonTotal, //comparison
          variance, //variance
          0,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.UnderOver}${index})`,
        ];
        break;

      case 'G':
        l1 = [
          l1Data.id,
          '',
          l1Data.account,
          l1Data.description,
          '', //TODO: FRINGES
          fringeCalc === 'production' ? grandTotalWithFringes : grandTotal, //total
          fringeCalc === 'production' ? grandComparisonWithFringes : grandComparison, //comparison
          variance, //variance
          0,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.UnderOver}${index})`,
        ];
        break;

      case 'FS':
        l1 = [
          l1Data.id,
          '',
          `'${l1Data.account}`,
          l1Data.description,
          '', //TODO: FRINGES
          subFringesTotalForFS, //total
          subFringesComparison, //comparison
          variance, //variance
          0,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.UnderOver}${index})`,
        ];
        break;

      case 'F':
        l1 = [
          l1Data.id,
          0,
          `'${l1Data.account}`,
          l1Data.description as string,
          '',
          getColumnTotalValue(L1ColumnAlias.Fringes),
          `=SUMIF(${l1RowType}1:${l1RowType}${l1DataSheet.length - 1},"D",${
            L1ColumnAlias.FringeComparison
          }1:${L1ColumnAlias.FringeComparison}${l1DataSheet.length - 1})`,
          variance,
          0,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.UnderOver}${index})`,
        ];
        break;
      case 'T':
        l1 = [
          l1Data.id,
          '',
          '',
          l1Data.description as string,
          '', //TODO: FRINGES
          // getColumnTotalValue(l1TotalColumn), //: TOTAL
          fringeCalc === 'budget'
            ? getTotalWithFringes(l1TotalColumn)
            : fringeCalc === 'production'
              ? `${getColumnTotalValue(l1TotalColumn)}+${getFSTotalValue(l1TotalColumn)}`
              : getColumnTotalValue(l1TotalColumn), //: TOTAL
          fringeCalc === 'budget'
            ? getComparisonWithFringes(l1ComparisonColumn)
            : fringeCalc === 'production'
              ? `${getColumnTotalValue(l1ComparisonColumn)}+${getFSTotalValue(l1ComparisonColumn)}`
              : getColumnTotalValue(l1ComparisonColumn), //: COMPARISON
          variance, //: VARIANCE
          0,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.UnderOver}${index})`,
        ];
        break;

      default:
        l1 = [
          l1Data.id,
          underOver,
          `'${l1Data.account}`,
          l1Data.description,
          fringes, //FRINGES
          getNetValue(l2TotalColumn), //TOTAL
          getNetValue(l2ComparisonColumn), //COMPARISON
          variance,
          // getNetValue(l2VarianceColumn), //VARIANCE
          maxIntVariance,
          l1Data.rowType,
          `=ROW(${L1ColumnAlias.Account}${index})`,
          `=INDEX(l2_${l1Data.id}!${L2ColumnAlias.Comparison}:${L2ColumnAlias.Comparison}, MATCH("F", l2_${l1Data.id}!${l2RowType}:${l2RowType}, 0))`,
        ];
        break;
    }

    return l1;
  });

  return formulaL1Sheet;
};
