import {
  CircularProgress,
  FormControl,
  SxProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  Theme,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import theme from '@/app/theme/base/theme';

type TextareaFieldProps = {
  error?: string | boolean;
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  labelText?: string;
  width?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onDelete?: () => Promise<void>;
  minRows?: number;
  maxRows?: number;
} & TextareaAutosizeProps;

export const TextareaField = ({
  labelText,
  sx,
  onChange,
  error,
  onDelete,
  width,
  isLoading = false,
  isDisabled = false,
  fullWidth = true,
  minRows = 3,
  maxRows = 6,
  ...props
}: TextareaFieldProps) => {
  const [touched, setTouched] = useState(false);
  const onTouch = () => {
    if (!touched) {
      setTouched(true);
    }
  };
  const disabled = isDisabled || isLoading;

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        width: width,
        position: 'relative',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {labelText}
        </Typography>
      </Stack>
      <TextareaAutosize
        {...props}
        minRows={minRows}
        maxRows={maxRows}
        onBlur={onTouch}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        style={{
          maxWidth: '100%',
          fontSize: '14px',
          backgroundColor: 'transparent',
          color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
          borderColor: touched && !!error ? theme.palette.error.main : theme.palette.grey[400],
          resize: 'none',
          marginBottom: '16px',
          marginTop: '4px',
          borderRadius: '4px',
          padding: '8px',
          fontFamily: 'Hellix',
          outlineColor: theme.palette.primary.main,
        }}
      />
      {isLoading && (
        <CircularProgress
          size={20}
          sx={{
            position: 'absolute',
            top: '27px',
            right: '14px',
            color: theme.palette.primary.main,
          }}
        />
      )}
      {touched && typeof error === 'string' && (
        <Typography
          variant="caption"
          color={theme.palette.error.main}
          sx={{
            fontSize: '14px !important',
            margin: '5px 0 0 0 !important',
            padding: '0 !important',
          }}
        >
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default TextareaField;
