export const formatDate = (date: string, format = 'dd-MM-yyyy' as string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const d = new Date(date);

  const map: Record<string, string> = {
    yyyy: d.getFullYear().toString(),
    yy: d.getFullYear().toString().slice(-2),
    MMM: months[d.getMonth()],
    MM: (d.getMonth() + 1).toString().padStart(2, '0'),
    dd: ('0' + d.getDate()).slice(-2),
    d: d.getDate().toString(),
    hh: d.getHours().toString(),
    mm: d.getMinutes().toString(),
    ss: d.getSeconds().toString(),
  };

  return format.replace(/yyyy|yy|MMM|MM|dd|d|hh|mm|ss/gi, (matched) => map[matched]);
};
