import instanceOfCellError from '@/helpers/checkIfCellError';
import { IHeaderNotes, IReportDataL1 } from '@/interfaces/IReportDataSheet';
import { ISetData } from '@/interfaces/ISetData';

const transformItem = (item: any, fields: string[]) => {
  return fields.reduce(
    (acc, field, index) => {
      if (instanceOfCellError(item[index])) {
        console.error('--- Report exception logger ---');
        console.error(`Current item: ${JSON.stringify(item, null, 2)}`);
        console.error('---------------------');
        const errorMessage = JSON.stringify(item, null, 2);
        throw new Error(errorMessage);
      }
      //console.log(`Field: ${field}, Value: ${item[index]}`); // Add this line for debugging
      acc[field] = item[index];
      return acc;
    },
    {} as Record<string, any>,
  );
};

export const transformFilteredLayer = (filteredLayer: any, fields: any): IReportDataL1[] => {
  try {
    return filteredLayer.map((item: any) => transformItem(item, fields));
  } catch (err: any) {
    console.error(`An error occurred : ${err.message}`);
    throw new Error(`${err.message}`);
  }
};

const L1Fields = [
  'sheetIndex',
  'rowIndex',
  'underOver',
  'account',
  'description',
  'fringes',
  'total',
  'comparison',
  'variance',
  'maxVariance',
  'rowType',
];

const L2Fields = [...L1Fields];
const L3Fields = [
  'sheetIndex',
  'rowIndex',
  'underOver',
  'range',
  'fringe',
  'fringes',
  'groups',
  'loc',
  'set',
  'description',
  'units',
  'desc',
  'x',
  'rate',
  'cu',
  'total',
  'comparison',
  'variance',
  'maxVariance',
  'rowType',
  'fringesTotal',
];

export const transformFilteredL1 = (filteredL1: any[]) =>
  transformFilteredLayer(filteredL1, L1Fields);
export const transformFilteredL2 = (filteredL2: any[]) =>
  transformFilteredLayer(filteredL2, L2Fields);
export const transformFilteredL3 = (filteredL3: any[]) =>
  transformFilteredLayer(filteredL3, L3Fields);

export const transformFilteredL1Export = (filteredL1: any[]): IReportDataL1[] =>
  filteredL1.map((item) => ({
    sheetIndex: item[0],
    rowIndex: item[1],
    underOver: item[2],
    account: item[3] ?? '',
    description: item[4],
    fringes: item[5],
    total: item[6],
    comparison: item[7],
    variance: item[8],
    maxVariance: item[9],
    rowType: item[10],
  }));

export const transformFilteredL2Export = (filteredL2: any[]) =>
  filteredL2.map((item) => ({
    sheetIndex: item[0],
    rowIndex: item[1],
    underOver: item[2],
    account: item[3],
    description: item[4],
    fringes: item[5],
    total: item[6],
    comparison: item[7],
    variance: item[8],
    maxVariance: item[9],
    rowType: item[10],
  }));

export const transformFilteredL3Export = (filteredL3: any[]) => {
  return filteredL3.map((item) => ({
    sheetIndex: item[0],
    rowIndex: item[1],
    underOver: item[2],
    range: item[3],
    fringe: item[4],
    fringes: item[5],
    groups: item[6],
    loc: item[7],
    set: item[8],
    description: item[9],
    units: item[10],
    desc: item[11],
    x: item[12],
    rate: item[13],
    cu: item[14],
    total: item[15],
    comparison: item[16],
    variance: item[17],
    maxVariance: item[18],
    rowType: item[19],
    fringesTotal: item[20],
  }));
};

export const transformCurrencyToReport = (filteredCurrency: any[]) =>
  filteredCurrency.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    rate: item[4],
    default: item[5],
    includeFringe: item[6],
    total: item[7],
    usage: item[8],
    attachments: item[9],
    notes: item[10],
  }));

export const transformGlobalToReport = (filteredGlobal: any[]) =>
  filteredGlobal.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    units: item[4],
    usage: item[5],
    attachments: item[6],
    notes: item[7],
  }));

export const transformGroupsToReport = (filteredGroup: any[]) =>
  filteredGroup.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    suppress: item[4],
    cap: item[5],
    cu: item[6],
    color: item[7],
    includeFringe: item[8],
    total: item[9],
    usage: item[10],
    attachments: item[11],
    notes: item[12],
  }));

export const transformLocationsToReport = (filteredLocations: any[]) =>
  filteredLocations.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    includeFringe: item[8],
    total: item[9],
    usage: item[10],
    attachments: item[11],
    notes: item[12],
  }));

export const transformSetsToReport = (filteredSets: any[]): ISetData[] =>
  filteredSets.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    includeFringe: item[4],
    total: item[5],
    usage: item[6],
    attachments: item[7],
    notes: item[8],
  }));

export const transformUnitDescToReport = (filteredUnitDesc: any[]) =>
  filteredUnitDesc.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    units: item[4],
    usage: item[5],
    attachments: item[6],
    notes: item[7],
  }));

export const transformCostToDateToReport = (filteredCostsToDate: any[]) =>
  filteredCostsToDate.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    units: item[4],
    values: item[5],
    total: item[6],
    usage: item[7],
    attachments: item[8],
    notes: item[9],
  }));

export const transformFringeToReport = (filteredFringes: any[]) =>
  filteredFringes.map((item) => ({
    id: item[0],
    category: item[1],
    code: item[2],
    description: item[3],
    rate: item[4],
    unitDescription: item[5],
    cap: item[6],
    cu: item[7],
    total: item[8],
    usage: item[9],
    attachments: item[10],
    notes: item[11],
  }));

export const transformHeaderNoteToReport = (
  headerNotes: IHeaderNotes | undefined,
): IHeaderNotes[] => {
  const result = [];
  if (headerNotes) {
    result.push({
      left: headerNotes.left || '',
      right: headerNotes.right || '',
    });
  }

  return result;
};
