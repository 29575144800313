import styled from '@emotion/styled';
import ArchiveIcon from '@mui/icons-material/Archive';
import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FolderIcon from '@mui/icons-material/Folder';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LocalStorageVariables, S3ObjectType } from '@/enums';
import { useFetchFiles } from '@/hooks';
import { formatDate, nameShortener } from '@/utils';
import { fileUrlAtom, rootFolder } from '@/atoms/GlobalAtoms';
import { useToaster } from '@/components/common/Toaster';
import {
  ArrowSquareUpIcon,
  BookmarkIcon,
  EditIcon,
  FolderAddIcon,
  TrashIcon,
} from '@/components/icon';
import { TooltipDetailIcon } from '@/components/tooltipDetailIcon';
import { getLastParentFolderPath } from '@/helpers/folderPath';
import DataSheetApiService, { IAchieveFile, IUnAchieveFile } from '@/services/DataSheetApiService';
import { AddFolderDialogBox } from './AddFolder';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';
import { RenameDialogBox } from './RenameFolderFile';
import { IFileResponse } from './types';

export interface FileViewerProps {
  selectedFile: IFileResponse | null;
  setSelectedFile: (selectedFile: IFileResponse | null) => void;
  folderPath?: string;
  setFolderPath?: React.Dispatch<React.SetStateAction<string>>;
  onSelectedFileAndOpen?: (selectedFile: IFileResponse) => void;
  archiveToggleStatus?: boolean;
  templateToggleStatus?: boolean;
  showDeleteIcon?: boolean;
  showArchiveIcon?: boolean;
  readonly?: boolean;
}

const StyledListItem = styled.div`
  & .MuiListItem-root {
    border-bottom: 1px solid ${({ theme }) => (theme as Theme).palette.secondary.main};
  }
  & .MuiListItemText-root {
    display: flex;
    justify-content: space-between;
  }
  & {
    overflow-x: hidden;
  }
`;

const StyledDialogActionTopBarIcons = styled.div`
  color: white;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledFileActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Separator = styled.div`
  margin-top: 1.3rem;
  border-top: 1px solid #d8d9e0;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minWidth: 460,
  },
}));

export function FileViewer({
  selectedFile,
  folderPath,
  setFolderPath,
  setSelectedFile,
  onSelectedFileAndOpen,
  archiveToggleStatus,
  templateToggleStatus,
  showDeleteIcon = true,
  showArchiveIcon = true,
  readonly = false,
}: FileViewerProps) {
  const classes = useStyles();
  const snackBar = useToaster();
  const [query, setQuery] = useState<string>('');
  const theme = useTheme(); //Main theme
  const [openAddFolderDialog, setOpenAddFolderDialog] = useState<boolean>(false);
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);
  const [openArchiveConfirmationDialog, setOpenArchiveConfirmationDialog] =
    useState<boolean>(false);
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState<boolean>(false);
  const [fileIsOpened, setFileIsOpened] = useState(false);
  const fileUrl = useRecoilValue(fileUrlAtom);
  const rootPath = useRecoilValue(rootFolder);

  const { data, isFetching, refetch } = useFetchFiles({
    rootPath,
    query,
    folderPath,
    archiveToggleStatus,
    templateToggleStatus,
  });

  const generateArchiveConfirmationMessage = (file: IFileResponse): string => {
    let message = '';
    const fileName = nameShortener(file.filename, 35);
    if (file.type === S3ObjectType.ARCHIVED_FILE) {
      message = `<p> Are you sure you want to unarchive <strong> ${fileName} </strong> ? </p> `;
    } else {
      message = `<p>Are you sure you want to archive <strong>${fileName}</strong> ?</p>`;
    }

    return message;
  };

  const generateDeleteConfirmationMessage = (file: IFileResponse): string => {
    const fileName = nameShortener(file.filename, 35);

    const message = `<p> Are you sure you want to delete <strong> ${fileName} </strong> ? </p> `;

    return message;
  };
  const handleListItemClick = (file: IFileResponse) => {
    setSelectedFile(file);
  };

  const handleListItemClickAndOpen = useCallback(
    (file: IFileResponse) => {
      if (S3ObjectType.USERS_FOLDER === file.type) {
        if (setFolderPath === undefined || folderPath === undefined) return;

        const path = folderPath.concat(file.filename);
        setFolderPath(path);
      } else {
        onSelectedFileAndOpen?.(file);
      }
    },
    [folderPath, onSelectedFileAndOpen, setFolderPath],
  );

  async function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
  }

  const renderFileIcon = (file: IFileResponse) => {
    switch (file.type) {
      case S3ObjectType.JSON_FILE:
        return <DescriptionIcon sx={{ minWidth: '35px', color: '#b97d34' }} />;
      case S3ObjectType.ARCHIVED_FILE:
        return <ArchiveIcon sx={{ minWidth: '35px', opacity: 0.5 }} />;
      case S3ObjectType.USERS_FOLDER:
        return <FolderIcon sx={{ minWidth: '35px', color: '#f7b140' }} />;
      case S3ObjectType.TEMPLATE_FILE:
        return <FileOpenIcon sx={{ minWidth: '35px', color: '#965f06' }} />;
      case S3ObjectType.TEMPORARY_FILE:
        return <RestorePageIcon sx={{ minWidth: '35px', color: '#965f06' }} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const fullFilePath = folderPath
        ? folderPath.concat(selectedFile.filename)
        : selectedFile.filename;

      if (fullFilePath === fileUrl) {
        setFileIsOpened(true);
      } else {
        setFileIsOpened(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const renderSpinner = () => {
    return (
      <StyledContentCenter>
        <CircularProgress />
      </StyledContentCenter>
    );
  };

  const renderFileItemList = () => {
    if ((data ?? []).length <= 0) {
      return <StyledContentCenter>No results found</StyledContentCenter>;
    }
    return data?.map((file) => {
      const fileName =
        file.filename.length < 35 ? file.filename : file.filename.substring(0, 34) + '...';

      return (
        <ListItem
          key={file.filename}
          onClick={() => {
            if (readonly) return;
            handleListItemClick(file);
          }}
          onDoubleClick={() => {
            if (readonly) return;
            if (file.type !== S3ObjectType.ARCHIVED_FILE) {
              handleListItemClickAndOpen(file);
            }
          }}
          className={selectedFile?.filename === file.filename ? 'Mui-selected' : ''}
          style={{
            cursor: readonly ? 'not-allowed' : 'pointer',
            pointerEvents: readonly ? 'none' : 'auto',
            opacity: readonly ? '50%' : '100%',
            paddingLeft: readonly ? undefined : 0,
          }}
        >
          <ListItemIcon>{renderFileIcon(file)}</ListItemIcon>
          <ListItemText
            primary={fileName}
            secondary={formatDate(file.lastModified, 'd-MMM-yy hh:mm')}
          />
        </ListItem>
      );
    });
  };

  async function handleArchiveAndUnarchive() {
    setOpenArchiveConfirmationDialog(false);

    if (!selectedFile || !username) return;

    let filename = selectedFile.filename;

    if (folderPath) {
      filename = folderPath.concat(selectedFile.filename);
    }

    if (selectedFile.type === S3ObjectType.USERS_FOLDER && filename.endsWith('/')) {
      filename = filename.slice(0, -1);
    }

    const fileName: IUnAchieveFile | IAchieveFile = { filename, type: selectedFile.type };

    if (selectedFile.type === S3ObjectType.ARCHIVED_FILE) {
      try {
        await DataSheetApiService.unAchieveDataSheet(username, fileName);
        snackBar.success(`Successfully unarchive ${fileName.filename}`);
      } catch (err) {
        snackBar.error(`Sorry could not unarchive ${fileName.filename}`);
      }
    } else {
      try {
        await DataSheetApiService.achieveDataSheet(username, fileName);
        snackBar.success(`Successfully archived ${fileName.filename}`);
      } catch (err) {
        snackBar.error(`Sorry could not archive ${fileName.filename}`);
      }
    }

    setSelectedFile(null);
    await refetch();
  }

  async function handleSelectedFileDelete() {
    setOpenDeleteConfirmationDialog(false);

    if (!selectedFile || !username) return;

    try {
      if (selectedFile.type === S3ObjectType.USERS_FOLDER) {
        let foldername = selectedFile.filename;

        if (folderPath) {
          foldername = folderPath.concat(selectedFile.filename);
        }

        if (foldername.endsWith('/')) {
          foldername = foldername.slice(0, -1);
        }

        await DataSheetApiService.deleteFolderByFoldername(username, foldername);
        await refetch();
        snackBar.success(`Successfully deleted folder ${foldername}`);

        setSelectedFile(null);
      } else {
        let filename = selectedFile.filename;
        if (folderPath) {
          filename = folderPath.concat(filename);
        }

        await DataSheetApiService.deleteFileByFilename(username, filename);
        await refetch();
        snackBar.success(`Successfully deleted file ${selectedFile.filename}`);
        setSelectedFile(null);
      }
    } catch (err) {
      snackBar.error(`Sorry could not delete ${selectedFile.filename}`);
    }
  }

  function handleRename() {
    if (fileIsOpened) {
      snackBar.warning('Unable to perform action. This file is already open');
    } else {
      setOpenRenameDialog(true);
    }
  }

  function handleDelete() {
    if (fileIsOpened) {
      snackBar.warning('Unable to perform action. This file is already open');
    } else {
      setOpenDeleteConfirmationDialog(true);
    }
  }

  function handleArchive() {
    if (fileIsOpened) {
      snackBar.warning('Unable to perform action. This file is already open');
    } else {
      setOpenArchiveConfirmationDialog(true);
    }
  }

  function handleBack() {
    if (setFolderPath === undefined || folderPath === undefined) return;
    const lastParentFolderPath = getLastParentFolderPath(folderPath);
    setFolderPath(lastParentFolderPath);
  }

  return (
    <StyledListItem theme={theme}>
      <StyledFileActionBar>
        <StyledTitle>
          <StyledDialogActionTopBarIcons>
            <TooltipDetailIcon
              onClickIcon={handleRename}
              tooltip="Edit"
              icon={<EditIcon fontSize="medium" />}
              disableIcon={!selectedFile ?? false}
            />
            {showArchiveIcon && (
              <TooltipDetailIcon
                disableIcon={!selectedFile ?? false}
                onClickIcon={handleArchive}
                tooltip={
                  selectedFile?.type === S3ObjectType.ARCHIVED_FILE ? 'Unarchive' : 'Archive'
                }
                icon={<BookmarkIcon fontSize="medium" />}
              />
            )}
            <TooltipDetailIcon
              onClickIcon={() => setOpenAddFolderDialog(true)}
              tooltip="Add Folder"
              icon={<FolderAddIcon fontSize="medium" />}
            />
            {showDeleteIcon && (
              <TooltipDetailIcon
                disableIcon={!selectedFile ?? false}
                onClickIcon={handleDelete}
                tooltip="Delete"
                icon={
                  <TrashIcon
                    fontSize="medium"
                    style={{
                      marginBottom: '3px',
                    }}
                  />
                }
              />
            )}
            <TooltipDetailIcon
              disableIcon={folderPath === '' || folderPath === undefined}
              onClickIcon={handleBack}
              tooltip="Back"
              icon={
                <ArrowSquareUpIcon
                  fontSize="small"
                  style={{
                    marginBottom: '3px',
                  }}
                />
              }
            />
          </StyledDialogActionTopBarIcons>
        </StyledTitle>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="standard"
          InputLabelProps={{
            style: { color: theme.palette.secondary.main },
          }}
          onChange={handleSearchChange}
        />
      </StyledFileActionBar>
      <Separator />
      <List className={classes.root}>{isFetching ? renderSpinner() : renderFileItemList()}</List>
      <AddFolderDialogBox
        open={openAddFolderDialog}
        setOpen={setOpenAddFolderDialog}
        folderPath={folderPath}
        onFileRefetch={refetch}
      />
      <RenameDialogBox
        open={openRenameDialog}
        setOpen={setOpenRenameDialog}
        folderPath={folderPath}
        onFileRefetch={refetch}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        fetchedFiles={data}
        setFileIsOpened={setFileIsOpened}
      />
      {selectedFile && username && (
        <>
          <ConfirmationDialog
            open={openArchiveConfirmationDialog}
            onOpen={setOpenArchiveConfirmationDialog}
            title={'Confirm'}
            message={generateArchiveConfirmationMessage(selectedFile)}
            onClickYes={handleArchiveAndUnarchive}
            onClickNo={() => setOpenArchiveConfirmationDialog(false)}
            isYesNoOptionAllowed={true}
            isHtmlMessage={true}
          />
          <ConfirmationDialog
            open={openDeleteConfirmationDialog}
            onOpen={setOpenDeleteConfirmationDialog}
            title={'Confirm'}
            message={generateDeleteConfirmationMessage(selectedFile)}
            onClickYes={handleSelectedFileDelete}
            onClickNo={() => setOpenDeleteConfirmationDialog(false)}
            isYesNoOptionAllowed={true}
            isHtmlMessage={true}
          />
        </>
      )}
    </StyledListItem>
  );
}

export default FileViewer;
