import newAxiosInstance from 'axios';
import { LocalStorageVariables, S3ObjectType } from '@/enums';
import { axios } from '@/utils';
import { UploadableFile } from '@/components/dialogBoxes/OpenAttachmentDialog/OpenAttachmentDialog';
import { IFileResponse } from '@/components/dialogBoxes/types';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IShareFile } from '@/interfaces/IShareFile';

async function fetchSheets(
  rootPath: string,
  query?: string,
  folderPath?: string,
  includeAchieves?: boolean,
  includeTemplates?: boolean,
  includeTemps?: boolean,
): Promise<IFileResponse[]> {
  try {
    const { data } = await axios.get<IFileResponse[]>(`/v1/datasheet/list/${rootPath}`, {
      params: { query, folderPath, includeAchieves, includeTemplates, includeTemps },
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function saveSheetByFileType(
  username: string,
  budgetSheet: IDataSheet,
  filename: string,
  fileType: S3ObjectType,
  previousPath?: string,
): Promise<{
  status: string;
  message: string;
}> {
  const body = {
    filename,
    fileType,
    budgetSheet,
    previousPath,
  };

  try {
    const { data } = await axios.post<{
      status: string;
      message: string;
    }>(`/v1/datasheet/${username}`, body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function shareSheet(
  username: string,
  filename: string,
  requestBody: IShareFile,
): Promise<any> {
  try {
    const { data } = await axios.post(`/v1/datasheet/share/${username}`, requestBody, {
      params: { filename },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

interface IPresignedSheetResponse {
  message: string;
  filename: string;
  jsonFilePath?: string;
  tempFilePath?: string;
  tmplFilePath?: string;
}

async function loadSheetFromPresignedUrl(path: string): Promise<IDataSheet> {
  const { data } = await newAxiosInstance.get(path);
  if (typeof data === 'object') {
    return data as unknown as IDataSheet;
  } else {
    return JSON.parse(data) as unknown as IDataSheet;
  }
}

async function loadPresignedData(
  userName: string,
  filePath: string,
): Promise<IPresignedSheetResponse> {
  try {
    const { data } = await axios.get(`/v1/datasheet/presigned/${userName}`, {
      params: { filePath },
    });
    return data as IPresignedSheetResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

interface IBodyForPreSignedUrlAttachment {
  uploadFileName: string;
  contentType: string;
}

interface IUploadAttachment {
  presignedUrl: string;
  file: UploadableFile;
}

async function loadPreSignedUrlForAttachmentUpload(
  fileDetails: IBodyForPreSignedUrlAttachment[],
  files: UploadableFile[],
  dataSheetName: string,
): Promise<any> {
  const filesWithPresignedUrls: IUploadAttachment[] = [];
  const email = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  const body = {
    fileDetails,
    path: dataSheetName,
  };

  try {
    const response = await axios.post(`/v1/datasheet/${email}/attachments`, body);

    for (const data of response.data) {
      for (const file of files) {
        if (data.uploadFileName === file.file.name) {
          filesWithPresignedUrls.push({ presignedUrl: data.presignedUrl, file: file });
        }
      }
    }
    const upload = await uploadAttachments(filesWithPresignedUrls);
    return upload;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function uploadAttachments(files: IUploadAttachment[]) {
  try {
    const responses = await Promise.all(
      files.map((file) =>
        newAxiosInstance.put(file.presignedUrl, file.file.file, {
          headers: { 'Content-Type': file.file.file.type },
        }),
      ),
    );

    return responses;
  } catch (error) {
    return Promise.reject(error);
  }
}

interface IAchieveFile {
  filename: string;
  type: S3ObjectType;
}

async function achieveDataSheet(
  username: string,
  body: IAchieveFile | Array<IAchieveFile>,
): Promise<void> {
  try {
    await axios.post(`/v1/datasheet/${username}/archives`, body);
  } catch (error) {
    return Promise.reject(error);
  }
}

interface IUnAchieveFile {
  filename: string;
  type: S3ObjectType;
}

async function unAchieveDataSheet(
  username: string,
  body: IUnAchieveFile | Array<IUnAchieveFile>,
): Promise<void> {
  try {
    await axios.post(`/v1/datasheet/${username}/unarchive`, body);
  } catch (error) {
    return Promise.reject(error);
  }
}

interface IAddFolderBody {
  foldername: string;
}

async function addFolder(body: Array<IAddFolderBody> | IAddFolderBody): Promise<void> {
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
  try {
    return await axios.post(`/v1/datasheet/folder/${username}`, body);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

interface IGetAttachment {
  getFileName: string;
}

async function getAttachment(attachments: IGetAttachment[], dataSheetName: string): Promise<any> {
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  try {
    const getFileNames = attachments[0].getFileName;
    const path = dataSheetName;
    return await axios.get(`/v1/datasheet/presigned/${username}/attachments`, {
      params: {
        getFileNames,
        path,
      },
    });
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

interface IDeleteAttachmentBody {
  deleteFileName: string;
  contentType: string;
}

async function deleteAttachment(
  body: IDeleteAttachmentBody[],
  dataSheetName: string,
): Promise<void> {
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
  const filenames = body.map((file) => file.deleteFileName);
  const filenamesString = filenames.join(',');

  try {
    const deleteFileNames = filenamesString;
    const path = dataSheetName;
    await axios.delete(`/v1/datasheet/delete/attachment/${username}`, {
      params: {
        deleteFileNames,
        path,
      },
    });
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

async function deleteFolderByFoldername(username: string, foldername: string) {
  try {
    return await axios.delete(`/v1/datasheet/folder/${username}`, {
      params: { foldername },
    });
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

async function deleteFileByFilename(username: string, filename: string) {
  try {
    return await axios.delete(`/v1/datasheet/file/${username}`, {
      params: { filename },
    });
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

interface IRenameFileFolder {
  contentName: string;
  contentType: S3ObjectType;
  newContentName: string;
  rootPath: string;
}

async function renameFileFolder(username: string, body: IRenameFileFolder) {
  try {
    return await axios.post(`/v1/datasheet/rename/file/folder/${username}`, body);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
}

export default {
  deleteFolderByFoldername,
  deleteFileByFilename,
  achieveDataSheet,
  unAchieveDataSheet,
  fetchSheets,
  shareSheet,
  loadPreSignedUrlForAttachmentUpload,
  loadPresignedData,
  addFolder,
  deleteAttachment,
  getAttachment,
  loadSheetFromPresignedUrl,
  saveSheetByFileType,
  renameFileFolder,
};

export type {
  IAchieveFile,
  IAddFolderBody,
  IBodyForPreSignedUrlAttachment,
  IDeleteAttachmentBody,
  IGetAttachment,
  IPresignedSheetResponse,
  IUnAchieveFile,
  IUploadAttachment,
};
