import axios from 'axios';
import { Config } from '@/config';
import { refreshSession } from './awsCognitoIdentity';
import { retrieveTokenFromLocalStorage } from './extractTokensFromLocalStorage';
import { LocalStorageVariables } from '../enums/localStorage';

const instance = axios.create({
  baseURL: Config.baseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

const dpfOAuthinstance = axios.create({
  baseURL: Config.costTracker.dpfOAuthUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

const dpfInstance = axios.create({
  baseURL: Config.costTracker.dpfApiUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = await retrieveTokenFromLocalStorage('.accessToken');
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        await refreshSession();
        const config = error.config;
        const accessToken = await retrieveTokenFromLocalStorage('.accessToken');
        config.headers.Authorization = `Bearer ${accessToken}`;
        return instance.request(config);
      } catch (error) {
        console.error('Failed to refresh session:', error);
        throw error;
      }
    }
    throw error;
  },
);

instance.interceptors.response.use(
  async (config) => {
    const accessToken = await retrieveTokenFromLocalStorage('.accessToken');
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return config;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        await refreshSession();
        const config = error.config;
        const accessToken = await retrieveTokenFromLocalStorage('.accessToken');
        config.headers.Authorization = `Bearer ${accessToken}`;
        return instance.request(config);
      } catch (error) {
        console.error('Failed to refresh session:', error);
        throw error;
      }
    }
    throw error;
  },
);

dpfInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(LocalStorageVariables.DPF_API_ACCESS_TOKEN);
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

dpfInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status.toString().startsWith('4')) {
      localStorage.removeItem(LocalStorageVariables.DPF_API_ACCESS_TOKEN);

      const event = new CustomEvent('dpfTokenInvalidated');
      window.dispatchEvent(event);
    }
    return Promise.reject(error);
  },
);

export { dpfOAuthinstance, dpfInstance, instance as axios };
