import { S3ObjectType } from '@/enums';
import { IFileResponse } from '@/components/dialogBoxes/types';

export const filterFilesByTypeAndName = (
  files: IFileResponse[],
  acceptedTypes: S3ObjectType[],
  filename: string,
) => {
  return files.filter((file) => {
    const isAcceptedType = acceptedTypes.includes(file.type);
    const matchesFilename = file.filename === filename;
    return isAcceptedType && matchesFilename;
  });
};
