import reorderCriteria from './reorderCriteria';

export const getOrderFor = (key: string, currentSheetName?: string): number => {
  const criteriaList = reorderCriteria(currentSheetName);
  for (const criteria of criteriaList) {
    if (
      criteria.sheetName === key ||
      (criteria.startsWith && key.startsWith(criteria.startsWith))
    ) {
      return criteria.order;
    }
  }
  const criteriaListLastIndex = criteriaList.length - 1;
  const criteriaListLastIndexOrder = criteriaList[criteriaListLastIndex].order;
  return criteriaListLastIndexOrder + 1; // Default order
};

const globalSearchSort = (a: string, b: string, currentSheetName?: string): number => {
  const orderA = getOrderFor(a, currentSheetName);
  const orderB = getOrderFor(b, currentSheetName);

  if (orderA !== orderB) {
    return orderA - orderB;
  }

  return a.localeCompare(b);
};

export default globalSearchSort;
