import { ValuesType } from 'utility-types';
import { BudgetLevels, SheetNames } from '@/enums';
import { ICostsToDateData } from '@/interfaces/ICostsToDateData';
import { ICurrencyData } from '@/interfaces/ICurrencyData';
import { IFileAttachmentList } from '@/interfaces/IFileAttachments';
import { IFringeData } from '@/interfaces/IFringeData';
import { IGlobalData } from '@/interfaces/IGlobalData';
import { IGroupData } from '@/interfaces/IGroupData';
import { ILocationData } from '@/interfaces/ILocationData';
import { IMeta } from '@/interfaces/IMetaData';
import { INotesList } from '@/interfaces/INotes';
import { ISetData } from '@/interfaces/ISetData';
import { IUnitDescriptionData } from '@/interfaces/IUnitDescriptionData';

export interface IDataSheetNames {
  l2: Array<string>;
  l3: Array<string>;
}

export interface IL1Data {
  id: string | number;
  account: string | null;
  description: string | null;
  rowType: string;
  comparison?: string | number;
}

export interface IL2Data {
  id: string | number;
  account: string | null;
  description: string | null;
  rowType: string;
  comparison?: string | number;
}

export interface IL3Data {
  id: string | number;
  range: string;
  fringe: string;
  fringes: string;
  groups: string;
  loc: string;
  set: string;
  description: string;
  units: string;
  desc: string;
  x: string;
  rate: string;
  cu: string;
  comparison: string | number;
  rowType: string;
  fringeComparison: string | number;
}

export type IL1DataSheet = Array<IL1Data>;

export interface IL2DataSheet {
  [key: string]: Array<IL2Data>;
}

export interface IL3DataSheet {
  [key: string]: Array<IL3Data>;
}

export interface IUser {
  name: string;
  age: number;
}

export interface IDataSheetConfigs {
  dropCurrencyAtReset: string;
  varianceCalc: string;
  // [key: string]: string | number;
}

export const initialConfigs: IDataSheetConfigs = {
  dropCurrencyAtReset: '',
  varianceCalc: '',
};

export interface ISelectedCellData {
  row: number;
  col: number;
  colHeader: string;
}

export interface ICellCoords {
  row: number;
  col: number;
}

export enum DateSheetAttributeType {
  META = 'meta',
  SHEET_NAMES = 'sheetNames',
  L1 = 'l1',
  L2 = 'l2',
  L3 = 'l3',
  COSTS_TO_DATE = 'costsToDate',
  CURRENCY = 'currency',
  FRINGES = 'fringes',
  GLOBALS = 'globals',
  GROUPS = 'groups',
  LOCATIONS = 'locations',
  SETS = 'sets',
  UNIT_DESCRIPTIONS = 'unitDesc',
  FILE_ATTACHMENTS = 'fileAttachments',
  CONFIGS = 'configs',
}

export const sheetAttributeMapperToSheetLevel = {
  [DateSheetAttributeType.L1]: BudgetLevels.FIRST_LEVEL,
  [DateSheetAttributeType.L2]: BudgetLevels.SECOND_LEVEL,
  [DateSheetAttributeType.L3]: BudgetLevels.THIRD_LEVEL,
};

export interface IDataSheet {
  [SheetNames.META]: IMeta;
  [SheetNames.SHEET_NAMES]: IDataSheetNames;
  [SheetNames.L1]: IL1DataSheet;
  [SheetNames.L2]: IL2DataSheet;
  [SheetNames.L3]: IL3DataSheet;
  [SheetNames.LOCATIONS]: Array<ILocationData>;
  [SheetNames.GLOBALS]: Array<IGlobalData>;
  [SheetNames.CURRENCY]: Array<ICurrencyData>;
  [SheetNames.FRINGES]: Array<IFringeData>;
  [SheetNames.SETS]: Array<ISetData>;
  [SheetNames.GROUPS]: Array<IGroupData>;
  [SheetNames.UNIT_DESCRIPTIONS]: Array<IUnitDescriptionData>;
  [SheetNames.COSTS_TO_DATE]: Array<ICostsToDateData>;
  [SheetNames.FILE_ATTACHMENTS]: IFileAttachmentList;
  [SheetNames.CONFIGS]: IDataSheetConfigs;
  [SheetNames.NOTES]: INotesList;
  [SheetNames.CT_L1]: any;
  [SheetNames.CT_L2A]: any;
  [SheetNames.CT_L3]: any;
  [SheetNames.LOOKUP]: any;
}

export const dataSheetKeys: Array<IDataSheetKeys> = [
  SheetNames.META,
  SheetNames.SHEET_NAMES,
  SheetNames.L1,
  SheetNames.L2,
  SheetNames.L3,
  SheetNames.LOCATIONS,
  SheetNames.GLOBALS,
  SheetNames.CURRENCY,
  SheetNames.FRINGES,
  SheetNames.SETS,
  SheetNames.GROUPS,
  SheetNames.UNIT_DESCRIPTIONS,
  SheetNames.COSTS_TO_DATE,
  SheetNames.FILE_ATTACHMENTS,
  SheetNames.NOTES,
];

export type IDataSheetValue = ValuesType<IDataSheet>;
export type IDataSheetKeys = keyof IDataSheet;

const sampleLevel1Data: IL1Data = {
  id: '',
  account: null,
  description: null,
  rowType: '',
};

export const level1Keys = Object.keys(sampleLevel1Data) as Array<keyof IL1Data>;

const sampleLevel2Data: IL2Data = {
  id: '',
  account: null,
  description: null,
  rowType: '',
};

export const level2Keys = Object.keys(sampleLevel2Data) as Array<keyof IL2Data>;

const sampleLevel3Data: IL3Data = {
  id: '',
  range: '',
  fringe: '',
  fringes: '',
  groups: '',
  loc: '',
  set: '',
  description: '',
  units: '',
  desc: '',
  x: '',
  rate: '',
  cu: '',
  comparison: '',
  rowType: '',
  fringeComparison: '',
};

export const level3Keys = Object.keys(sampleLevel3Data) as Array<keyof IL3Data>;
