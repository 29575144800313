import lodash from 'lodash';
import { SYSTEM_CURRENCY, SYSTEM_CURRENCY_LABEL } from '@/constants';
import { IDataSheetConfigs } from '@/interfaces/IDataSheet';
import { ILookupSheet } from '@/interfaces/IFormulaSheet';

export const transformLookup = (lookups: IDataSheetConfigs) => {
  const lookupSheet: ILookupSheet = [];
  if (lodash.isEmpty(lookups)) {
    lookupSheet.push(['dropCurrencyAtReset', `=${SYSTEM_CURRENCY}`]);
    return lookupSheet;
  }

  Object.keys(lookups).forEach((key) => {
    let dropCurrencyAtReset = SYSTEM_CURRENCY;
    if (key === 'dropCurrencyAtReset') {
      dropCurrencyAtReset = lookups[key] === SYSTEM_CURRENCY_LABEL ? SYSTEM_CURRENCY : lookups[key];
    }

    switch (key) {
      case 'dropCurrencyAtReset':
        lookupSheet.push(['dropCurrencyAtReset', `=${dropCurrencyAtReset ?? SYSTEM_CURRENCY}`]);
        break;
      default:
        break;
    }
  });
  return lookupSheet;
};
