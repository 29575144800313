import { ICurrencyImport } from '@/interfaces/IImportData';

export interface IAccountFormat {
  categoryDigits: number;
  accountDigits: number;
  setDigits: number;
  separator: string;
}

export const initialAccountFormat: IAccountFormat = {
  accountDigits: 0,
  categoryDigits: 0,
  separator: '',
  setDigits: 0,
};

export interface IFile {
  owner: string;
  created: string;
  lastModified: string;
  category: string;
  keywords: string;
  lastAuthor: string;
  note: string;
  projectName: string;
  subject: string;
  title: string;
  version: string;
}

export const initialFile: IFile = {
  category: '',
  created: '',
  keywords: '',
  lastAuthor: '',
  lastModified: '',
  note: '',
  owner: '',
  projectName: '',
  subject: '',
  title: '',
  version: '',
};

export interface IFringes {
  calc: string;
  allocation: string;
  cutoff: number;
}

export const initialFringes: IFringes = { allocation: '', calc: '', cutoff: 0 };

export interface INumberFormat {
  format: string;
  rateDecimalPlaces: number;
  xDecimalPlaces: number;
  unitsDecimalPlaces: number;
  varianceCalculation: string;
}

export const initialNumberFormat: INumberFormat = {
  format: '',
  rateDecimalPlaces: 0,
  xDecimalPlaces: 0,
  unitsDecimalPlaces: 0,
  varianceCalculation: '',
};

export interface IOptions {
  lockBudget: boolean;
  showFormulas: boolean;
  insertBaseCurrency: boolean;
  DeleteRowsWithVariances: boolean;
}

export const initialOptions: IOptions = {
  DeleteRowsWithVariances: false,
  insertBaseCurrency: false,
  lockBudget: false,
  showFormulas: false,
};

export interface IMeta {
  projectName: string;
  version: string;
  notes: string;
  numberFormat: INumberFormat;
  accountFormat: IAccountFormat;
  fringes: IFringes;
  options: IOptions;
  file: IFile;
  isConflictSuppress: number;
  currencyImport: ICurrencyImport;
  costTrackerOAuthApiUrl: string;
  costTrackerOAuthRedirectUrl: string;
  costTrackerMask: string;
}

export const initialMeta: IMeta = {
  accountFormat: initialAccountFormat,
  file: initialFile,
  fringes: initialFringes,
  isConflictSuppress: 0,
  notes: '',
  numberFormat: initialNumberFormat,
  options: initialOptions,
  projectName: '',
  version: '',
  currencyImport: {
    baseCurrency: '',
    convCurrency: '',
  },
  costTrackerOAuthApiUrl: '',
  costTrackerOAuthRedirectUrl: '',
  costTrackerMask: '',
};
