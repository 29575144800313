import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { DialogBoxContainer, MainButton } from '@/components/common';
import { useCostTracker } from '@/contexts/CostTrackerContext';

import { ApiConnectionForm } from './ApiConnectionForm';
import { HeaderTitle } from './HeaderTitle';
import { useApiConnectionForm, type ApiConnectionFormState } from './hooks/useApiConnectionForm';
import { useOptionForm, type OptionFormState } from './hooks/useOptionForm';
import { OptionForm } from './OptionForm';

interface CostTrackerOptionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CostTrackerOptionDialog = ({ open, setOpen }: CostTrackerOptionDialogProps) => {
  const setDataSheet = useSetRecoilState(dataSheetAtom);

  const { isDpfConnected, openAndMonitorOAuthWindow, setCostTrackerOptions } = useCostTracker();
  const apiConnectionForm = useApiConnectionForm();
  const optionForm = useOptionForm();

  const handleSaveOptions = () => {
    const { apiUrl, redirectUrl } = apiConnectionForm.getValues();
    const {
      mask,
      episodeCode,
      costTrackerMode,
      viewTransactionsLevel3,
      includeUnpostedData,
      detaAsSets,
    } = optionForm.getValues();

    handleStoreConnectionSetting({
      apiUrl,
      redirectUrl,
      mask: mask ?? '',
    });
    setCostTrackerOptions({
      mask: mask ?? '',
      episodeCode: episodeCode ?? '',
      costTrackerMode,
      viewTransactionsLevel3,
      includeUnpostedData,
      detaAsSets,
    });
    setOpen(false);
  };

  const handleStoreConnectionSetting = (
    data: ApiConnectionFormState & Required<Pick<OptionFormState, 'mask'>>,
  ) =>
    setDataSheet((prevDataSheet) => {
      if (!prevDataSheet) return prevDataSheet;
      return {
        ...prevDataSheet,
        meta: {
          ...prevDataSheet.meta,
          costTrackerOAuthApiUrl: data.apiUrl,
          costTrackerOAuthRedirectUrl: data.redirectUrl,
          costTrackerMask: data.mask,
        },
      };
    });

  const onSubmit = (data: ApiConnectionFormState) => openAndMonitorOAuthWindow(data);

  return (
    <DialogBoxContainer
      open={open}
      setOpen={setOpen}
      dialogTitle="Cost Tracker Options"
      boxWidth={'800px'}
      dialogActions={
        <Stack direction="row" justifyContent={'flex-end'} width={'100%'}>
          <MainButton
            onClick={handleSaveOptions}
            disabled={!optionForm.formState.isValid}
            color="primary"
            variant="contained"
          >
            Save
          </MainButton>
        </Stack>
      }
    >
      <Stack direction="column" spacing={3} width={'50%'}>
        <FormProvider {...apiConnectionForm}>
          <ApiConnectionForm isDpfConnected={isDpfConnected} onSubmit={onSubmit} />
        </FormProvider>
        <Stack direction="column" width={'100%'}>
          <HeaderTitle name="Options" />
          <FormProvider {...optionForm}>
            <OptionForm isDpfConnected={isDpfConnected} />
          </FormProvider>
        </Stack>
      </Stack>
    </DialogBoxContainer>
  );
};
