import {
  FormControl,
  IconButton,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';
import React, { ReactElement, useState } from 'react';
import theme from '@/app/theme/base/theme';
import { DeleteIcon } from '@/components/icon';

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  error?: string | boolean;
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  labelText?: string;
  deleteOption?: boolean;
  width?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onDelete?: () => Promise<void>;
};

export const InputTextField = ({
  labelText,
  sx,
  onChange,
  error,
  deleteOption,
  onDelete,
  width,
  isLoading = false,
  isDisabled = false,
  fullWidth = true,
  ...props
}: Props): ReactElement => {
  const [touched, setTouched] = useState(false);
  const onTouch = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        width: width,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {labelText}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {deleteOption && (
            <IconButton sx={{ cursor: 'pointer' }} aria-label="delete-item">
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <TextField
        {...props}
        size={'small'}
        error={touched && !!error}
        helperText={touched && typeof error === 'string' ? error : undefined}
        onBlur={onTouch}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          endAdornment: isLoading && (
            <CircularProgress size={20} sx={{ color: theme.palette.primary.main }} />
          ),
        }}
        disabled={isDisabled || isLoading}
        sx={{
          mb: 2,
          mt: 0.5,
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '14px !important',
            margin: '5px 0 0 0 !important',
            padding: '0 !important',
          },
          '& .MuiOutlinedInput-root': {
            fontSize: '14px',
          },
        }}
      />
    </FormControl>
  );
};
