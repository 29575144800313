import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const BookmarkIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.6152 1.5H5.38523C3.78773 1.5 2.49023 2.805 2.49023 4.395V14.9625C2.49023 16.3125 3.45773 16.8825 4.64273 16.23L8.30273 14.1975C8.69273 13.98 9.32273 13.98 9.70523 14.1975L13.3652 16.23C14.5502 16.89 15.5177 16.32 15.5177 14.9625V4.395C15.5102 2.805 14.2127 1.5 12.6152 1.5Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6152 1.5H5.38523C3.78773 1.5 2.49023 2.805 2.49023 4.395V14.9625C2.49023 16.3125 3.45773 16.8825 4.64273 16.23L8.30273 14.1975C8.69273 13.98 9.32273 13.98 9.70523 14.1975L13.3652 16.23C14.5502 16.89 15.5177 16.32 15.5177 14.9625V4.395C15.5102 2.805 14.2127 1.5 12.6152 1.5Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9375 6.78751C8.2725 7.27501 9.7275 7.27501 11.0625 6.78751"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
