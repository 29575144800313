import { DATA_SHEET_FILE_TYPES } from '@/enums';

export const getFileType = (fileName: string) => {
  if (fileName.includes('.tmpl')) {
    return DATA_SHEET_FILE_TYPES.TMPL;
  } else if (fileName.includes('.temp')) {
    return DATA_SHEET_FILE_TYPES.TEMP;
  }
  return DATA_SHEET_FILE_TYPES.JSON;
};
