import lodash from 'lodash';
import { generatedId } from '@/utils';
import { groupDataToFormulaTransformation } from '@/helpers/Mappers/GroupsMapper';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { IGroup } from '@/interfaces/IFormulaSheet';
import { GroupTypes, IGroupData } from '@/interfaces/IGroupData';

export function getEmptyGroup(): IGroupData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    suppress: 'N',
    cap: '0',
    cu: '',
    color: '',
    includeFringe: 'Y',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newGroupData: IGroup;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newGroup: IGroupData = getEmptyGroup();
  updatedDataSheet.groups = updatedDataSheet.groups || [];
  updatedDataSheet.groups.splice(index, 0, newGroup);
  const newGroupData = groupDataToFormulaTransformation(newGroup, index);
  const updatedIds = [newGroup.id.toString()];

  return { updatedDataSheet, newGroupData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: IGroupData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.groups = updatedDataSheet.groups.map((group) => {
    if (group.id === record.id) {
      updatedIds.push(group.id.toString());
      return { ...group, ...record };
    }
    return group;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.groups[0] = {
      ...getEmptyGroup(),
      id: updatedDataSheet.groups[0].id,
    };
  }
  const removedGroupsSheet: IGroupData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet[DateSheetAttributeType.GROUPS].forEach((groupData, index) => {
    if (!removeIndexes.includes(index)) {
      removedGroupsSheet.push(groupData);
    } else {
      updatedIds.push(groupData.id.toString());
    }
  });
  updatedDataSheet[DateSheetAttributeType.GROUPS] = removedGroupsSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: IGroupData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.groups[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.groups.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.groups.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedGroupRow(copiedRow: IGroupData): IGroupData {
  const updatedRow = getEmptyGroup();

  // columns that need to be updated before pasting
  const reviseColumns = [
    GroupTypes.CATEGORY,
    GroupTypes.DESCRIPTION,
    GroupTypes.CAP,
    GroupTypes.CU,
    GroupTypes.COLOR,
    GroupTypes.INCLUDE_FRINGE,
  ];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeGroupCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  emptyCells: ICellCoords[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const emptyCells: ICellCoords[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.groups.forEach((data: IGroupData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof IGroupData] = '';
        }
      });
    });
  }
  return { updatedDataSheet, updatedIds, emptyCells };
}
