import { createTheme } from '@mui/material/styles';
import { palettes } from './palettes';
import { typography } from './typography';

const theme = createTheme({
  palette: {
    ...palettes,
  },
  typography,
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '& svg': {
            stroke: palettes.iconStroke.main,
          },
        },
      },
    },
  },
});

export default theme;
