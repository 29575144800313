export enum SetColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  includeFringe = 4,
  total = 5,
  usage = 6,
  attachments = 7,
  notes = 8,
  rowType = 9,
  index = 10,
}

export enum VisibleSetColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  includeFringe = 3,
  total = 4,
  usage = 5,
  index = 6,
  rowType = 7,
}

export const setsToVisibleColumnMap: {
  [key in SetColumnIndexes]?: VisibleSetColumnIndexes;
} = {
  [SetColumnIndexes.id]: VisibleSetColumnIndexes.id,
  [SetColumnIndexes.category]: VisibleSetColumnIndexes.category,
  [SetColumnIndexes.code]: VisibleSetColumnIndexes.code,
  [SetColumnIndexes.description]: VisibleSetColumnIndexes.description,
  [SetColumnIndexes.includeFringe]: VisibleSetColumnIndexes.includeFringe,
  [SetColumnIndexes.total]: VisibleSetColumnIndexes.total,
  [SetColumnIndexes.usage]: VisibleSetColumnIndexes.usage,
};
