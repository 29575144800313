import { Stack } from '@mui/material';
import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BudgetLevels,
  CELL_ERROR_LOCATION,
  L1ColumnIndexes,
  L2ColumnIndexes,
  L3ColumnIndexes,
} from '@/enums';
import { useAggregations } from '@/hooks';
import {
  cellErrorDetailsAtom,
  cellErrorDialogAtom,
  cellErrorLocationAtom,
  dataSheetAtom,
} from '@/atoms/DataSheetAtom';
import { fileUrlAtom, selectedCurrencyAtom } from '@/atoms/GlobalAtoms';
import { DialogBoxContainer, MainButton, MultiSelectDropDown } from '@/components/common';
import { InputTextField } from '@/components/common/InputTextField';
import { SelectField } from '@/components/common/SelectField';
import { useToaster } from '@/components/common/Toaster';
import FormulaSheet from '@/components/sheets/FormulaSheet';
import {
  filterDataSheet,
  removeAdjacentSpecialRows,
  removeEmptySheetsAndOrderDataSheet,
} from '@/helpers/FilterDataSheet';
import { formatSelectOptionArray } from '@/helpers/formatSelectOption';
import { generateJSONDoc } from '@/helpers/generateJSONDoc';
import { generateXMLDoc } from '@/helpers/generateXMLDoc';
import { generateReportDataWithSheetName } from '@/helpers/ReportSheetHelper';
import { ICellError } from '@/interfaces/ICellError';
import { IDataSheet } from '@/interfaces/IDataSheet';
import * as AggregationCalculator from '@/sheets/Aggregations';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
}

const checkFingersAllocation = (item: any, sheetLevel: string, budgetOption: string) => {
  switch (sheetLevel) {
    case BudgetLevels.THIRD_LEVEL:
      if (budgetOption !== 'account' && item[L3ColumnIndexes.rowType] === 'F') {
        return false;
      } else return !(budgetOption === 'none' && item[L3ColumnIndexes.rowType] === 'F');
    case BudgetLevels.SECOND_LEVEL:
      if (budgetOption !== 'category' && item[L2ColumnIndexes.rowType] === 'F') {
        return false;
      } else return !(budgetOption === 'none' && item[L2ColumnIndexes.rowType] === 'F');
    case BudgetLevels.FIRST_LEVEL:
      if (budgetOption === 'production' && item[L1ColumnIndexes.rowType] === 'F') {
        return false;
      } else if (
        (budgetOption === 'category' || budgetOption === 'account') &&
        item[L1ColumnIndexes.rowType] === 'F'
      ) {
        return false;
      } else return !(budgetOption === 'none' && item[L1ColumnIndexes.rowType] === 'F');
    default:
      return true;
  }
};

const getBudgetSheetLevel = (sheetName: string): BudgetLevels | undefined => {
  if (sheetName.startsWith('l1')) {
    return BudgetLevels.FIRST_LEVEL;
  } else if (sheetName.startsWith('l2_')) {
    return BudgetLevels.SECOND_LEVEL;
  } else if (sheetName.startsWith('l3_')) {
    return BudgetLevels.THIRD_LEVEL;
  }
};

const filterValueSheetDateByBudgetOption = (
  valueSheetData: Record<string, CellValue[][]>,
  budgetOption: string,
): Record<string, CellValue[][]> => {
  console.info('valueSheetData ', valueSheetData);
  const filteredData: Record<string, CellValue[][]> = { ...valueSheetData };

  for (const sheetName in valueSheetData) {
    const sheetLevel = getBudgetSheetLevel(sheetName);

    if (sheetLevel && Object.prototype.hasOwnProperty.call(valueSheetData, sheetName)) {
      const rowData = valueSheetData[sheetName];
      filteredData[sheetName] = rowData.filter((cellValue) => {
        return checkFingersAllocation(cellValue, sheetLevel, budgetOption);
      });
    }
  }

  return filteredData;
};

export function ExportDialog({ open, setOpen, formulaSheet }: Props) {
  const aggregationsProcessor = useAggregations();

  const [exportState, setExportState] = useState({
    accountFormat: '',
    selectedCurrencies: [] as string[],
    selectedLocations: [] as string[],
    selectedGroups: [] as string[],
    selected: '',
  });
  const [loading, setLoading] = useState(false);
  const snackBar = useToaster();

  const { selected, selectedCurrencies, selectedLocations, selectedGroups } = exportState;
  const [dataSheet] = useRecoilState(dataSheetAtom);
  const fileUrl = useRecoilValue(fileUrlAtom);
  const selectedCurrency = useRecoilValue(selectedCurrencyAtom);
  const setExportErrorDialog = useSetRecoilState(cellErrorDialogAtom);
  const setExportErrorDetails = useSetRecoilState(cellErrorDetailsAtom);
  const setExportErrorLocation = useSetRecoilState(cellErrorLocationAtom);

  const onExport = async () => {
    setLoading(true);

    if (!selected || selected === 'null' || fileUrl === null) {
      snackBar.error('Please select a layout');
      setLoading(false);
      return;
    }

    const dataSheetCopy = lodash.cloneDeep(dataSheet) as IDataSheet;
    const filterData = filterDataSheet({
      dataSheetCopy,
      selectedCurrencies,
      selectedLocations,
      selectedGroups,
      selectedOption: selected,
    });

    const formatDataSheet = removeEmptySheetsAndOrderDataSheet(filterData);

    const hasDataInL3 = Object.keys(formatDataSheet.l3).some((key) => {
      const array = formatDataSheet.l3[key];
      return array.length > 0 && array.some((item) => item.rowType !== 'D');
    });

    if (!hasDataInL3) {
      snackBar.error('No data to export');
      setLoading(false);
      return;
    }

    const exportFileName = fileUrl.replace(/\.[^/.]+$/, '');

    if (selected === 'Hollywood Budgets Sheet') {
      const removeFileAttachments = formatDataSheet.fileAttachments
        ? { ...formatDataSheet, fileAttachments: {} }
        : formatDataSheet;

      const jsonString = JSON.stringify(removeFileAttachments);
      const downloadLink = document.createElement('a');
      const file = new Blob([jsonString], { type: 'application/json' });
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = `${exportFileName}.json`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      snackBar.success('Exported Successfully');
      setLoading(false);
      return;
    }

    const newFormulaSheet = new FormulaSheet();
    await newFormulaSheet.fromDataSheet(formatDataSheet);

    const sheetValuesBeforeAggregation: Record<string, CellValue[][]> =
      newFormulaSheet.getAllSheetValues();
    const updatedAggregations = await aggregationsProcessor.calculate(
      sheetValuesBeforeAggregation,
      formulaSheet.getL3FringesCellOrder(),
    );

    const aggregationsMap = AggregationCalculator.getAggregationMap(updatedAggregations);
    await newFormulaSheet.updateAggregations(aggregationsMap);

    let reportData: Record<string, CellValue[][]> = newFormulaSheet.getAllSheetValues();

    reportData = filterValueSheetDateByBudgetOption(reportData, formatDataSheet.meta?.fringes.calc);
    const transformedData = await generateReportDataWithSheetName({
      reportData,
      context: 'export',
    });

    if (Object.keys(transformedData.l3).length === 0) {
      snackBar.error('No data to export');
      setLoading(false);
      return;
    }

    // Default Currency Code
    const checkDefaultCurrency = formatDataSheet.currency.filter(
      (currency) => currency.default === 'Y',
    );
    const defaultCurrency =
      checkDefaultCurrency && checkDefaultCurrency.length > 0 ? checkDefaultCurrency[0].code : '';

    try {
      if (selected === 'XML') {
        generateXMLDoc(
          removeAdjacentSpecialRows(transformedData.l1),
          removeAdjacentSpecialRows(transformedData.l2),
          removeAdjacentSpecialRows(transformedData.l3),
          formatDataSheet,
          selectedCurrency,
          defaultCurrency,
          exportFileName,
        );
        snackBar.success('Exported Successfully');
      } else if (selected === 'JSON' || selected === 'Hollywood Budgets Sheet') {
        generateJSONDoc(
          removeAdjacentSpecialRows(transformedData.l1),
          removeAdjacentSpecialRows(transformedData.l2),
          removeAdjacentSpecialRows(transformedData.l3),
          formatDataSheet,
          selectedCurrency,
          defaultCurrency,
          exportFileName,
        );
        snackBar.success('Exported Successfully');
      }
    } catch (error: any) {
      try {
        let parsedExportError = JSON.parse(error.message);
        console.info('parsedExportError ===> ', error.message);
        if (!Array.isArray(parsedExportError)) {
          parsedExportError = [parsedExportError];
        }
        const newExportErrorDetails: ICellError[] = [];
        parsedExportError.forEach((item: any) => {
          if (item && typeof item === 'object' && 'value' in item) {
            const existingItem = newExportErrorDetails.find(
              (existingItem) => existingItem.address === item.address,
            );
            if (!existingItem) {
              newExportErrorDetails.push({
                value: item.value,
                address: item.address,
                message: item.message,
                type: item.message,
              });
            }
          }
        });
        setOpen(false);
        setExportErrorLocation(CELL_ERROR_LOCATION.EXPORT);
        setExportErrorDialog(true);
        setExportErrorDetails(newExportErrorDetails);
        return;
      } catch (parsingError) {
        console.error('Error parsing thrown error message:', parsingError);
      }
    } finally {
      setLoading(false);
    }
  };

  const addBlankOption = (options: any) => {
    return [{ value: '', label: '( Blank)' }, ...options];
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.key === 'Enter') {
      onExport();
    }
  };

  return (
    <DialogBoxContainer
      open={open}
      onKeyDown={handleKeyDown}
      setOpen={setOpen}
      dialogTitle="Export"
      boxWidth={'310px'}
      dialogActions={
        <MainButton
          onClick={() => onExport()}
          autoFocus
          color="primary"
          variant="contained"
          loading={loading}
        >
          Export
        </MainButton>
      }
    >
      <Stack direction="row" spacing={3}>
        <Stack direction="column" width={'100%'}>
          <SelectField
            labelText="Layout"
            placeholder="Select Layout"
            options={[
              { value: 'Hollywood Budgets Sheet', label: 'Hollywood Budgets' },
              { value: 'JSON', label: 'JSON' },
              { value: 'XML', label: 'XML' },
            ]}
            onChange={(value) => {
              setExportState((prevState) => ({ ...prevState, selected: value }));
            }}
            value={selected}
          />
          <MultiSelectDropDown
            labelText="Currencies"
            placeholder="Currencies"
            options={addBlankOption(
              formatSelectOptionArray(formulaSheet.getCurrencyList(), 2, 2).filter(
                (option) => option.value !== '',
              ),
            )}
            values={selectedCurrencies || []}
            onChange={(value) => {
              setExportState((prevState) => ({ ...prevState, selectedCurrencies: value }));
            }}
          />
          <MultiSelectDropDown
            labelText="Location"
            placeholder="Location"
            options={addBlankOption(
              formatSelectOptionArray(formulaSheet.getLocationsList(), 2, 2).filter(
                (option) => option.value !== '',
              ),
            )}
            onChange={(value) => {
              setExportState((prevState) => ({ ...prevState, selectedLocations: value }));
            }}
            values={selectedLocations || []}
          />
          <MultiSelectDropDown
            labelText="Groups"
            placeholder="Groups"
            options={addBlankOption(
              formatSelectOptionArray(formulaSheet.getGroupList(), 2, 2).filter(
                (option) => option.value !== '',
              ),
            )}
            onChange={(value) => {
              setExportState((prevState) => ({ ...prevState, selectedGroups: value }));
            }}
            values={selectedGroups || []}
          />
          <InputTextField
            labelText="Account Format"
            placeholder="Account Format"
            value={exportState.accountFormat}
            onChange={(value) =>
              setExportState((prevState) => ({ ...prevState, accountFormat: value }))
            }
          />
        </Stack>
      </Stack>
    </DialogBoxContainer>
  );
}

export default ExportDialog;
