import styled from '@emotion/styled';

export const StyledMenu = styled.div`
  background: #f7f8f9;
  min-height: 100vh;
`;

export const StyledMenuItem = styled.div`
  background: #f1f3f4;
  margin-bottom: 10px;
  border-radius: 7px;
  width: 160px;
`;

export const StyledMenuItemText = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  width: 160px;
`;
