import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const RefreshIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M19 11C19 15.416 15.416 19 11 19C6.584 19 3.888 14.552 3.888 14.552M3.888 14.552H7.504M3.888 14.552V18.552M3 11C3 6.584 6.552 3 11 3C16.336 3 19 7.448 19 7.448M19 7.448V3.448M19 7.448H15.448"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
