export enum CurrencyTypes {
  ID = 'id',
  CATEGORY = 'category',
  CODE = 'code',
  DESCRIPTION = 'description',
  RATE = 'rate',
  DEFAULT = 'default',
  INCLUDE_FRINGE = 'includeFringe',
  TOTAL = 'total',
  USAGE = 'usage',
  ATTACHMENTS = 'attachments',
  NOTES = 'notes',
}

export interface ICurrencyData {
  id: string;
  category: string;
  code: string;
  description: string;
  rate: string;
  default: string;
  includeFringe: string;
  total: string;
  usage: string;
  attachments: string;
  notes: string;
}
