/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import axios, { AxiosError } from 'axios';
import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { APP_PATH, CELL_ERROR_LOCATION, LocalStorageVariables, REPORT_TYPE } from '@/enums';
import { useField } from '@/hooks';
import { useAggregations } from '@/hooks';
import { WebStorage, reportInputValidator, generateReportId } from '@/utils';
import {
  dataSheetAtom,
  cellErrorDetailsAtom,
  cellErrorDialogAtom,
  cellErrorLocationAtom,
} from '@/atoms/DataSheetAtom';
import { fileUrlAtom, rootFolder, selectedCurrencyAtom } from '@/atoms/GlobalAtoms';
import {
  CheckboxField,
  DialogBoxContainer,
  InputTextField,
  MainButton,
  MultiSelectDropDown,
  SelectField,
} from '@/components/common';
import { useToaster } from '@/components/common/Toaster';
import { FringeAllocationDataKeys } from '@/data/meta/FringeAllocationData';
import {
  filterDataSheet,
  filterDataSheetByFringeAllocation,
  removeZeroTotalsFromL3Rows,
} from '@/helpers/FilterDataSheet';
import {
  formatSelectOptionArray,
  formatSelectOptionArrayCommon,
} from '@/helpers/formatSelectOption';
import { generateReportDataWithSheetName } from '@/helpers/ReportSheetHelper';
import { ICellError } from '@/interfaces/ICellError';
import { IDataSheet, IDataSheetConfigs } from '@/interfaces/IDataSheet';
import { IMeta } from '@/interfaces/IMetaData';
import { IHeaderNotes } from '@/interfaces/IReportDataSheet';
import {
  deleteMRTReportLayout,
  deleteSavedReports,
  getMRTReportLayoutList,
  getSavedReports,
  saveReportData,
  uploadMRTReportLayout,
} from '@/services/ReportsAPIService';
import * as AggregationCalculator from '@/sheets/Aggregations';
import FormulaSheet from '@/sheets/FormulaSheet';
import { TextButton } from './style';
import { IReportFilterList } from './types';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
}

const DEFAULT_REPORT: IReportFilterList = {
  reportName: 'Default',
  layoutName: '',
  layoutType: 'Budget Report3',
  baseCurrency: '',
  currencies: '',
  groups: '',
  locations: '',
  sets: '',
  headerNoteLeft: '',
  headerNoteRight: '',
  createSubBudgets: 'false',
  onConflictExclude: 'false',
  excludeFringes: 'false',
  printZeroTotalRows: 'true',
  defaultReport: true,
};

const SYSTEM_LAYOUT_OPTIONS = [
  {
    value: 'Budget Report3',
    label: 'Default',
  },
  {
    value: 'WB Report',
    label: 'Layout 02',
  },
];

export const ReportDialogBox = ({ open, setOpen, formulaSheet }: Props) => {
  const snackBar = useToaster();
  const aggregationsProcessor = useAggregations();
  const [updateReportData, setUpdateReportData] = useState(false);
  const [reportList, setReportList] = useState<Array<IReportFilterList>>([]);
  const [reportLayoutList, setReportLayoutList] = useState<{ value: string; label: string }[]>([]);
  const [selectedReport, setSelectedReport] = useState<string>();
  const [reportName, setReportName, reportNameError] = useField<string>('', reportInputValidator);
  const [layoutName, setLayoutName, layoutNameError] = useField<string>('', reportInputValidator);
  const reportLayoutFileInputRef = useRef<HTMLInputElement | null>(null);
  const [isReportLayoutFileUploading, setIsReportLayoutFileUploading] = useState<boolean>(false);
  const [isReportLayoutFileDeleting, setIsReportLayoutFileDeleting] = useState<boolean>(false);
  const [headerNoteLeft, setHeaderNoteLeft] = useState<string>();
  const [headerNoteRight, setHeaderNoteRight] = useState<string>();
  const [baseCurrency, setBaseCurrency] = useState<string>();
  const [dataSheet] = useRecoilState(dataSheetAtom);
  const rootPath = useRecoilValue(rootFolder);
  const fileUrl = useRecoilValue(fileUrlAtom);
  const setReportErrorDialog = useSetRecoilState(cellErrorDialogAtom);
  const setReportErrorLocation = useSetRecoilState(cellErrorLocationAtom);
  const setErrorDetails = useSetRecoilState(cellErrorDetailsAtom);
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>();
  const [selectedLocations, setSelectedLocations] = useState<string[]>();
  const [selectedGroups, setSelectedGroups] = useState<string[]>();
  const [selectedSets, setSelectedSets] = useState<string[]>();
  const [selectedLayout, setSelectedLayout] = useState<string | undefined>(undefined);
  const [defaultReport, setDefaultReport] = useState<boolean>(false);
  const [printZeroTotal, setPrintZeroTotal] = useState<boolean>(false);
  const [isReportSavedSuccessfully, setIsReportSavedSuccessfully] = useState(false);
  const [isLayoutUploadSuccessfully, setIsLayoutUploadedSuccessfully] = useState(false);
  const selectedCurrency = useRecoilValue(selectedCurrencyAtom);
  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
  const webStorage = new WebStorage();
  // Select Report Template Layout Variables
  const [isDeleteIconDisabled, setIsDeleteIconDisabled] = useState<boolean>(true);

  const resetFormFields = () => {
    setLayoutName('');
    setReportName('');
    setSelectedLocations([]);
    setSelectedGroups([]);
    setSelectedLayout(undefined);
    setSelectedCurrencies([]);
    setSelectedReport('null');
    setSelectedSets([]);
    setBaseCurrency('');
    setHeaderNoteLeft('');
    setHeaderNoteRight('');
    setDefaultReport(false);
    setPrintZeroTotal(false);
    setUpdateReportData(!updateReportData);
  };

  const updateReportLayoutList = useCallback(async () => {
    if (!username || username === '') return;

    const layoutList = await getMRTReportLayoutList(username);
    const layoutOptions = layoutList.map((layout) => ({ value: layout, label: layout }));
    setReportLayoutList(SYSTEM_LAYOUT_OPTIONS.concat(layoutOptions));
  }, [username]);

  useEffect(() => {
    const getReportList = async () => {
      const remoteSavedReports = await getSavedReports(rootPath, fileUrl);
      const reportsList = [...remoteSavedReports, DEFAULT_REPORT];
      setReportList(reportsList);
      const defaultReport = reportsList.filter((item: IReportFilterList) => item.defaultReport);
      if (defaultReport?.length > 0) {
        setSelectedReport(defaultReport[0].reportName);
        setSelectedFormValues(defaultReport[0]);
      }
    };
    if (rootPath) {
      getReportList();
      (async () => await updateReportLayoutList())();
    }
    // eslint-disable-next-line
  }, [updateReportData, fileUrl]);

  const openReportPreviewInNewTab = (reportId: string, selectedLayout?: string) => {
    const baseUrl = window.location.origin;
    const url = new URL(APP_PATH.reportPreview, baseUrl);
    url.searchParams.append('key', reportId);
    selectedLayout && url.searchParams.append('layout', selectedLayout);
    window.open(url, '_blank');
  };

  const generateReport = async (): Promise<void> => {
    try {
      const dataSheetCopy = lodash.cloneDeep(dataSheet) as IDataSheet;
      const filteredReport = filterDataSheet({
        dataSheetCopy,
        selectedCurrencies,
        selectedLocations,
        selectedGroups,
        selectedSets,
      });
      const newFormulaSheet = new FormulaSheet();

      await newFormulaSheet.fromDataSheet(filteredReport);
      const sheetValuesBeforeAggregation: Record<string, CellValue[][]> =
        newFormulaSheet.getAllSheetValues();
      const reportDataValueAggregations = printZeroTotal
        ? sheetValuesBeforeAggregation
        : removeZeroTotalsFromL3Rows(sheetValuesBeforeAggregation);
      const updatedAggregations = await aggregationsProcessor.calculate(
        reportDataValueAggregations,
        formulaSheet.getL3FringesCellOrder(),
      );
      const aggregationsMap = AggregationCalculator.getAggregationMap(updatedAggregations);
      await newFormulaSheet.updateAggregations(aggregationsMap);
      newFormulaSheet.updateBaseCurrency(selectedCurrency);
      const reportDataValue: Record<string, CellValue[][]> = newFormulaSheet.getAllSheetValues();
      const reportData = printZeroTotal
        ? reportDataValue
        : removeZeroTotalsFromL3Rows(reportDataValue);
      const headerNotes: IHeaderNotes = {
        left: headerNoteLeft,
        right: headerNoteRight,
      };
      const meta = filteredReport.meta as IMeta;
      const configWithProjectName = {
        ...filteredReport.configs,
        projectName: dataSheetCopy.meta.file.projectName,
      } as IDataSheetConfigs & { projectName: string };
      const filteredReportData = filterDataSheetByFringeAllocation(
        reportData,
        dataSheetCopy.meta?.fringes.calc as FringeAllocationDataKeys,
      );

      try {
        const transformedData = await generateReportDataWithSheetName({
          reportData: filteredReportData,
          headerNotes,
          meta,
          configs: configWithProjectName,
          context: 'report',
        });
        console.log(`transformedData / report input data ===>`, transformedData);

        const reportId = generateReportId();
        await webStorage.saveReport(reportId, JSON.stringify(transformedData));

        openReportPreviewInNewTab(reportId, selectedLayout);
      } catch (err: any) {
        try {
          const parsedError = JSON.parse(err.message);
          const newErrorDetails: ICellError[] = [];
          parsedError.forEach((item: any) => {
            if (item && typeof item === 'object' && 'value' in item) {
              // Check if an item with the same 'address' already exists in 'newErrorDetails'.
              const existingItem = newErrorDetails.find(
                (existingItem) => existingItem.address === item.address,
              );
              if (!existingItem) {
                newErrorDetails.push({
                  value: item.value,
                  address: item.address,
                  message: item.message,
                  type: item.type,
                });
              }
            }
          });
          setErrorDetails(newErrorDetails);
        } catch (parsingError) {
          console.error('Error parsing thrown error message:', parsingError);
        }
        setReportErrorLocation(CELL_ERROR_LOCATION.REPORT);
        setReportErrorDialog(true);
        setOpen(false);
        console.error(`Error generating report: ${err.message}`);
        return;
      }
    } catch (err) {
      snackBar.error('Error generating report. Please try again');
      console.error(`Error generating report: ${err}`);
    } finally {
      setOpen(false);
    }
  };

  const handleInsertReportData = async () => {
    if (reportNameError) {
      setIsReportSavedSuccessfully(false);
      setIsLayoutUploadedSuccessfully(false);
      return;
    }
    const reportData = {
      reportName,
      layoutName: layoutName || '',
      layoutType: selectedLayout || '',
      baseCurrency: baseCurrency || '',
      currencies: JSON.stringify(selectedCurrencies) || '',
      groups: JSON.stringify(selectedGroups) || '',
      locations: JSON.stringify(selectedLocations) || '',
      sets: JSON.stringify(selectedSets) || '',
      headerNoteLeft: headerNoteLeft || '',
      headerNoteRight: headerNoteRight || '',
      createSubBudgets: 'false',
      onConflictExclude: 'false',
      excludeFringes: 'false',
      printZeroTotalRows: printZeroTotal ? 'true' : 'false',
      defaultReport: defaultReport ? 'true' : 'false',
    };

    if (reportName !== '' || reportName !== undefined || reportName !== null) {
      const response = await saveReportData(reportData, rootPath, fileUrl);
      if (response.status === 'SUCCESS') {
        setIsReportSavedSuccessfully(true);
        resetFormFields();

        snackBar.success('Report data saved successfully.');
      } else {
        snackBar.error('Error while saving report data. Please try again later.');
      }
    }
  };

  const handleFillReportData = (value: string) => {
    setSelectedReport(value);
    setSelectedFormValues(
      reportList.filter((item: IReportFilterList) => item.reportName === value)[0],
    );
  };

  const updateSelectMRTReportLayoutDeleteBtnStatus = useCallback(
    (layoutName: string) => {
      const systemLayoutValues = SYSTEM_LAYOUT_OPTIONS.map(({ value }) => value);
      const isDisable = layoutName === 'null' || systemLayoutValues.includes(layoutName);
      setIsDeleteIconDisabled(isDisable);
    },
    [reportList],
  );

  const setSelectedFormValues = useCallback(
    (item: IReportFilterList) => {
      setLayoutName(item?.layoutName || '');
      setReportName(item?.reportName || '');
      setSelectedCurrencies(item?.currencies ? JSON.parse(item?.currencies) : []);
      setSelectedGroups(item?.groups ? JSON.parse(item?.groups) : []);
      setSelectedLocations(item?.locations ? JSON.parse(item?.locations) : []);
      setSelectedSets(item?.sets ? JSON.parse(item?.sets) : []);
      setSelectedLayout(item?.layoutType ?? undefined);
      setBaseCurrency(item?.baseCurrency || '');
      setHeaderNoteLeft(item?.headerNoteLeft || '');
      setHeaderNoteRight(item?.headerNoteRight || '');
      setDefaultReport(item?.defaultReport);
      setPrintZeroTotal(!!item?.printZeroTotalRows);

      updateSelectMRTReportLayoutDeleteBtnStatus(item?.layoutType || 'null');
    },
    [updateSelectMRTReportLayoutDeleteBtnStatus],
  );

  const handleDeleteSaveReport = async (): Promise<void> => {
    const reportExists: boolean = reportList.some(
      (item: IReportFilterList) => item.reportName === reportName,
    );

    if (!reportExists) {
      snackBar.error('Report name does not exist.');
      return;
    }

    try {
      const response = await deleteSavedReports(username, fileUrl, reportName);
      if (response.status === 'SUCCESS') {
        resetFormFields();
        snackBar.success('Report deleted successfully.');
      } else {
        snackBar.error('Failed to delete the report. Please try again later.');
      }
    } catch (error) {
      snackBar.error('Error while deleting report data. Please try again later.');
    }
  };

  const handleSelectReportLayout = useCallback(
    (selectValue: string) => {
      setSelectedLayout(selectValue);
      updateSelectMRTReportLayoutDeleteBtnStatus(selectValue);
    },
    [updateSelectMRTReportLayoutDeleteBtnStatus],
  );

  const handleReportLayoutUploadBtn = useCallback(async () => {
    if (layoutName === '') {
      snackBar.error('Layout Name cannot be empty!');
      return;
    }
    if (layoutNameError) {
      return;
    }
    const getReportLayoutOptionLabels = () => reportLayoutList.map(({ label }) => label);

    if (getReportLayoutOptionLabels().includes(layoutName)) {
      snackBar.error('Layout Name already exist, rename the layout name.');
      return;
    }
    reportLayoutFileInputRef?.current?.click();
  }, [reportLayoutList, layoutName, snackBar]);

  const handleDeleteSelectReportLayout = useCallback(async () => {
    if (!username || !selectedLayout) return;
    setIsReportLayoutFileDeleting(true);

    const saveReportLayoutList = reportList.map(({ layoutType }) => layoutType);
    const isLayoutIncludeInReport = saveReportLayoutList.includes(selectedLayout);

    if (isLayoutIncludeInReport) {
      snackBar.error('MRT report layout is available in saved reports.');
      setIsReportLayoutFileDeleting(false);
      return;
    }

    await deleteMRTReportLayout({
      username,
      uploadFileName: selectedLayout,
      contentType: REPORT_TYPE.MRT,
    });
    await updateReportLayoutList();
    setIsReportLayoutFileDeleting(false);
    snackBar.success(`Successfully deleted report layout File '${selectedLayout}'`);
    setSelectedLayout(undefined);
  }, [username, snackBar, selectedLayout, updateReportLayoutList]);

  const resetReportLayoutFileInputRef = () => {
    if (reportLayoutFileInputRef.current) {
      reportLayoutFileInputRef.current.value = '';
    }
  };

  const handleReportLayoutFileInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!username) {
        resetReportLayoutFileInputRef();
        return;
      }

      const selectedFiles = event.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const selectedFile = selectedFiles[0];
        if (selectedFile.name.endsWith(`.${REPORT_TYPE.MRT}`)) {
          // Handle the selected .mrt file here
          setIsReportLayoutFileUploading(true);
          try {
            await uploadMRTReportLayout({
              username,
              file: selectedFile,
              uploadFileName: layoutName,
              contentType: REPORT_TYPE.MRT,
            });
            await updateReportLayoutList();
          } catch (error) {
            if (axios.isAxiosError(error)) {
              const axiosError = error as AxiosError;
              if (axiosError.response) {
                // An error occurred during the DELETE request, and there is a response from the server
                const response = axiosError.response;
                console.error('HTTP error status:', response.status);
                console.error('Server response data:', response.data);
                snackBar.error(response.data as unknown as string);
              } else {
                // An error occurred, but there was no response from the server
                console.error('Network error:', error.message);
                snackBar.error('Network error occurred.');
              }
            }
            setIsReportLayoutFileUploading(false);
            setIsLayoutUploadedSuccessfully(true);
            return;
          }
          snackBar.success(`Successfully uploaded the MRT Report Layout '${layoutName}'.`);
          setLayoutName('');
          setIsLayoutUploadedSuccessfully(true);
        } else {
          // Display an error message or prevent further processing
          console.error('Invalid file format. Please select a .mrt file.');
        }
      }
      setIsReportLayoutFileUploading(false);
      resetReportLayoutFileInputRef();
    },
    [layoutName, snackBar, updateReportLayoutList, username],
  );

  const handleGenerateReport = async () => {
    await generateReport();
    setOpen(false);
  };

  return (
    <DialogBoxContainer
      open={open}
      setOpen={setOpen}
      onDialogBoxClose={() => {
        resetFormFields();
        setOpen(false);
      }}
      dialogTitle="Reports"
      boxWidth={'700px'}
      dialogActions={
        <Stack direction="row" spacing={3} width={'100%'} justifyContent={'flex-end'}>
          <MainButton
            onClick={handleGenerateReport}
            autoFocus
            color="primary"
            variant="contained"
            disabled={!selectedLayout || selectedLayout === 'null'}
          >
            Run
          </MainButton>
        </Stack>
      }
    >
      <Stack direction="row" spacing={3}>
        <Stack direction="column" width={'100%'}>
          <div style={{ maxWidth: '316px' }}>
            <SelectField
              labelText={'Saved Reports'}
              placeholder={'Select Report'}
              deleteOption
              onDelete={handleDeleteSaveReport}
              isDeleteIconDisabled={selectedReport === DEFAULT_REPORT.reportName}
              options={
                (reportList &&
                  formatSelectOptionArrayCommon(reportList, 'reportName', 'reportName')) ||
                []
              }
              onChange={(value) => handleFillReportData(value)}
              value={selectedReport}
            />
          </div>
          <CheckboxField
            label={<Typography variant="body2">Default Report</Typography>}
            checked={defaultReport}
            onChange={setDefaultReport}
            sx={{
              marginTop: '-20px !important',
              marginBottom: '5px !important',
              zIndex: 1000,
              width: 'fit-content',
            }}
          />
        </Stack>
        <SelectField
          labelText={'Layout'}
          placeholder={'Select Layout'}
          isDeleteIconDisabled={isDeleteIconDisabled || isReportLayoutFileDeleting}
          isDisabled={isReportLayoutFileDeleting || isReportLayoutFileUploading}
          deleteOption
          isLoading={lodash.isEmpty(reportLayoutList)}
          onDelete={handleDeleteSelectReportLayout}
          options={reportLayoutList}
          onChange={handleSelectReportLayout}
          value={selectedLayout}
        />
      </Stack>

      <Stack direction="row" spacing={3}>
        <Stack direction="column" width={'100%'}>
          <InputTextField
            labelText={'Report Name'}
            placeholder={'Enter Report Name'}
            onChange={(value) => {
              setReportName(value);
              setIsReportSavedSuccessfully(false);
            }}
            value={reportName}
            error={isReportSavedSuccessfully ? false : reportNameError}
            required
          />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mt={-2}>
            <TextButton onClick={() => handleInsertReportData()}>Save</TextButton>
          </Stack>

          <SelectField
            labelText="Base Currency"
            placeholder="Select Base Currency"
            options={formatSelectOptionArray(formulaSheet.getCurrencyList(), 2, 2)}
            onChange={(value) => setBaseCurrency(value)}
            value={baseCurrency}
          />
        </Stack>
        <Stack direction="column" width={'100%'}>
          <InputTextField
            labelText={'Layout Name'}
            placeholder={'Enter Layout Name'}
            onChange={(value) => {
              setLayoutName(value);
              setIsLayoutUploadedSuccessfully(false);
            }}
            value={layoutName}
            isLoading={isReportLayoutFileUploading}
            isDisabled={isReportLayoutFileUploading}
            error={isLayoutUploadSuccessfully ? false : layoutNameError}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mt={-2}
            mb={2}
            spacing={2}
          >
            <input
              type={'file'}
              ref={reportLayoutFileInputRef}
              onChange={handleReportLayoutFileInput}
              style={{ display: 'none' }}
              multiple={false}
              accept={'.mrt'}
            />
            <TextButton
              disabled={isReportLayoutFileUploading}
              onClick={handleReportLayoutUploadBtn}
            >
              Upload
            </TextButton>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={3}>
        <MultiSelectDropDown
          labelText="Currency"
          placeholder="Currency"
          options={formatSelectOptionArray(formulaSheet.getCurrencyList(), 2, 2).filter(
            (option) => option.value !== '',
          )}
          values={selectedCurrencies || []}
          onChange={(value) => {
            setSelectedCurrencies(value);
          }}
          countOption={true}
        />
        <MultiSelectDropDown
          labelText="Location"
          placeholder="Location"
          options={formatSelectOptionArray(formulaSheet.getLocationsList(), 2, 2).filter(
            (option) => option.value !== '',
          )}
          onChange={(value) => {
            setSelectedLocations(value);
          }}
          values={selectedLocations || []}
          countOption={true}
        />
      </Stack>

      <Stack direction="row" spacing={3}>
        <MultiSelectDropDown
          labelText="Groups"
          placeholder="Groups"
          options={formatSelectOptionArray(formulaSheet.getGroupList(), 2, 2).filter(
            (option) => option.value !== '',
          )}
          onChange={(value) => {
            setSelectedGroups(value);
          }}
          values={selectedGroups || []}
          countOption={true}
        />
        <MultiSelectDropDown
          labelText="Sets"
          placeholder="Select Sets"
          options={formatSelectOptionArray(formulaSheet.getSetsList(), 2, 2).filter(
            (option) => option.value !== '',
          )}
          onChange={(value) => {
            setSelectedSets(value);
          }}
          values={selectedSets || []}
          countOption={true}
        />
      </Stack>

      <Stack direction="row" spacing={3}>
        <InputTextField
          labelText="Header Notes left"
          placeholder="Enter here.."
          multiline
          rows={3}
          onChange={(value) => setHeaderNoteLeft(value)}
          value={headerNoteLeft}
        />
        <InputTextField
          labelText="Header Notes right"
          placeholder="Enter here.."
          multiline
          rows={3}
          onChange={(value) => setHeaderNoteRight(value)}
          value={headerNoteRight}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <CheckboxField
          label={<Typography variant="body2">Print Zero Total Rows</Typography>}
          checked={printZeroTotal}
          onChange={setPrintZeroTotal}
          sx={{
            marginLeft: '-10px !important',
          }}
        />
      </Stack>
    </DialogBoxContainer>
  );
};
