/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatSelectOptionArray = (
  array: any[],
  labelIndex: number | string,
  valueIndex: number | string,
) => {
  return array.map((item) => {
    return {
      label: item[labelIndex]?.replace(/[^\w\s]/gi, ''),
      value: item[valueIndex]?.replace(/[^\w\s]/gi, ''),
    };
  });
};

export const formatSelectOptionArrayCommon = (
  array: any[],
  labelIndex: number | string,
  valueIndex: number | string,
) => {
  return array.map((item) => {
    return {
      label: item[labelIndex],
      value: item[valueIndex],
    };
  });
};
