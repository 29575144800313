export enum CurrencyColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  rate = 4,
  default = 5,
  includeFringe = 6,
  total = 7,
  usage = 8,
  attachments = 9,
  notes = 10,
  rowType = 11,
  index = 12,
}

export enum VisibleCurrencyColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  rate = 3,
  default = 4,
  includeFringe = 5,
  total = 6,
  usage = 7,
  index = 8,
}

export const currencyToVisibleColumnMap: {
  [key in CurrencyColumnIndexes]?: VisibleCurrencyColumnIndexes;
} = {
  [CurrencyColumnIndexes.id]: VisibleCurrencyColumnIndexes.id,
  [CurrencyColumnIndexes.category]: VisibleCurrencyColumnIndexes.category,
  [CurrencyColumnIndexes.code]: VisibleCurrencyColumnIndexes.code,
  [CurrencyColumnIndexes.description]: VisibleCurrencyColumnIndexes.description,
  [CurrencyColumnIndexes.rate]: VisibleCurrencyColumnIndexes.rate,
  [CurrencyColumnIndexes.default]: VisibleCurrencyColumnIndexes.default,
  [CurrencyColumnIndexes.includeFringe]: VisibleCurrencyColumnIndexes.includeFringe,
  [CurrencyColumnIndexes.total]: VisibleCurrencyColumnIndexes.total,
  [CurrencyColumnIndexes.usage]: VisibleCurrencyColumnIndexes.usage,
};
