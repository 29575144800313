import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const AccountIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.0002 10C12.3013 10 14.1668 8.13452 14.1668 5.83333C14.1668 3.53214 12.3013 1.66666 10.0002 1.66666C7.69898 1.66666 5.8335 3.53214 5.8335 5.83333C5.8335 8.13452 7.69898 10 10.0002 10Z"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
