import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const ReceiveSquareIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.24992 20.1666H13.7499C18.3333 20.1666 20.1666 18.3333 20.1666 13.75V8.24998C20.1666 3.66665 18.3333 1.83331 13.7499 1.83331H8.24992C3.66659 1.83331 1.83325 3.66665 1.83325 8.24998V13.75C1.83325 18.3333 3.66659 20.1666 8.24992 20.1666Z"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 10.5508L11 13.3008L13.75 10.5508"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13.3008V5.96747"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 15.1342C9.06583 16.3258 12.9342 16.3258 16.5 15.1342"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
