import { LinearProgress } from '@mui/material';
import React, { ReactElement } from 'react';
import { classes } from '@/components/common/FormProgressBar/style';

type Props = {
  value: number;
};

export const FormProgressBar = ({ value }: Props): ReactElement => (
  <LinearProgress variant="buffer" value={value} sx={classes.progressBar} />
);
