import { useState } from 'react';
import type { ConfirmationDialogProps } from './ConfirmationDialog';

type ConfirmationDialogOptions = {
  message: string;
  isYesNoOptionAllowed: boolean;
  title?: string;
  closeAfterAction?: boolean;
  isHtmlMessage?: boolean;
} & Pick<ConfirmationDialogProps, 'onClickNo' | 'onClickOk' | 'onClickYes'>;

const DEFAULT_OPTIONS: ConfirmationDialogOptions = {
  message: '',
  isYesNoOptionAllowed: false,
  title: 'Message Alert',
  closeAfterAction: true,
  isHtmlMessage: false,
};

const useConfirmationDialog = (): {
  openDialog: (options: ConfirmationDialogOptions) => void;
  closeDialog: () => void;
  confirmationDialogProps: ConfirmationDialogProps;
} => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmationDialogOptions>(DEFAULT_OPTIONS);

  const openDialog = ({
    message,
    isYesNoOptionAllowed = false,
    title = '',
    closeAfterAction = true,
    isHtmlMessage = false,
    ...actions
  }: ConfirmationDialogOptions) => {
    setOptions({
      ...actions,
      message,
      isYesNoOptionAllowed,
      title,
      closeAfterAction,
      isHtmlMessage,
    });
    setIsOpen(true);
  };

  const closeDialog = () => setIsOpen(false);

  const handleAction = (action?: () => void) => {
    action?.();
    if (options.closeAfterAction) setIsOpen(false);
  };

  return {
    openDialog,
    closeDialog,
    confirmationDialogProps: {
      open: isOpen,
      message: options.message,
      isYesNoOptionAllowed: options.isYesNoOptionAllowed,
      title: options?.title ?? '',
      isHtmlMessage: options.isHtmlMessage,
      onOpen: setIsOpen,
      onClickNo: () => handleAction(options.onClickNo),
      onClickOk: () => handleAction(options.onClickOk),
      onClickYes: () => handleAction(options.onClickYes),
    },
  };
};

export default useConfirmationDialog;
