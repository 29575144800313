import IConfig from '@/interfaces/IConfig';

export const config: IConfig = {
  environment: 'QA',
  region: 'us-east-1',
  baseUrl: 'https://m9spweuczc.execute-api.us-east-1.amazonaws.com/',
  cognitoPoolId: 'us-east-1_9kDGZOOWB',
  cognitoClientId: '43poktssmc233sibh1ckp79npg',
  hyperformulaLicense: 'internal-use-in-handsontable',
  handsontableLicense: 'non-commercial-and-evaluation',
  stimulsoftLicense: '',
  subscription: {
    enabled: true,
    redirectUrl: 'https://subscriptions.zoho.com.au/portal/hollywoodbudgets',
  },
  analytics: {
    enabled: true,
    postHogKey: 'phc_OY5iaLvrFLy5FAsOhKvjGeGDCqkCCxoDk7aUZmsjOcZ',
    postHogApiHost: 'https://us.i.posthog.com',
  },
  costTracker: {
    dpfApiUrl: 'https://dpf-auprod-main-apigw.digitalpaperflow.com/prod',
    dpfOAuthUrl: 'https://dpf-auprod-main-apigw.digitalpaperflow.com/prod',
    dpfOAuthCredentials:
      'MGU0MmYxMjhjMTQ4NDdmMjljMTBkMzc4YWE5YWFmNWU6Mjg2YTE1ZTEwZjljNDMxOWE1ZTE5NmVhYjNkNTdjZGE=',
  },
};
