export const shiftElementToFirstPosition = <T>(array: T[], elementToShift: T): T[] => {
  const index = array.indexOf(elementToShift);

  if (index !== -1) {
    // Remove the element from its current position
    array.splice(index, 1);

    // Add it to the beginning of the array
    array.unshift(elementToShift);
  }

  return array;
};
