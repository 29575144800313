import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import { Validator } from '@/utils';

export const useField = <T = string>(
  initialValue: T,
  validator: Validator<T>,
): [T, Dispatch<SetStateAction<T>>, string | boolean] => {
  const [value, setValue] = useState(initialValue);
  const error: string | boolean = useMemo(() => {
    const result = validator(value);
    if (typeof result === 'string') {
      return result;
    }
    return !result;
  }, [value, validator]);
  return [value, setValue, error];
};
