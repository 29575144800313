export class FringeCellOrder extends Array {
  push(...items: string[]): number {
    const newItems = items.filter((item) => this.indexOf(item) === -1);
    super.push(...newItems);
    return this.length;
  }

  rename(oldCode: string, newCode: string): void {
    const index = this.indexOf(oldCode);
    if (index > -1) {
      this[index] = newCode;
    }
  }

  getAll(): string[] {
    return this;
  }
}
