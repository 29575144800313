import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DATA_SHEET_FILE_TYPES, S3ObjectType } from '@/enums';
import { useFetchFiles } from '@/hooks';
import { getFileType, useStateCallback } from '@/utils';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { fileUrlAtom, isNewlySavedFile, openFileTypeAtom, rootFolder } from '@/atoms/GlobalAtoms';
import DialogBox from '@/components/dialogBoxes/dialogBox/DialogBox';
import FileViewer from '@/components/dialogBoxes/FileViewer';
import { IFileResponse } from '@/components/dialogBoxes/types';
import { IDataSheet } from '@/interfaces/IDataSheet';
import FormulaSheet from '@/sheets/FormulaSheet';
import SaveActionFooter from './SaveActionFooter';

/* eslint-disable-next-line */
export interface SaveAsDialogProps {
  suggestedNewFileName?: string;
  open: boolean;
  onClose: (isOpen: boolean, action?: string) => void;
  onSave?: (fileURL: string, fileType: S3ObjectType) => Promise<void>;
}

const convertToJSONFileName = (fileName: string) => {
  return fileName.replace(/\.[^.]+$/, '.json');
};

const getFileNameFromUrl = (url: string) => {
  const regex = /[^/]+$/;
  const match = url?.match(regex);
  return match ? match[0] : '';
};

const generateUniqueFilename = (filename: string, fileList?: IFileResponse[]) => {
  const fileParts = filename.match(/(.*?)(\((\d+)\))?(\.\w+)?$/);
  if (!fileParts) return filename;

  const [, baseName, , existingNumber, extension = ''] = fileParts;

  let counter = existingNumber ? parseInt(existingNumber, 10) : 1;
  let suggestedName = filename;

  const isFilenameTaken = (name: string) => fileList?.some((file) => file.filename === name);

  while (isFilenameTaken(suggestedName)) {
    suggestedName = `${baseName}(${counter})${extension}`;
    counter++;
  }

  return suggestedName;
};

export function SaveAsDialog({ suggestedNewFileName, open, onClose, onSave }: SaveAsDialogProps) {
  const formulaSheet = useRef(new FormulaSheet());
  const [fileUrl, setFileUrl] = useRecoilState(fileUrlAtom);
  const rootPath = useRecoilValue(rootFolder);
  const dataSheet = useRecoilValue(dataSheetAtom);
  const [openFileType, setOpenFileType] = useRecoilState(openFileTypeAtom);
  const [newFileUrl, setNewFileUrl] = useState<string>();
  const setIsNewlySavedFile = useSetRecoilState(isNewlySavedFile);
  const [isSaving, setIsSaving] = useStateCallback<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const [folderPath, setFolderPath] = useState<string>('');

  const isSaveAsTempFile = onSave !== undefined && !!suggestedNewFileName;

  const { data: fetchedFiles } = useFetchFiles({
    rootPath,
    folderPath,
  });

  const handleSave = async (fileURL: string, fileType: S3ObjectType) => {
    const previousPath = fileUrl || suggestedNewFileName;
    const isNewlySavedFile = !(!!suggestedNewFileName && !fileUrl);
    if (!rootPath) return;
    setIsSaving(true);
    try {
      await formulaSheet.current.saveDataSheetInFolderOnRemote(
        rootPath,
        fileURL,
        fileType,
        dataSheet as IDataSheet,
        previousPath,
      );
      setIsNewlySavedFile(isNewlySavedFile);
      setFileUrl(fileURL);
      onClose(false, 'success');
    } catch (e) {
      onClose(false, 'error');
    }
    setIsSaving(false);
    setOpenFileType(getFileType(fileURL));
  };

  const handleSaveAsTempFile = (fileURL: string, fileType: S3ObjectType) => {
    setIsSaving(true, () => {
      onSave?.(fileURL, fileType);
    });
  };

  const handleOpenFile = async () => {
    if (!selectedFile) return;

    if (selectedFile.type === S3ObjectType.USERS_FOLDER) {
      const path = folderPath.concat(selectedFile.filename);
      setFolderPath(path);
      setSelectedFile(null);
    }
  };

  const handleSelectedFileAndOpen = (file: IFileResponse) => {
    setSelectedFile(file);
    handleOpenFile();
  };

  useEffect(() => {
    let fileName = '';

    if (selectedFile) {
      fileName =
        openFileType === DATA_SHEET_FILE_TYPES.TMPL
          ? convertToJSONFileName(selectedFile.filename)
          : selectedFile.filename;
    } else {
      fileName = fileUrl
        ? getFileNameFromUrl(fileUrl)
        : convertToJSONFileName(suggestedNewFileName ?? '');
    }

    if (suggestedNewFileName && !fileUrl) {
      fileName = generateUniqueFilename(fileName, fetchedFiles);
    }

    setNewFileUrl(fileName);
  }, [selectedFile, fileUrl, openFileType, suggestedNewFileName, fetchedFiles]);

  return (
    <DialogBox
      title="Save As"
      isOpen={open}
      isLoading={isSaving}
      onClose={() => {
        setNewFileUrl('');
        setFolderPath('');
        setSelectedFile(null);
        onClose(false);
      }}
      dialogBoxContent={
        <FileViewer
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          folderPath={folderPath}
          setFolderPath={setFolderPath}
          onSelectedFileAndOpen={handleSelectedFileAndOpen}
          showArchiveIcon={false}
          showDeleteIcon={false}
          templateToggleStatus={true}
          readonly={isSaveAsTempFile}
        />
      }
      dialogBoxFooter={
        <SaveActionFooter
          key={newFileUrl}
          newFileUrl={newFileUrl ?? ''}
          setNewFileUrl={setNewFileUrl}
          handleSave={isSaveAsTempFile ? handleSaveAsTempFile : handleSave}
          isSaving={isSaving}
          folderPath={folderPath}
          fetchedFiles={fetchedFiles}
          saveAsTemplateDisabled={isSaveAsTempFile}
        />
      }
    />
  );
}

export default SaveAsDialog;
