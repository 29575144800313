import { formatSets } from '@/utils';
import { buildL3RowJSON } from '@/helpers/Mappers/SheetImportMapper';

import {
  addSystemRowImportSheet,
  buildConfigImport,
  buildL1FromImportSheet,
  buildL2FromImportSheet,
  buildMetaAndConfigs,
  buildMetaDataFrom,
  formatMasterAndSpecificSet,
  initializeSet,
  updateDataSheet,
  updateImportDataWithNewIds,
  updateL3RowFromImportSheet,
} from '@/helpers/SheetImportHelper';
import { IDataSheet } from '@/interfaces/IDataSheet';
import {
  IAccountImport,
  ICategoryImport,
  IDetailImport,
  IDetailImportXML,
  IL3DataImport,
} from '@/interfaces/ISheetImport';

const addMetaDataJSON = (meta: any, dataSheet: IDataSheet) => {
  buildMetaAndConfigs(buildMetaDataFrom, meta, dataSheet);
};

const addConfigDataJSON = (meta: any, dataSheet: IDataSheet) => {
  buildMetaAndConfigs(buildConfigImport, meta, dataSheet);
};

const addL1FromJSON = (categories: ICategoryImport[], dataSheetFromJSON: IDataSheet): void => {
  buildL1FromImportSheet(categories, dataSheetFromJSON);
};

const addL2FromJSON = (accounts: IAccountImport[], dataSheetFromJSON: IDataSheet): void => {
  buildL2FromImportSheet(accounts, dataSheetFromJSON);
};

const addL3FromJSON = (
  details: IDetailImport[],
  dataSheetFromJSON: IDataSheet,
  meta: any,
): void => {
  const { masterSet, currency, sets, locations, unitDesc, fringes, groups } = initializeSet();

  const seenLowerCase = new Set<string>();

  const addToMasterAndSpecificSet = (
    value: string,
    specificSet: Set<string>,
    applyFormatter = true,
  ) => {
    formatMasterAndSpecificSet(value, specificSet, masterSet, seenLowerCase, applyFormatter);
  };
  for (const detail of details) {
    const l3Object = buildL3RowJSON(detail);
    // addToSets(l3Object, currency, sets, locations, unitDesc, addToMasterAndSpecificSet);
    addToMasterAndSpecificSet(l3Object.cu, currency);
    // TODD: need pass maxlength from meta
    addToMasterAndSpecificSet(l3Object.set, sets, false);
    addToMasterAndSpecificSet(l3Object.loc, locations, false);
    addToMasterAndSpecificSet(l3Object.desc, unitDesc);
    addFringesFromSheetJSON(l3Object, fringes, addToMasterAndSpecificSet);
    addGroupsFromSheetJSON(l3Object, groups, addToMasterAndSpecificSet);
    updateL3RowFromImportSheet(dataSheetFromJSON, l3Object);
  }

  addSystemRowImportSheet(dataSheetFromJSON);
  updateDataSheet(dataSheetFromJSON, currency, sets, locations, unitDesc, fringes, groups, meta);
};

export const jsonFilteredImportDataWithNewIds = (data: IDetailImportXML) =>
  updateImportDataWithNewIds(
    data,
    addL1FromJSON,
    addL2FromJSON,
    addL3FromJSON,
    addMetaDataJSON,
    addConfigDataJSON,
  );

const addFringesFromSheetJSON = (
  l3Object: IL3DataImport,
  fringes: Set<string>,
  addToMasterAndSpecificSet: (value: string, specificSet: Set<string>) => void,
): void => {
  if (l3Object.fringes !== '') {
    l3Object.fringes.split(',').forEach((fringe) => {
      addToMasterAndSpecificSet(fringe.trim(), fringes);
    });
  }
};

const addGroupsFromSheetJSON = (
  l3Object: IL3DataImport,
  groups: Set<string>,
  addToMasterAndSpecificSet: (
    value: string,
    specificSet: Set<string>,
    applyFormatter: boolean,
  ) => void,
): void => {
  if (l3Object.groups !== '') {
    l3Object.groups.split(',').forEach((group) => {
      addToMasterAndSpecificSet(group.trim(), groups, false);
    });
  }
};
