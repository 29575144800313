import { type CellValue } from 'hyperformula';
import { SheetNames } from '@/enums';
import { CurrencyColumnIndex } from '@/interfaces/IFormulaCurrencyIndex';
import type FormulaSheet from '@/sheets/FormulaSheet';

export const getCurrencyList = (formulaSheet: FormulaSheet): { [key in string]: CellValue } => {
  const currencySheetValues = formulaSheet.getSheetValues(SheetNames.CURRENCY);
  const currencyList: { [key in string]: CellValue } = {};
  let hasDefaultCurrency = false;

  currencySheetValues[SheetNames.CURRENCY].forEach((currency) => {
    const currencyCode = currency[CurrencyColumnIndex.code] as string;

    if (currencyCode) {
      currencyList[currencyCode] = currency[CurrencyColumnIndex.value];

      if (currency[CurrencyColumnIndex.default] === 'Y') {
        hasDefaultCurrency = true;
      }
    }
  });

  if (!hasDefaultCurrency) {
    currencyList['Default'] = 1;
  }

  return currencyList;
};
