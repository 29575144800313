import styled from '@emotion/styled';
import {
  FormControl,
  IconButton,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';
import React, { ReactElement, useState } from 'react';
import theme from '@/app/theme/base/theme';
import { DeleteIcon, EditPenIcon, PlusIcon } from '@/components/icon';

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  error?: string | boolean;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme>;
  labelText?: string;
  pluseOption?: boolean;
  editOption?: boolean;
  deleteOption?: boolean;
  onDelete?: () => Promise<void>;
  options: { value: string; label: string }[];
  placeholder?: string;
  value?: string;
  count?: number | string;
  fullWidth?: boolean;
  width?: string;
  isDeleteIconDisabled?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const StyledIconButton = styled('span')(() => ({
  '& .MuiIconButton-root.Mui-disabled': {
    opacity: 0.5,
  },
}));

export const SelectField = ({
  labelText,
  onChange,
  error,
  pluseOption,
  editOption,
  deleteOption,
  onDelete,
  options,
  placeholder,
  value,
  count,
  fullWidth = true,
  isDeleteIconDisabled = false,
  isLoading = false,
  isDisabled = false,
  width,
  ...props
}: Props): ReactElement => {
  const [touched, setTouched] = useState(false);
  const onTouch = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        width: width,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {labelText}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {pluseOption && (
            <Stack
              sx={{
                cursor: 'pointer',
              }}
            >
              <PlusIcon />
            </Stack>
          )}
          {editOption && (
            <Stack
              sx={{
                cursor: 'pointer',
              }}
            >
              <EditPenIcon />
            </Stack>
          )}
          {deleteOption && (
            <StyledIconButton>
              <IconButton
                onClick={onDelete}
                style={{ color: 'white' }}
                disabled={isDeleteIconDisabled}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledIconButton>
          )}
          {count && (
            <Stack
              sx={{
                color: theme.palette.text.secondary,
                fontSize: '12px',
                fontWeight: 300,
              }}
            >
              ({count})
            </Stack>
          )}
        </Stack>
      </Stack>
      <TextField
        {...props}
        size={'small'}
        select={!isLoading}
        value={value ?? 'null'}
        onChange={(e) => onChange?.(e.target.value)}
        error={touched && !!error}
        helperText={touched && typeof error === 'string' ? error : undefined}
        onBlur={onTouch}
        disabled={isDisabled || isLoading}
        InputProps={{
          endAdornment: isLoading && <CircularProgress size={20} color={'success'} />,
        }}
        sx={{
          mb: 2,
          mt: 0.5,
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '14px',
            margin: '5px 0 0 0',
            padding: '0',
          },
          '& .MuiSelect-select': {
            fontSize: '14px',
          },
        }}
      >
        {placeholder && (
          <MenuItem
            value="null"
            sx={{
              fontSize: '14px',
              color: theme.palette.text.secondary,
            }}
          >
            {placeholder}
          </MenuItem>
        )}
        {options.map(
          ({ label, value }, index) =>
            label && (
              <MenuItem
                key={index}
                value={value}
                sx={{
                  fontSize: '14px',
                }}
              >
                {label}
              </MenuItem>
            ),
        )}
      </TextField>
    </FormControl>
  );
};
