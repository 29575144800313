import { useCallback, useEffect, useState } from 'react';
import { LocalStorageVariables, SheetNames } from '@/enums';

type ColumnWidths = {
  [sheetName in SheetNames]?: {
    [columnIndex: number]: number | undefined;
  };
};

interface UseColumnWidthsArgs {
  sheetName: SheetNames;
  visibleColumnsCount: number;
}

const DEFAULT_COLUMN_WIDTHS: ColumnWidths = {
  [SheetNames.L1]: {
    0: 170,
    1: 110,
    2: 720,
    3: 110,
    4: 110,
    5: 110,
    6: 110,
  },
  [SheetNames.CT_L1]: {
    0: 150,
    1: 110,
    2: 320,
    3: 150,
    4: 100,
    5: 130,
    6: 140,
    7: 130,
    8: 160,
    9: 160,
    10: 160,
    11: 140,
    12: 170,
    13: 90,
  },
  [SheetNames.L2]: {
    0: 120,
    1: 80,
    2: 600,
    3: 100,
    4: 100,
    5: 150,
    6: 150,
  },
  [SheetNames.CT_L2A]: {
    0: 150,
    1: 60,
    2: 60,
    3: 85,
    4: 60,
    5: 320,
    6: 150,
    7: 100,
    8: 130,
    9: 140,
    10: 130,
    11: 160,
    12: 160,
    13: 160,
    14: 140,
    15: 170,
    16: 90,
  },
  [SheetNames.CT_L3]: {
    0: 100,
    1: 130,
    4: 50,
    5: 120,
    6: 440,
    7: 80,
    8: 70,
    9: 120,
    10: 55,
    11: 80,
    12: 90,
    13: 80,
    14: 90,
    15: 60,
    16: 60,
    17: 60,
    18: 60,
    19: 70,
    20: 130,
    21: 100,
    22: 70,
  },
  [SheetNames.L3]: {
    0: 100,
    1: 80,
    2: 80,
    3: 90,
    4: 110,
    5: 110,
    6: 50,
    7: 50,
    8: 350,
    9: 70,
    10: 80,
    11: 50,
    12: 100,
    13: 50,
    14: 100,
    15: 110,
    16: 110,
  },
  [SheetNames.CURRENCY]: {
    0: 100,
    1: 80,
    2: 400,
    3: 80,
    4: 80,
    5: 80,
    6: 100,
    7: 100,
  },
  [SheetNames.SETS]: {
    0: 75,
    1: 80,
    2: 325,
    3: 90,
    4: 100,
  },
  [SheetNames.FRINGES]: {
    0: 150,
    1: 60,
    2: 200,
    3: 80,
    4: 90,
    5: 80,
    6: 60,
    7: 100,
    8: 100,
  },
  [SheetNames.GROUPS]: {
    0: 100,
    1: 65,
    2: 300,
    3: 90,
    4: 70,
    5: 70,
    6: 70,
    7: 100,
    8: 120,
    9: 120,
  },
  [SheetNames.LOCATIONS]: {
    0: 100,
    1: 70,
    2: 400,
    3: 100,
    4: 100,
    5: 100,
  },
  [SheetNames.UNIT_DESCRIPTIONS]: {
    0: 100,
    1: 80,
    2: 450,
    3: 70,
    4: 85,
  },
};

export const useColumnWidths = ({ sheetName, visibleColumnsCount }: UseColumnWidthsArgs) => {
  const [columnWidths, setColumnWidths] = useState<ColumnWidths[SheetNames]>();

  const getColumnWidths = useCallback(() => {
    const widthsInJson = localStorage.getItem(LocalStorageVariables.COLUMN_WIDTHS);

    return widthsInJson ? (JSON.parse(widthsInJson) as ColumnWidths) : null;
  }, []);

  /**
   * Creates an array of column widths for visible columns.
   * Undefined for widths not specified.
   *
   * @return {Array<number | undefined>} Array of column widths.
   */
  const columnWidthsArray = useCallback(
    () =>
      Array.from({ length: visibleColumnsCount }, (_, index) => columnWidths?.[index] ?? undefined),
    [columnWidths, visibleColumnsCount],
  );

  const onColumnSizeChange = (width: number, colIndex: number) => {
    setColumnWidths((columnWidths) => {
      const updatedWidths = { ...columnWidths, [colIndex]: width };

      const allWidths = getColumnWidths() ?? {};
      allWidths[sheetName] = updatedWidths;
      localStorage.setItem(LocalStorageVariables.COLUMN_WIDTHS, JSON.stringify(allWidths));

      return updatedWidths;
    });
  };

  useEffect(() => {
    const allWidths = getColumnWidths();
    const initialWidths = allWidths?.[sheetName] ?? DEFAULT_COLUMN_WIDTHS[sheetName];

    setColumnWidths(initialWidths);
  }, [getColumnWidths, sheetName]);

  return {
    columnWidths: columnWidthsArray(),
    onColumnSizeChange,
  };
};
