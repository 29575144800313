export enum CostsToDateTypes {
  ID = 'id',
  CATEGORY = 'category',
  CODE = 'code',
  DESCRIPTION = 'description',
  UNITS = 'units',
  VALUE = 'value',
  TOTAL = 'total',
  USAGE = 'usage',
  ATTACHMENTS = 'attachments',
  NOTES = 'notes',
}

export interface ICostsToDateData {
  id: string;
  category: string;
  code: string;
  description: string;
  units: string;
  value: string;
  total: string;
  usage: string;
  attachments: string;
  notes: string;
}

export interface ICostToDateCSV {
  Account: string;
  'Account Description': string;
  Amount: string;
}

export interface ICodeValidationCSV {
  isValid: boolean;
  errorCode: string | null;
}

export interface ICodeValidationImportCTD {
  isError: boolean;
  validRows: ICostsToDateData[];
  message?: React.ReactNode;
}
