/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellValue } from 'hyperformula';
import { L1ColumnIndexes, L2ColumnIndexes, L3ColumnIndexes, RowTypes } from '@/enums';
import { FringeAllocationDataKeys } from '@/data/meta/FringeAllocationData';
import { IDataSheet } from '@/interfaces/IDataSheet';

interface Props {
  dataSheetCopy: IDataSheet;
  selectedCurrencies?: string[];
  selectedLocations?: string[];
  selectedGroups?: string[];
  selectedSets?: string[];
  isFilterAllSheets?: boolean;
  selectedOption?: string;
}

export const filterDataSheet = ({
  dataSheetCopy,
  selectedCurrencies = [],
  selectedLocations = [],
  selectedGroups = [],
  selectedSets = [],
  isFilterAllSheets = false,
  selectedOption = '',
}: Props) => {
  const filteredDataSheet: any = {
    l1: [],
    l2: {},
    l3: {},
  };

  const fringesAllocation = dataSheetCopy.meta?.fringes.calc as FringeAllocationDataKeys;
  const isPivotBudgetSheet = selectedOption === 'Hollywood Budgets Sheet';

  const applyFilters = (item: any) => {
    const groups = item.groups?.split(',') ?? [];

    const isCurrencyMatch =
      selectedCurrencies.length === 0 ||
      selectedCurrencies.includes(item.cu) ||
      (item.cu === '' && selectedCurrencies.includes(''));
    const isLocationMatch =
      selectedLocations.length === 0 ||
      selectedLocations.includes(item.loc) ||
      (item.loc === '' && selectedLocations.includes(''));
    const isGroupMatch =
      selectedGroups.length === 0 ||
      selectedGroups.some((value) => groups.includes(value)) ||
      (groups.includes('') && selectedGroups.includes(''));
    const isSetMatch =
      selectedSets.length === 0 ||
      selectedSets.includes(item.set) ||
      (item.set === '' && selectedSets.includes(''));
    const isOverallMatch = isCurrencyMatch && isLocationMatch && isGroupMatch && isSetMatch;
    if (item.rowType === 'D') {
      return isOverallMatch;
    } else {
      return isFilterAllSheets && !isPivotBudgetSheet
        ? shouldIncludeRow(item, 'l3', fringesAllocation)
        : true;
    }
  };

  Object.keys(dataSheetCopy?.l3)?.forEach((key) => {
    filteredDataSheet.l3[key] = dataSheetCopy?.l3[key].filter((item: any) => applyFilters(item));
  });
  dataSheetCopy.l3 = filteredDataSheet.l3 || {};

  if (isFilterAllSheets && !isPivotBudgetSheet) {
    Object.keys(dataSheetCopy?.l2)?.forEach((key) => {
      filteredDataSheet.l2[key] = dataSheetCopy?.l2[key].filter((item: any) =>
        shouldIncludeRow(item, 'l2', fringesAllocation),
      );
    });
    dataSheetCopy.l2 = filteredDataSheet.l2 || {};

    filteredDataSheet.l1 = dataSheetCopy?.l1.filter((item: any) =>
      shouldIncludeRow(item, 'l1', fringesAllocation),
    );
    dataSheetCopy.l1 = filteredDataSheet.l1 || [];
  }

  return dataSheetCopy;
};

const shouldIncludeRow = (
  rowData: CellValue[],
  sheetLevel: 'l1' | 'l2' | 'l3',
  fringesAllocation: FringeAllocationDataKeys,
) => {
  const rowTypeIndex = {
    l1: L1ColumnIndexes.rowType,
    l2: L2ColumnIndexes.rowType,
    l3: L3ColumnIndexes.rowType,
  }[sheetLevel];
  const rowType = rowData[rowTypeIndex] as string;

  const isNone = fringesAllocation === FringeAllocationDataKeys.NONE;
  const isBudget = fringesAllocation === FringeAllocationDataKeys.BUDGET;
  const isProduction = fringesAllocation === FringeAllocationDataKeys.PRODUCTION;
  const isCategory = fringesAllocation === FringeAllocationDataKeys.CATEGORY;
  const isAccount = fringesAllocation === FringeAllocationDataKeys.ACCOUNT;

  if (isNone && (rowType === RowTypes.F || rowType === RowTypes.FS)) return false;

  if (isProduction) {
    if (sheetLevel === 'l1' && rowType === RowTypes.FS) return true;
    if (
      (sheetLevel === 'l2' || sheetLevel === 'l3') &&
      (rowType === RowTypes.F || rowType === RowTypes.FS)
    )
      return false;
  }

  if (isBudget) {
    if (sheetLevel === 'l1' && rowType === RowTypes.F) return true;
    if (
      (sheetLevel === 'l2' || sheetLevel === 'l3') &&
      (rowType === RowTypes.F || rowType === RowTypes.FS)
    )
      return false;
  }

  if (isCategory) {
    if (sheetLevel === 'l2' && rowType === RowTypes.F) return true;
    if (
      (sheetLevel === 'l1' || sheetLevel === 'l3') &&
      (rowType === RowTypes.F || rowType === RowTypes.FS)
    )
      return false;
  }

  if (isAccount) {
    if (sheetLevel === 'l3' && rowType === RowTypes.F) return true;
    if (
      (sheetLevel === 'l1' || sheetLevel === 'l2') &&
      (rowType === RowTypes.F || rowType === RowTypes.FS)
    )
      return false;
  }

  if (rowType === RowTypes.F || rowType === RowTypes.FS) return false;

  return true;
};

export const filterDataSheetByFringeAllocation = (
  dataSheet: Record<string, CellValue[][]>,
  fringesAllocation: FringeAllocationDataKeys,
) => {
  const filteredDataSheet: Record<string, CellValue[][]> = { ...dataSheet };

  Object.entries(dataSheet).forEach(([key, levelData]) => {
    if (key === 'l1' || key.startsWith('l2_') || key.startsWith('l3_')) {
      const sheetLevel = key.startsWith('l2_') ? 'l2' : key.startsWith('l3_') ? 'l3' : 'l1';
      filteredDataSheet[key] = levelData.filter((rowData) =>
        shouldIncludeRow(rowData, sheetLevel, fringesAllocation),
      );
    }
  });

  return filteredDataSheet;
};

export const removeEmptySheetsAndOrderDataSheet = (dataSheet: IDataSheet) => {
  // ===== L3 empty sheet removal =====
  const removeL3Keys = Object.keys(dataSheet.l3).filter((key) => {
    if (dataSheet.l3[key].length !== 0) {
      return !dataSheet.l3[key].some((item: any) => item.rowType === 'D');
    }
  });

  removeL3Keys.forEach((key: any) => {
    delete dataSheet?.l3[key];
  });

  // ===== L2 empty sheet removal =====
  Object.keys(dataSheet.l2).forEach((key) => {
    dataSheet.l2[key] = dataSheet.l2[key].filter((item: any) => !removeL3Keys.includes(item.id));
  });

  const removeL2Keys = Object.keys(dataSheet.l2).filter(
    (key) => !dataSheet.l2[key].some((item: any) => item.rowType === 'D'),
  );

  removeL2Keys.forEach((key: any) => {
    delete dataSheet?.l2[key];
  });

  // ===== L1 empty sheet removal =====
  dataSheet.l1 = dataSheet.l1.filter((item: any) => !removeL2Keys.includes(item.id));

  // L2 sheetNames removal and order
  dataSheet.sheetNames.l2 = dataSheet.l1
    .filter((item: any) => item.rowType === 'D')
    .map((item: any) => item.id);

  // L3 sheetNames removal and order
  const l3Keys = dataSheet.sheetNames.l2.map((key) =>
    dataSheet.l2[key].filter((item: any) => item.rowType === 'D').map((item: any) => item.id),
  );
  dataSheet.sheetNames.l3 = l3Keys.flat();

  return dataSheet;
};

export const removeZeroTotalsFromL3Rows = (reportData: any) => {
  Object.keys(reportData).forEach((key) => {
    if (key.startsWith('l3_')) {
      reportData[key] = reportData[key].filter((item: any) => item[14] !== 0);
    }
  });
  return reportData;
};

export const removeAdjacentSpecialRows = (data: any[]) => {
  const specialRowTypes = new Set([RowTypes.S, RowTypes.G, RowTypes.FS]);
  let prevRowType: string | null = null;

  return data.filter((row, index) => {
    if (prevRowType === RowTypes.T && row.rowType === RowTypes.S) {
      return false;
    }
    if (specialRowTypes.has(row.rowType) && prevRowType === row.rowType) {
      return false;
    }
    prevRowType = row.rowType;
    return true;
  });
};
