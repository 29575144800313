export enum CostsToDateColumnIndexes {
  id = 0,
  category = 1,
  code = 2,
  description = 3,
  units = 4,
  value = 5,
  total = 6,
  usage = 7,
  attachments = 8,
  notes = 9,
  rowType = 10,
  index = 11,
}

export enum VisibleCostsToDateColumnIndexes {
  id = -1,
  category = 0,
  code = 1,
  description = 2,
  units = 3,
  total = 4,
  usage = 5,
  index = 6,
}

export const ctdToVisibleColumnMap: {
  [key in CostsToDateColumnIndexes]?: VisibleCostsToDateColumnIndexes;
} = {
  [CostsToDateColumnIndexes.id]: VisibleCostsToDateColumnIndexes.id,
  [CostsToDateColumnIndexes.category]: VisibleCostsToDateColumnIndexes.category,
  [CostsToDateColumnIndexes.code]: VisibleCostsToDateColumnIndexes.code,
  [CostsToDateColumnIndexes.description]: VisibleCostsToDateColumnIndexes.description,
  [CostsToDateColumnIndexes.units]: VisibleCostsToDateColumnIndexes.units,
  [CostsToDateColumnIndexes.total]: VisibleCostsToDateColumnIndexes.total,
  [CostsToDateColumnIndexes.usage]: VisibleCostsToDateColumnIndexes.usage,
};
