import { axios } from '@/utils';

export default async function fetchSystemUsers(): Promise<[]> {
  try {
    const { data } = await axios.get('/v1/users');
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
