import { Tabs as BaseTabs } from '@mui/material';
import { useState } from 'react';

import { NavLink, TabsContainer } from './Tabs.styled';
import { TabsProps } from './Tabs.types';

const a11yProps = (index: number) => ({
  id: `main-tab-${index}`,
  'aria-controls': `main-tabpanel-${index}`,
});

const Tabs = ({ tabs, onTabChange }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (newTabIndex: number) => {
    setActiveTab(newTabIndex);
    onTabChange(newTabIndex);
  };

  return (
    <TabsContainer>
      <BaseTabs variant="fullWidth" value={activeTab} aria-label="main tab for pages">
        {tabs.map(({ href, label, index }) => (
          <NavLink
            replace={true}
            onClick={() => handleTabChange(index)}
            to={href}
            key={index}
            {...a11yProps(index)}
          >
            {label}
          </NavLink>
        ))}
      </BaseTabs>
    </TabsContainer>
  );
};

export default Tabs;
