export const pasteSpecialOptions = [
  {
    value: 'fringes',
    label: 'Fringes',
  },
  {
    value: 'groups',
    label: 'Groups',
  },
  {
    value: 'loc',
    label: 'Loc',
  },
  {
    value: 'set',
    label: 'Set',
  },
  {
    value: 'description',
    label: 'Description',
  },
  {
    value: 'units',
    label: 'Units',
  },
  {
    value: 'desc',
    label: 'Units Desc',
  },
  {
    value: 'x',
    label: 'X',
  },
  {
    value: 'cu',
    label: 'CU',
  },
  {
    value: 'rate',
    label: 'Rate',
  },
  {
    value: 'paste-special',
    label: 'Paste Special',
  },
];
