import { SimplePaletteColorOptions, createTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    btnOutLine: PaletteColorOptions;
    iconStroke: SimplePaletteColorOptions;
    txtColor: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    btnOutLine: true;
    txtColor: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const palettes = {
  primary: {
    main: '#F79B2A',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#d8d9e0',
  },
  text: {
    primary: '#262626',
    secondary: '#929292',
  },
  formLink: {
    primary: '#747474',
  },
  btnOutLine: createColor('#747474'),
  iconStroke: {
    main: '#747474',
  },
};
