import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { L1ColumnAlias, SheetNames } from '@/enums';
import { dataSheetAtom } from '@/atoms/DataSheetAtom';
import { type DocumentSummaryDetails, documentSummaryDetailsAtom } from '@/atoms/GlobalAtoms';
import { OutletContext } from '@/components/layout';

import { getCurrencyList } from './useDocumentSummary.utils';

interface UseTopBarCalculationReturnType {
  documentSummaryDetails: DocumentSummaryDetails;
  calculateDocumentSummary: () => void;
  updateCostTrackerSummary: (efc: number, prevEfc: number, periodVar: number) => void;
}

const useDocumentSummary = (): UseTopBarCalculationReturnType => {
  const { formulaSheet } = useOutletContext<OutletContext>();
  const dataSheet = useRecoilValue(dataSheetAtom);
  const [documentSummaryDetails, setDocumentSummaryDetails] = useRecoilState(
    documentSummaryDetailsAtom,
  );

  const includeFringeSum =
    (dataSheet?.meta.fringes.calc !== undefined &&
      ['production', 'budget'].includes(dataSheet.meta.fringes.calc)) ??
    false;

  const calculateDocumentSummary = useCallback(() => {
    if (!formulaSheet || !dataSheet) return;
    const total = formulaSheet.getSheetSums(SheetNames.L1, L1ColumnAlias.Total, includeFringeSum);
    const comparison = formulaSheet.getSheetSums(
      SheetNames.L1,
      L1ColumnAlias.Comparison,
      includeFringeSum,
    );
    const variance = formulaSheet.getSheetSums(SheetNames.L1, L1ColumnAlias.Variance);
    const currencies = Object.keys(getCurrencyList(formulaSheet));

    setDocumentSummaryDetails((prevState) => ({
      ...prevState,
      total,
      efc: total,
      periodVar: prevState.prevEfc - total,
      comparison,
      variance,
      currencies,
    }));
  }, [dataSheet, formulaSheet, includeFringeSum, setDocumentSummaryDetails]);

  const updateCostTrackerSummary = useCallback(
    (estimatedFinalCost: number, efcw: number, periodVar: number) => {
      setDocumentSummaryDetails((prevState) => ({
        ...prevState,
        efc: estimatedFinalCost,
        prevEfc: efcw,
        periodVar,
      }));
    },
    [setDocumentSummaryDetails],
  );

  return { documentSummaryDetails, calculateDocumentSummary, updateCostTrackerSummary };
};

export default useDocumentSummary;
