import { formatSets } from '@/utils';
import { buildL3RowFromXML } from '@/helpers/Mappers/SheetImportMapper';
import {
  addSystemRowImportSheet,
  buildConfigImport,
  buildL1FromImportSheet,
  buildL2FromImportSheet,
  buildMetaAndConfigs,
  buildMetaDataFrom,
  createFringeAndGroupDetails,
  formatMasterAndSpecificSet,
  initializeSet,
  updateDataSheet,
  updateImportDataWithNewIds,
  updateL3RowFromImportSheet,
} from '@/helpers/SheetImportHelper';
import { IDataSheet } from '@/interfaces/IDataSheet';
import {
  IAccountImport,
  ICategoryImport,
  IDetailImportXML,
  IL3DataImport,
} from '@/interfaces/ISheetImport';

const addMetaDataJSON = (meta: any, dataSheet: IDataSheet) => {
  buildMetaAndConfigs(buildMetaDataFrom, meta, dataSheet);
};

const addConfigDataJSON = (meta: any, dataSheet: IDataSheet) => {
  buildMetaAndConfigs(buildConfigImport, meta, dataSheet);
};

const addL1FromXml = (categories: ICategoryImport[], dataSheetFromXML: IDataSheet): void => {
  buildL1FromImportSheet(categories, dataSheetFromXML);
};

const addL2FromXml = (accounts: IAccountImport[], dataSheetFromXML: IDataSheet): void => {
  buildL2FromImportSheet(accounts, dataSheetFromXML);
};

const addL3FromXml = (
  details: IDetailImportXML[],
  dataSheetFromXML: IDataSheet,
  meta: any,
): void => {
  const { masterSet, currency, sets, locations, unitDesc, fringes, groups } = initializeSet();

  const seenLowerCase = new Set<string>();

  const addToMasterAndSpecificSet = (
    value: string,
    specificSet: Set<string>,
    applyFormatter = true,
  ) => {
    formatMasterAndSpecificSet(value, specificSet, masterSet, seenLowerCase, applyFormatter);
  };

  for (const detail of details) {
    const { fringeDetails, groupDetails } = createFringeAndGroupDetails(detail);
    const l3Object = buildL3RowFromXML(detail, fringeDetails, groupDetails);
    //addToSets(l3Object, currency, sets, locations, unitDesc, addToMasterAndSpecificSet);
    addToMasterAndSpecificSet(l3Object.cu, currency);
    addToMasterAndSpecificSet(l3Object.set, sets, false);
    addToMasterAndSpecificSet(l3Object.loc, locations, false);
    addToMasterAndSpecificSet(l3Object.desc, unitDesc);
    addFringesFromSheetXml(detail, l3Object, fringes, addToMasterAndSpecificSet);
    addGroupsFromSheetXml(detail, l3Object, groups, addToMasterAndSpecificSet);
    updateL3RowFromImportSheet(dataSheetFromXML, l3Object);
  }

  addSystemRowImportSheet(dataSheetFromXML);
  updateDataSheet(dataSheetFromXML, currency, sets, locations, unitDesc, fringes, groups, meta);
};

export const xmlFilteredImportDataWithNewIds = (data: IDetailImportXML) =>
  updateImportDataWithNewIds(
    data,
    addL1FromXml,
    addL2FromXml,
    addL3FromXml,
    addMetaDataJSON,
    addConfigDataJSON,
  );

export const addFringesFromSheetXml = (
  detail: IDetailImportXML,
  l3Object: IL3DataImport,
  fringes: Set<string>,
  addToMasterAndSpecificSet: (value: string, specificSet: Set<string>) => void,
): void => {
  if (Array.isArray(detail.dFringes?.dFringe)) {
    detail.dFringes.dFringe.forEach((fringe: string) => {
      if (fringe !== '') {
        addToMasterAndSpecificSet(fringe, fringes);
      }
    });
  } else if (l3Object.fringes !== '') {
    addToMasterAndSpecificSet(l3Object.fringes, fringes);
  }
};

export const addGroupsFromSheetXml = (
  detail: IDetailImportXML,
  l3Object: IL3DataImport,
  groups: Set<string>,
  addToMasterAndSpecificSet: (
    value: string,
    specificSet: Set<string>,
    applyTransformation: boolean,
  ) => void,
): void => {
  if (Array.isArray(detail.dGroups?.dGroup)) {
    detail.dGroups.dGroup.forEach((group: string) => {
      if (group !== '') {
        addToMasterAndSpecificSet(group, groups, false);
      }
    });
  } else if (l3Object.groups !== '') {
    addToMasterAndSpecificSet(l3Object.groups, groups, false);
  }
};
