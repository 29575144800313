import lodash from 'lodash';
import { CostsToDateColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { costsToDateToFormulaTransformation } from '@/helpers/Mappers/CostsToDateMapper';
import {
  CostsToDateTypes,
  ICodeValidationCSV,
  ICostsToDateData,
  ICostToDateCSV,
} from '@/interfaces/ICostsToDateData';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { ICostsToDateSheet, INamedExpressionData } from '@/interfaces/IFormulaSheet';

export function getEmptyCostsToDate(): ICostsToDateData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    units: '0',
    value: '0',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newCostToDate: (string | number | null)[];
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newCostsToDate: ICostsToDateData = getEmptyCostsToDate();
  updatedDataSheet.costsToDate = updatedDataSheet.costsToDate || [];
  updatedDataSheet.costsToDate.splice(index, 0, newCostsToDate);
  const newCostToDate = costsToDateToFormulaTransformation(newCostsToDate, index);
  const updatedIds = [newCostsToDate.id.toString()];
  return {
    updatedDataSheet,
    newCostToDate,
    updatedIds,
  };
}

export function editRow(
  dataSheet: IDataSheet,
  record: ICostsToDateData,
): { updatedDataSheet: IDataSheet; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.costsToDate = updatedDataSheet.costsToDate.map((costsToDate) => {
    if (costsToDate.id === record.id) {
      updatedIds.push(costsToDate.id.toString());
      return {
        ...costsToDate,
        ...record,
      };
    }
    return costsToDate;
  });

  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): { updatedDataSheet: IDataSheet; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.costsToDate[0] = {
      ...getEmptyCostsToDate(),
      id: updatedDataSheet.costsToDate[0].id,
    };
  }
  const removedCostsToDateSheet: ICostsToDateData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.costsToDate.forEach((costsToDateData, index) => {
    if (!removeIndexes.includes(index)) {
      removedCostsToDateSheet.push(costsToDateData);
    } else {
      updatedIds.push(costsToDateData.id.toString());
    }
  });
  updatedDataSheet.costsToDate = removedCostsToDateSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: ICostsToDateData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.costsToDate[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.costsToDate.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.costsToDate.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedCTDRow(copiedRow: ICostsToDateData): ICostsToDateData {
  const updatedRow = getEmptyCostsToDate();

  // columns that need to be updated before pasting
  const reviseColumns = [CostsToDateTypes.CATEGORY, CostsToDateTypes.DESCRIPTION];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeCTDCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  removeNamedExpressionCodes: string[];
  emptyCells: ICellCoords[];
  updatedNamedExpressions: INamedExpressionData[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const removeNamedExpressionCodes: string[] = [];
  const emptyCells: ICellCoords[] = [];
  const updatedNamedExpressions: INamedExpressionData[] = [];

  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.costsToDate.forEach((data: ICostsToDateData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof ICostsToDateData] = '';
        }
        const code = dataSheet[DateSheetAttributeType.COSTS_TO_DATE][item.row].code;
        if (item.col === CostsToDateColumnIndexes.code) {
          removeNamedExpressionCodes.push(code);
        }
        if (
          item.col === CostsToDateColumnIndexes.units &&
          !removeNamedExpressionCodes.includes(code)
        ) {
          updatedNamedExpressions.push({ code, expression: '0' });
        }
      });
    });
  }
  return {
    updatedDataSheet,
    updatedIds,
    removeNamedExpressionCodes,
    emptyCells,
    updatedNamedExpressions,
  };
}

export function isCSVNamedExpressionValid(
  csvDataInputArray: ICostToDateCSV[],
  prefix?: string,
): ICodeValidationCSV {
  const accountSet = new Set<string>();

  for (const csvDataInputObject of csvDataInputArray) {
    const account = csvDataInputObject['Account'];

    accountSet.add(account);
  }
  return { isValid: true, errorCode: null };
}

export function importToCostsToDateFromCSV(
  csvDataInputArray: ICostToDateCSV[],
  category: string,
  prefix?: string,
): ICostsToDateData[] | ICodeValidationCSV {
  if (!csvDataInputArray) {
    return [];
  }

  const seenAccounts = new Set<string>();
  const uniqueCsvDataInputArray: ICostToDateCSV[] = [];
  for (let i = csvDataInputArray.length - 1; i >= 0; i--) {
    const account = csvDataInputArray[i]['Account'];
    if (!seenAccounts.has(account)) {
      seenAccounts.add(account);
      uniqueCsvDataInputArray.push(csvDataInputArray[i]);
    }
  }
  uniqueCsvDataInputArray.reverse();
  const validation = isCSVNamedExpressionValid(uniqueCsvDataInputArray, prefix);

  if (!validation.isValid) {
    return validation;
  }

  return uniqueCsvDataInputArray.map((csvDataInputObject) => {
    const account = csvDataInputObject['Account'];
    let codeSet;
    if (prefix && account.startsWith('_') && prefix.startsWith('_')) {
      codeSet = account;
    } else if (prefix && account.startsWith('_')) {
      codeSet = `${prefix}${account}`;
    } else if (prefix && prefix.startsWith('_')) {
      codeSet = `${prefix}${account}`;
    } else {
      codeSet = prefix ? `${prefix}_${account}` : account;
    }

    const costsToDateRowFromCSV: ICostsToDateData = {
      id: generatedId(),
      category: category,
      code: codeSet,
      description: csvDataInputObject['Account Description'] ?? '',
      units: csvDataInputObject['Amount'],
      value: csvDataInputObject['Amount'],
      total: '',
      usage: '',
      attachments: '',
      notes: '',
    };
    return costsToDateRowFromCSV;
  });
}

export function checkUsageCTD(data: ICostsToDateSheet): boolean {
  return data.some((costsToDate) => parseInt(costsToDate[7] as string) >= 1);
}
