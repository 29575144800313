import styled from '@emotion/styled';
import { Button } from '@mui/material';
import theme from '@/app/theme/base/theme';

export const DialogContentBoxText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

export const BoxButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
`;

export const TextButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  color: ${theme.palette.text.secondary};
  text-decoration: underline;
  z-index: 1;
`;
