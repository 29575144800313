export interface IFileAttachment {
  id: string;
  name: string;
  displayName: string;
  path: string;
  lastModified: string;
}

export interface IFileAttachmentList {
  [key: string]: IFileAttachment[];
}

export const initialFileAttachmentList: IFileAttachmentList = {};
