import { Controller, useFormContext } from 'react-hook-form';
import { InputTextField, ThemeSwitch } from '@/components/common';
import { OptionFormState } from './hooks/useOptionForm';

interface OptionFormProps {
  isDpfConnected?: boolean;
}

export const OptionForm = ({ isDpfConnected }: OptionFormProps) => {
  const { control, trigger } = useFormContext<OptionFormState>();

  return (
    <>
      <Controller
        name="mask"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputTextField
            labelText="Mask"
            type="text"
            placeholder="Enter the Mask..."
            isDisabled={!isDpfConnected}
            onChange={onChange}
            value={value}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="episodeCode"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputTextField
            labelText="Episode Code"
            type="text"
            placeholder="Enter the Episode Code..."
            isDisabled={!isDpfConnected}
            onChange={onChange}
            value={value}
            error={error?.message}
          />
        )}
      />

      <Controller
        name="costTrackerMode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ThemeSwitch
            label="Cost Tracker Mode"
            disabled={!isDpfConnected}
            checked={value}
            onChange={(_e, checked) => onChange(checked)}
          />
        )}
      />

      <Controller
        name="viewTransactionsLevel3"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ThemeSwitch
            label="View Transactions Level 3"
            disabled={!isDpfConnected}
            checked={value}
            onChange={(_e, checked) => onChange(checked)}
          />
        )}
      />

      <Controller
        name="includeUnpostedData"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <ThemeSwitch
            label="Include Unposted Data"
            disabled={!isDpfConnected}
            checked={value}
            onChange={(_e, checked) => onChange(checked)}
          />
        )}
      />

      <Controller
        name="detaAsSets"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <ThemeSwitch
            label="Using Detl field in Eclipse for Sets"
            disabled={!isDpfConnected}
            checked={value}
            onChange={(_e, checked) => {
              onChange(checked);
              trigger(['mask']);
            }}
          />
        )}
      />
    </>
  );
};
