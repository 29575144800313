import lodash from 'lodash';
import { generatedId } from '@/utils';
import { locationDataToFormulaTransformation } from '@/helpers/Mappers/LocationsMapper';
import { ICellCoords, IDataSheet, ISelectedCellData } from '@/interfaces/IDataSheet';
import { ILocation } from '@/interfaces/IFormulaSheet';
import { ILocationData, LocationTypes } from '@/interfaces/ILocationData';

export function getEmptyLocation(): ILocationData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    includeFringe: 'N',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newLocationData: ILocation;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newLocation: ILocationData = getEmptyLocation();
  updatedDataSheet.locations = updatedDataSheet.locations || [];
  updatedDataSheet.locations.splice(index, 0, newLocation);
  const newLocationData = locationDataToFormulaTransformation(newLocation, index);
  const updatedIds = [newLocation.id.toString()];

  return { updatedDataSheet, newLocationData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: ILocationData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.locations = updatedDataSheet.locations.map((location) => {
    if (location.id === record.id) {
      updatedIds.push(location.id.toString());
      return { ...location, ...record };
    }
    return location;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.locations[0] = { ...getEmptyLocation(), id: updatedDataSheet.locations[0].id };
  }
  const removedLocationsSheet: ILocationData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.locations.forEach((locationData, index) => {
    if (!removeIndexes.includes(index)) {
      removedLocationsSheet.push(locationData);
    } else {
      updatedIds.push(locationData.id.toString());
    }
  });
  updatedDataSheet.locations = removedLocationsSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: ILocationData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.locations[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.locations.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.locations.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedLocationRow(copiedRow: ILocationData): ILocationData {
  const updatedRow = getEmptyLocation();

  // columns that need to be updated before pasting
  const reviseColumns = [
    LocationTypes.CATEGORY,
    LocationTypes.DESCRIPTION,
    LocationTypes.INCLUDE_FRINGE,
  ];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeLocationsCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  emptyCells: ICellCoords[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const emptyCells: ICellCoords[] = [];

  if (updatedDataSheet) {
    // eslint-disable-next-line array-callback-return
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      // eslint-disable-next-line array-callback-return
      updatedDataSheet.locations.forEach((data: ILocationData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof ILocationData] = '';
        }
      });
    });
  }
  return { updatedDataSheet, updatedIds, emptyCells };
}

// is row all records selected
export const isAllRecordsSelected = (dataSheet: IDataSheet, selectedIndex: number[]) => {
  return selectedIndex.length === dataSheet.locations.length;
};
