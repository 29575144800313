import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

type Props = ButtonProps & {
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
  loading?: boolean;
};

export const MainButton = ({
  onClick,
  disabled,
  loading,
  children,
  ...props
}: Props): ReactElement => (
  <Button
    onClick={onClick}
    disabled={disabled || loading}
    {...props}
    sx={{
      textTransform: 'none',
      padding: '0.8rem 2rem',
      fontWeight: 600,
      boxShadow: 'none',
      fontSize: '14px',
    }}
  >
    {loading && (
      <CircularProgress
        size={20}
        sx={{
          marginRight: '0.5rem',
        }}
      />
    )}
    {children}
  </Button>
);
