export function hasSameSubarrayLength(arr: unknown[][]): boolean {
  const firstSubarrayLength = arr[0].length;
  return arr.every((subarray) => subarray.length === firstSubarrayLength);
}

export function containsSeriesNumber(array: number[]): boolean {
  if (array.length === 1) {
    return true;
  }

  // Iterate over the array and check if each element is adjacent to the next element.
  for (let i = 0; i < array.length - 1; i++) {
    if (array[i + 1] !== array[i] + 1) {
      return false;
    }
  }

  // If all elements of the array are adjacent, return true.
  return true;
}
