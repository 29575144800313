import Dexie from 'dexie';

export class WebStorage {
  private readonly database: any;
  private readonly databaseName: string;
  private readonly dataSheetKey: string;
  private readonly reportsKey: string;

  constructor() {
    this.databaseName = 'PIVOT_WEB_STORE';
    this.dataSheetKey = 'DATA_SHEET';
    this.reportsKey = 'REPORTS';

    this.database = new Dexie(this.databaseName);
    this.database.version(1).stores({
      sheet: 'name,value,sync',
      reports: 'key,value',
    });

    this.database.open();
  }

  async isItDatabaseExists(): Promise<boolean> {
    return await Dexie.exists(this.databaseName);
  }

  async isItDataSheetExists(): Promise<boolean> {
    const databaseExists = await this.isItDatabaseExists();
    if (databaseExists) {
      const sheetExists = await this.getDataSheet();
      if (sheetExists) {
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  }

  async getDataSheet(): Promise<any> {
    if (this.database) {
      const dataSheet = await this.database.sheet.where('name').equals(this.dataSheetKey).first();
      if (dataSheet) {
        return Promise.resolve(dataSheet.value);
      }
    }

    return Promise.resolve(null);
  }

  async isDataSheetPendingSyncing(): Promise<boolean> {
    const dataSheet = await this.database.sheet.where('name').equals(this.dataSheetKey).first();
    if (dataSheet?.sync === 'on') {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  async turnOffSyncing(): Promise<void> {
    const dataSheet = await this.getDataSheet();
    if (dataSheet) {
      await this.database.sheet.update(this.dataSheetKey, {
        sync: 'off',
      });
    }
    return Promise.resolve();
  }

  async getExistingCount(): Promise<number> {
    return await this.database.sheet.where('name').equals(this.dataSheetKey).count();
  }

  async saveSheet(data: any, sync: 'on' | 'off', initialLoad: boolean): Promise<void> {
    await this.database.sheet.add({
      name: this.dataSheetKey,
      value: data,
      sync,
      initialLoad,
    });
  }

  async updateSheet(data: any, sync: 'on' | 'off'): Promise<void> {
    await this.database.sheet.update(this.dataSheetKey, {
      value: data,
      sync,
    });
  }

  async save(data: any) {
    console.debug('Saving to local database...');
    if (!this.database) {
      return;
    }

    const existingCount = await this.getExistingCount();
    if (existingCount === 0) {
      await this.saveSheet(data, 'off', true);
    } else {
      await this.updateSheet(data, 'on');
    }
  }

  async removeDataSheet(): Promise<void> {
    if (this.database) {
      await this.database.sheet.where('name').equals(this.dataSheetKey).delete();
    }
  }

  async saveReport(key: string, data: string): Promise<void> {
    try {
      const existingReport = await this.database.reports.get({ key });
      if (existingReport) {
        await this.database.reports.update(key, { value: data });
      } else {
        await this.database.reports.add({ key, value: data });
      }
    } catch (error) {
      console.error('Error saving report:', error);
    }
  }

  async getReportByKey(key: string): Promise<string | null> {
    const report = await this.database.reports.get({ key });
    return report ? report.value : null;
  }

  async removeReports(): Promise<void> {
    if (this.database) {
      await this.database.sheet.where('name').equals(this.reportsKey).delete();
    }
  }

  async removeDatabase(): Promise<void> {
    if (this.database) {
      this.database.close();
      await this.database.delete();
    }
  }
}
