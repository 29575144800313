import { ICellError } from '@/interfaces/ICellError';

const instanceOfCellError = (object: any): object is ICellError => {
  if (object && typeof object !== 'number' && typeof object !== 'string') {
    return 'message' in object && 'value' in object && 'address' in object && 'type' in object;
  }
  return false;
};

export default instanceOfCellError;

export const checkCellError = (item: any) => {
  try {
    if (instanceOfCellError(item)) {
      console.error('--- Report exception logger ---');
      console.error(`Current item: ${JSON.stringify(item, null, 2)}`);
      console.error('---------------------');
      const errorMessage = JSON.stringify(item, null, 2);
      throw new Error(errorMessage);
    }
    return item;
  } catch (err: any) {
    console.error(`An error occurred : ${err.message}`);
    throw err;
  }
};
