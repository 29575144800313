import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useRecoilState } from 'recoil';
import { noop } from '@/utils';
import { toasterAtom } from '@/atoms/GlobalAtoms';
import IToasterAtom from '@/interfaces/IToasterAtom';

interface IToasterProps {
  autoHideDuration?: number;
}

const Toaster = ({ autoHideDuration = 6000 }: IToasterProps) => {
  const [config, setConfig] = useRecoilState<IToasterAtom>(toasterAtom);

  function handleClose() {
    setConfig({
      ...config,
      show: false,
    });

    if (config.onClose) {
      config.onClose();
    }
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={config.show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={config.type} sx={{ width: '100%' }}>
        {config.message}
      </Alert>
    </Snackbar>
  );
};

function useToaster() {
  const [config, setConfig] = useRecoilState<IToasterAtom>(toasterAtom);

  return {
    info: (message: string, onClose?: () => void) => {
      setConfig({
        ...config,
        show: true,
        type: 'info',
        message: message,
        onClose: onClose ?? noop,
      });
    },
    success: (message: string, onClose?: () => void) => {
      setConfig({
        ...config,
        show: true,
        type: 'success',
        message: message,
        onClose: onClose ?? noop,
      });
    },
    error: (message: string, onClose?: () => void) => {
      setConfig({
        ...config,
        show: true,
        type: 'error',
        message: message,
        onClose: onClose ?? noop,
      });
    },
    warning: (message: string, onClose?: () => void) => {
      setConfig({
        ...config,
        show: true,
        type: 'warning',
        message: message,
        onClose: onClose ?? noop,
      });
    },
  };
}

export { Toaster, useToaster };
