import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  TextField,
  SxProps,
  Theme,
  TextFieldProps,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';
import theme from '@/app/theme/base/theme';

type Props = Omit<TextFieldProps, 'onChange' | 'error'> & {
  error?: string | boolean;
  onChange?: (value: string) => void;
  sx?: SxProps<Theme>;
  labelText?: string;
};

export const InputPasswordField = ({
  labelText,
  sx,
  onChange,
  error,
  ...props
}: Props): ReactElement => {
  const [touched, setTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onTouch = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!touched) {
      setTouched(true);
    }
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl fullWidth>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        {labelText}
      </Typography>
      <TextField
        size={'small'}
        {...props}
        error={touched && !!error}
        helperText={touched && typeof error === 'string' ? error : undefined}
        onBlur={onTouch}
        onChange={handleChange}
        type={showPassword ? 'text' : 'password'}
        sx={{
          mb: 2,
          mt: 0.5,
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '14px !important',
            margin: '5px 0 0 0 !important',
            padding: '0 !important',
          },
          '& .MuiOutlinedInput-root': {
            fontSize: '14px',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
