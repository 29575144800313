import lodash from 'lodash';
import { UnitDescriptionColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { INamedExpressionData } from '@/interfaces/IFormulaSheet';
import { IUnitDescriptionData, UnitDescriptionTypes } from '@/interfaces/IUnitDescriptionData';
import { unitDescDataToFormulaTransformation } from './Mappers/UnitDescriptionsMapper';

export function getEmptyUnitDesc(): IUnitDescriptionData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    units: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newUnitDescData: (string | number | null)[];
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newUnitDesc: IUnitDescriptionData = getEmptyUnitDesc();
  updatedDataSheet.unitDesc = updatedDataSheet.unitDesc || [];
  updatedDataSheet.unitDesc.splice(index, 0, newUnitDesc);
  const newUnitDescData = unitDescDataToFormulaTransformation(newUnitDesc, index);
  const updatedIds = [newUnitDesc.id.toString()];

  return {
    updatedDataSheet,
    newUnitDescData,
    updatedIds,
  };
}

export function editRow(
  dataSheet: IDataSheet,
  record: IUnitDescriptionData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.unitDesc = updatedDataSheet.unitDesc.map((unitDesc) => {
    if (unitDesc.id === record.id) {
      updatedIds.push(unitDesc.id.toString());
      return { ...unitDesc, ...record };
    }
    return unitDesc;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): { updatedDataSheet: IDataSheet; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.unitDesc[0] = { ...getEmptyUnitDesc(), id: updatedDataSheet.unitDesc[0].id };
  }
  const removedUnitDescriptionSheet: IUnitDescriptionData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet[DateSheetAttributeType.UNIT_DESCRIPTIONS].forEach(
    (unitDescriptionData, index) => {
      if (!removeIndexes.includes(index)) {
        removedUnitDescriptionSheet.push(unitDescriptionData);
      } else {
        updatedIds.push(unitDescriptionData.id.toString());
      }
    },
  );
  updatedDataSheet[DateSheetAttributeType.UNIT_DESCRIPTIONS] = removedUnitDescriptionSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: IUnitDescriptionData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.unitDesc[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.unitDesc.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.unitDesc.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedUnitDescriptionRow(
  copiedRow: IUnitDescriptionData,
): IUnitDescriptionData {
  const updatedRow = getEmptyUnitDesc();

  // columns that need to be updated before pasting
  const reviseColumns = [
    UnitDescriptionTypes.CATEGORY,
    UnitDescriptionTypes.DESCRIPTION,
    UnitDescriptionTypes.UNITS,
  ];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeUnitsDescCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  removeNamedExpressionCodes: string[];
  emptyCells: ICellCoords[];
  updatedNamedExpressions: INamedExpressionData[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const removeNamedExpressionCodes: string[] = [];
  const emptyCells: ICellCoords[] = [];

  const updatedNamedExpressions: INamedExpressionData[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.unitDesc.forEach((data: IUnitDescriptionData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof IUnitDescriptionData] = '';
        }
        if (item.col === UnitDescriptionColumnIndexes.code) {
          removeNamedExpressionCodes.push(
            dataSheet[DateSheetAttributeType.UNIT_DESCRIPTIONS][item.row].code,
          );
        }
        const code = dataSheet[DateSheetAttributeType.UNIT_DESCRIPTIONS][item.row].code;
        if (
          item.col === UnitDescriptionColumnIndexes.units &&
          !removeNamedExpressionCodes.includes(code)
        ) {
          updatedNamedExpressions.push({ code, expression: '0' });
        }
      });
    });
  }
  return {
    updatedDataSheet,
    updatedIds,
    removeNamedExpressionCodes,
    emptyCells,
    updatedNamedExpressions,
  };
}
