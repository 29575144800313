export function getLastParentFolderPath(folderPath: string): string {
  folderPath = removeLastSlash(folderPath);
  const folders = folderPath.split('/');
  let result = '';
  if (folders.length > 1) {
    folders.pop();
    result = folders.join('/').concat('/');
  }
  return result;
}

export function removeLastSlash(str: string): string {
  if (str.endsWith('/') && str.lastIndexOf('/') === str.length - 1) {
    return str.slice(0, str.length - 1);
  }
  return str;
}
