import IConfig from '@/interfaces/IConfig';

export const config: IConfig = {
  environment: 'PROD',
  region: 'ap-southeast-2',
  baseUrl: 'https://api.app.hollywoodbudgets.com/',
  cognitoPoolId: 'ap-southeast-2_0URC7VGlc',
  cognitoClientId: '5sdpt19ltm61spa3fipu4danbi',
  hyperformulaLicense: 'internal-use-in-handsontable',
  handsontableLicense: 'bb1b5-b3d0f-3e073-04435-fcd5f',
  stimulsoftLicense:
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnYADvcVY7U2/OK46A0UTaMaP7CFyjaldb8hzkYa+nBiQfefSdFURtRbKd/bUwwOEXo8FoEz05R9rIkZ+KzotqSEf++wZ9FSDTqx2KcfRf4PlkVPr0/9p+Gh1TZwgGboOHYPYYKRoH8/JqEmDhKjPn7RHa7KlreJMBXl8RsHm9Yo/TorRLqUJQrMHs8ehwaxN5ftxUMQAyWQYwcXJv1XTb6cf+vVtQC3BAl61a/kEzZxOvnofsTxx8rzBRWmbU5bDNrNwnBFZxRplY9OO1n+E9Pz8GektFOCrgLO3TdCOlI7ZTI8hHG1IHyi18GjOPJuTLkzGEPD2s/nbbdLB3k2OAexGv2s4DzyrEnEISyYvNbVEt8uZn6xCDnw8HoSPF7aguj/O/DOHwp8T6sxEvG7Qbubv+4aulNrWHBb80c7iMxj0XAQ7lwXQ6e7kbi40DCWRukVKAtSTsA55XjNLYqtA9BqzRz2BrVLbRPWUomz3glqnFGFsXXKUJPwkBtM0cgSltMdTWOIaHJmJtqAr1oQtW4',
  subscription: {
    enabled: true,
    redirectUrl: 'https://subscriptions.zoho.com.au/portal/hollywoodbudgets',
  },
  analytics: {
    enabled: true,
    postHogKey: 'phc_OY5iaLvrFLy5FAsOhKvjGeGDCqkCCxoDk7aUZmsjOcZ',
    postHogApiHost: 'https://us.i.posthog.com',
  },
  costTracker: {
    dpfApiUrl: 'https://dpf-auprod-main-apigw.digitalpaperflow.com/prod',
    dpfOAuthUrl: 'https://dpf-auprod-main-apigw.digitalpaperflow.com/prod',
    dpfOAuthCredentials:
      'MGU0MmYxMjhjMTQ4NDdmMjljMTBkMzc4YWE5YWFmNWU6Mjg2YTE1ZTEwZjljNDMxOWE1ZTE5NmVhYjNkNTdjZGE=',
  },
};
