import { Divider, Icon, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import lodash from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IMPORT_FILE, SELECTED_FILE } from '@/enums';
import {
  budgetSheetSavedResponseAtom,
  fileUrlAtom,
  openSaveAsAtom,
  rootFolder,
} from '@/atoms/GlobalAtoms';
import { AlertMessage, SelectField } from '@/components/common';
import { SaveAsDialog } from '@/components/dialogBoxes';
import { boxStyles, typographyStyles } from '@/components/dialogBoxes/ImportCTDDialog/style';
import { IFileResponse } from '@/components/dialogBoxes/types';
import { CloseSquareIcon, ReceiveSquareGrayIcon } from '@/components/icon';
import {
  buildDataSheetFromJSONFile,
  buildDataSheetFromPivotSheet,
  buildDataSheetFromXmlFile,
} from '@/helpers/SheetImportHelper';
import DataSheetApiService from '@/services/DataSheetApiService';
import FormulaSheet from '@/sheets/FormulaSheet';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  formulaSheet: FormulaSheet;
  onImport: (
    data: any,
    file: IFileResponse[],
  ) => Promise<{
    success: boolean;
    message: string;
  }>;
  onDataSheetSave: (method: 'manual' | 'auto') => Promise<void>;
}

export function ImportDialog({ open, setOpen, formulaSheet, onImport, onDataSheetSave }: Props) {
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [invalidFormat, setInvalidFormat] = useState<boolean>(false);
  const [openSaveAsStatus, setOpenSaveAsStatus] = useRecoilState(openSaveAsAtom);
  const [successfullySync, setSuccessfulSync] = useState<boolean>(false);
  const [saveAsOpen, setSaveAsOpen] = useState<boolean>(false);
  const [importData, setImportData] = useState<any>();
  const [selected, setSelected] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isLayoutSelected, setIsLayoutSelected] = useState<boolean>(false);
  const [noSelectionError, setNoSelectionError] = useState<string | null>(null);
  const [files, setFiles] = useState<IFileResponse[]>([]);
  const rootPath = useRecoilValue(rootFolder);
  const fileUrl = useRecoilValue(fileUrlAtom);

  const setBudgetSheetSavedResponse = useSetRecoilState(budgetSheetSavedResponseAtom);

  let accept = {};

  switch (selected) {
    case 'JSON':
      accept = { 'file/json': ['.json'] };
      break;
    case 'XML':
      accept = { 'file/xml': ['.xml'] };
      break;
    default:
      accept = { 'file/json': ['.json'] };
  }

  const { acceptedFiles, getRootProps, getInputProps }: DropzoneState = useDropzone({
    accept,
    onDropRejected: () => {
      if (selected === SELECTED_FILE.JSON) {
        setErrorMessage('Invalid file type. Please upload a JSON file');
      } else if (selected === SELECTED_FILE.XML) {
        setErrorMessage('Invalid file type. Please upload a XML file');
      } else if (selected === SELECTED_FILE.PIVOT_SHEET) {
        setErrorMessage('Please upload a Hollywood Budgets sheet.');
      }
    },
    onDropAccepted: () => {
      setErrorMessage('');
    },
  });

  const handleClose = (): void => {
    setOpen(false);
    setNoSelectionError(null);
  };

  useEffect(() => {
    acceptedFiles.forEach((file) => handleImportFileLayout(file));
  }, [acceptedFiles]);

  const handleImportFileLayout = (file: File) => {
    if (!selected || selected === 'null') {
      setNoSelectionError('Please select a layout before uploading a file.');
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result;
      setUploadFile(file);
      setErrorMessage(null);
      if (fileContent) {
        buildDataSheetFrom(file.type, fileContent.toString());
      }
    };

    reader.onerror = () => {
      console.error('File reading has failed');
      setErrorMessage('File reading has failed');
    };

    reader.readAsText(file);
  };

  const buildDataSheetFrom = (fileType: string, fileContent: string) => {
    if (fileType === IMPORT_FILE.JSON && selected === SELECTED_FILE.PIVOT_SHEET) {
      buildDataSheetFromPivotSheet(fileContent, setInvalidFormat, setErrorMessage, setImportData);
    } else if (fileType === IMPORT_FILE.XML && selected === SELECTED_FILE.XML) {
      buildDataSheetFromXmlFile(fileContent, setInvalidFormat, setErrorMessage, setImportData);
    } else if (fileType === IMPORT_FILE.JSON && selected === SELECTED_FILE.JSON) {
      buildDataSheetFromJSONFile(fileContent, setInvalidFormat, setErrorMessage, setImportData);
    } else {
      setErrorMessage('Invalid file type. Only JSON and XML are accepted');
    }
  };

  const triggerSnackBar = (action: string) => {
    if (action === 'success') {
      setBudgetSheetSavedResponse({
        isOpenSnackBar: true,
        message: 'Successfully saved',
        severity: 'success',
      });
    } else if (action === 'error') {
      setBudgetSheetSavedResponse({
        isOpenSnackBar: true,
        message: 'Error saving budget Sheet',
        severity: 'error',
      });
    }
  };

  const triggerSnackBarOnImport = (action: string) => {
    setTimeout(() => {
      if (action === 'success') {
        setBudgetSheetSavedResponse({
          isOpenSnackBar: true,
          message: 'Import is success. Please enter file name to save',
          severity: 'success',
        });
      } else if (action === 'error') {
        setBudgetSheetSavedResponse({
          isOpenSnackBar: true,
          message: 'Error during import',
          severity: 'error',
        });
      }
    }, 500);
  };

  const handleOnImport = async () => {
    if (!selected || selected === 'null' || !isLayoutSelected) {
      setIsError(true);
      setErrorMessages('Please select a layout');
      setIsImporting(false);
      return;
    }

    setIsImporting(true);
    try {
      const { success: importSuccess, message } = await onImport(importData, files);
      if (importSuccess) {
        triggerSnackBarOnImport('success');
        setSuccessfulSync(true);
        setSaveAsOpen(true);

        if (saveAsOpen) {
          setOpenSaveAsStatus(false);
        }
      } else {
        setBudgetSheetSavedResponse({
          isOpenSnackBar: true,
          message: message,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error during import:', error);
      triggerSnackBarOnImport('error');
    } finally {
      setIsImporting(false);
    }
  };

  useEffect(() => {
    if (openSaveAsStatus) {
      setOpen(false);
      setTimeout(() => {
        setOpenSaveAsStatus(false); // Open the SaveAsDialog after a short delay
      }, 2000);
    }
  }, [openSaveAsStatus, setOpen]);

  useEffect(() => {
    if (uploadFile !== undefined) {
      setUploadSuccess(true);
    }
  }, [uploadFile, invalidFormat]);

  useEffect(() => {
    if (selected && selected !== 'null') {
      setIsLayoutSelected(true);
      setIsError(false);
      // Reset the uploaded file, success, and error states whenever the file type selection changes
      setUploadFile(undefined);
      setUploadSuccess(false);
      setErrorMessage(null);
      setInvalidFormat(false);
      setImportData(undefined);
    } else {
      setIsLayoutSelected(false);
    }
  }, [selected]);

  useEffect(() => {
    if (invalidFormat) {
      setErrorMessage('Invalid format');
      setUploadSuccess(false);
    }
  }, [uploadFile, invalidFormat]);

  const handleSaveAsFileImport = (isOpen: boolean, action?: string) => {
    if (fileUrl === null) return;

    const untitledRegex = /^Untitled(\s\(\d+\))?\.json$/;
    setSaveAsOpen(isOpen);

    if (!isOpen) {
      setOpen(false);
      setTimeout(() => {
        if (fileUrl && untitledRegex.test(fileUrl)) {
          onDataSheetSave('manual');
        }
      }, 100);
    }
    const debouncedTriggerSnackBar = lodash.debounce(triggerSnackBar, 100);
    if (action) {
      debouncedTriggerSnackBar(action);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFilesFromServer = useCallback(
    lodash.debounce(async () => {
      if (!rootPath) return;
      const response = await DataSheetApiService.fetchSheets(rootPath, '', '', false, false, false);

      if (response) {
        const filterFiles = response.filter((file) => file.filename.length > 0);
        setFiles(filterFiles);
      } else {
        setFiles([]);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    fetchFilesFromServer();

    return () => {
      fetchFilesFromServer.cancel();
    };
  }, [fetchFilesFromServer]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      style={{
        maxHeight: '750px',
        overflowX: 'hidden',
      }}
      onKeyDown={handleKeyDown}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '310px',
          },
        },
      }}
    >
      {successfullySync && <SaveAsDialog open={saveAsOpen} onClose={handleSaveAsFileImport} />}
      <div
        style={{
          backgroundColor: '#000',
          color: '#fff',
          fontWeight: '400',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
          {'Import'}
        </DialogTitle>
        <CloseSquareIcon
          fontSize="medium"
          onClick={() => setOpen(false)}
          sx={{ cursor: 'pointer', color: 'black', stroke: 'white' }}
        />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: 'black',
          }}
          id="alert-dialog-description"
        >
          <Stack direction="row" spacing={3}>
            <Stack direction="column" width={'100%'}>
              <div>
                <Typography variant="h6" mb={1} sx={{ fontWeight: 600 }}>
                  {'Import'}
                </Typography>
                <Divider
                  sx={{
                    height: '2px',
                    backgroundColor: '#F1F3F4',
                    marginBottom: '15px',
                    borderColor: '#F1F3F4',
                  }}
                />
              </div>

              {noSelectionError && <AlertMessage message={noSelectionError} severity="error" />}

              {isError ? <AlertMessage message={errorMessages} severity="error" /> : null}
              <SelectField
                labelText="Layout"
                placeholder="Select Layout"
                options={[
                  { value: 'PIVOT', label: 'Hollywood Budgets' },
                  { value: 'JSON', label: 'JSON' },
                  { value: 'XML', label: 'XML' },
                ]}
                onChange={(value) => {
                  setSelected(value);
                  setNoSelectionError(null);
                }}
                value={selected}
              />
              <Box {...getRootProps()} sx={boxStyles}>
                {uploadSuccess ? (
                  <Typography>
                    <Typography sx={{ textAlign: 'center' }}>{uploadFile?.name}</Typography>
                  </Typography>
                ) : (
                  <>
                    <input {...getInputProps()} required />
                    {errorMessage ? (
                      <Typography sx={typographyStyles}>{errorMessage}</Typography>
                    ) : (
                      <Typography sx={typographyStyles}>
                        Drag & Drop or Click to Add File
                      </Typography>
                    )}
                    {!errorMessage && (
                      <Icon sx={{ fontSize: 18 }}>
                        <ReceiveSquareGrayIcon />
                      </Icon>
                    )}
                  </>
                )}
              </Box>
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isLayoutSelected || !uploadSuccess || isImporting}
          style={{
            marginBottom: '10px',
            marginRight: '5px',
            fontSize: '14px',
            fontWeight: '600',
          }}
          color="warning"
          variant="contained"
          onClick={() => handleOnImport()}
          autoFocus
        >
          {isImporting ? 'Importing' : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportDialog;
