import styled from '@emotion/styled';
import { type Theme } from '@mui/material/styles';
import { NavLink as BaseNavLink } from 'react-router-dom';

export const TabsContainer = styled.div`
  .MuiTabs-flexContainer {
    border-bottom: 2px solid ${({ theme }) => (theme as Theme).palette.primary.main};
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-root {
    min-height: 0.1rem;
  }
`;

export const NavLink = styled(BaseNavLink)`
  flex: 1 0 auto;
  color: gray;
  background-color: ${({ theme }) => (theme as Theme).palette.secondary.main};
  margin: 0 0.2rem 0 0;
  padding: 0.6rem;
  min-height: 0.1rem;
  text-align: center;
  font-size: 12px;
  font-family: Hellix, sans-serif;
  font-weight: bold;
  line-height: 1.25;
  min-width: 90px;
  max-width: none;

  text-transform: none;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  user-select: none;
  vertical-align: middle;

  &.active {
    color: white;
    background-color: ${({ theme }) => (theme as Theme).palette.primary.main};
  }

  &:last-child {
    margin: 0;
  }
`;
