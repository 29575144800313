/* eslint-disable @typescript-eslint/no-explicit-any */
import { SYSTEM_CURRENCY_LABEL } from '@/constants';
import { formatDate } from '@/utils';
import instanceOfCellError, { checkCellError } from '@/helpers/checkIfCellError';

const getDAggPercent = (fringe: any) => {
  const fringeValue = instanceOfCellError(fringe) ? fringe.value : fringe;
  if (fringeValue) {
    return (fringeValue * 100).toFixed(2) + '%';
  }
};

// TODO : update this to common file
export function getSelectedCurrency(selectedCurrency: string): string {
  if (selectedCurrency === SYSTEM_CURRENCY_LABEL) {
    return SYSTEM_CURRENCY_LABEL;
  }
  return selectedCurrency;
}

export const generateJSONDoc = (
  l1Data: any,
  l2Data: any,
  l3Data: any,
  formatDataSheet: any,
  selectedCurrency: string,
  defaultCurrency: string,
  fileName: string,
) => {
  const budget = {
    budget: {
      metadata: {
        budgetdata: {},
        defaultfields: {},
      },
      categories: {
        category: [] as any,
      },
      accounts: {
        account: [] as any,
      },
      details: {
        detail: [] as any,
      },
    },
  };

  // metadata
  budget.budget.metadata.budgetdata = {
    BudgetName: formatDataSheet?.meta?.file?.title,
    BudgetUUID: '',
    ProjectName: formatDataSheet?.meta?.file?.projectName,
    ProjectUUID: '',
    Created: formatDataSheet?.meta?.file?.created
      ? formatDate(formatDataSheet?.meta?.file?.created)
      : '',
    Modified: formatDataSheet?.meta?.file?.created
      ? formatDate(formatDataSheet?.meta?.file?.lastModified)
      : '',
    BaseCurrency: defaultCurrency,
    ConvCurrency: getSelectedCurrency(selectedCurrency),
    CategoryCount: '',
    DetailAccountCount: '',
    DetailLineCount: '',
    FringeCount: formatDataSheet?.fringes?.length || 0,
    GlobalCount: formatDataSheet?.globals?.length || 0,
    UnitCount: formatDataSheet?.unitDesc?.length || 0,
    LocationCount: formatDataSheet?.locations?.length || 0,
    SetCount: formatDataSheet?.sets?.length || 0,
    CurrencyCount: formatDataSheet?.currency?.length || 0,
  };

  budget.budget.metadata.defaultfields = {
    Title: formatDataSheet?.meta?.file?.title,
    Production: '',
    Season: '',
    EpisodeNumber: '',
    TotalEpisodes: '',
    Status: '',
    ProductionBaseCountry: '',
    ProductionBaseRegion: '',
    ProductionBaseCity: '',
    LocationShootDays: '',
    StageShootDays: '',
    StartProductionDate: '',
    EndProductionDate: '',
    ProductionYear: '',
  };

  // l1 data
  l1Data.forEach((item: any) => {
    budget.budget.categories.category.push({
      cID: item.rowIndex,
      cNumber: checkCellError(item.account),
      cDescription: item.description,
      cRowType: item.rowType,
      cFringe: checkCellError(item.fringes),
      cOriginal: checkCellError(item.comparison),
      cTotal: checkCellError(item.total),
      cVariance: checkCellError(item.variance),
    });
  });

  // l2 data
  l2Data.forEach((item: any) => {
    budget.budget.accounts.account.push({
      categoryID: item.sheetIndex,
      aID: item.rowIndex,
      aNumber: checkCellError(item.account),
      aDescription: item.description,
      aRowType: item.rowType,
      aFringe: checkCellError(item.fringes),
      aOriginal: checkCellError(item.comparison),
      aTotal: checkCellError(item.total),
      aVariance: checkCellError(item.variance),
    });
  });

  // l3 data
  l3Data.forEach((item: any) => {
    budget.budget.details.detail.push({
      accountID: item.sheetIndex,
      dAggPercent: getDAggPercent(item.fringe),
      dFringes: item.fringes ? item.fringes.split(',') : [],
      dGroups: item.groups ? item.groups.split(',') : [],
      dLocation: item.loc,
      dSet: item.set,
      dDescription: item.description,
      dRowType: item.rowType,
      dAmount: checkCellError(item.units),
      dUnit: item.desc,
      dX: item.x,
      dCurrency: item.cu,
      dRate: item.rate,
      dSubtotal: checkCellError(item.total),
    });
  });

  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(budget));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${fileName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
