import lodash from 'lodash';

import { GroupColumnIndexes, GroupsColumnAlias } from '@/enums';
import { generatedId } from '@/utils';
import { IGroup, IGroupsSheet } from '@/interfaces/IFormulaSheet';
import { IGroupData } from '@/interfaces/IGroupData';

export const transformGroups = (groups: IGroupData[]) => {
  if (lodash.isEmpty(groups)) {
    return [];
  }

  const groupsSheet: IGroupsSheet = groups?.map((group: IGroupData, index) => {
    return groupDataToFormulaTransformation(group, index);
  });

  const groupTotalRow = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', // units
    '', // cap
    '', // cu
    '', // color
    '', // includeFringe
    0, // total
    0, // usage
    `=SUM(${GroupsColumnAlias.Attachments}0:${GroupsColumnAlias.Attachments}${groupsSheet.length})`, // attachments
    '', // notes
    'T', // row type
    `=ROW(${GroupsColumnAlias.Code}${groupsSheet.length + 1})-1`,
  ];

  groupsSheet.push(groupTotalRow);
  return groupsSheet;
};

export const groupsToDataSheetTransformation = (group: IGroup) => {
  return {
    id: group[GroupColumnIndexes.id],
    category: group[GroupColumnIndexes.category],
    code: group[GroupColumnIndexes.code],
    description: group[GroupColumnIndexes.description],
    suppress: group[GroupColumnIndexes.suppress],
    cap: group[GroupColumnIndexes.cap],
    cu: group[GroupColumnIndexes.cu],
    color: group[GroupColumnIndexes.color],
    includeFringe: group[GroupColumnIndexes.includeFringe],
    total: group[GroupColumnIndexes.total],
    usage: group[GroupColumnIndexes.usage],
    attachments: group[GroupColumnIndexes.attachments],
    notes: group[GroupColumnIndexes.notes],
  } as IGroupData;
};

export const groupDataToFormulaTransformation = (group: IGroupData, index: number) => {
  return [
    group.id,
    group.category,
    group.code,
    group.description,
    group.suppress,
    group.cap,
    group.cu,
    group.color,
    group.includeFringe,
    0,
    0,
    group.attachments,
    group.notes,
    'D',
    `=ROW(${GroupsColumnAlias.Code}${index + 1})-1`,
  ] as IGroup;
};
