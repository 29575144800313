import lodash from 'lodash';
import { CurrencyColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { currencyDataToFormulaTransformation } from '@/helpers/Mappers/CurrencyMapper';
import { CurrencyTypes, ICurrencyData } from '@/interfaces/ICurrencyData';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { ICurrency, INamedExpressionData } from '@/interfaces/IFormulaSheet';

export function getEmptyCurrency(): ICurrencyData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    rate: '1',
    default: 'N',
    includeFringe: 'N',
    total: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newCurrencyData: ICurrency;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newCurrency: ICurrencyData = getEmptyCurrency();
  updatedDataSheet.currency = updatedDataSheet.currency || [];
  updatedDataSheet.currency.splice(index, 0, newCurrency);
  const newCurrencyData = currencyDataToFormulaTransformation(newCurrency, index);
  const updatedIds = [newCurrency.id.toString()];
  return { updatedDataSheet, newCurrencyData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: ICurrencyData,
): { updatedDataSheet: IDataSheet; updatedDefaultCurrency: number[]; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedDefaultCurrency: number[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.currency = updatedDataSheet.currency.map((currency, index: number) => {
    if (currency.id === record.id) {
      updatedIds.push(currency.id.toString());
      return { ...currency, ...record };
    }
    if (record.default === 'Y') {
      updatedDefaultCurrency.push(index);
      updatedIds.push(currency.id.toString());
      return {
        ...currency,
        default: 'N',
      };
    }
    return currency;
  });
  return { updatedDataSheet, updatedDefaultCurrency, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): { updatedDataSheet: IDataSheet; updatedIds: string[] } {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.currency[0] = {
      ...getEmptyCurrency(),
      id: updatedDataSheet.currency[0].id,
    };
  }
  const removedCurrencySheet: ICurrencyData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.currency.forEach((currencyData, index) => {
    if (!removeIndexes.includes(index)) {
      removedCurrencySheet.push(currencyData);
    } else {
      updatedIds.push(currencyData.id.toString());
    }
  });
  updatedDataSheet.currency = removedCurrencySheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: ICurrencyData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.currency[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.currency.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.currency.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedCurrencyRow(copiedRow: ICurrencyData): ICurrencyData {
  const updatedRow = getEmptyCurrency();

  // columns that need to be updated before pasting
  const reviseColumns = [CurrencyTypes.CATEGORY, CurrencyTypes.DESCRIPTION, CurrencyTypes.RATE];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeCurrencyCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  removeNamedExpressionCodes: string[];
  emptyCells: ICellCoords[];
  updatedNamedExpressions: INamedExpressionData[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const removeNamedExpressionCodes: string[] = [];
  const emptyCells: ICellCoords[] = [];

  const updatedNamedExpressions: INamedExpressionData[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.currency.forEach((data: ICurrencyData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof ICurrencyData] = '';
        }
        if (item.col === CurrencyColumnIndexes.code) {
          removeNamedExpressionCodes.push(
            dataSheet[DateSheetAttributeType.CURRENCY][item.row].code,
          );
        }
        const code = dataSheet[DateSheetAttributeType.CURRENCY][item.row].code;
        if (item.col === CurrencyColumnIndexes.rate && !removeNamedExpressionCodes.includes(code)) {
          updatedNamedExpressions.push({ code, expression: '0' });
        }
      });
    });
  }
  return {
    updatedDataSheet,
    updatedIds,
    removeNamedExpressionCodes,
    emptyCells,
    updatedNamedExpressions,
  };
}
