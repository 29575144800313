import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const HelpIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <svg
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9999 20.1666C16.0416 20.1666 20.1666 16.0416 20.1666 11C20.1666 5.95831 16.0416 1.83331 10.9999 1.83331C5.95825 1.83331 1.83325 5.95831 1.83325 11C1.83325 16.0416 5.95825 20.1666 10.9999 20.1666Z"
          stroke="#262626"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 7.33331V11.9166"
          stroke="#262626"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9949 14.6667H11.0031"
          stroke="#262626"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
