import { removedExtensionsFilename } from './removedExtensionsFilename';
import { S3ObjectType } from '../enums/FileType';

//file type can be either JSON_FILE or TEMPLATE_FILE

export function existingFileNameHandler(fileName: string, fileType: S3ObjectType) {
  let modifiedFileName = fileName;

  const extension = fileType === S3ObjectType.JSON_FILE ? 'json' : 'tmpl';

  let fileNameWithoutExtension = '';

  fileNameWithoutExtension = removedExtensionsFilename(fileName);

  //check if there is a counter at the end in the filenameWithoutExtension in the form of (1) or (2) etc
  const counterRegex = /\((\d+)\)/;
  const counterMatch = RegExp(counterRegex).exec(fileNameWithoutExtension);

  //if there is a countRegExp(counterRegex).exec(fileNameWithoutExtension)
  if (counterMatch) {
    const counter = parseInt(counterMatch[1], 10);
    const newCounter = counter + 1;
    modifiedFileName = `${fileNameWithoutExtension.replace(
      counterRegex,
      `(${newCounter})`,
    )}.${extension}`;
  } else {
    //if there is no counter, add (1) to the filename
    modifiedFileName = `${fileNameWithoutExtension}(1).${extension}`;
  }

  return modifiedFileName;
}
