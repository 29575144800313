import { useWorker, WORKER_STATUS } from '@koale/useworker';
import { FringeCellOrder } from '@/utils';
import * as AggregationCalculator from '@/components/sheets/Aggregations';
import { IAggregationMapValue } from '@/interfaces/IAggregationMap';

export function useAggregations() {
  const [mainCalculateProcessor, { status: mainCalculateProcessStatus }] = useWorker(
    AggregationCalculator.calculateAggregations,
  );

  const [fallbackCalculateProcessor] = useWorker(AggregationCalculator.calculateAggregations);

  const [mainUpdateProcessor, { status: mainUpdateProcessStatus }] = useWorker(
    AggregationCalculator.updateAggregations,
  );

  const [fallbackUpdateProcessor] = useWorker(AggregationCalculator.updateAggregations);

  const [mainRenameProcessor, { status: mainRenameProcessStatus }] = useWorker(
    AggregationCalculator.renameCode,
  );

  const [fallbackRenameProcessor] = useWorker(AggregationCalculator.renameCode);

  const [mainRemoveProcessor, { status: mainRemoveProcessStatus }] = useWorker(
    AggregationCalculator.removeAggregations,
  );

  const [fallbackRemoveProcessor] = useWorker(AggregationCalculator.removeAggregations);

  function getCalculateProcessor() {
    let processor = mainCalculateProcessor;
    if (mainCalculateProcessStatus === WORKER_STATUS.RUNNING) {
      processor = fallbackCalculateProcessor;
    }

    return processor;
  }

  function getUpdateProcessor() {
    let processor = mainUpdateProcessor;
    if (mainUpdateProcessStatus === WORKER_STATUS.RUNNING) {
      processor = fallbackUpdateProcessor;
    }

    return processor;
  }

  function getRenameProcessor() {
    let processor = mainRenameProcessor;
    if (mainRenameProcessStatus === WORKER_STATUS.RUNNING) {
      processor = fallbackRenameProcessor;
    }

    return processor;
  }

  function getRemoveProcessor() {
    let processor = mainRemoveProcessor;
    if (mainRemoveProcessStatus === WORKER_STATUS.RUNNING) {
      processor = fallbackRemoveProcessor;
    }

    return processor;
  }

  async function calculate(
    sheets: Record<string, Array<Array<unknown>>>,
    fringeOrder: FringeCellOrder,
  ): Promise<Map<string, Map<string, IAggregationMapValue>>> {
    const processor = getCalculateProcessor();
    return await processor(sheets, fringeOrder);
  }

  async function updateBudgets(
    sheetIds: string[],
    sheet: Record<string, Array<Array<unknown>>>,
    fringeCellOrder: FringeCellOrder,
    aggregations: Map<string, Map<string, IAggregationMapValue>>,
  ): Promise<Map<string, Map<string, IAggregationMapValue>>> {
    const processor = getUpdateProcessor();
    return await processor(sheetIds, sheet, fringeCellOrder, aggregations);
  }

  async function renameCode(
    oldCode: string,
    newCode: string,
    aggregations: Map<string, Map<string, IAggregationMapValue>>,
    aggregationName: string,
  ): Promise<Map<string, Map<string, IAggregationMapValue>>> {
    const processor = getRenameProcessor();
    return await processor(oldCode, newCode, aggregations, aggregationName);
  }

  async function removeAggregations(
    sheetIds: string[],
    aggregations: Map<string, Map<string, IAggregationMapValue>>,
  ): Promise<Map<string, Map<string, IAggregationMapValue>>> {
    const processor = getRemoveProcessor();
    return await processor(sheetIds, aggregations);
  }

  return {
    calculate,
    updateBudgets,
    renameCode,
    removeAggregations,
  };
}
