export function convertToReadableDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export const currencyFormatter = {
  pattern: {
    thousandSeparated: true,
    mantissa: 0,
  },
  culture: 'en-US',
};

export const fringePercentageFormatter = {
  pattern: {
    thousandSeparated: true,
    output: 'percent',
    mantissa: 2,
  },
  culture: 'en-US',
};

export function decimalFormatter(
  NumberOfDecimals: number | string = 2,
  thousandSeparated = true,
  culture = 'en-US',
) {
  if (NumberOfDecimals === 'none') {
    return undefined;
  } else {
    return {
      pattern: {
        thousandSeparated,
        mantissa: Number(NumberOfDecimals),
      },
      culture,
    };
  }
}

export function stringTruncate(stringValue: string, maxLength: number): string {
  let output = stringValue;

  if (stringValue.length > maxLength) {
    output = `${stringValue.slice(0, maxLength)}...`;
  }

  return output;
}
