/* eslint-disable @typescript-eslint/no-explicit-any */
import { axios } from '@/utils';
import { IUserSubscription } from '@/interfaces/IUserSubscription';
import { SignUpForm } from '@/pages/SignUpPage/useSignUpForm';

export async function signUp(body: SignUpForm): Promise<any> {
  try {
    const { data } = await axios.post('/signUp', {
      ...body,
    });

    return data;
  } catch (error) {
    console.error('Sign Up error: ', error);
    return Promise.reject(error);
  }
}

export async function updateUser(path: string, body: Partial<IUserSubscription>) {
  try {
    const { data } = await axios.post(`/v1/user/${path}`, body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

interface UserDataResponse {
  status: string;
  phoneNumber: string;
  locale: string;
  firstName: string;
  lastName: string;
  hasSubscribed: boolean;
}

export async function getUser(path: string) {
  try {
    const { data } = await axios.get(`/v1/user/${path}`);
    return data as UserDataResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

interface UserSubscription {
  productLevel: string;
  expiryDate: string;
  trialExpiryDate?: string;
}

export async function getUserSubscription(path: string): Promise<UserSubscription> {
  try {
    const { data } = await axios.get<UserSubscription>(`/v1/users/${path}/subscription`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
