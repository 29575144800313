import newAxiosInstance from 'axios';
import { axios } from '@/utils';
import {
  IReportDeleteResponse,
  IReportFilterList,
  IReportSaveResponse,
  ISaveReportFilters,
} from '@/components/dialogBoxes/ReportDialogBox/types';

export async function saveReportData(
  postData: ISaveReportFilters,
  rootPath: string | null,
  fileName: string | null,
): Promise<IReportSaveResponse> {
  try {
    const { data } = await axios.post(`/v1/reports/${rootPath}/${fileName}`, postData);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSavedReports(
  rootPath: string | null,
  fileName: string | null,
): Promise<[IReportFilterList]> {
  try {
    const { data } = await axios.get(`/v1/reports/${rootPath}/${fileName}`);
    return Promise.resolve(data.items);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchMrtTemplate(
  username: string,
  layoutName: string,
  contentType: string,
): Promise<{ preSignedUrl: string }> {
  try {
    const { data } = await axios.get(`/v1/reports/mrt/templates/pre-signed/${username}`, {
      params: {
        layoutName,
        contentType,
      },
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteSavedReports(
  username: string | null,
  fileName: string | null,
  reportName: string | null,
): Promise<IReportDeleteResponse> {
  try {
    const { data } = await axios.delete(`/v1/reports/${username}/${fileName}/${reportName}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @MRT-REPORT-LAYOUT
 * End-point
 */

type IGetMRTReportLayoutList = Array<string>;

export const getMRTReportLayoutList = async (
  username: string,
): Promise<IGetMRTReportLayoutList> => {
  try {
    const { data: layoutList } = await axios.get(`/v1/reports/mrt/templates/${username}`);
    return (layoutList ? layoutList : []) as Array<string>;
  } catch (error) {
    return Promise.reject(error);
  }
};

export interface IUploadMRTReportLayoutDetail {
  uploadFileName: string;
  contentType: string;
}

const getMRTReportLayoutPreSignedUrl = async (
  username: string,
  uploadFileDetail: IUploadMRTReportLayoutDetail,
): Promise<string> => {
  try {
    const requestBody = {
      fileDetails: uploadFileDetail,
    };
    const { data: uploadLayoutDetailList } = await axios.post(
      `/v1/reports/mrt/templates/pre-signed/${username}`,
      requestBody,
    );
    return uploadLayoutDetailList?.[0]?.preSignedUrl as string;
  } catch (error) {
    return Promise.reject(error);
  }
};

export interface IUploadMRTReportLayout extends IUploadMRTReportLayoutDetail {
  username: string;
  file: File;
}

export const uploadMRTReportLayout = async ({
  username,
  file,
  uploadFileName,
  contentType,
}: IUploadMRTReportLayout): Promise<void> => {
  try {
    const preSignedUrl = await getMRTReportLayoutPreSignedUrl(username, {
      uploadFileName,
      contentType,
    });
    // Upload new file
    await newAxiosInstance.put(preSignedUrl, file, {
      headers: {
        'Content-Type': contentType,
      },
    });
  } catch (error) {
    return Promise.reject(error as Error);
  }
};

export interface IDeleteMRTReportLayout extends IUploadMRTReportLayoutDetail {
  username: string;
}

export const deleteMRTReportLayout = async ({
  username,
  uploadFileName,
  contentType,
}: IDeleteMRTReportLayout): Promise<void> => {
  try {
    await axios.delete(`/v1/reports/mrt/templates/${username}`, {
      params: {
        deleteFileNames: uploadFileName,
        contentType,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
