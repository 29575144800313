import { SvgIcon } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
  size?: number;
};

export const DeleteIcon = ({ size }: Props): ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      sx={{
        width: size || 18,
        height: size || 18,
        fill: 'none',
      }}
    >
      <path
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 4.485a76.276 76.276 0 00-7.515-.375c-1.485 0-2.97.075-4.455.225l-1.53.15M6.375 3.728l.165-.983c.12-.712.21-1.245 1.478-1.245h1.964c1.268 0 1.366.563 1.478 1.252l.165.976M13.909 4.91l-.466 8.968c-.079 1.399-.143 2.486-2.143 2.486H6.7c-2 0-2.065-1.087-2.143-2.486L4.09 4.91M7.747 12.375h2.498M7.125 9.375h3.75"
      />
    </SvgIcon>
  );
};
