import { FringeCellOrder } from '@/utils';
import { IAggregationMap, IAggregationMapValue } from '@/interfaces/IAggregationMap';

function toAggregationMap(
  aggregations: Map<string, Map<string, IAggregationMapValue>>,
  prefix: string,
): Map<string, IAggregationMapValue> {
  const toCode = (key: string) => key.replace(prefix, '');

  const aggregationMap: Map<string, IAggregationMapValue> = new Map<string, IAggregationMapValue>();
  aggregations?.forEach((aggregation) => {
    aggregation.forEach((value: unknown, key: string) => {
      if (key.startsWith(prefix)) {
        const code = toCode(key);
        const { total = [], fringeTotal = [], count } = value as IAggregationMapValue;
        if (!aggregationMap.has(code)) {
          aggregationMap.set(code, {
            total: total ? [...total] : [],
            fringeTotal: fringeTotal ? [...fringeTotal] : [],
            count: count,
          } as unknown as IAggregationMapValue);
        } else {
          const {
            total: currentTotal = [],
            fringeTotal: currentFringeTotal = [],
            count: previousCount,
          } = aggregationMap.get(code) as IAggregationMapValue;
          const nw = {
            count: previousCount + count,
            total: [...currentTotal, ...total],
            fringeTotal: [...currentFringeTotal, ...fringeTotal],
          } as IAggregationMapValue;
          aggregationMap.set(code, nw);
        }
      }
    });
  });

  return aggregationMap;
}

export function calculateAggregations(
  sheets: Record<string, Array<Array<unknown>>>,
  fringeOrder: FringeCellOrder,
): Map<string, Map<string, IAggregationMapValue>> {
  const DATA_COLUMNS_LENGTH = 27;

  enum L3ColumnIndexes {
    id = 0,
    fringes = 4,
    groups = 5,
    loc = 6,
    set = 7,
    units = 9,
    desc = 10,
    x = 11,
    rate = 12,
    cu = 13,
    total = 14,
    rowType = 18,
    fringeTotal = 19,
    index = 21,
    unitFormula = 22,
    xFormula = 23,
    rateFormula = 24,
    concatenateUnitXRate = 25,
  }

  enum GlobalColumnIndexes {
    code = 2,
    calculation = 5,
  }

  enum FringesColumnIndexes {
    code = 2,
    unitDesc = 5,
  }

  const getFringeIndexByCode = (code: string): number => {
    const index = fringeOrder.findIndex((fringe) => fringe === code);
    return DATA_COLUMNS_LENGTH + index + 1;
  };

  const aggregations = new Map<string, Map<string, IAggregationMapValue>>();

  for (const sheetId in sheets) {
    const aggregation: Map<string, IAggregationMapValue> = new Map<string, IAggregationMapValue>();
    if (sheetId.startsWith('l3_')) {
      sheets[sheetId].forEach((sheet) => {
        const total = isNaN(sheet[L3ColumnIndexes.total] as number)
          ? 0
          : (sheet[L3ColumnIndexes.total] as number);
        const fringeTotal = isNaN(sheet[L3ColumnIndexes.fringeTotal] as number)
          ? 0
          : (sheet[L3ColumnIndexes.fringeTotal] as number);
        if (sheet[L3ColumnIndexes.rowType] === 'D') {
          const fringes = (sheet[L3ColumnIndexes.fringes] as string).split(',');
          if (fringes?.length > 0) {
            fringes.forEach((fringe) => {
              const fringeCode = `$fringe_${fringe}`;
              if (fringe) {
                const fringeIndex = getFringeIndexByCode(fringe);
                const fringeCodeTotal = isNaN(sheet[fringeIndex] as number)
                  ? 0
                  : (sheet[fringeIndex] as number);
                if (!aggregation.has(fringeCode)) {
                  aggregation.set(fringeCode, {
                    total: [fringeCodeTotal],
                    count: 1,
                  });
                } else {
                  const { total: currentTotal, count } = aggregation.get(
                    fringeCode,
                  ) as IAggregationMapValue;
                  currentTotal?.push(fringeCodeTotal);
                  aggregation.set(fringeCode, {
                    total: currentTotal,
                    count: count + 1,
                  });
                }
              }
            });
          }

          const groups = (sheet[L3ColumnIndexes.groups] as string).split(',');
          if (groups?.length > 0) {
            groups.forEach((group) => {
              const groupCode = `$group_${group}`;
              if (group) {
                if (!aggregation.has(groupCode)) {
                  aggregation.set(groupCode, {
                    total: [total],
                    fringeTotal: [fringeTotal],
                    count: 1,
                  });
                } else {
                  const {
                    total: currentTotal,
                    fringeTotal: currentFringeTotal,
                    count,
                  } = aggregation.get(groupCode) as IAggregationMapValue;
                  currentTotal?.push(total);
                  currentFringeTotal?.push(fringeTotal);
                  aggregation.set(groupCode, {
                    total: currentTotal,
                    fringeTotal: currentFringeTotal,
                    count: count + 1,
                  });
                }
              }
            });
          }

          const loc = `$loc_${sheet[L3ColumnIndexes.loc]}`;
          if (sheet[L3ColumnIndexes.loc]) {
            if (!aggregation.has(loc)) {
              aggregation.set(loc, { total: [total], fringeTotal: [fringeTotal], count: 1 });
            } else {
              const {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count,
              } = aggregation.get(loc) as IAggregationMapValue;
              currentTotal?.push(total);
              currentFringeTotal?.push(fringeTotal);
              aggregation.set(loc, {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count: count + 1,
              });
            }
          }

          const set = `$set_${sheet[L3ColumnIndexes.set]}`;
          if (sheet[L3ColumnIndexes.set]) {
            if (!aggregation.has(set)) {
              aggregation.set(set, { total: [total], fringeTotal: [fringeTotal], count: 1 });
            } else {
              const {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count,
              } = aggregation.get(set) as IAggregationMapValue;
              currentTotal?.push(total);
              currentFringeTotal?.push(fringeTotal);
              aggregation.set(set, {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count: count + 1,
              });
            }
          }
          const units = `$units_${sheet[L3ColumnIndexes.desc]}`;
          if (sheet[L3ColumnIndexes.desc]) {
            if (!aggregation.has(units)) {
              aggregation.set(units, { count: 1 });
            } else {
              const { count } = aggregation.get(units) as IAggregationMapValue;
              aggregation.set(units, {
                count: count + 1,
              });
            }
          }

          const cu = `$cu_${sheet[L3ColumnIndexes.cu]}`;
          if (sheet[L3ColumnIndexes.cu]) {
            if (!aggregation.has(cu)) {
              aggregation.set(cu, { total: [total], fringeTotal: [fringeTotal], count: 1 });
            } else {
              const {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count,
              } = aggregation.get(cu) as IAggregationMapValue;
              currentTotal?.push(total);
              currentFringeTotal?.push(fringeTotal);
              aggregation.set(cu, {
                total: currentTotal,
                fringeTotal: currentFringeTotal,
                count: count + 1,
              });
            }
          }

          if (sheet[L3ColumnIndexes.concatenateUnitXRate]) {
            const concatenateUnitXRates = `${sheet[L3ColumnIndexes.concatenateUnitXRate]}`
              .replace(/\s|\(|\)/g, '')
              .split(',')
              .flatMap((substring) =>
                substring
                  .split(/([-+*/=\s])/)
                  .filter(
                    (part) => part !== '' && !/[-+*/=\s]/.test(part) && isNaN(parseInt(part)),
                  ),
              );

            if (concatenateUnitXRates?.length > 0) {
              const uniqueConcatCode = [...new Set(concatenateUnitXRates)];
              uniqueConcatCode.forEach((code) => {
                if (code !== '') {
                  const count = concatenateUnitXRates.filter((formula) => formula === code).length;
                  const variableCode = `$variables_${code}`;
                  if (code) {
                    if (!aggregation.has(variableCode)) {
                      aggregation.set(variableCode, {
                        total: [total],
                        count: count,
                      });
                    } else {
                      const { total: currentTotal, count: previousCount } = aggregation.get(
                        variableCode,
                      ) as IAggregationMapValue;
                      currentTotal?.push(total);
                      aggregation.set(variableCode, {
                        total: currentTotal,
                        count: count + previousCount,
                      });
                    }
                  }
                }
              });
            }
          }
        }
      });
    }
    if (sheetId === 'globals') {
      const globals = sheets[sheetId];
      globals.forEach((global) => {
        const unitFormulas = `${global[GlobalColumnIndexes.calculation]}`
          .replace(/\s|\(|\)/g, '')
          .split(',')
          .flatMap((substring) =>
            substring
              .split(/([-+*/=\s])/)
              .filter((part) => part !== '' && !/[-+*/=\s]/.test(part) && isNaN(parseInt(part))),
          );
        const code = global[GlobalColumnIndexes.code];
        if (code !== '') {
          unitFormulas.forEach((code) => {
            const count = unitFormulas.filter((formula) => formula === code).length;
            const variableCode = `$variables_${code}`;
            if (!aggregation.has(variableCode)) {
              aggregation.set(variableCode, { total: [0], count: count });
            } else {
              const { count: previousCount } = aggregation.get(
                variableCode,
              ) as IAggregationMapValue;
              aggregation.set(variableCode, {
                total: [0],
                count: previousCount + count,
              });
            }
          });
        }
      });
    }
    if (sheetId === 'fringes') {
      const fringes = sheets[sheetId];
      fringes.forEach((fringe) => {
        const code = fringe[FringesColumnIndexes.code];
        const unitDesc = fringe[FringesColumnIndexes.unitDesc];
        if (code !== '' && unitDesc !== '' && unitDesc !== '%') {
          const unitDescCode = `$units_${unitDesc}`;
          if (!aggregation.has(unitDescCode)) {
            aggregation.set(unitDescCode, { total: [0], count: 1 });
          } else {
            const { count: previousCount } = aggregation.get(unitDescCode) as IAggregationMapValue;
            aggregation.set(unitDescCode, {
              total: [0],
              count: previousCount + 1,
            });
          }
        }
      });
    }
    if (aggregation.size > 0) {
      aggregations.set(sheetId, aggregation);
    }
  }

  return aggregations;
}

export function updateAggregations(
  sheetIds: string[],
  sheetValues: Record<string, Array<Array<unknown>>>,
  fringeCellOrder: FringeCellOrder,
  aggregations: Map<string, Map<string, IAggregationMapValue>>,
): Map<string, Map<string, IAggregationMapValue>> {
  const DATA_COLUMNS_LENGTH = 27;

  enum L3ColumnIndexes {
    id = 0,
    fringes = 4,
    groups = 5,
    loc = 6,
    set = 7,
    units = 9,
    desc = 10,
    x = 11,
    rate = 12,
    cu = 13,
    total = 14,
    rowType = 18,
    fringeTotal = 19,
    index = 21,
    unitFormula = 22,
    xFormula = 23,
    rateFormula = 24,
    concatenateUnitXRate = 25,
    a,
  }

  const getFringeIndexByCode = (code: string): number => {
    const index = fringeCellOrder.findIndex((fringe) => fringe === code);
    return DATA_COLUMNS_LENGTH + index + 1;
  };

  sheetIds.forEach((sheetId) => {
    const aggregation = new Map<string, IAggregationMapValue>();
    const sheet = sheetValues[sheetId];
    sheet.forEach((row) => {
      const total = isNaN(row[L3ColumnIndexes.total] as number)
        ? 0
        : (row[L3ColumnIndexes.total] as number);
      const fringeTotal = isNaN(row[L3ColumnIndexes.fringeTotal] as number)
        ? 0
        : (row[L3ColumnIndexes.fringeTotal] as number);
      if (row[L3ColumnIndexes.rowType] === 'D') {
        const fringes = (row[L3ColumnIndexes.fringes] as string).split(',');
        if (fringes?.length > 0) {
          fringes.forEach((fringe) => {
            const fringeCode = `$fringe_${fringe}`;
            if (fringe) {
              const fringeIndex = getFringeIndexByCode(fringe);
              const fringeCodeTotal = isNaN(row[fringeIndex] as number)
                ? 0
                : (row[fringeIndex] as number);
              if (!aggregation.has(fringeCode)) {
                aggregation.set(fringeCode, {
                  total: [fringeCodeTotal],
                  count: 1,
                });
              } else {
                const { total: currentTotal, count } = aggregation.get(
                  fringeCode,
                ) as IAggregationMapValue;
                currentTotal?.push(fringeCodeTotal);
                aggregation.set(fringeCode, {
                  total: currentTotal,
                  count: count + 1,
                });
              }
            }
          });
        }

        const groups = (row[L3ColumnIndexes.groups] as string).split(',');
        if (groups?.length > 0) {
          groups.forEach((group) => {
            const groupCode = `$group_${group}`;
            if (group) {
              if (!aggregation.has(groupCode)) {
                aggregation.set(groupCode, {
                  total: [total],
                  fringeTotal: [fringeTotal],
                  count: 1,
                });
              } else {
                const {
                  total: currentTotal = [],
                  fringeTotal: currentFringeTotal = [],
                  count,
                } = aggregation.get(groupCode) as IAggregationMapValue;

                aggregation.set(groupCode, {
                  total: [...currentTotal, total],
                  fringeTotal: [...currentFringeTotal, fringeTotal],
                  count: count + 1,
                });
              }
            }
          });
        }

        const loc = `$loc_${row[L3ColumnIndexes.loc]}`;
        if (row[L3ColumnIndexes.loc]) {
          if (!aggregation.has(loc)) {
            aggregation.set(loc, { total: [total], fringeTotal: [fringeTotal], count: 1 });
          } else {
            const {
              total: currentTotal = [],
              fringeTotal: currentFringeTotal = [],
              count,
            } = aggregation.get(loc) as IAggregationMapValue;

            aggregation.set(loc, {
              total: [...currentTotal, total],
              fringeTotal: [...currentFringeTotal, fringeTotal],
              count: count + 1,
            });
          }
        }

        const set = `$set_${row[L3ColumnIndexes.set]}`;
        if (row[L3ColumnIndexes.set]) {
          if (!aggregation.has(set)) {
            aggregation.set(set, { total: [total], fringeTotal: [fringeTotal], count: 1 });
          } else {
            const {
              total: currentTotal = [],
              fringeTotal: currentFringeTotal = [],
              count,
            } = aggregation.get(set) as IAggregationMapValue;

            aggregation.set(set, {
              total: [...currentTotal, total],
              fringeTotal: [...currentFringeTotal, fringeTotal],
              count: count + 1,
            });
          }
        }

        const units = `$units_${row[L3ColumnIndexes.desc]}`;
        if (row[L3ColumnIndexes.desc]) {
          if (!aggregation.has(units)) {
            aggregation.set(units, { count: 1 });
          } else {
            const { count } = aggregation.get(units) as IAggregationMapValue;
            aggregation.set(units, {
              count: count + 1,
            });
          }
        }

        const cu = `$cu_${row[L3ColumnIndexes.cu]}`;
        if (row[L3ColumnIndexes.cu]) {
          if (!aggregation.has(cu)) {
            aggregation.set(cu, { total: [total], fringeTotal: [fringeTotal], count: 1 });
          } else {
            const {
              total: currentTotal = [],
              fringeTotal: currentFringeTotal = [],
              count,
            } = aggregation.get(cu) as IAggregationMapValue;

            aggregation.set(cu, {
              total: [...currentTotal, total],
              fringeTotal: [...currentFringeTotal, fringeTotal],
              count: count + 1,
            });
          }
        }

        if (row[L3ColumnIndexes.concatenateUnitXRate]) {
          const concatenateUnitXRates = `${row[L3ColumnIndexes.concatenateUnitXRate]}`
            .replace(/\s|\(|\)/g, '')
            .split(',')
            .flatMap((substring) =>
              substring
                .split(/([-+*/=\s])/)
                .filter((part) => part !== '' && !/[-+*/=\s]/.test(part) && isNaN(parseInt(part))),
            );
          if (concatenateUnitXRates?.length > 0) {
            const uniqueConcatCode = [...new Set(concatenateUnitXRates)];
            uniqueConcatCode.forEach((code) => {
              const count = concatenateUnitXRates.filter((formula) => formula === code).length;
              const variableCode = `$variables_${code}`;
              if (code) {
                if (!aggregation.has(variableCode)) {
                  aggregation.set(variableCode, { total: [total], count: count });
                } else {
                  const { total: currentTotal = [], count: previousCount = 0 } = aggregation.get(
                    variableCode,
                  ) as IAggregationMapValue;

                  aggregation.set(variableCode, {
                    total: [...currentTotal, total],
                    count: count + previousCount,
                  });
                }
              }
            });
          }
        }
      }
    });

    if (aggregations.size > 0) {
      aggregations.set(sheetId, aggregation);
    } else {
      aggregations.delete(sheetId);
    }
  });

  return aggregations;
}

export function renameCode(
  oldCode: string,
  newCode: string,
  aggregations: Map<string, Map<string, IAggregationMapValue>>,
  aggregationName: string,
): Map<string, Map<string, IAggregationMapValue>> {
  let prefix = '';
  switch (aggregationName) {
    case 'fringe':
      prefix = '$fringe_';
      break;
    case 'location':
      prefix = '$loc_';
      break;
    case 'set':
      prefix = '$set_';
      break;
    case 'currency':
      prefix = '$cu_';
      break;
    case 'unitDescription':
      prefix = '$units_';
      break;
    case 'group':
      prefix = '$group_';
      break;
    case 'variables':
      prefix = '$variables_';
      break;
  }
  aggregations.forEach((aggregation) => {
    aggregation.forEach((ag, key) => {
      if (key === `${prefix}${oldCode}`) {
        aggregation.delete(key);
        aggregation.set(`${prefix}${newCode}`, ag);
      }
    });
  });

  return aggregations;
}

export function removeAggregations(
  sheetIds: string[],
  aggregations: Map<string, Map<string, IAggregationMapValue>>,
): Map<string, Map<string, IAggregationMapValue>> {
  sheetIds.forEach((sheetId: string) => {
    aggregations.delete(sheetId);
  });
  return aggregations;
}

export function getAggregationMap(
  aggregations: Map<string, Map<string, IAggregationMapValue>>,
): IAggregationMap {
  const sets = toAggregationMap(aggregations, '$set_');
  const locations = toAggregationMap(aggregations, '$loc_');
  const unitDescriptions = toAggregationMap(aggregations, '$units_');
  const currencies = toAggregationMap(aggregations, '$cu_');
  const fringes = toAggregationMap(aggregations, '$fringe_');
  const groups = toAggregationMap(aggregations, '$group_');
  const variables = toAggregationMap(aggregations, '$variables_');
  return {
    sets,
    locations,
    unitDescriptions,
    currencies,
    fringes,
    groups,
    variables,
  };
}
