import { L1ColumnAlias, L2ColumnAlias, L3ColumnAlias } from '@/enums';
import {
  IDataSheetConfigs,
  IL1DataSheet,
  IL2Data,
  IL2DataSheet,
  IL3Data,
  IL3DataSheet,
} from '@/interfaces/IDataSheet';
import { IL2, IL2Sheet } from '@/interfaces/IFormulaSheet';
import { IMeta } from '@/interfaces/masterDataTypes';

interface ITransformL2Sheet {
  l2DataSheet: Array<IL2Data>;
  l3DataSheet: IL3DataSheet;
  level1RowNumber: number;
  metaData: IMeta;
  configData: IDataSheetConfigs;
}

const getSubTotalRowIndexes = (level2Data: IL2Data[]) => {
  const subTotalRowsIndex: number[] = [];
  for (let i = 0; i < level2Data.length; i++) {
    if (level2Data[i].rowType === 'S') {
      subTotalRowsIndex.push(i);
    }
  }
  return subTotalRowsIndex;
};

const getPrevSubTotalIndex = (index: number, subTotalRowsIndex: number[]) => {
  const prevIndex = subTotalRowsIndex.findIndex((i) => i === index - 1);
  if (prevIndex === -1) {
    return -1;
  } else {
    return subTotalRowsIndex[prevIndex - 1] + 2;
  }
};

export const transformL2Sheet = ({
  l2DataSheet,
  l3DataSheet,
  level1RowNumber,
  metaData,
  configData,
}: ITransformL2Sheet): IL2Sheet => {
  const fringeCalc = metaData.fringes.calc;
  const subTotalRowsIndex = getSubTotalRowIndexes(l2DataSheet);

  const totalDescription = `=UPPER(IF(l1!${L1ColumnAlias.Description}$${level1RowNumber}="","TOTAL",CONCATENATE("TOTAL FOR ",l1!${L1ColumnAlias.Description}$${level1RowNumber})))`;

  const formulaL2Sheet: IL2Sheet = l2DataSheet.map((l2Data: IL2Data, li: number): IL2 => {
    const index: number = li + 1;
    let l3DataLength = 0;
    const level3DataSheet: Array<IL3Data> = l3DataSheet[l2Data.id];
    const prevIndex = index - 1;
    const prevSubTotalIndex = getPrevSubTotalIndex(index, subTotalRowsIndex);
    const startIndex = prevIndex === subTotalRowsIndex[0] ? 1 : prevSubTotalIndex;

    // LEVEL 2
    const l2RowType = L2ColumnAlias.RowType;
    const l2TotalColumn = L2ColumnAlias.Total;
    const l2ComparisonColumn = L2ColumnAlias.Comparison;
    const l2VarianceColumn = L2ColumnAlias.Variance;
    const subFringeTotal = `=SUM(${L2ColumnAlias.Fringes}${startIndex}:${L2ColumnAlias.Fringes}${prevIndex})`;
    const subTotal = `=SUM(${l2TotalColumn}${startIndex}:${l2TotalColumn}${prevIndex})`;
    const comparisonTotal = `=SUM(${l2ComparisonColumn}${startIndex}:${l2ComparisonColumn}${prevIndex})`;
    const varianceTotal = `=SUM(${l2VarianceColumn}${startIndex}:${l2VarianceColumn}${prevIndex})`;

    if (l2Data.rowType === 'D' || l2Data.rowType === 'F') {
      l3DataLength = level3DataSheet?.length;
    }

    // MAXIMUM VARIANCE CALCULATION
    const l2MaxVarianceColumn = `=MAX(-MINIFS(${l2VarianceColumn}:${l2VarianceColumn},${l2RowType}:${l2RowType},"D"),MAXIFS(${l2VarianceColumn}:${l2VarianceColumn},${l2RowType}:${l2RowType},"D"))`;

    // UNDER OVER CALCULATION
    const underOver = `=IF(AND(${l2VarianceColumn}${index}>-1,${l2VarianceColumn}${index}<1), 0, INT((${l2VarianceColumn}${index}/${L2ColumnAlias.MaxVariance}${index}) * 100))`;

    // LEVEL 3
    const l3RowType = L3ColumnAlias.RowType;
    const l3TotalColumn = L3ColumnAlias.Total;
    const l3FringeTotalColumn = L3ColumnAlias.FringesTotal;
    const l3ComparisonColumn = L3ColumnAlias.Comparison;

    const isNegativeVariance = configData?.varianceCalc === 'under';
    const sign = isNegativeVariance ? 1 : -1;
    const variance = `=ABS(${l2TotalColumn}${index}-${l2ComparisonColumn}${index})*IF(${l2TotalColumn}${index}>${l2ComparisonColumn}${index}, ${sign}, ${-sign})`;

    const getTotalForL3Row = (fringeCalc: string, sheetId: string | number) => {
      if (fringeCalc === 'account') {
        return getCombinedNetValueWithFringes(l3TotalColumn, l3FringeTotalColumn, sheetId);
      } else {
        return getSheetNetValue(l3TotalColumn, sheetId, l3RowType);
      }
    };

    const getSheetNetValue = (column: string, sheetId: string | number, rowTypeColumn: string) => {
      return `=INDEX(l3_${sheetId}!${column}:${column}, MATCH("T", l3_${sheetId}!${rowTypeColumn}:${rowTypeColumn}, 0))`;
    };

    const getFringesComparisonValue = () => {
      return `=SUMIF(${L2ColumnAlias.RowType}1:${L2ColumnAlias.RowType}${
        l2DataSheet.length - 2
      }, "D",${L2ColumnAlias.FringeComparison}1:${L2ColumnAlias.FringeComparison}${
        l2DataSheet.length - 2
      })`;
    };

    const getCombinedNetValueWithFringes = (
      totalColumn: string,
      fringesColumn: string,
      sheetId: string | number,
    ) => {
      return `=SUMIF(l3_${sheetId}!${l3RowType}1:${l3RowType}${
        l3DataLength - 1
      },"D",l3_${sheetId}!${totalColumn}1:${totalColumn}${
        l3DataLength - 1
      })+SUMIF(l3_${sheetId}!${l3RowType}1:${l3RowType}${
        l3DataLength - 1
      }, "D", l3_${sheetId}!${fringesColumn}1:${fringesColumn}${l3DataLength - 1})`;
    };

    const getColumnTotalValue = (column: string) => {
      return `=SUMIF(${l2RowType}1:${l2RowType}${l2DataSheet.length - 1},"D",${column}1:${column}${
        l2DataSheet.length - 1
      })`;
    };

    const getTotalWithFringes = (column: string) => {
      return `=SUMIF(${l2RowType}1:${l2RowType}${l2DataSheet.length - 1},"D",${column}1:${column}${
        l2DataSheet.length - 1
      })+SUMIF(${l2RowType}1:${l2RowType}${l2DataSheet.length - 1},"D",${L2ColumnAlias.Fringes}1:${
        L2ColumnAlias.Fringes
      }${l2DataSheet.length - 1})`;
    };

    const getComparisonWithFringes = (column: string) => {
      return `=SUMIF(${l2RowType}1:${l2RowType}${l2DataSheet.length - 1},"D",${column}1:${column}${
        l2DataSheet.length - 1
      })+SUMIF(${l2RowType}1:${l2RowType}${l2DataSheet.length - 1},"F",${column}1:${column}${
        l2DataSheet.length - 1
      })`;
    };

    switch (l2Data.rowType) {
      case 'T':
        return [
          l2Data.id,
          '',
          '',
          totalDescription,
          '',
          fringeCalc === 'category'
            ? getTotalWithFringes(l2TotalColumn)
            : getColumnTotalValue(l2TotalColumn),
          fringeCalc === 'category'
            ? getComparisonWithFringes(l2ComparisonColumn)
            : getColumnTotalValue(l2ComparisonColumn),
          variance,
          l2MaxVarianceColumn,
          l2Data.rowType,
          `=ROW(${L2ColumnAlias.UnderOver}${index})`,
        ];
      case 'F':
        return [
          l2Data.id,
          0,
          `'${l2Data.account}`,
          l2Data.description as string,
          '',
          `=SUMIF(${L2ColumnAlias.RowType}1:${L2ColumnAlias.RowType}${
            l2DataSheet.length - 2
          }, "D",${L2ColumnAlias.Fringes}1:${L2ColumnAlias.Fringes}${l2DataSheet.length - 2})`,
          getFringesComparisonValue(),
          variance,
          0,
          l2Data.rowType,
          `=ROW(${L2ColumnAlias.UnderOver}${index})`,
        ];
      case 'S':
        return [
          l2Data.id,
          '',
          l2Data.account,
          l2Data.description,
          subFringeTotal,
          subTotal,
          comparisonTotal,
          varianceTotal,
          0,
          l2Data.rowType,
          `=ROW(${L2ColumnAlias.UnderOver}${index})`,
        ];

      default:
        return [
          l2Data.id,
          underOver,
          `'${l2Data.account}`,
          l2Data.description,
          `=SUM(l3_${l2Data.id}!$${L3ColumnAlias.FringesTotal}:${L3ColumnAlias.FringesTotal})`, // L3 Fringe total
          getTotalForL3Row(fringeCalc, l2Data.id),
          getSheetNetValue(l3ComparisonColumn, l2Data.id, l3RowType),
          // getSheetNetValue(l3VarianceColumn, l2Data.id, l3RowType),
          variance,
          l2MaxVarianceColumn,
          l2Data.rowType,
          `=ROW(${L2ColumnAlias.UnderOver}${index})`,
          `=l3_${l2Data.id}!${L3ColumnAlias.Comparison}${l3DataLength - 1}`,
        ];
    }
  });

  return formulaL2Sheet;
};

export const transformAllL2Sheets = (
  l1DataSheet: IL1DataSheet,
  l2DataSheet: IL2DataSheet,
  l3DataSheet: IL3DataSheet,
  meta: IMeta,
  configData: IDataSheetConfigs,
): IL2Sheet => {
  let L2formulaSheets: IL2Sheet = {} as IL2Sheet;
  Object.keys(l2DataSheet).forEach((key) => {
    const l2Data: Array<IL2Data> = l2DataSheet[key];
    const level1RowNumber = l1DataSheet.findIndex((level1) => level1.id === key) + 1;
    if (Array.isArray(l2Data)) {
      const l2SheetDataToTransform = {
        l2DataSheet: l2Data,
        l3DataSheet: l3DataSheet,
        level1RowNumber: level1RowNumber,
        metaData: meta,
        configData: configData,
      };
      const l2FormulaSheet: IL2Sheet = transformL2Sheet(l2SheetDataToTransform);
      L2formulaSheets = { ...L2formulaSheets, [key]: l2FormulaSheet };
    }
  });

  return L2formulaSheets;
};
