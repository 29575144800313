import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

export const SumIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.7 20H8.3C3.8 20 2 18.2 2 13.7V8.3C2 3.8 3.8 2 8.3 2H13.7C18.2 2 20 3.8 20 8.3V13.7C20 18.2 18.2 20 13.7 20Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_159_1377)">
        <path
          d="M6.53862 17C6.48177 16.9752 6.42193 16.9535 6.36508 16.9256C5.99404 16.7304 5.88632 16.2347 6.14066 15.897C6.18255 15.8412 6.23043 15.7916 6.2783 15.7421C7.69063 14.2208 9.10296 12.7026 10.5153 11.1813C10.5422 11.1503 10.5721 11.1193 10.6021 11.0821C10.5691 11.0449 10.5422 11.0139 10.5153 10.983C9.085 9.3873 7.65472 7.79473 6.22444 6.20217C5.98506 5.93571 5.93719 5.63517 6.0868 5.35631C6.2035 5.13943 6.38901 5.02479 6.6254 5.0031C6.65233 5 6.67926 5 6.7032 5C9.56376 5 12.4213 5 15.2819 5C15.626 5 15.8833 5.1859 15.9671 5.49884C15.9851 5.5701 15.997 5.64446 15.997 5.71882C16 6.27033 16 6.82184 15.997 7.37026C15.994 7.73277 15.7816 8.00852 15.4644 8.07359C15.0425 8.16034 14.6775 7.84121 14.6685 7.37335C14.6625 7.07591 14.6685 6.77847 14.6685 6.48412C14.6685 6.45314 14.6685 6.42215 14.6685 6.38187C12.5141 6.38187 10.3687 6.38187 8.2023 6.38187C8.23821 6.42525 8.26214 6.45624 8.28907 6.48722C9.50691 7.84431 10.7247 9.1983 11.9426 10.5554C12.2627 10.9117 12.2627 11.2804 11.9366 11.6305C10.7397 12.9194 9.54281 14.2084 8.34293 15.4973C8.31301 15.5314 8.2801 15.5655 8.23521 15.615C10.3866 15.615 12.5201 15.615 14.6715 15.615C14.6715 15.4322 14.6715 15.2463 14.6715 15.0604C14.6715 14.859 14.7223 14.6731 14.863 14.5275C15.0545 14.3323 15.2849 14.2703 15.5392 14.3571C15.7936 14.4438 15.9701 14.6297 15.9821 14.9086C16.003 15.3981 16.003 15.8908 15.9851 16.3834C15.9731 16.6871 15.7876 16.8792 15.5123 16.9721C15.4884 16.9814 15.4614 16.9907 15.4375 17C12.4692 17 9.50391 17 6.53862 17Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_1377">
          <rect width="10" height="12" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
