import { AlertColor } from '@mui/material';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import { RangeType } from 'handsontable/common';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { SYSTEM_CURRENCY } from '@/constants';
import { BudgetLevels, DATA_SHEET_FILE_TYPES } from '@/enums';
import { tableColumnHeaderDefault } from '@/data/atomDefault/tableColumnHeader';
import { AccountSearchResult } from '@/helpers/AccountSearchHelper';
import { GlobalSearchResultType } from '@/helpers/GlobalSearchHelper';
import IToasterAtom from '@/interfaces/IToasterAtom';
import { IPageTitleDetails } from '@/interfaces/sheetTitleTypes';
import { SUBSCRIPTION_DEFAULT_LEVEL } from '../constants/subscription';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage,
});

interface IBudgetSelectedCell {
  firstLevel: CellCoords;
  secondLevel: CellCoords;
}

interface ISideBarToggle {
  left: boolean;
  right: boolean;
}

interface IBudgetSheetSharedResponse {
  isOpenSnackBar: boolean;
  message: string;
  severity: AlertColor;
}

export interface DocumentSummaryDetails {
  total: number;
  comparison: number;
  variance: number;
  efc: number;
  prevEfc: number;
  periodVar: number;
  currencies: string[];
}

export interface ITableColumnIndex {
  headerList: string[];
  selectedIndex: number[];
  sheetName: string;
  isOpen: boolean;
}

interface ImportCSVDataDetails {
  isNotAppendValue: boolean | null;
  categoryValue: string;
  prefixValue: string;
}

export const fileUrlAtom = atom<string | null>({
  key: 'fileUrlAtom',
  default: null,
});

export const isNewlySavedFile = atom<boolean>({
  key: 'isNewlySavedFileAtom',
  default: false,
});

export const isDataSheetInitializingAtom = atom<boolean>({
  key: 'isDataSheetInitializingAtom',
  default: true,
});

export const openFileDialogAtom = atom<boolean>({
  key: 'openFileDialogAtom',
  default: false,
});

export const sideBarToggleAtom = atom<ISideBarToggle>({
  key: 'sideBarToggleAtom',
  default: {
    left: false,
    right: true,
  },
});

export const searchDivToggleAtom = atom<boolean>({
  key: 'searchDivToggleAtom',
  default: false,
});

export const selectedCurrencyAtom = atom<string>({
  key: 'selectedCurrencyAtom',
  default: SYSTEM_CURRENCY,
});

export const budgetSheetLevelAtom = atom<BudgetLevels>({
  key: 'budgetLevelAtom',
  default: BudgetLevels.FIRST_LEVEL,
});

export const budgetSheetHighlightCellAtom = atom<RangeType>({
  key: 'budgetSheetHighlightCellAtom',
  default: {
    startRow: -1,
    startCol: -1,
    endRow: -1,
    endCol: -1,
  },
});

export const documentSummaryDetailsAtom = atom<DocumentSummaryDetails>({
  key: 'documentSummaryDetailsAtom',
  default: {
    total: 0,
    comparison: 0,
    variance: 0,
    efc: 0,
    prevEfc: 0,
    periodVar: 0,
    currencies: [],
  },
});

export const budgetSelectedCellAtom = atom<IBudgetSelectedCell>({
  key: 'budgetSelectedCellAtom',
  default: {
    firstLevel: {
      row: -1,
      col: -1,
    } as CellCoords,
    secondLevel: {
      row: -1,
      col: -1,
    } as CellCoords,
  },
});

export const pageTitleDetailsAtom = atom<IPageTitleDetails>({
  key: 'pageTitleDetails',
  default: {
    fileName: '',
    selectedSheetName: 'Top Sheet',
    accountNumber: '',
    fullDescription: '',
  },
});

export const currentTabIndexAtom = atom<number>({
  key: 'currentTabIndexAtom',
  default: 0,
});

export const isAuthAtom = atom<boolean>({
  key: 'isAuthAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const rootFolder = atom<string | null>({
  key: 'rootFolder',
  default: null,
});

export const subscriptionLevelAtom = atom<string>({
  key: 'subscriptionLevel',
  default: SUBSCRIPTION_DEFAULT_LEVEL,
});

export const searchInputFocusAtom = atom<boolean>({
  key: 'searchInputFocus',
  default: false,
});

export const toasterAtom = atom<IToasterAtom>({
  key: 'toasterOpen',
  default: {
    show: false,
    message: '',
    type: 'info',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
  } as IToasterAtom,
});

export const budgetSheetSharedResponseAtom = atom<IBudgetSheetSharedResponse>({
  key: 'budgetSheetSharedResponseAtom',
  default: {
    isOpenSnackBar: false,
    message: '',
    severity: 'success',
  },
});

export const budgetSheetSavedResponseAtom = atom<IBudgetSheetSharedResponse>({
  key: 'budgetSheetSavedResponseAtom',
  default: {
    isOpenSnackBar: false,
    message: '',
    severity: 'success',
  },
});

export const jsonReportAtom = atom<any>({
  key: 'jsonReportAtom',
  default: {},
});

export const fileSaveAutoAtom = atom<boolean>({
  key: 'fileSaveMethodAtom',
  default: false,
});

export const tableColumnHeaderAtom = atom<ITableColumnIndex[]>({
  key: 'tableColumnHeaderAtom',
  default: tableColumnHeaderDefault,
});

export const importCSVDetailsAtom = atom<ImportCSVDataDetails>({
  key: 'importCSVDetailsAtom',
  default: {
    isNotAppendValue: null,
    categoryValue: '',
    prefixValue: '',
  },
});

export const openFileTypeAtom = atom<string>({
  key: 'openFileTypeAtom',
  default: DATA_SHEET_FILE_TYPES.JSON,
});

export const userRoleAtom = atom<string>({
  key: 'userRoleAtom',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const costTrackerOptionsAtom = atom<{
  mask: string;
  episodeCode: string;
  costTrackerMode: boolean;
  viewTransactionsLevel3: boolean;
  includeUnpostedData: boolean;
  detaAsSets: boolean;
}>({
  key: 'costTrackerOptionsAtom',
  default: {
    mask: '',
    episodeCode: '',
    costTrackerMode: false,
    viewTransactionsLevel3: false,
    includeUnpostedData: false,
    detaAsSets: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const searchAccountSelectedResultAtom = atom<AccountSearchResult | undefined>({
  key: 'searchAccountSelectedResultAtom',
  default: undefined,
});

export const isGlobalSearchTriggeredAtom = atom<boolean>({
  key: 'isGlobalSearchTriggeredAtom',
  default: false,
});

export type IsSearchingType = boolean;

export type IsSearchTriggeredType = boolean;

export type SearchedQueryType = string | undefined;

export type SelectedResultType = Record<string, Array<GlobalSearchResultType>> | undefined;

export type SelectedResultKeyType = string | undefined;

export type SearchResultsType = Record<string, Array<GlobalSearchResultType>> | undefined;

export interface GlobalSearchStateType {
  isSearching: IsSearchingType;
  isSearchTriggered: IsSearchTriggeredType;
  searchedQuery: SearchedQueryType;
  selectedResult: SelectedResultType;
  selectedResultKey: SelectedResultKeyType;
  searchResults: SearchResultsType;
}

export const globalSearchStateAtom = atom<GlobalSearchStateType>({
  key: 'globalSearchState',
  default: {
    searchedQuery: undefined,
    selectedResult: undefined,
    isSearchTriggered: false,
    isSearching: false,
    selectedResultKey: undefined,
    searchResults: undefined,
  },
});

export const openSaveAsAtom = atom<boolean>({
  key: 'openSaveAsAtom',
  default: false,
});

export const reportDialogBoxAtom = atom<boolean>({
  key: 'reportDialogBox',
  default: false,
});
