import { isEmpty } from 'lodash';
import { SYSTEM_CURRENCY } from '@/constants';
import { FringesColumnAlias, L3ColumnAlias, SheetNames } from '@/enums';
import { IFringeData } from '@/interfaces/IFringeData';

export default class FringeTotalFormula {
  private fringeSheet: Array<IFringeData>;
  private fringes: string;
  private index: number;
  private totalRef: string | number;
  private currency: string;
  private rangeStart: boolean;
  private previousFringeRef: string;
  private previousL3UnitsRef: string;
  private unitDesc: string;
  private units: string | number;

  constructor(fringesSheet: Array<IFringeData>) {
    this.fringes = '';
    this.fringeSheet = fringesSheet;
    this.totalRef = 0;
    this.index = 0;
    this.currency = '';
    this.rangeStart = true;
    this.previousFringeRef = '';
    this.previousL3UnitsRef = '';
    this.unitDesc = '%';
    this.units = 0;
  }

  withTotalRef(total: string | number): this {
    if (typeof total === 'number') {
      this.totalRef = total;
    } else if (typeof total === 'string') {
      if (total.startsWith('=')) {
        this.totalRef = total.substring(1, total.length);
      } else {
        this.totalRef = total;
      }
    }

    return this;
  }

  withIndex(index: number): this {
    this.index = index;
    return this;
  }

  withRangeStart(start: boolean): this {
    this.rangeStart = start;
    return this;
  }

  withFringesString(fringes: string): this {
    if (!isEmpty(fringes)) {
      this.fringes = fringes;
    }

    return this;
  }

  withCurrency(currency: string): this {
    this.currency = currency;
    return this;
  }

  withPreviousFringeRef(fringeRef: string): this {
    this.previousFringeRef = `SUM(${fringeRef})`;
    return this;
  }

  withPreviousL3UnitsRef(unitsRef: string): this {
    this.previousL3UnitsRef = `SUM(${unitsRef})`;
    return this;
  }

  withUnitDesc(unitDesc: string): this {
    this.unitDesc = unitDesc;
    return this;
  }

  withUnits(units: string | number): this {
    this.units = units;
    return this;
  }

  build(): string {
    let formula = '';
    const fringeData = this.fringeSheet.find((f) => f.code === this.fringes);

    const fringeDataIndex = this.fringeSheet.findIndex((f) => f.code === this.fringes);
    if (!isEmpty(fringeData)) {
      const fringeUnitDesc = `${SheetNames.FRINGES}!${FringesColumnAlias.UnitDescription}${
        fringeDataIndex + 1
      }`;
      const fringeCu = `${SheetNames.FRINGES}!${FringesColumnAlias.Cu}${fringeDataIndex + 1}`;
      const cap = `IF(ISNUMBER(${SheetNames.FRINGES}!${FringesColumnAlias.Cap}${
        fringeDataIndex + 1
      }),${SheetNames.FRINGES}!${FringesColumnAlias.Cap}${fringeDataIndex + 1},0)`;
      const fringeRate = `${SheetNames.FRINGES}!${FringesColumnAlias.Rate}${fringeDataIndex + 1}`;
      const fringeCuValue = `${SheetNames.FRINGES}!${FringesColumnAlias.FringeCuValue}${
        fringeDataIndex + 1
      }`;
      const exchangeRate = `IF(${L3ColumnAlias.Cu}${this.index}="",${SYSTEM_CURRENCY},${this.currency}/BASE_CURRENCY)`;
      const fringeExchangeRate = `IF(${fringeCu}="",1,${fringeCuValue}/BASE_CURRENCY)`;
      const l3UnitDescValue =
        this.unitDesc === '%' ? `${this.units}/100` : this.unitDesc === '' ? 0 : this.unitDesc;
      const fringeUnitDescValue = `${SheetNames.FRINGES}!${FringesColumnAlias.UnitDescValue}${
        fringeDataIndex + 1
      }`;

      const l3Units = this.units === '' ? 0 : this.units;

      if (this.rangeStart) {
        formula = `=IF(${fringeUnitDesc}="%",IF(${cap}<=0,${this.totalRef}*${this.fringes},
                IF(${this.totalRef}>${cap}*${fringeExchangeRate},${cap}*${this.fringes}*${fringeExchangeRate},${this.totalRef}*${this.fringes})),
                IF(${cap}<=0,IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}*${fringeRate},
                IF(IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}>${cap},${cap}*${fringeRate},
                IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}*${fringeRate}))*${exchangeRate})`;
      } else {
        formula = `=IF(${fringeUnitDesc}="%",IF(${cap}<=0,${this.totalRef}*${this.fringes},
                IF(${this.totalRef}+${this.previousFringeRef}/${this.fringes}>${cap}*${fringeExchangeRate},${cap}*${this.fringes}*${fringeExchangeRate}-${this.previousFringeRef},${this.totalRef}*${this.fringes})),
                IF(${fringeRate}=0,0,IF(${cap}<=0,IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}*${fringeRate},
                IF(IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}>${cap}-(${this.previousFringeRef}/(${fringeRate}*${exchangeRate})),
                IF(${cap}-(${this.previousFringeRef}/(${fringeRate}*${exchangeRate}))>0,(${cap}-(${this.previousFringeRef}/(${fringeRate}*${exchangeRate})))*${fringeRate},0),
                IF(${fringeUnitDescValue}<=0,1,IF(${l3UnitDescValue}<=0,1,${l3UnitDescValue}/${fringeUnitDescValue}))*${l3Units}*${fringeRate}))*${exchangeRate}))`;
      }
    }

    return formula.replace(/ /g, '').replace(/\n/g, '');
  }
}
