import lodash from 'lodash';
import { GlobalAlias, GlobalColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { IGlobal, IGlobalSheet } from '@/interfaces/IFormulaSheet';
import { IGlobalData } from '@/interfaces/IGlobalData';

export const transformGlobals = (globals: IGlobalData[]) => {
  if (lodash.isEmpty(globals)) {
    return [];
  }

  const globalSheet: IGlobalSheet = globals?.map((global: IGlobalData, index) => {
    return globalDataToFormulaTransformation(global, index);
  });

  const globalTotalRow: IGlobal = [
    generatedId(), // id
    '', // category
    '', // code
    'Total', // description
    '', //calculation
    '', // units
    0, // usage
    `=SUM(${GlobalAlias.Attachments}0:${GlobalAlias.Attachments}${globalSheet.length})`, // attachments
    '', // notes
    'T', // row type
    '',
    `=ROW(${GlobalAlias.Code}${globalSheet.length + 1})-1`,
  ];

  globalSheet.push(globalTotalRow);

  return globalSheet;
};

export const globalsToDataSheetTransformation = (global: IGlobal) => {
  return {
    id: global[GlobalColumnIndexes.id],
    category: global[GlobalColumnIndexes.category],
    code: global[GlobalColumnIndexes.code],
    description: global[GlobalColumnIndexes.description],
    calculation: global[GlobalColumnIndexes.calculation],
    units: global[GlobalColumnIndexes.units],
    usage: global[GlobalColumnIndexes.usage],
    attachments: global[GlobalColumnIndexes.attachments],
    notes: global[GlobalColumnIndexes.notes],
  } as IGlobalData;
};

export const globalDataToFormulaTransformation = (global: IGlobalData, index: number) => {
  return [
    global.id,
    global.category,
    global.code,
    global.description,
    global.units,
    `=IF(ISFORMULA(${GlobalAlias.Units}${index + 1}),FORMULATEXT(${GlobalAlias.Units}${
      index + 1
    }), ${GlobalAlias.Units}${index + 1})`,
    0,
    global.attachments,
    global.notes,
    'D',
    `=IF(ISFORMULA(${GlobalAlias.Units}${index + 1}),FORMULATEXT(${GlobalAlias.Units}${
      index + 1
    }), ${GlobalAlias.Units}${index + 1})`,
    `=ROW(${GlobalAlias.Code}${index + 1})-1`,
  ] as IGlobal;
};
