import lodash from 'lodash';
import { GlobalColumnIndexes } from '@/enums';
import { generatedId } from '@/utils';
import { globalDataToFormulaTransformation } from '@/helpers/Mappers/GlobalMapper';
import {
  DateSheetAttributeType,
  ICellCoords,
  IDataSheet,
  ISelectedCellData,
} from '@/interfaces/IDataSheet';
import { IGlobal, INamedExpressionData } from '@/interfaces/IFormulaSheet';
import { GlobalTypes, IGlobalData } from '@/interfaces/IGlobalData';

export function getEmptyGlobal(): IGlobalData {
  return {
    id: generatedId(),
    category: '',
    code: '',
    description: '',
    calculation: '',
    units: '0',
    usage: '0',
    attachments: '0',
    notes: '',
  };
}

export function addRow(
  dataSheet: IDataSheet,
  index: number,
): {
  updatedDataSheet: IDataSheet;
  newGlobalData: IGlobal;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const newGlobal: IGlobalData = getEmptyGlobal();
  updatedDataSheet.globals = updatedDataSheet.globals || [];
  updatedDataSheet.globals.splice(index, 0, newGlobal);
  const newGlobalData = globalDataToFormulaTransformation(newGlobal, index);
  const updatedIds = [newGlobal.id.toString()];
  return { updatedDataSheet, newGlobalData, updatedIds };
}

export function editRow(
  dataSheet: IDataSheet,
  record: IGlobalData,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  updatedDataSheet.globals = updatedDataSheet.globals.map((global) => {
    if (global.id === record.id) {
      updatedIds.push(global.id.toString());
      return { ...global, ...record };
    }
    return global;
  });
  return { updatedDataSheet, updatedIds };
}

export function removeRow(
  dataSheet: IDataSheet,
  removeIndexes: number[],
  isDeleteAllRecords: boolean,
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  if (isDeleteAllRecords) {
    removeIndexes.pop();
    updatedDataSheet.globals[0] = {
      ...getEmptyGlobal(),
      id: updatedDataSheet.globals[0].id,
    };
  }
  const removedGlobalSheet: IGlobalData[] = [];
  const updatedIds: string[] = [];
  updatedDataSheet.globals.forEach((globalData, index) => {
    if (!removeIndexes.includes(index)) {
      removedGlobalSheet.push(globalData);
    } else {
      updatedIds.push(globalData.id.toString());
    }
  });
  updatedDataSheet.globals = removedGlobalSheet;
  return { updatedDataSheet, updatedIds };
}

export function moveRow(dataSheet: IDataSheet, fromIndexes: number[], toIndex: number) {
  const dataSheetCopy: IDataSheet = lodash.cloneDeep(dataSheet);

  const movedElements: IGlobalData[] = [];
  fromIndexes.forEach((index) => {
    movedElements.push(dataSheetCopy.globals[index]);
  });
  if (movedElements.length === fromIndexes.length) {
    dataSheetCopy.globals.splice(fromIndexes[0], fromIndexes.length);
    dataSheetCopy.globals.splice(toIndex, 0, ...movedElements);
  }

  return dataSheetCopy;
}

export function handleUpdateCopiedGlobalRow(copiedRow: IGlobalData): IGlobalData {
  const updatedRow = getEmptyGlobal();

  // columns that need to be updated before pasting
  const reviseColumns = [GlobalTypes.CATEGORY, GlobalTypes.DESCRIPTION, GlobalTypes.UNITS];

  lodash.forEach(reviseColumns, (column) => {
    updatedRow[column] = copiedRow[column];
  });

  return updatedRow;
}

export function removeGlobalCellData(
  dataSheet: IDataSheet,
  selectedCellData: ISelectedCellData[],
): {
  updatedDataSheet: IDataSheet;
  updatedIds: string[];
  removeNamedExpressionCodes: string[];
  emptyCells: ICellCoords[];
  updatedNamedExpressions: INamedExpressionData[];
} {
  const updatedDataSheet: IDataSheet = lodash.cloneDeep(dataSheet);
  const updatedIds: string[] = [];
  const removeNamedExpressionCodes: string[] = [];
  const emptyCells: ICellCoords[] = [];
  const updatedNamedExpressions: INamedExpressionData[] = [];
  if (updatedDataSheet) {
    selectedCellData.forEach((item: ISelectedCellData) => {
      emptyCells.push({
        row: item.row,
        col: item.col,
      });
      updatedDataSheet.globals.forEach((data: IGlobalData, key: number) => {
        if (item.row === key) {
          updatedIds.push(data.id.toString());
          data[item.colHeader as keyof IGlobalData] = '';
        }
        if (item.col === GlobalColumnIndexes.code) {
          removeNamedExpressionCodes.push(dataSheet[DateSheetAttributeType.GLOBALS][item.row].code);
        }
        const code = dataSheet[DateSheetAttributeType.GLOBALS][item.row].code;
        if (item.col === GlobalColumnIndexes.units && !removeNamedExpressionCodes.includes(code)) {
          updatedNamedExpressions.push({ code, expression: '0' });
        }
      });
    });
  }
  return {
    updatedDataSheet,
    updatedIds,
    removeNamedExpressionCodes,
    emptyCells,
    updatedNamedExpressions,
  };
}
