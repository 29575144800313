import styled from '@emotion/styled';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { convertToReadableDate } from '@/utils';
import { ViewAndUpdateNote } from '@/components/common';
import { TrashIcon } from '@/components/icon';
import { INotes } from '@/interfaces/INotes';
import { IFileResponse } from './types';

export interface NotesFileViewerProps {
  //setSelectedFile: (selectedFile: IFileResponse) => void;
  onSelectedFileAndOpen?: (selectedFile: IFileResponse) => void;
  selectedRowIndexes?: number[];
  selectedRowIndex?: number;
  notes: INotes[] | undefined;
  addNotes: (notes: string) => void;
  deleteNotes: (noteIds: string[]) => void;
  updateNote: (note: INotes) => void;
}

const StyledListItem = styled.div`
  & .MuiListItem-root {
    border-bottom: 1px solid ${({ theme }) => (theme as Theme).palette.secondary.main};
  };
  & .MuiListItemText-root {
    display: flex;
    justify-content: space-between;
  };
  & .MuiButton-textPrimary {
    color: #747474;
  };
  .MuiListItem-root {
    cursor: pointer;
    padding-left: 0px;
    max-height: 50px;
  };
  .MuiList-root::-webkit-scrollbar {
    display: none;
  };
  .MuiList-root {
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 20px;
  };

  .MuiButton-root {
    min-width: 0px;
  };
  .MuiFormHelperText-root {
    margin-right: 0px;
    text-align: right;
  };
  display: flex;
  flex-direction: column;
  height: 45vh;
`;

const StyledDialogActionTopBarIcons = styled.div`
  color: white;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFileActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5rem;
`;

const StyledContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

const StyledButton = styled.div`
  display: flex;
`;

const Separator = styled.div`
  margin-top: 0.5rem;
  border-top: 1px solid #d8d9e0;
  margin-bottom: 0rem;
`;

const NoteTextForm = styled.div`
  margin-top: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemContainer = styled.div`
  max-height: 250px;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

//the last webkit scrollbar is added to hide the scroll bar on chrome browsers
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: 460,
  },
}));

export function NotesFileViewer({
  selectedRowIndexes,
  addNotes,
  deleteNotes,
  updateNote,
  notes,
}: NotesFileViewerProps) {
  const classes = useStyles();
  const [notesCount, setNotesCount] = useState<boolean>(false);
  const theme = useTheme(); //Main theme
  const [noteBoxValue, setNoteBoxValue] = useState<string>('');
  const [checkBoxIndexes, setCheckBoxIndexes] = useState<number[]>([]);
  const [viewNote, setViewNote] = useState<boolean>(false);
  const [viewAndUpdateNote, setViewAndUpdateNote] = useState<INotes>();
  const MAXCHAREACTERLENGTH = 500;

  useEffect(() => {
    handleNoteToUpdate();
    if (notes) {
      if (notes.length > 0) {
        setNotesCount(true);
      } else {
        setNotesCount(false);
      }
    }
  }, [selectedRowIndexes, notes, checkBoxIndexes]);

  const onClickAddNotes = (note: string) => {
    if (selectedRowIndexes) {
      if (selectedRowIndexes.length > 0) {
        addNotes(note);
      }
    }
  };

  const handleDeleteNote = () => {
    const ids: string[] = [];
    if (notes) {
      notes.forEach((note, index) => {
        checkBoxIndexes.forEach((checkedBox) => {
          if (index === checkedBox) {
            ids.push(note.id);
          }
        });
      });
      deleteNotes(ids);
    }
  };

  const handleUpdateNote = (value: string) => {
    if (viewAndUpdateNote) {
      updateNote({ ...viewAndUpdateNote, note: value });
    }
  };

  const handleNoteToUpdate = () => {
    if (notes) {
      if (checkBoxIndexes.length === 1) {
        const noteToBeUpdated = notes[checkBoxIndexes[0]];
        if (noteToBeUpdated) {
          setNoteBoxValue(noteToBeUpdated.note);
        }
      } else {
        setNoteBoxValue('');
      }
    }
  };

  const handleCheckBoxChange = (index: number) => {
    if (checkBoxIndexes.includes(index)) {
      const update = checkBoxIndexes.filter((value) => value !== index);
      setCheckBoxIndexes(update);
    } else {
      setCheckBoxIndexes([...checkBoxIndexes, index]);
    }
  };

  const checkAll = () => {
    const allIndexes: Array<number> = [];

    if (notes) {
      notes.forEach((file, index) => {
        allIndexes.push(index);
      });

      if (checkBoxIndexes.length === notes.length) {
        setCheckBoxIndexes([]);
      } else {
        setCheckBoxIndexes(allIndexes);
      }
    }
  };

  const handleViewNote = (note: INotes) => {
    setViewNote(true);
    setViewAndUpdateNote(note);
  };

  const handleBackFromNote = () => {
    setViewNote(false);
  };

  const renderDataSheetList = () => {
    if (!notes) {
      return <StyledContentCenter>No results found</StyledContentCenter>;
    }
    return (
      <ListItemContainer>
        {notesCount ? (
          <>
            {notes.map((note, index) => {
              const noteName =
                note.note.length < 20 ? note.note : note.note.substring(0, 20) + '...';
              return (
                <ListItem
                  key={note.id}
                  disableGutters={true}
                  onDoubleClick={() => handleViewNote(note)}
                >
                  <ListItemButton role={undefined} dense>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      checked={checkBoxIndexes.includes(index)}
                      onChange={() => handleCheckBoxChange(index)}
                    />
                    <ListItemText
                      primary={noteName}
                      secondary={convertToReadableDate(note.lastModified)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </>
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            No Notes found
          </Typography>
        )}
      </ListItemContainer>
    );
  };
  return (
    <StyledListItem theme={theme}>
      <StyledFileActionBar>
        {viewNote && viewAndUpdateNote ? (
          <StyledTitle>
            <IconButton onClick={handleBackFromNote}>
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            <strong>Back</strong>
          </StyledTitle>
        ) : (
          <>
            <StyledTitle>
              <Checkbox
                indeterminate={
                  checkBoxIndexes.length > 0 && checkBoxIndexes.length !== notes?.length
                }
                onChange={checkAll}
                checked={checkBoxIndexes.length === notes?.length}
              />
              <strong>File Name</strong>
              <StyledDialogActionTopBarIcons></StyledDialogActionTopBarIcons>
            </StyledTitle>

            <StyledButton>
              <ButtonContainer>
                <TrashIcon style={{ color: 'white' }} />
                <Button
                  variant="text"
                  onClick={handleDeleteNote}
                  disabled={checkBoxIndexes.length < 1}
                >
                  Delete
                </Button>
              </ButtonContainer>
            </StyledButton>
          </>
        )}
      </StyledFileActionBar>
      <Separator />
      {viewNote && viewAndUpdateNote ? (
        <ViewAndUpdateNote
          note={viewAndUpdateNote}
          onClickUpdate={(update) => handleUpdateNote(update)}
        />
      ) : (
        <>
          {' '}
          <List className={classes.root}>{renderDataSheetList()}</List>
          <NoteTextForm>
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              sx={{ width: '100%' }}
              minRows={4}
              maxRows={4}
              onChange={(val) => setNoteBoxValue(val.target.value)}
              value={noteBoxValue}
              helperText={`${noteBoxValue.length} / ${MAXCHAREACTERLENGTH}`}
              InputProps={{
                inputProps: {
                  maxLength: 500,
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
              onClick={() => onClickAddNotes(noteBoxValue)}
              disabled={noteBoxValue.length < 1}
              fullWidth
            >
              Add Note
            </Button>{' '}
          </NoteTextForm>
        </>
      )}
    </StyledListItem>
  );
}

export default NotesFileViewer;
